import confirmAnimation from "../Components/confirmAnimation.json";
import Lottie from "react-lottie-player";
import { useParams } from "react-router-dom";
import { sendCandidateToEmployer } from "../backend";

const CandidateSentSuccess = () => {
  let { branchId, candidateId } = useParams();
  sendCandidateToEmployer(branchId, candidateId);

  return (
    <div
      className="page-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <div style={{ textAlign: "center", maxWidth: "650px", margin: "auto" }}>
        <Lottie
          animationData={confirmAnimation}
          loop
          play
          style={{
            width: "150px",
            height: "150px",
            margin: "100px auto 20px",
          }}
        />
        <p
          style={{
            opacity: 0.5,
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "smaller",
          }}
        >
          Thank you!
        </p>
        <h4>We sent your profile to the employer 🚀</h4>
        <br />
        <p style={{ maxWidth: "500px", margin: "auto" }}>
          If they're interested in hiring you, they will reach out to you on
          Whatsapp - usually within the next 2 days!
        </p>
      </div>
    </div>
  );
};

export default CandidateSentSuccess;
