import { useState, useEffect, useCallback } from "react";
import { Select, TextField, FieldStatus } from "./Forms";
import { getAllRoles } from "../utils";
import { debounce } from "lodash";
import {
  recordCandidateNotInterested,
  recordNotInterestedReasons,
  updateCandidate, updateInterview,
} from "../backend";

const NotInterestedInput = ({
  candidate,
  employerId,
  interview = false,
  onChange = (field, value) => {},
  compact = false,
  style = {},
}) => {
  const [selected, setSelected] = useState({});
  const [fieldsStatus, setFieldsStatus] = useState({});
  const [candidateData, setCandidateData] = useState(candidate);

  let interviewSpecificOptions =  ["Scheduling Conflicts"];

  let feedbackOptions = interview ? ([
    "Looking for Different Role",
    "Salary Too Low",
    "Location Not Ideal",
    "I Found a Job",
  ].concat(interviewSpecificOptions)) : [
    "Looking for Different Role",
    "Salary Too Low",
    "Location Not Ideal",
    "No Interviews Available",
    "I Found a Job",
  ];

  //eslint-disable-next-line
  let saveCandidate = useCallback(
    debounce(
      (args) => {
        updateCandidate(args);
        setFieldsStatus({
          ...fieldsStatus,
          [Object.keys(args.properties)[0]]: "saved",
        });
      },
      2000,
      {
        trailing: true,
        leading: false,
      }
    ),
    []
  );

  const updateValue = async (field, value) => {
    setCandidateData({
      ...candidateData,
      [field]: value,
    });
    setFieldsStatus({
      ...fieldsStatus,
      [field]: "loading",
    });
    saveCandidate({ id: candidateData["Id"], properties: { [field]: value } });
    onChange(field, value);
  };

  const markCandidateInactive = async (active = true) => {
    await updateCandidate({
      id: candidateData["Id"],
      properties: { "Open to Opportunities": active },
    });
  };

  const updateInterviewFeedback = async (label) => {
    await updateInterview({
        id: interview["Id"],
        properties: { "cancellation reason": label },
    });
 };

  useEffect(() => {
    let reasons = Object.keys(selected).filter((key) => selected[key]);
    if (reasons.length > 0) {
      recordCandidateNotInterested(candidate["Id"], employerId);
      recordNotInterestedReasons(employerId, candidate["Id"], reasons);
    }
    //eslint-disable-next-line
  }, [selected]);

  return (
    <div style={{ padding: "16px", ...style }}>
        {interview ? (<h6 style={{ marginBottom: "16px" }}>Why do you want to cancel?</h6>
        ): (<h6 style={{ marginBottom: "16px" }}>Why are you not interested?</h6>)}
      <div>
        {[...feedbackOptions.slice(0, compact ? 3 : feedbackOptions.length), "Other"].map(
          (label, i) => {
            return (
              <div
                key={label}
                style={{
                  display: "flex",
                  marginBottom: "5px",
                }}
              >
                <input
                  type="checkbox"
                  checked={selected[label] ?? false}
                  onChange={() => {
                    let s = JSON.parse(JSON.stringify(selected));
                    s[label] = !s[label];
                    setSelected(s);
                    if (label === "I Found a Job")
                      markCandidateInactive(!s[label]);
                    else if (interviewSpecificOptions.includes(label))
                      updateInterviewFeedback(label)
                  }}
                  style={{
                    margin: "0 8px 0 0",
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                    borderColor: "rgb(209, 213, 219)",
                    cursor: "pointer",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <label
                    style={{
                      lineHeight: "20px",
                      cursor: "pointer",
                      fontWeight: "normal",
                    }}
                    onClick={() => {
                      let s = JSON.parse(JSON.stringify(selected));
                      s[label] = !s[label];
                      setSelected(s);
                      if (label === "I Found a Job")
                        markCandidateInactive(!s[label]);
                    }}
                  >
                    {label}
                  </label>
                  {label === "Looking for Different Role" && selected[label] ? (
                    <div style={{ position: "relative", margin: "8px 0 16px" }}>
                      <Select
                        label="Update your preferred roles"
                        selected={
                          candidateData["What positions are you looking for?"]
                        }
                        options={getAllRoles()}
                        onChange={(v) =>
                          updateValue("What positions are you looking for?", v)
                        }
                      />
                      <FieldStatus
                        status={
                          fieldsStatus["What positions are you looking for?"]
                        }
                      />
                    </div>
                  ) : label === "Salary Too Low" && selected[label] ? (
                    <div style={{ position: "relative", margin: "8px 0 16px" }}>
                      <TextField
                        label="Update your minimum salary"
                        value={candidateData["Minimum Salary"]}
                        prefix="£"
                        suffix="/hour"
                        onChange={(v) => updateValue("Minimum Salary", v)}
                      />
                      <FieldStatus status={fieldsStatus["Minimum Salary"]} />
                    </div>
                  ) : label === "Location Not Ideal" && selected[label] ? (
                    <div className="row" style={{ margin: "8px 0 16px" }}>
                      <div style={{ position: "relative", flex: 1 }}>
                        <Select
                          label="I can commute"
                          selected={[
                            `${candidateData["Max Commute"]}${
                              JSON.stringify(candidateData["Max Commute"])
                                .length <= 3
                                ? " mins"
                                : ""
                            }`,
                          ]}
                          options={["15 mins", "30 mins", "45 mins", "1 hour"]}
                          allowMultiple={false}
                          onChange={(v) => updateValue("Max Commute", v)}
                        />
                        <FieldStatus status={fieldsStatus["Max Commute"]} />
                      </div>
                      &nbsp;&nbsp;&nbsp;
                      <div style={{ position: "relative", flex: 1 }}>
                        <TextField
                          label="From this postcode"
                          value={candidateData["Postcode"]}
                          onChange={(v) => updateValue("Postcode", v)}
                        />
                        <FieldStatus status={fieldsStatus["Postcode"]} />
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default NotInterestedInput;
