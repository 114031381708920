import {useNavigate, useParams} from "react-router-dom";
import { CvUpload } from "../Components/Forms";
import { useEffect, useState } from "react";
import { updateCandidate, uploadCandidateCV } from "../backend";
import Lottie from "react-lottie-player";
import confirmAnimation from "../Components/confirmAnimation.json";
import { Modal } from "../Components";

const UploadCV = () => {
  let { candidateId } = useParams();
  const [cvUploaded, setCvUploaded] = useState(false);
  const [cv, setCv] = useState(null);
  const [experience, setExperience] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const uploadCv = async () => {
      setCvUploaded(true);
      await updateCandidate({
        id: candidateId,
        properties: experience,
      });
      await uploadCandidateCV(candidateId, cv);
      navigate("/candidate-opportunities?id=" + candidateId);
    };
    if (cv != null) uploadCv();
  }, [cv, experience, candidateId]);

  return (
    <div
      className="flex h-screen items-center justify-center"
      style={{ backgroundColor: "#f2f0ed", height: "100vh" }}
    >
      <Modal isOpen={cvUploaded} closeModal={() => setCvUploaded(false)}>
        <div style={{ textAlign: "center" }}>
          <br />
          <br />
          <h6 style={{ marginBottom: "1rem" }}>CV Uploaded Successfully</h6>
          <div style={{ marginBottom: "3.5rem" }}>
            <p>You will be redirected to the homepage shortly.</p>
          </div>
          <div>
            <Lottie
              animationData={confirmAnimation}
              loop
              play
              style={{
                width: "150px",
                height: "150px",
                margin: "24px auto",
              }}
            />
          </div>
        </div>
      </Modal>
      <div
        style={{
          marginTop: "40vh",
          width: "50vw",
        }}
      >
        <CvUpload
          label="Upload your CV"
          value={cv}
          compulsory
          onChange={(value) => {
            if (typeof value.candidateExperience === "object") {
              setExperience(value.candidateExperience);
            }
            setCv(value.url);
          }}
        />
      </div>
    </div>
  );
};

export default UploadCV;
