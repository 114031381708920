import { useState, useEffect, useCallback } from "react";
import { Select, TextField, FieldStatus } from "./Forms";
import {getAllRoles, getEmployerSecret, getRoleFromId} from "../utils";
import { debounce } from "lodash";
import {
  recordCandidateNotInterested,
  recordNotInterestedReasons, rejectCandidate,
  updateCandidate, updateEmployer, updateInterview,
} from "../backend";
import {useNavigate} from "react-router-dom";

const RejectedInput = ({
  candidate,
  employerId,
  employer,
  roleId,
  interview = false,
  onChange = (field, value) => {},
  compact = false,
  style = {},
}) => {
  const navigate = useNavigate()
  const [selected, setSelected] = useState({});
  const [fieldsStatus, setFieldsStatus] = useState({});
  const [role, setRole] = useState(getRoleFromId(employer, roleId))
  const [employerData, setEmployerData] = useState(employer);

  let feedbackOptions = [
    "Not hiring anymore",
    "Not Enough Experience",
    "Experience is Not Relevant",
  ];

  const markEmployerNotHiring = async (active = true) => {
    navigate(
      `/${
        employer.employerId
      }/${getEmployerSecret(
        employer.employerId
      )}?pause_branch=${employerId}`
    )
  };

  const saveEmployer = async () => {
    // loop over open roles
    for (let i = 0; i < employer.openPositions.length; i++) {
      // if role matches, update
      if (employer.openPositions[i].role == role.role) {
        employer.openPositions[i] = role;
        break;
      }
    }
    updateEmployer(employer)
  }

  useEffect(() => {
    let reasons = Object.keys(selected).filter((key) => selected[key]);
    console.log("reasons",reasons)
    if (reasons.length > 0) {
      saveEmployer();
    }
    //eslint-disable-next-line
  }, [role]);

  useEffect(() => {
    let reasons = Object.keys(selected).filter((key) => selected[key]);
    console.log("reasons",reasons)
    if (reasons.length > 0) {
      saveEmployer()
      rejectCandidate(employerId,candidate["Id"],roleId)
      recordNotInterestedReasons(employerId, candidate["Id"], reasons);
    }
    //eslint-disable-next-line
  }, [selected]);

  return (
    <div style={{ padding: "16px", ...style }}>
      <h6 style={{ marginBottom: "16px" }}>Why are you not interested in this candidate?</h6>
      <div>
        {[...feedbackOptions.slice(0, compact ? 3 : feedbackOptions.length), "Other"].map(
          (label, i) => {
            return (
              <div
                key={label}
                style={{
                  display: "flex",
                  marginBottom: "5px",
                }}
              >
                <input
                  type="checkbox"
                  checked={selected[label] ?? false}
                  onChange={() => {
                    let s = JSON.parse(JSON.stringify(selected));
                    s[label] = !s[label];
                    setSelected(s);
                    if (label === "Not hiring anymore")
                      markEmployerNotHiring(!s[label]);
                  }}
                  style={{
                    margin: "0 8px 0 0",
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                    borderColor: "rgb(209, 213, 219)",
                    cursor: "pointer",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <label
                    style={{
                      lineHeight: "20px",
                      cursor: "pointer",
                      fontWeight: "normal",
                    }}
                    onClick={() => {
                      let s = JSON.parse(JSON.stringify(selected));
                      s[label] = !s[label];
                      setSelected(s);
                      if (label === "Not hiring anymore")
                        markEmployerNotHiring(!s[label]);
                    }}
                  >
                    {label}
                  </label>
                  {label === "Not Enough Experience" && selected[label] ? (
                    <div style={{ position: "relative", margin: "8px 0 16px" }}>
                      <TextField
                        label="Update the experience required for the role"
                        value={role.monthsOfExperience}
                        onChange={(v) =>{
                          role.monthsOfExperience = v
                          setRole({
                            ...role,
                            "monthsOfExperience": v,
                          })
                        }
                        }
                      />
                      <FieldStatus
                        status={
                          fieldsStatus["Update the experience required for the role"]
                        }
                      />
                    </div>
                    ): label === "Experience is Not Relevant" && selected[label] && (
                    <div style={{ position: "relative", margin: "8px 0 16px" }}>
                      <Select
                        label="Update your preferred experience"
                        selected={[role.typeOfExperience]}
                        allowSearch={false}
                        options={["Overall",role.typeOfExperience]}
                        onChange={(v) => {
                          setRole({
                            ...role,
                            "typeOfExperience": v[1],
                          })
                        }
                        }
                      />
                      <FieldStatus
                        status={
                          fieldsStatus["typeOfExperience"]
                        }
                      />
                    </div>
                  ) }
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default RejectedInput;
