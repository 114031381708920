import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import ReactTooltip from "react-tooltip";
import { Select } from "../Components/Forms";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import { LinkGenerator } from "../Components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  getAllBranches,
  getInteractionData,
  getCandidateStats,
  getWeelyInteractionStats,
  getBranch,
  getSupplyBlockers,
} from "../backend";
import { isMobile } from "react-device-detect";
import moment from "moment-timezone";
import { percentToColor } from "../utils";

const supplyBlockerTooltips = {
  "Job Type":
    "How many candidates are looking for this job-type (Full-time, Part-time)?",
  Salary: "How many candidates are willing to work at this salary?",
  Location: "How many candidates are willing to commute to this location?",
  Experience: "How many candidates are there with the experience required?",
  Role: "How many candidates are looking for this role?",
  "Shown to Candidates":
    "How many candidates have been shown this opportunity? (Relative score)",
  "Picked by Candidates":
    "How many candidates have said Yes to this opportunity? (Relative score)",
};

const whereComingFrom = (candidates) => {
  const total = candidates.length;
  const fields = {
    Indeed: 0,
    Reed: 0,
    "Google Ads": 0,
    "Google Ads BoH": 0,
    "From Friends/Family": 0,
  };
  candidates.forEach((c) => {
    fields[c["Source"]]++;
  });
  return Object.entries(fields)
    .map((e) => {
      return { name: e[0], value: Math.round((e[1] * 100) / total) || 0 };
    })
    .filter((e) => e.value > 0);
};

export const formatWeeklyInteractions = (weeklyData, fields, fieldNames) => {
  return weeklyData.map((data) => {
    let obj = { name: data.name };
    fields.forEach((field, i) => {
      obj[fieldNames[i]] = data[field];
    });
    return obj;
  });
};

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [employers, setEmployers] = useState([]);
  const [candidateStats, setCandidateStats] = useState();
  const [linkGeneratorOpen, setLinkGeneratorOpen] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState({
    name: `All Branches`,
    id: "all",
  });
  const [interactionData, setInteractionData] = useState({
    sentTimes: "",
    contactedTimes: "",
    openedTimes: "",
    scheduledTimes: "",
  });
  const [interactionDataStart, setInteractionDataStart] = useState(
    moment("20230209", "YYYYMMDD").toDate()
  );
  const [supplyBlockers, setSupplyBlockers] = useState(null);
  const [weeklyInteractions, setWeeklyInteractions] = useState();
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout, error } =
    useAuth0();

  if (error) {
    logout({ returnTo: window.location.origin });
  }

  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect();
    //eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    const getData = async () => {
      console.time("cs");
      console.time("ab");
      console.time("id");
      let [cs, ab, id] = await Promise.all([
        getCandidateStats().then((res) => {
          console.timeEnd("cs");
          return res;
        }),
        getAllBranches().then((res) => {
          console.timeEnd("ab");
          return res;
        }),
        getInteractionData(selectedEmployer.id).then((res) => {
          console.timeEnd("id");
          return res;
        }),
      ]);
      setEmployers(
        [{ name: `All Branches (${ab.length})`, id: "all" }].concat(ab)
      );
      setSelectedEmployer({
        name: `All Branches (${ab.length})`,
        id: "all",
      });
      setCandidateStats(cs);
      setInteractionData(id);
      getWeelyInteractionStats().then((res) => setWeeklyInteractions(res));
      let sb = await getSupplyBlockers(selectedEmployer.id);
      setSupplyBlockers(sb);
    };
    if (!selectedEmployer.name.startsWith("All Branches (")) getData();
    //eslint-disable-next-line
  }, []);

  const updateInteractionData = async (employerId, date) => {
    if (employerId === "all") {
      let data = await getInteractionData(employerId, null, date);
      setInteractionData(data);
    } else {
      let [data, employer] = await Promise.all([
        getInteractionData(employerId, null, date),
        getBranch(employerId),
      ]);
      setInteractionData(data);
      setSelectedEmployer(employer);
    }
  };

  const fetchSupplyBlockers = async (employerId) => {
    setSupplyBlockers(null);
    let sb = await getSupplyBlockers(employerId);
    setSupplyBlockers(sb);
  };

  const getCandidateEngagement = () => {
    let opportunitiesShown = 0;
    let opportunitiesInterested = 0;
    candidateStats.forEach((candidate) => {
      if (candidate["Interesting Employers"])
        opportunitiesShown += Object.keys(
          candidate["Interesting Employers"]
        ).length;
      if (candidate["Interesting Employers"])
        opportunitiesInterested += Object.keys(
          candidate["Interesting Employers"]
        ).filter((key) => candidate["Interesting Employers"][key]).length;
    });
    return Math.round((100 * opportunitiesInterested) / opportunitiesShown);
  };

  return (
    <div>
      {isLoading || interactionData.sentTimes === "" ? (
        <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              margin: "auto",
            }}
          >
            <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
          </div>
        </div>
      ) : linkGeneratorOpen ? (
        <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
          <LinkGenerator
            branches={employers.slice(1)}
            close={() => setLinkGeneratorOpen(false)}
          />
        </div>
      ) : (
        isAuthenticated && (
          <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
            <div
              style={{
                maxWidth: "900px",
                margin: "auto",
                height: "fit-content",
              }}
            >
              <div
                className="row"
                style={{ width: "100%", display: "inline-block" }}
              >
                <div style={{ display: "inline-block" }}>
                  <h2>Hirebolt Admin</h2>
                  <p>
                    <span style={{ lineHeight: "10px" }}>{user.email}</span>{" "}
                    <span
                      style={{
                        display: "inline-block",
                        position: "relative",
                        top: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="icon-small text-color-bluemine"
                        style={{ fontSize: "20px" }}
                        onClick={() =>
                          logout({ returnTo: window.location.origin })
                        }
                      >
                        logout
                      </div>
                    </span>
                  </p>
                </div>
              </div>
              <div className="column">
                <div
                  style={{
                    background: "#efefef",
                    borderRadius: "5px",
                    padding: "25px",
                    marginTop: "25px",
                  }}
                  className="column"
                >
                  <h6>Interview & Employer Stats</h6>
                  <div
                    className="row-all-devices"
                    style={{ marginBottom: "16px" }}
                  >
                    <p
                      style={{
                        fontSize: "small",
                        whiteSpace: "nowrap",
                        lineHeight: "25px",
                      }}
                    >
                      Starting from
                    </p>
                    <DatePicker
                      selected={interactionDataStart}
                      onChange={(date) => {
                        setInteractionDataStart(date);
                        updateInteractionData(selectedEmployer.id, date);
                      }}
                    />
                  </div>
                  <Select
                    selected={selectedEmployer.name}
                    allowMultiple={false}
                    onChange={(value) => {
                      let employer = employers.find((e) => e.name === value);
                      setSelectedEmployer(employer);
                      updateInteractionData(employer.id, interactionDataStart);
                      fetchSupplyBlockers(employer.id);
                    }}
                    options={employers.map((e) => e.name)}
                  />
                  {Object.keys(selectedEmployer).includes("address") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        fontWeight: "bold",
                        fontSize: "x-small",
                        margin: "16px 0 0",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "rgba(0,0,0,0.1)",
                          color: "rgba(0,0,0,0.66)",
                          borderRadius: "4px",
                          padding: "0 8px",
                        }}
                      >
                        {[undefined, true].includes(selectedEmployer.active) &&
                        (!selectedEmployer.pausedUntil ||
                          moment(selectedEmployer.pausedUntil).isBefore(
                            moment()
                          ))
                          ? "Active"
                          : "Paused"}
                      </div>
                      <div
                        style={{
                          backgroundColor: "rgba(0,0,0,0.1)",
                          color: "rgba(0,0,0,0.66)",
                          borderRadius: "4px",
                          padding: "0 8px",
                        }}
                      >
                        {selectedEmployer.optOut ? "On Autopilot" : "Opt-in"}
                      </div>
                    </div>
                  )}
                  <p style={{ margin: "16px 0 8px" }}>
                    <b>Supply</b>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    {interactionData && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                          marginBottom: "8px",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <div
                            data-tip="On opt-in: all candidates who book a slot. On opt-out: all candidates that confirm 'Can Attend'"
                            data-for="employer-received"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.1)",
                              flex: 1,
                              padding: "4px",
                              borderRadius: "4px",
                              cursor: "help",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                margin: "auto",
                                width: "fit-content",
                              }}
                            >
                              {interactionData.sentTimes}
                            </div>
                          </div>
                          <ReactTooltip
                            effect="solid"
                            id="employer-received"
                            place="top"
                            delayShow={300}
                          />
                          <p
                            style={{
                              fontSize: "10px",
                              textTransform: "uppercase",
                              textAlign: "center",
                              lineHeight: "0.7rem",
                              marginTop: "4px",
                            }}
                          >
                            Candidates Sent
                          </p>
                        </div>
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <div
                            data-tip="Number of candidates called by the employer"
                            data-for="employer-called"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.1)",
                              flex: 1,
                              padding: "4px",
                              borderRadius: "4px",
                              cursor: "help",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                margin: "auto",
                                width: "fit-content",
                              }}
                            >
                              {interactionData.contactedTimes +
                                interactionData.calledTimes}
                            </div>
                          </div>
                          <ReactTooltip
                            effect="solid"
                            id="employer-called"
                            place="top"
                            delayShow={300}
                          />
                          <p
                            style={{
                              fontSize: "10px",
                              textTransform: "uppercase",
                              textAlign: "center",
                              lineHeight: "0.7rem",
                              marginTop: "4px",
                            }}
                          >
                            Candidates Called
                          </p>
                        </div>
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <div
                            data-tip="On opt-in: CVs the employer clicks 'interested' on. On opt-out: this is the same as CVs sent"
                            data-for="employer-engagement"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.1)",
                              flex: 1,
                              padding: "4px",
                              borderRadius: "4px",
                              cursor: "help",
                            }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                margin: "auto",
                                width: "fit-content",
                              }}
                            >
                              {interactionData.scheduledTimes}
                            </div>
                          </div>
                          <ReactTooltip
                            effect="solid"
                            id="employer-engagement"
                            place="top"
                            delayShow={300}
                          />
                          <p
                            style={{
                              fontSize: "10px",
                              textTransform: "uppercase",
                              textAlign: "center",
                              lineHeight: "0.7rem",
                              marginTop: "4px",
                            }}
                          >
                            Interviews Scheduled
                          </p>
                        </div>
                      </div>
                    )}
                    {[undefined, true].includes(selectedEmployer.active) &&
                      supplyBlockers && (
                        <div>
                          {Object.keys(supplyBlockers).map((position) => (
                            <div>
                              <p
                                style={{
                                  fontSize: "smaller",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {position.length > 30
                                  ? position.substring(0, 30) + "..."
                                  : position}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "4px",
                                  marginBottom: "8px",
                                }}
                              >
                                {supplyBlockers[position].map((entry, i) => (
                                  <div
                                    key={"supply-blocker" + i}
                                    style={{
                                      flex: 1,
                                    }}
                                  >
                                    <div
                                      data-tip={
                                        supplyBlockerTooltips[
                                          Object.keys(entry)[0]
                                        ]
                                      }
                                      data-for={"supply-blocker" + i}
                                      style={{
                                        backgroundColor: percentToColor(
                                          Object.values(entry)[0]
                                        ),
                                        flex: 1,
                                        padding: "4px",
                                        borderRadius: "4px",
                                        cursor: "help",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          margin: "auto",
                                          width: "fit-content",
                                        }}
                                      >
                                        {Object.values(entry)[0]}
                                      </div>
                                    </div>
                                    <ReactTooltip
                                      effect="solid"
                                      id={"supply-blocker" + i}
                                      place="top"
                                      delayShow={300}
                                    />
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        textTransform: "uppercase",
                                        textAlign: "center",
                                        lineHeight: "0.7rem",
                                        marginTop: "4px",
                                      }}
                                    >
                                      {Object.keys(entry)[0]}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                  <div>
                    {weeklyInteractions ? (
                      <LineChart
                        width={Math.min(800, window.innerWidth - 50)}
                        height={isMobile ? 150 : 300}
                        data={formatWeeklyInteractions(
                          weeklyInteractions,
                          [
                            "showUpRate",
                            "cancellationRate",
                            "employerEngagement",
                            "hireRate",
                          ],
                          [
                            "Show Up Rate (%)",
                            "Cancellation Rate (%)",
                            "Employer Engagement (%)",
                            "Hired Rate (%)",
                          ]
                        )}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        style={{ marginLeft: "-35px", fontSize: "12px" }}
                      >
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, 100]} />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="Show Up Rate (%)"
                          stroke="#ff7300"
                          yAxisId={0}
                        />
                        <Line
                          type="monotone"
                          dataKey="Cancellation Rate (%)"
                          stroke="#466d1d"
                          yAxisId={0}
                        />
                        <Line
                          type="monotone"
                          dataKey="Employer Engagement (%)"
                          stroke="#006ee6"
                          yAxisId={0}
                        />
                        <Line
                          type="monotone"
                          dataKey="Hired Rate (%)"
                          stroke="#d4af37"
                          yAxisId={0}
                        />
                      </LineChart>
                    ) : (
                      <div
                        style={{
                          backgroundColor: "rgba(0,0,0,0.05)",
                          borderRadius: "4px",
                          width: "375px",
                          height: "150px",
                          display: "flex",
                        }}
                      >
                        <p style={{ margin: "auto" }}>Loading...</p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    background: "#efefef",
                    borderRadius: "5px",
                    padding: "25px",
                    margin: "25px 0",
                    minWidth: isMobile ? "100%" : "350px",
                  }}
                  className="column"
                >
                  <h6>Candidate Stats</h6>
                  {candidateStats ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <div>
                        <p style={{ fontSize: "small" }}>
                          Based on latest {candidateStats.length} quality checks
                        </p>
                        <div
                          style={{ marginTop: "32px" }}
                          data-tip="Out of all the opportunities we show to candidates, how many are they interested in?"
                          data-for="candidate-engagement"
                        >
                          <p>
                            <b>
                              Candidate Engagement {"="}{" "}
                              {getCandidateEngagement()}%
                            </b>
                          </p>
                        </div>
                        <ReactTooltip
                          effect="solid"
                          id="candidate-engagement"
                          place="top"
                          delayShow={300}
                        />
                        <p style={{ marginTop: "16px" }}>
                          <b>Candidate Sources</b>
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                            margin: "8px 0",
                          }}
                        >
                          {candidateStats &&
                            whereComingFrom(candidateStats)
                              .sort((a, b) => b.value - a.value)
                              .map((res, i) => (
                                <div
                                  key={"candidate-source" + i}
                                  style={{
                                    flex: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: `rgba(0,0,0,${
                                        0.1 - i / 30
                                      })`,
                                      flex: 1,
                                      padding: "4px",
                                      borderRadius: "4px",
                                      cursor: "help",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        margin: "auto",
                                        width: "fit-content",
                                      }}
                                    >
                                      {res.value}%
                                    </div>
                                  </div>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      textTransform: "uppercase",
                                      textAlign: "center",
                                      lineHeight: "0.7rem",
                                      marginTop: "4px",
                                    }}
                                  >
                                    {res.name}
                                  </p>
                                </div>
                              ))}
                        </div>
                        <p style={{ margin: "16px 0 8px" }}>
                          <b>Candidate Supply</b>
                        </p>
                      </div>
                      {weeklyInteractions ? (
                        <LineChart
                          width={Math.min(800, window.innerWidth - 50)}
                          height={isMobile ? 150 : 300}
                          data={formatWeeklyInteractions(
                            weeklyInteractions,
                            [
                              "candidateSupply",
                              "candidatesSent",
                              "interviewsHeld",
                            ],
                            [
                              "Candidate Supply",
                              "CVs Sent to Employers",
                              "Interviews Held",
                            ]
                          )}
                          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                          style={{ marginLeft: "-35px", fontSize: "12px" }}
                        >
                          <XAxis dataKey="name" />
                          <YAxis domain={[0, 100]} />
                          <Tooltip />
                          <Line
                            type="monotone"
                            dataKey="Candidate Supply"
                            stroke="#006ee6"
                            yAxisId={0}
                          />{" "}
                          <Line
                            type="monotone"
                            dataKey="CVs Sent to Employers"
                            stroke="#ff7300"
                            yAxisId={0}
                          />
                          <Line
                            type="monotone"
                            dataKey="Interviews Held"
                            stroke="#466d1d"
                            yAxisId={0}
                          />
                        </LineChart>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "rgba(0,0,0,0.05)",
                            borderRadius: "4px",
                            width: "375px",
                            height: "150px",
                            display: "flex",
                          }}
                        >
                          <p style={{ margin: "auto" }}>Loading...</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "200px",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          margin: "auto",
                        }}
                      >
                        <MoonLoader
                          color={"rgb(22, 46, 60)"}
                          loading={true}
                          size={50}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: "inline", float: "right" }}>
                <button
                  onClick={() => setLinkGeneratorOpen(true)}
                  style={{
                    fontWeight: "bold",
                    padding: "15px 25px",
                    backgroundColor: "#efefef",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  Generate Link
                </button>
                <button
                  onClick={() =>
                    window.open(
                      window.location.href + "/sales-tracker",
                      "_blank"
                    )
                  }
                  style={{
                    fontWeight: "bold",
                    padding: "15px 25px",
                    backgroundColor: "#efefef",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  Sales Tracker
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://hiring-mvp.herokuapp.com/employer/follow-ups",
                      "_blank"
                    )
                  }
                  style={{
                    fontWeight: "bold",
                    padding: "15px 25px",
                    backgroundColor: "#efefef",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  Follow Ups
                </button>
                <button
                  onClick={() => navigate("/admin/onboard-employer")}
                  style={{
                    fontWeight: "bold",
                    padding: "15px 25px",
                    backgroundColor: "#162e3c",
                    color: "#efefef",
                    borderRadius: "5px",
                    marginRight: "10px",
                  }}
                >
                  + New Employer
                </button>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AdminDashboard;
