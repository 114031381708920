/* eslint-disable import/no-webpack-loader-syntax */
import { useState } from "react";
import Map, { Marker, Popup } from "react-map-gl";
import MAP_STYLE from "./mapStyle.json";
import { isMobile } from "react-device-detect";
import "mapbox-gl/dist/mapbox-gl.css";
import { Slide } from "react-slideshow-image";
import mapboxgl from "mapbox-gl";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const TOKEN =
  "pk.eyJ1IjoibG9yZW5zem9zYW5pIiwiYSI6ImNsanlmZmFsNjAzejAzcWxseGx0aTI5bG4ifQ.IDtNSxun7iibKJWxhKK-1w";

const EmployersMap = ({ candidate, employers, showEmployer }) => {
  const [popupInfo, setPopupInfo] = useState(null);

  const getSalaryFormatted = (candidate, employer) => {
    let role = employer.position;
    return role.minSalary && role.minSalary >= 10
      ? role.minSalary + "-" + role.maxSalary + "/hr"
      : role.maxSalary
      ? [role.maxSalary + "/hr"]
      : ["-"];
  };

  const centerMap = (employers) => {
    let lat = 0;
    let long = 0;
    employers.forEach((employer) => {
      lat += employer.latitude;
      long += employer.longitude;
    });
    return {
      latitude: lat / employers.length ?? 0,
      longitude: long / employers.length ?? 0,
    };
  };

  return (
    <Map
      initialViewState={{
        longitude: centerMap(employers).longitude,
        latitude: centerMap(employers).latitude,
        zoom: 12,
        bearing: 0,
        pitch: 0,
      }}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={TOKEN}
      style={{
        width: isMobile ? "90vw" : "80vw",
        height: "80vh",
      }}
    >
      {employers.map((employer, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={employer.longitude ?? 0}
          latitude={employer.latitude ?? 0}
          anchor="center"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo(employer);
          }}
          style={{ cursor: "pointer", height: 20 }}
        >
          <div
            className="employer-map-marker"
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              width: "28px",
              height: "28px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              position: "relative",
              zIndex: 100000,
            }}
          >
            <span className="icon-small" style={{ fontSize: "16px" }}>
              work
            </span>
          </div>
        </Marker>
      ))}
      {popupInfo && (
        <Popup
          longitude={popupInfo.longitude ?? 0}
          latitude={popupInfo.latitude ?? 0}
          onClose={() => setPopupInfo(null)}
        >
          <div>
            {popupInfo?.pictures?.[0] ? (
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                  zIndex: "2000",
                  display: "block",
                }}
              >
                <Slide
                  autoplay={false}
                  transitionDuration={300}
                  onClick={(e) => {
                    e.originalEvent.stopPropagation();
                  }}
                >
                  {popupInfo.pictures.map((slideImage, index) => (
                    <div key={index}>
                      <div
                        style={{
                          height: "150px",
                          alignItems: "center",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundImage: `url(${slideImage})`,
                          borderTopLeftRadius: "16px",
                          borderTopRightRadius: "16px",
                        }}
                      ></div>
                    </div>
                  ))}
                </Slide>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                  zIndex: "2000",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage:
                    "url(/images/pexels-rodnae-productions-4921154-p-1600.jpg)",
                }}
              ></div>
            )}
            <div
              style={{ padding: "16px", cursor: "pointer" }}
              onClick={() => {
                showEmployer(popupInfo.id);
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  lineHeight: 1.1,
                  justifyContent: "space-between",
                }}
              >
                <h6>{popupInfo.position.name}</h6>
                <h6
                  style={{
                    marginTop: "4px",
                    fontFamily: "Spacegrotesk, sans-serif",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#162e3c",
                    whiteSpace: "nowrap",
                  }}
                >
                  <span
                    className="icon-small"
                    style={{
                      display: "inline",
                      fontSize: "14px",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    currency_pound
                  </span>
                  {getSalaryFormatted(candidate, popupInfo)}
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontFamily: "Spacegrotesk, sans-serif",
                    fontSize: "12px",
                    color: "#666",
                    margin: "0px",
                    marginBottom: "8px",
                  }}
                >
                  {popupInfo.name}
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    opacity: "0.55",
                    whiteSpace: "nowrap",
                    paddingLeft: "8px",
                  }}
                >
                  MORE INFO {">"}
                </p>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </Map>
  );
};

export default EmployersMap;
