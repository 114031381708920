import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Select } from "../Components/Forms";
import {
  getAuthEmployerInfo,
  updateEmployer,
  sendReschedulingRequest,
} from "../backend";
import {
  formatAvailability,
  formatAvailabilityToInterviewSlots,
} from "../utils";
import { useParams } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import ReactTooltip from "react-tooltip";
import confirmAnimation from "../Components/confirmAnimation.json";
import Lottie from "react-lottie-player";

const EmployerSlots = () => {
  let { employerId, branchId, candidateId, employerSecret } = useParams();
  const [employer, setEmployer] = useState();
  const [branch, setBranch] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [roleSpecificInterviewSlots, setRoleSpecificInterviewSlots] =
    useState(false);
  const [interviewSlots, setInterviewSlots] = useState();

  useEffect(() => {
    const fetchSlots = async () => {
      let emp = await getAuthEmployerInfo(employerId, employerSecret);
      let branch = emp.branches.find((b) => b.id === branchId);
      let slots = branch.openPositions[0]?.roleInterviewSlots;
      if (slots) {
        setRoleSpecificInterviewSlots(true);
        slots = [];
        for (const i in branch.openPositions) {
          let interviewSlots = formatAvailabilityToInterviewSlots(
            branch.openPositions[i].availability,
            branch.openPositions[i].roleInterviewSlots[0].duration
          );
          slots.push(interviewSlots);
        }
        setInterviewSlots(slots);
      } else {
        setInterviewSlots(
          emp.branches.find((b) => b.id === branchId)?.interviewSlots
        );
      }
      setEmployer(emp);
      setBranch(branch);
    };
    fetchSlots();
    //eslint-disable-next-line
  }, []);

  const confirmSlots = () => {
    let emp = employer;
    let index = emp.branches.findIndex((b) => b.id === branchId);
    if (!roleSpecificInterviewSlots) {
      emp.branches[index].interviewSlots = interviewSlots;
      emp.branches[index].availability = formatAvailability(interviewSlots);
    } else {
      for (const i in emp.branches[index].openPositions) {
        emp.branches[index].openPositions[i].roleInterviewSlots =
          interviewSlots[i];
        emp.branches[index].openPositions[i].availability = formatAvailability(
          interviewSlots[i]
        );
      }
    }
    updateEmployer(emp);
    sendReschedulingRequest(candidateId, branchId);
    setConfirmed(true);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "#f2f0ed",
        display: "flex",
      }}
    >
      {interviewSlots ? (
        <div
          style={{
            maxWidth: "750px",
            height: "fit-content",
            margin: "auto",
            padding: "48px 24px",
          }}
        >
          {!confirmed ? (
            <div>
              <h3>Update your interview/trial availability</h3>
              <p style={{ marginTop: "8px" }}>
                This is your recurring availability for interviews/trials on a
                weekly basis, shown to all candidates. The candidate will select
                a slot among the ones you state.
                <span
                  className="icon-large"
                  data-tip={
                    "The more availability is given, the more likely the candidate is to move forward!"
                  }
                  data-for="textfield-tooltip"
                  style={{
                    display: "inline",
                    fontSize: "17px",
                    lineHeight: "17px",
                    height: "17px",
                    width: "17px",
                    position: "relative",
                    top: "4px",
                    left: "4px",
                    opacity: ".5",
                    cursor: "pointer",
                  }}
                >
                  help
                </span>
                <ReactTooltip
                  effect="solid"
                  id="textfield-tooltip"
                  place="bottom"
                />
              </p>

              <div style={{ marginTop: "32px" }}>
                {!roleSpecificInterviewSlots ? (
                  <div>
                    {interviewSlots.map((slot, s) => {
                      return (
                        <div>
                          <div
                            className="row"
                            style={{ width: "100%", height: "fit-content" }}
                            key={"slot" + s}
                          >
                            <div
                              className="column"
                              style={{
                                marginRight: "15px",
                                height: "fit-content",
                              }}
                            >
                              <label className="main-label">1. Day</label>
                              <Select
                                selected={slot.days}
                                onChange={(e) => {
                                  let slots = JSON.parse(
                                    JSON.stringify(interviewSlots)
                                  );
                                  slots[s].days = e;
                                  setInterviewSlots(slots);
                                }}
                                options={moment.weekdays()}
                              ></Select>
                            </div>
                            <div
                              className="column"
                              style={{ marginRight: "15px" }}
                            >
                              <label className="main-label">
                                2. Start Time
                              </label>
                              <Select
                                selected={slot.times}
                                onChange={(e) => {
                                  let slots = JSON.parse(
                                    JSON.stringify(interviewSlots)
                                  );
                                  slots[s].times = e;
                                  setInterviewSlots(slots);
                                }}
                                options={[
                                  "8:00",
                                  "8:30",
                                  "9:00",
                                  "9:30",
                                  "10:00",
                                  "10:30",
                                  "11:00",
                                  "11:30",
                                  "12:00",
                                  "12:30",
                                  "13:00",
                                  "13:30",
                                  "14:00",
                                  "14:30",
                                  "15:00",
                                  "15:30",
                                  "16:00",
                                  "16:30",
                                  "17:00",
                                  "17:30",
                                  "18:00",
                                  "18:30",
                                  "19:00",
                                  "19:30",
                                  "20:00",
                                  "20:30",
                                  "21:00",
                                ]}
                              ></Select>
                            </div>
                            <div className="column">
                              <label className="main-label">
                                3. Duration (minutes)
                              </label>
                              <Select
                                allowMultiple={false}
                                style={{ flex: 3 }}
                                selected={slot.duration.toString()}
                                options={[15, 30, 60, 90, 120, 180, 240, 300]}
                                onChange={(e) => {
                                  let slots = JSON.parse(
                                    JSON.stringify(interviewSlots)
                                  );
                                  slots[s].duration = e;
                                  setInterviewSlots(slots);
                                }}
                              />
                            </div>
                          </div>
                          {s !== interviewSlots.length - 1 && (
                            <hr style={{ margin: "16px 0" }} />
                          )}
                        </div>
                      );
                    })}
                    <div style={{ marginTop: "16px" }}>
                      <button
                        className="button"
                        style={{
                          fontSize: "smaller",
                          backgroundColor: "rgba(22, 46, 60, 0.1)",
                          color: "#162e3c",
                          padding: "4px 16px",
                          border: "none",
                          borderBottom: "0px none",
                        }}
                        onClick={() =>
                          setInterviewSlots([
                            ...interviewSlots,
                            { days: [], times: [], duration: "" },
                          ])
                        }
                      >
                        Add another slot
                      </button>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <button
                        className="button w-button"
                        style={{
                          width: "100%",
                          padding: "8px",
                        }}
                        onClick={() => confirmSlots()}
                      >
                        Confirm
                        <span
                          className="icon-small"
                          style={{
                            display: "inline-block",
                            fontSize: "20px",
                            lineHeight: "20px",
                            height: "20px",
                            width: "20px",
                            position: "relative",
                            top: "4px",
                            left: "4px",
                            color: "#f2f0ed",
                          }}
                        >
                          check
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    {interviewSlots.map((slots, i) => (
                      <div>
                        <h3>
                          Interview slots for {branch.openPositions[i].role[0]}{" "}
                          role
                        </h3>
                        {slots.map((slot, s) => (
                          <div>
                            <div
                              className="row"
                              style={{
                                width: "100%",
                                height: "fit-content",
                                marginTop: "24px",
                              }}
                              key={"slot" + s}
                            >
                              <div
                                className="column"
                                style={{
                                  marginRight: "15px",
                                  height: "fit-content",
                                }}
                              >
                                <Select
                                  label="1. Day"
                                  selected={slot.days}
                                  onChange={(e) => {
                                    let slots = JSON.parse(
                                      JSON.stringify(interviewSlots)
                                    );
                                    slots[i][s].days = e;
                                    setInterviewSlots(slots);
                                  }}
                                  options={moment.weekdays()}
                                ></Select>
                              </div>
                              <div
                                className="column"
                                style={{ marginRight: "15px" }}
                              >
                                <Select
                                  label="2. Start Time"
                                  selected={slot.times}
                                  onChange={(e) => {
                                    let slots = JSON.parse(
                                      JSON.stringify(interviewSlots)
                                    );
                                    slots[i][s].times = e;
                                    setInterviewSlots(slots);
                                  }}
                                  options={[
                                    "8:00",
                                    "8:30",
                                    "9:00",
                                    "9:30",
                                    "10:00",
                                    "10:30",
                                    "11:00",
                                    "11:30",
                                    "12:00",
                                    "12:30",
                                    "13:00",
                                    "13:30",
                                    "14:00",
                                    "14:30",
                                    "15:00",
                                    "15:30",
                                    "16:00",
                                    "16:30",
                                    "17:00",
                                    "17:30",
                                    "18:00",
                                    "18:30",
                                    "19:00",
                                    "19:30",
                                    "20:00",
                                    "20:30",
                                    "21:00",
                                  ]}
                                ></Select>
                              </div>
                              <div className="column">
                                <Select
                                  label="3. Duration"
                                  allowMultiple={false}
                                  style={{ flex: 3 }}
                                  selected={slot.duration.toString() + " mins"}
                                  options={[
                                    "15 mins",
                                    "30 mins",
                                    "60 mins",
                                    "90 mins",
                                    "120 mins",
                                    "180 mins",
                                    "240 mins",
                                    "300 mins",
                                  ]}
                                  onChange={(e) => {
                                    let slots = JSON.parse(
                                      JSON.stringify(interviewSlots)
                                    );
                                    slots[i][s].duration = parseInt(
                                      e.split(" ")[0]
                                    );
                                    setInterviewSlots(slots);
                                  }}
                                />
                              </div>
                            </div>
                            {s !== slots.length - 1 && (
                              <hr style={{ margin: "16px 0" }} />
                            )}
                          </div>
                        ))}
                        <div style={{ marginTop: "24px" }}>
                          <button
                            className="button"
                            style={{
                              fontSize: "smaller",
                              backgroundColor: "rgba(22, 46, 60, 0.1)",
                              color: "#162e3c",
                              padding: "4px 16px",
                              border: "none",
                              borderBottom: "0px none",
                              marginBottom: "10px",
                            }}
                            onClick={() => {
                              let slots = JSON.parse(
                                JSON.stringify(interviewSlots)
                              );
                              slots[i].push({
                                days: [],
                                times: [],
                                duration: "",
                              });
                              setInterviewSlots(slots);
                            }}
                          >
                            Add another slot
                          </button>
                        </div>
                      </div>
                    ))}
                    <div style={{ marginTop: "16px" }}>
                      <button
                        className="button w-button"
                        style={{
                          width: "100%",
                          padding: "8px",
                        }}
                        onClick={() => confirmSlots()}
                      >
                        Confirm
                        <span
                          className="icon-small"
                          style={{
                            display: "inline-block",
                            fontSize: "20px",
                            lineHeight: "20px",
                            height: "20px",
                            width: "20px",
                            position: "relative",
                            top: "4px",
                            left: "4px",
                            color: "#f2f0ed",
                          }}
                        >
                          check
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  textAlign: "center",
                  padding: "0 24px",
                  maxWidth: "700px",
                  margin: "auto",
                }}
              >
                <Lottie
                  animationData={confirmAnimation}
                  loop
                  play
                  speed={0.75}
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: "35px auto 0",
                  }}
                />
                <p
                  style={{
                    fontSize: "smaller",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Thanks for submitting!
                </p>
                <h4 style={{ fontFamily: "Spacegrotesk, sans-serif" }}>
                  We asked the candidate to reschedule
                </h4>
                <br />
                <p style={{ fontSize: "16px", marginBottom: "150px" }}>
                  We will let you know once they do so.
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              margin: "auto",
            }}
          >
            <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployerSlots;
