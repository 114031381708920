import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { pauseContact } from "../backend";

const PauseContactInput = ({ candidateId, compact = false, style = {} }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);

  let pauseOptions = [
    { id: 1, label: "Pause for 1 month" },
    { id: 2, label: "Pause for 2 month" },
    { id: 3, label: "Pause for 3 month" },
    { id: 4, label: "Pause for 4 month" },
    { id: 5, label: "Pause for 5 month" },
    { id: 6, label: "Pause for 6 month" },
    { id: 7, label: "Don't contact me again" },
  ];
  const handleSubmit = async () => {
    await pauseContact(candidateId, selected + 1);
    navigate(`/candidate-pause-success`);
  };

  return (
    <div style={{ padding: "16px", ...style }}>
      <h6 style={{ marginBottom: "16px" }}>How long do you want to pause</h6>
      <div>
        {pauseOptions.map((option, i) => (
          <div
            key={option.id}
            style={{
              display: "flex",
              marginBottom: "5px",
            }}
          >
            <input
              key={option.id}
              type="checkbox"
              checked={selected === i}
              onClick={() => setSelected(i)}
              onChange={console.log("change")}
              style={{
                margin: "0 8px 0 0",
                width: "20px",
                height: "20px",
                borderRadius: "5px",
                borderColor: "rgb(209, 213, 219)",
                cursor: "pointer",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <label
                style={{
                  lineHeight: "20px",
                  cursor: "pointer",
                  fontWeight: "normal",
                }}
                onClick={(event) => setSelected(i)}
              >
                {option.label}
              </label>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          className="button w-button"
          style={{
            marginTop: "8px",
            padding: "4px 32px",
            opacity: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => handleSubmit()}
        >
          Confirm{" "}
          <span
            className="icon-small"
            style={{
              display: "inline",
              fontSize: "16px",
              margin: "0 0 0 8px",
              position: "relative",
              top: "2px",
              color: "white",
            }}
          >
            check
          </span>
        </button>
      </div>
    </div>
  );
};

export default PauseContactInput;
