import { isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import { Modal } from "../Components";
import Lottie from "react-lottie-player";
import confirmAnimation from "../Components/confirmAnimation.json";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { wait } from "../utils";

const candidates = [
  {
    name: "Laurence Adeyemi",
    role: "Waiter",
    email: "janecooper@example.com",
    rating: 5,
  },
  {
    name: "Riyad Sadath",
    role: "Waiter",
    email: "janecooper@example.com",
    rating: 5,
  },
  {
    name: "Max Rincon",
    role: "Bartender",
    email: "janecooper@example.com",
    rating: 4.8,
  },
  {
    name: "Anna Andreyeva",
    role: "Waiter",
    email: "janecooper@example.com",
    rating: 4.7,
  },
];

const SocialAccountability = () => {
  const [connectClicked, setConnectClicked] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const navigateToOpportunities = () => {
    if (state?.candidate) {
      navigate("/candidate-opportunities", {
        state: state,
      });
    } else {
      navigate("/candidate-success-nocv");
    }
  };
  return (
    <div
      style={{
        backgroundColor: "rgb(242, 240, 237)",
        height: isMobile ? "100%" : "100vh",
        padding: "48px",
      }}
    >
      <Modal
        isOpen={connectClicked}
        closeModal={() => setConnectClicked(false)}
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <br />
          <h6 style={{ marginBottom: "1rem" }}>connect request sent</h6>
          <div>
            <Lottie
              animationData={confirmAnimation}
              loop
              play
              style={{
                width: "150px",
                height: "150px",
                margin: "24px auto",
              }}
            />
          </div>
        </div>
      </Modal>
      <h4 className="container" style={{ paddingBottom: "8px" }}>
        People you might know from your past experiences
      </h4>
      <p className="container">
        Connect with friends and former colleagues. Stay in touch with them and
        see what they're up to!
      </p>
      <br />
      <br />
      <div className="container w-layout-grid" style={{ paddingTop: "10px" }}>
        {candidates.map((candidate) => (
          <div
            id="candidate-info"
            className="col-auto"
            style={{
              padding: "18px 18px 18px 0",
              maxWidth: "700px",
            }}
          >
            <div style={{ gap: "16px" }}>
              <div
                style={{
                  flex: 1,
                  height: "100px",
                  maxWidth: "200px",
                  borderRadius: "16px",
                  backgroundColor: "#ebe9e6",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textDecoration: "none",
                }}
              >
                <p
                  className="icon-small"
                  style={{
                    margin: "0 auto",
                    fontSize: "54px",
                    height: "54px",
                    color: "#49616e",
                  }}
                >
                  account_box
                </p>
              </div>
              <div
                style={{
                  flex: 3,
                  height: "fit-content",
                  margin: "auto 0",
                }}
              >
                <h5
                  style={{
                    fontWeight: "bold",
                    color: "#162e3c",
                  }}
                >
                  {candidate.name}
                </h5>
                <p style={{ fontSize: "smaller", fontWeight: "bold" }}>
                  {candidate.role}
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    lineHeight: "1.5rem",
                    width: "fit-content",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    gap: "4px",
                  }}
                >
                  <span>
                    <span
                      style={{
                        marginRight: "2px",
                      }}
                    >
                      {candidate.rating}/5
                    </span>
                    <span
                      className="icon-small"
                      style={{
                        display: "inline-block",
                        fontSize: "16px",
                        lineHeight: "16px",
                        margin: 0,
                        padding: 0,
                        height: "16px",
                        width: "24px",
                        position: "relative",
                        top: "4px",
                        right: "8px",
                        left: "4px",
                        color: "#f5b82f",
                      }}
                    >
                      star
                    </span>
                    <span
                      className="icon-small"
                      data-tip="This rating is based on the candidate's interview track record. The more cancellations and no-shows, the lower the rating."
                      data-for="textfield-tooltip"
                      style={{
                        display: "inline",
                        fontSize: "17px",
                        lineHeight: "17px",
                        height: "17px",
                        width: "17px",
                        position: "relative",
                        top: "4px",
                        left: "1px",
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                    >
                      help
                    </span>
                    <ReactTooltip
                      effect="solid"
                      id="textfield-tooltip"
                      place="bottom"
                    />
                  </span>
                </p>
                <button
                  className="button w-button"
                  style={{
                    padding: "0.3rem 1.0rem",
                    borderRadius: "8px",
                    flex: 1,
                    whiteSpace: "nowrap",
                    fontSize: "smaller",
                    width: "fit-content",
                    height: "fit-content",
                    marginTop: "8px",
                    border: "none",
                  }}
                  onClick={async () => {
                    setConnectClicked(true);
                    await wait(1500);
                    setConnectClicked(false);
                  }}
                >
                  Connect
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="container">
        <button
          className="button w-button"
          style={{
            padding: "8px 32px",
            borderRadius: "8px",
            flex: 1,
            whiteSpace: "nowrap",
            width: isMobile ? "80%" : "33%",
            margin: "64px 0 16px 0",
          }}
          onClick={() => navigateToOpportunities()}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SocialAccountability;
