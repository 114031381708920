import { useState } from "react";
import { default as ReactSelect } from "react-select";

const Select = ({
  label,
  selected = [],
  placeholder = "",
  options = [],
  compulsory = false,
  allowMultiple = true,
  allowSearch = true,
  showDropdownIcon = true,
  suggestion = "",
  onChange = () => {},
  isOptionDisabled = () => false,
  formatOptionLabel = (option) => option.label,
  style = {},
  inputStyle,
}) => {
  const [interacted, setInteracted] = useState(false);

  return (
    <div className="column" style={style}>
      <label
        className="main-label"
        style={{
          textTransform: "uppercase",
          fontSize: "smaller",
          fontWeight: "bold",
          margin: "0 0 8px",
          color: "#162e3c",
        }}
      >
        {label}
        {compulsory && (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              verticalAlign: "super",
              marginLeft: "4px",
            }}
          >
            *
          </span>
        )}
      </label>
      <ReactSelect
        isMulti={allowMultiple}
        closeMenuOnSelect={!allowMultiple}
        hideSelectedOptions={allowMultiple}
        isSearchable={allowSearch}
        isClearable={false}
        isOptionDisabled={isOptionDisabled}
        formatOptionLabel={formatOptionLabel}
        className="rounded form-text"
        value={
          allowMultiple
            ? selected &&
              selected.map((e) => {
                return { label: e, value: e };
              })
            : selected && selected.length
            ? { label: selected, value: selected }
            : null
        }
        placeholder={placeholder}
        options={options.map((e) => {
          return { label: e, value: e };
        })}
        components={
          showDropdownIcon
            ? {}
            : {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }
        }
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            outline: "none",
            border:
              compulsory && interacted && (!selected || !selected.length)
                ? "1px solid red"
                : "none",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
            border: "none",
            borderRadius: "8px",
            boxShadow:
              "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important",
            outline: "none",
            padding: "8px",
            backgroundColor: "rgb(250,253,255)",
            display: "flex",
            ...inputStyle,
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            opacity: 0.5,
          }),
        }}
        onChange={(e) => {
          if (allowMultiple) {
            onChange(e.map((item) => item.value));
          } else {
            onChange(e.value);
          }
        }}
        onBlur={() => setInteracted(true)}
      />
      {compulsory && interacted && (!selected || !selected.length) && (
        <p style={{ color: "red", fontSize: "smaller", marginTop: "8px" }}>
          Please select a value
        </p>
      )}
      {interacted && suggestion && (
        <p style={{ color: "grey", fontSize: "smaller", marginTop: "8px" }}>
          <span
            className="icon-small"
            style={{
              display: "inline-block",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              textAlign: "center",
              marginRight: "4px",
              fontSize: "15px",
              position: "relative",
              top: "2px",
            }}
          >
            announcement
          </span>
          {suggestion}
        </p>
      )}
    </div>
  );
};

export default Select;
