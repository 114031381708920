import confirmAnimation from "../Components/confirmAnimation.json";
import Lottie from "react-lottie-player";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {pauseContactFoundJob} from "../backend";

const CandidateFoundJobSuccess = () => {
    let { candidateId } = useParams();

    useEffect(() => {
        const pause = async () => {
            await pauseContactFoundJob(candidateId);
        };
        pause();
        //eslint-disable-next-line
    }, []);

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "#f2f0ed",
                position: "absolute",
                top: 0,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                alignItems: "flex-start",
            }}
        >
            <div className="navbar-container" style={{ height: "fit-content" }}>
                <a href={`/candidates`} style={{ padding: "16px", opacity: "0.25" }}>
                    <img
                        src="images/hirebolt-logo-black.png"
                        loading="lazy"
                        width="130"
                        alt=""
                        className="shopscribe-logo shopscribe-logo-white"
                    />
                </a>
            </div>
            <div
                style={{
                    textAlign: "center",
                    padding: "0 24px",
                    maxWidth: "700px",
                    margin: "auto",
                }}
            >
                <Lottie
                    animationData={confirmAnimation}
                    loop
                    play
                    speed={0.75}
                    style={{
                        width: "200px",
                        height: "200px",
                        margin: "35px auto 0",
                    }}
                />
                <p
                    style={{
                        fontSize: "smaller",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                    }}
                >
                    Thanks for submitting!
                </p>
                <h4 style={{ fontFamily: "Spacegrotesk, sans-serif" }}>
                    We will no longer send you opportunities
                </h4>
                <br />
                <p style={{ fontSize: "16px", marginBottom: "150px" }}>
                    If you need help finding another job and want us to resume sending opportunities, please contact us on WhatsApp or at <a href="mailto:info@hirebolt.com">info@hirebolt.com</a>
                </p>
            </div>
        </div>
    );
};

export default CandidateFoundJobSuccess;
