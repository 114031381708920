import {
  getBranch,
  getInterviewInfo,
  getCandidateInfo,
  interviewReminderResponse,
} from "../backend";
import { getRoleFromId, wait, getJobType } from "../utils";
import { Referrals, Modal, NotInterestedInput } from "../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MoonLoader from "react-spinners/MoonLoader";
import Lottie from "react-lottie-player";
import confirmAnimation from "../Components/confirmAnimation.json";
import moment from "moment/moment";
import { AnimatePresence, motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";

const InterviewConfirmation = () => {
  const navigate = useNavigate();
  let { interviewId } = useParams();
  const [interview, setInterview] = useState();
  const [employer, setEmployer] = useState();
  const [candidate, setCandidate] = useState();
  const [role, setRole] = useState();
  const [interviewType, setInterviewType] = useState("Interview");
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [interviewCancelled, setInterviewCancelled] = useState(false);
  const [interviewConfirmed, setInterviewConfirmed] = useState(false);

  useEffect(() => {
    document.dispatchEvent(new Event("readystatechange"));

    async function fetchData() {
      let i = await getInterviewInfo(interviewId);
      setInterview(i);
      let e = await getBranch(i["Employer Id"]);
      setEmployer(e);
      let c = await getCandidateInfo(i["Candidate Id"]);
      setCandidate(c);
      let r = getRoleFromId(e, i["Role Id"]);
      setRole(r);
      if (r.interviewType) {
        setInterviewType(r.interviewType);
      }
    }

    fetchData();
    //eslint-disable-next-line
  }, []);

  const cancelInterview = () => {
    interviewReminderResponse(
      candidate["Id"],
      interviewId,
      "No - I Can't Attend"
    );
  };
  const confirmInterview = async () => {
    interviewReminderResponse(
      candidate["Id"],
      interviewId,
      "Yes - I'll Attend"
    );
    setInterviewConfirmed(true);
    await wait(3000);
    window.location.href = "https://www.hirebolt.com/candidates";
  };
  const getCommonPosition = (employer) => {
    if (employer && interview) {
      return getRoleFromId(employer, interview["Role Id"]);
    }
  };
  const parseInterviewDuration = (duration) => {
    if (duration <= 60) return `${duration} minutes`;
    return `${Math.floor(duration / 60)}${
      Math.floor(duration % 60) > 0 ? `.${Math.floor(duration % 60)}` : ""
    } hours`;
  };

  const getSalaryFormatted = () => {
    return role.minSalary && role.minSalary >= 10
      ? "£" + role.minSalary + "-" + role.maxSalary + "/hr"
      : role.maxSalary
      ? ["£" + role.maxSalary + "/hr"]
      : ["-"];
  };

  return (
    <div
      style={{
        background: "#f2f0ed",
        width: "100vw",
        height: "fit-content",
        minHeight: "100vh",
      }}
      className="flex items-center justify-center"
    >
      {interview && employer && candidate ? (
        <div
          className="margin-interview-scheduler"
          style={{ textAlign: "left" }}
        >
          {cancellationModalOpen && (
            <Modal
              isOpen={cancellationModalOpen}
              closeModal={() => setCancellationModalOpen(false)}
            >
              {interviewCancelled ? (
                <div style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <h6 style={{ marginBottom: "1rem" }}>
                    Interview Cancelled Successfully
                  </h6>
                  <div style={{ marginBottom: "3.5rem" }}>
                    <p>You will be redirected to the homepage shortly.</p>
                  </div>
                  <div>
                    <Lottie
                      animationData={confirmAnimation}
                      loop
                      play
                      style={{
                        width: "150px",
                        height: "150px",
                        margin: "24px auto",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <br />
                  <NotInterestedInput
                    candidate={candidate}
                    employerId={employer}
                    interview={interview}
                  />
                  <div className="row-all-devices" style={{ gap: "16px" }}>
                    <button
                      className="button w-button"
                      style={{
                        padding: "8px 32px",
                        borderRadius: "8px",
                        flex: 1,
                        whiteSpace: "nowrap",
                      }}
                      onClick={async () => {
                        cancelInterview(
                          interview["Employer Id"],
                          interview["Candidate Id"],
                          interview["Date"],
                          false
                        );
                        setInterviewCancelled(true);
                        await wait(3000);
                        window.location.href =
                          "https://www.hirebolt.com/candidates";
                      }}
                    >
                      Yes, Cancel
                    </button>
                    <button
                      className="button w-button button-tertiary"
                      style={{
                        padding: "8px 32px",
                        borderRadius: "8px",
                        flex: 1,
                      }}
                      onClick={() => setCancellationModalOpen(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              )}
            </Modal>
          )}
          {interviewConfirmed && (
            <Modal isOpen={interviewConfirmed}>
              <div style={{ textAlign: "center" }}>
                <br />
                <br />
                <h6 style={{ marginBottom: "1rem" }}>
                  Interview Confirmed Successfully
                </h6>
                <div style={{ marginBottom: "3.5rem" }}>
                  <p>You will be redirected to the homepage shortly.</p>
                </div>
                <div>
                  <Lottie
                    animationData={confirmAnimation}
                    loop
                    play
                    style={{
                      width: "150px",
                      height: "150px",
                      margin: "24px auto",
                    }}
                  />
                </div>
              </div>
            </Modal>
          )}
          <div style={{ margin: "24px" }}>
            <h2>
              {interview["Candidate"]} x {isMobile ? <br /> : ""}
              {employer.name}
            </h2>
            <div
              style={{ marginBottom: "3.5rem", marginTop: "8px" }}
              className="mobile-width "
            >
              <p>
                Confirm that you will attend your {interviewType.toLowerCase()}{" "}
                by using the buttons below.
              </p>
            </div>
          </div>
          <div style={{ gap: "48px" }}>
            <div className="" style={{ marginBottom: "50px" }}>
              <div style={{ marginBottom: "3.5rem" }}>
                <div className="container">
                  <div
                    style={{
                      width: isMobile ? "95vw" : "75%",
                      minWidth: isMobile ? "95vw" : "75%",
                      maxWidth: isMobile ? "95vw" : "75%",
                      marginTop: isMobile && !employer[0]?.name ? "24px" : 0,
                      height: "fit-content",
                      opacity: 1,
                      pointerEvents: "all",
                    }}
                  >
                    <AnimatePresence>
                      <motion.div
                        key={employer.id}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "24px",
                          flexGrow: 1,
                          display: isMobile ? "block" : "flex",
                          flexDirection: "column",
                          overflowY: isMobile ? "scroll" : "hidden",
                          margin: "0 16px",
                          position: "relative",
                          justifyContent: "flex-end",
                        }}
                        initial={{
                          x: 0,
                        }}
                        animate={{
                          x: 0,
                        }}
                      >
                        <div
                          style={{
                            padding: isMobile
                              ? "16px 32px 0"
                              : "24px 32px 0 32px",
                            width: "100%",
                            minWidth: "100%",
                            flexGrow: 1,
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "24px",
                            }}
                          >
                            {employer && candidate ? (
                              <div>
                                <p
                                  style={{
                                    color: "#162e3c",
                                    fontWeight: "bold",
                                    fontSize: isMobile ? "18px" : "20px",
                                    lineHeight: "24px",
                                    marginBottom: "0",
                                    fontFamily: "Fraunces",
                                    marginTop: "24px",
                                  }}
                                >
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        display: "inline-block",
                                        width: isMobile ? "32px" : "40px",
                                        fontSize: isMobile ? "20px" : "24px",
                                        position: "relative",
                                        top: isMobile ? "0" : "2px",
                                      }}
                                    >
                                      📅
                                    </span>
                                    {moment(interview["Date"]).format(
                                      "Do MMMM, h:mma"
                                    )}
                                  </span>
                                </p>
                                <p
                                  style={
                                    isMobile
                                      ? {
                                          fontSize: "12px",
                                          lineHeight: "1.1rem",
                                          paddingLeft: "32px",
                                          marginBottom: "8px",
                                        }
                                      : {
                                          fontSize: "smaller",
                                          paddingLeft: "40px",
                                          marginBottom: "8px",
                                        }
                                  }
                                >
                                  {(employer.openPositions[0]
                                    .roleInterviewSlots ||
                                    employer.interviewSlots) &&
                                    `Duration: ${parseInterviewDuration(
                                      parseInt(
                                        employer.openPositions[0]
                                          .roleInterviewSlots
                                          ? employer.openPositions[0]
                                              .roleInterviewSlots[0].duration
                                          : employer.interviewSlots[0].duration
                                      )
                                    )} `}
                                </p>
                                <p
                                  style={{
                                    color: "#162e3c",
                                    fontWeight: "bold",
                                    fontSize: isMobile ? "18px" : "20px",
                                    lineHeight: "24px",
                                    margin: "16px 0 0 0",
                                    fontFamily: "Fraunces",
                                  }}
                                >
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        display: "inline-block",
                                        width: isMobile ? "32px" : "40px",
                                        fontSize: isMobile ? "20px" : "24px",
                                        position: "relative",
                                        top: isMobile ? "0" : "2px",
                                      }}
                                    >
                                      📍
                                    </span>
                                    {employer.name}
                                  </span>
                                </p>
                                <p
                                  style={
                                    isMobile
                                      ? {
                                          fontSize: "12px",
                                          lineHeight: "1.1rem",
                                          paddingLeft: "32px",
                                          marginBottom: "8px",
                                        }
                                      : {
                                          fontSize: "smaller",
                                          paddingLeft: "40px",
                                          marginBottom: "8px",
                                        }
                                  }
                                >
                                  <a
                                    href={`http://maps.google.com/?q=${employer.name}, ${employer.address}`.replace(
                                      / /g,
                                      "+"
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      borderBottom: "1px solid",
                                      opacity: "0.66",
                                    }}
                                  >
                                    <b>{employer.address}</b>
                                  </a>
                                  <span
                                    className="icon-small"
                                    style={{
                                      display: "inline-block",
                                      marginLeft: "4px",
                                      fontSize: "12px",
                                      lineHeight: "12px",
                                      position: "relative",
                                      top: "2px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    launch
                                  </span>
                                </p>
                                <p
                                  style={{
                                    color: "#162e3c",
                                    fontWeight: "bold",
                                    fontSize: isMobile ? "18px" : "20px",
                                    lineHeight: "24px",
                                    margin: "0px 0 0 0",
                                    fontFamily: "Fraunces",
                                  }}
                                >
                                  <span>
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        display: "inline-block",
                                        width: isMobile ? "32px" : "40px",
                                        fontSize: isMobile ? "20px" : "24px",
                                        position: "relative",
                                        top: isMobile ? "0" : "2px",
                                      }}
                                    >
                                      🧑‍🍳
                                    </span>
                                    {getCommonPosition(employer)?.name} -{" "}
                                    {getSalaryFormatted(employer)}
                                  </span>
                                </p>
                                <p
                                  style={
                                    isMobile
                                      ? {
                                          fontSize: "12px",
                                          lineHeight: "1.1rem",
                                          paddingLeft: "32px",
                                          marginBottom: "8px",
                                        }
                                      : {
                                          fontSize: "smaller",
                                          paddingLeft: "40px",
                                          marginBottom: "8px",
                                        }
                                  }
                                >
                                  {getJobType(getCommonPosition(employer))}
                                </p>
                              </div>
                            ) : (
                              <Skeleton count={2} height="24px" />
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            fontWeight: "bold",
                            padding: "16px 0",
                            borderTop: "1px solid #efefef",
                          }}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              padding: "0 16px",
                            }}
                            onClick={async () => confirmInterview()}
                          >
                            <span
                              className="icon-small"
                              style={{
                                display: isMobile ? "block" : "inline-block",
                                fontSize: "32px",
                                width: "32px",
                                lineHeight: "20px",
                                height: "20px",
                                position: "relative",
                                top: "8px",
                                color: "#03ac13",
                                margin: isMobile ? "0 auto 8px" : "0",
                              }}
                            >
                              check
                            </span>{" "}
                            Confirm
                          </div>
                          {interview["Reschedules Allowed"] > 0 && (
                            <div
                              style={{
                                cursor: "pointer",
                                padding: "0 16px",
                              }}
                              onClick={() =>
                                navigate(`/reschedule/${interviewId}`)
                              }
                            >
                              <span
                                className="icon-small"
                                style={{
                                  display: isMobile ? "block" : "inline-block",
                                  fontSize: "32px",
                                  width: "32px",
                                  lineHeight: "20px",
                                  height: "20px",
                                  position: "relative",
                                  top: "8px",
                                  color: "#d6b85a",
                                  transform: "scale(0.8)",
                                  margin: isMobile ? "0 auto 8px" : "0",
                                }}
                              >
                                hourglass_full
                              </span>{" "}
                              Reschedule
                            </div>
                          )}
                          <div
                            style={{
                              cursor: "pointer",
                              padding: "0 16px",
                            }}
                            onClick={() => setCancellationModalOpen(true)}
                          >
                            <span
                              className="icon-small"
                              style={{
                                display: isMobile ? "block" : "inline-block",
                                fontSize: "32px",
                                width: "32px",
                                lineHeight: "20px",
                                height: "20px",
                                position: "relative",
                                top: "8px",
                                color: "red",
                                margin: isMobile ? "0 auto 8px" : "0",
                              }}
                            >
                              close
                            </span>{" "}
                            Cancel
                          </div>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ margin: "24px" }}>
            <Referrals
              candidateId={interview["Candidate Id"]}
              touchpointId={"confirmation_page"}
              style={{ marginTop: "50px" }}
            />
          </div>
        </div>
      ) : (
        <div style={{ height: "500px", width: "100vw", display: "flex" }}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              margin: "auto",
            }}
          >
            <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InterviewConfirmation;
