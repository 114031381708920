import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { wait } from "../utils";
import { TextField } from "../Components/Forms";

const CandidateHomepage = () => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const referrer = searchParams.get("ref");
  const utmSource = searchParams.get("utm_source");
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  // Animate
  const [hideContent, setHideContent] = useState(false);

  useEffect(() => {
    setHideContent(false);
    setTimeout(function () {
      var elements = document.getElementsByClassName("late-load");
      for (let el of elements) el.style.opacity = 1;
    }, 400);
    enableAccordions();
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  }, []);

  const enableAccordions = () => {
    let acc = document.getElementsByClassName("faq-question");
    let i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  };

  return (
    <div
      className="page-wrapper"
      style={{
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      <div
        className="navbar-component w-nav"
        style={{
          backgroundColor: "transparent",
          position: "absolute",
          width: "100%",
        }}
      >
        <div
          className="navbar-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <a
            href={`/${referrer ? `?ref=${referrer}` : ""}`}
            className="navbar-logo-link w-nav-brand"
          >
            <img
              src="images/hirebolt-logo-black.png"
              loading="lazy"
              width="130"
              alt=""
              className="shopscribe-logo"
              style={{
                filter:
                  "invert(13%) sepia(57%) saturate(562%) hue-rotate(158deg) brightness(94%) contrast(91%)",
              }}
            />
          </a>
          <button
            data-w-id="9f05ef66-55bb-8381-9ca4-a8a03fb82254"
            onClick={async () => {
              setHideContent(true);
              await wait(750);
              navigate(`/candidate-dashboard`);
            }}
            className="button w-inline-block"
            style={{ display: "flex", padding: "4px 16px" }}
          >
            <div style={{ color: "rgb(221, 240, 167)" }}>Login</div>
            <span
              className="icon-small"
              style={{
                float: "right",
                display: "inline-block",
                fontSize: "20px",
                lineHeight: "20px",
                height: "20px",
                width: "20px",
                position: "relative",
                left: "4px",
                color: "rgb(221, 240, 167)",
              }}
            >
              keyboard_arrow_right_icon
            </span>
          </button>
        </div>
      </div>
      <main
        className="main-wrapper"
        style={{
          backgroundColor: "#f2f0ed",
        }}
      >
        <section
          className="section-header-1"
          style={{
            color: "#162e3c !important",
            backgroundColor: "rgb(221, 240, 167)",
            maxHeight: hideContent ? 0 : "100vh",
            transition: "max-height .75s cubic-bezier(0.5,0.25,0,1) .1s",
          }}
        >
          <div
            className="page-padding"
            style={{
              opacity: hideContent ? 0 : 1,
              transition: "opacity .25s cubic-bezier(0.5,0.25,0,1)",
              maxWidth: "100vw",
            }}
          >
            <div className="container-large">
              <div
                className="padding-top"
                style={{ paddingTop: isMobile ? "75px" : "100px" }}
              >
                <div className="header-1-component">
                  <div className="margin-bottom margin-xxlarge landing-margin-bottom">
                    <div className="text-align-center">
                      <div className="header-1-heading-wrapper">
                        <div className="max-width-large tablet-medium">
                          <div className="margin-bottom margin-small">
                            <h1
                              data-w-id="27d5740f-2623-c299-0a89-dfc1ee53ff8e"
                              className="heading-xxlarge"
                              style={{
                                fontSize: isMobile ? "4vh" : "auto",
                              }}
                            >
                              <strong>
                                Get the best hospitality employers to reach out
                                to you
                              </strong>
                            </h1>
                          </div>
                          <div className="margin-bottom margin-small late-load">
                            <p className="text-size-large">
                              Stop sending dozens of applications. The
                              highest-paying, best employers in London will
                              reach out directly to you on WhatsApp.{" "}
                              <b>All for free.</b>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row late-load"
                        style={{ maxWidth: "700px", margin: "24px auto 12px" }}
                      >
                        <TextField
                          label="Your name"
                          placeholder="Alfredo Linguini"
                          onChange={(value) => setName(value)}
                          value={name}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <TextField
                          label="WhatsApp Number"
                          placeholder="+44 7321 321000"
                          onChange={(value) => setNumber(value)}
                          onEnter={async () => {
                            setHideContent(true);
                            await wait(750);
                            navigate(
                              `/candidate-form?${
                                referrer ? `ref=${referrer}&` : ""
                              }${name ? `name=${name}&` : ""}${
                                number
                                  ? `number=${number.replace("+", "%2B")}&`
                                  : ""
                              }${utmSource ? `utm_source=${utmSource}&` : ""}`
                            );
                          }}
                          value={number}
                        />
                      </div>
                      <div className="button-row button-row-center late-load">
                        <div className="button-wrapper no-margin-right">
                          <button
                            data-w-id="9f05ef66-55bb-8381-9ca4-a8a03fb82254"
                            onClick={async () => {
                              setHideContent(true);
                              await wait(750);
                              navigate(
                                `/candidate-form?${
                                  referrer ? `ref=${referrer}&` : ""
                                }${name ? `name=${name}&` : ""}${
                                  number
                                    ? `number=${number.replace("+", "%2B")}&`
                                    : ""
                                }${utmSource ? `utm_source=${utmSource}&` : ""}`
                              );
                            }}
                            className="button w-inline-block"
                            style={{ display: "flex" }}
                          >
                            <div style={{ color: "rgb(221, 240, 167)" }}>
                              Continue for Free
                            </div>
                            <span
                              className="icon-small"
                              style={{
                                display: "inline-block",
                                fontSize: "20px",
                                lineHeight: "20px",
                                height: "20px",
                                width: "20px",
                                position: "relative",
                                left: "4px",
                                color: "rgb(221, 240, 167)",
                              }}
                            >
                              keyboard_arrow_right_icon
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section-feature-2 late-load"
          style={{
            maxWidth: "100vw",
            overflowX: "hidden",
          }}
        >
          <div
            className="page-padding additional-page-padding"
            style={{
              opacity: hideContent ? 0 : 1,
              transition: "opacity .1s ease-in-out",
              maxWidth: "100vw",
              overflowX: "hidden",
            }}
          >
            <div className="container-large">
              <div
                className="padding-bottom padding-xhuge remove-bottom-padding"
                style={{
                  maxWidth: "100vw",
                  overflowX: "hidden",
                }}
              >
                <div
                  className="w-layout-grid feature-2-component"
                  style={{
                    alignItems: "flex-start",
                    marginTop: "16px",
                    gridColumnGap: "5rem",
                  }}
                >
                  <div
                    className="image-wrapper-1-1 home-v2-wrapper-2"
                    style={{ height: "100%", margin: 0 }}
                  ></div>
                  <div className="feature-2-content">
                    <div style={{ filter: "invert(1)", marginBottom: "48px" }}>
                      <div
                        className="header-1-logo-row justify-center"
                        style={{
                          paddingBottom: "1.5rem",
                          paddingTop: 0,
                        }}
                      >
                        <a
                          href="https://www.simmonsbar.co.uk/"
                          className="w-inline-block"
                        >
                          <div className="header-1-logo-wrapper">
                            <img
                              className="header-1-logo black-image"
                              src="images/Simmons.png"
                              alt="Simmons logo"
                              sizes="39.208335876464844px"
                              data-w-id="2d7db3fb-b214-3803-7685-12dc8bdf0038"
                              loading="lazy"
                              style={{
                                width: "150px",
                              }}
                            />
                          </div>
                        </a>
                        <a
                          href="https://www.chinchinicecream.com/"
                          className="w-inline-block"
                        >
                          <div className="header-1-logo-wrapper">
                            <img
                              src="images/chinchin_logo.png"
                              loading="lazy"
                              alt="Chin Chin logo"
                              height=""
                              data-w-id="acb829f1-fbab-4b10-f301-41c9597d4f71"
                              className="header-1-logo black-image"
                              style={{
                                filter: "invert(1)",
                                transform: "scale(1.2)",
                              }}
                            />
                          </div>
                        </a>
                        {!isMobile && (
                          <a
                            href="https://www.honestburgers.co.uk/"
                            className="w-inline-block"
                          >
                            <div className="header-1-logo-wrapper">
                              <img
                                className="header-1-logo black-image"
                                src="images/HonestBurgers.png"
                                width="120"
                                alt="Pho logo"
                                sizes="120px"
                                data-w-id="4e8ae3c7-1fb5-0071-2ec8-dd0a369e1bb8"
                                loading="lazy"
                                style={{
                                  width: "125px",
                                  height: "auto",
                                }}
                              />
                            </div>
                          </a>
                        )}
                      </div>
                      <p
                        style={{
                          color: "white",
                          opacity: 0.5,
                          fontSize: "small",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          widht: "100%",
                          textAlign: "center",
                        }}
                      >
                        and 500+ more hospitality employers
                      </p>
                    </div>
                    <div
                      className="w-layout-grid feature-2-item-list"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "24px",
                        padding: "48px",
                      }}
                    >
                      <div
                        data-w-id="cba9e83d-8ba2-aef2-17ca-9794cacbf2bd"
                        className="feature-2-item"
                      >
                        <div className="feature-2-item-top">
                          <div className="margin-bottom margin-xsmall">
                            <div className="align-left">
                              <div className="icon-wrapper">
                                <div className="icon-small text-color-bluemine">
                                  group
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="margin-bottom margin-xsmall">
                            <h2 className="heading-small">
                              <strong>Top employers only</strong>
                            </h2>
                          </div>
                          <p className="text-size-regular">
                            You'll find exclusively employers with high reviews
                            and salaries
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="f6f7115f-3dfa-f5b9-dd99-46c19d777ee7"
                        className="feature-2-item"
                      >
                        <div className="feature-2-item-top">
                          <div className="margin-bottom margin-xsmall">
                            <div className="align-left">
                              <div className="icon-wrapper">
                                <div className="icon-small text-color-bluemine">
                                  watch_later
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="margin-bottom margin-xsmall">
                            <h2 className="heading-small">
                              <strong>Find a job in days, not weeks</strong>
                            </h2>
                          </div>
                          <p className="text-size-regular">
                            Faster than job boards, with less effort
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="1cbcfb30-7147-92be-63dd-23ad1a875ca4"
                        className="feature-2-item"
                      >
                        <div className="feature-2-item-top">
                          <div className="margin-bottom margin-xsmall">
                            <div className="align-left">
                              <div className="icon-wrapper">
                                <div className="icon-small text-color-bluemine">
                                  not_interested
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="margin-bottom margin-xsmall align-left">
                            <h2 className="heading-small">
                              <strong>Stop sending applications</strong>
                            </h2>
                          </div>
                          <p
                            className="text-size-regular align-left"
                            style={{ textAlign: "left !important" }}
                          >
                            Complete a 2-minute form and get employers to reach
                            out
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="72ab7621-cb81-ce66-08e9-c187a47d53bd"
                        className="feature-2-item"
                      >
                        <div className="feature-2-item-top">
                          <div className="margin-bottom margin-xsmall">
                            <div className="align-left">
                              <div className="icon-wrapper">
                                <div className="icon-small text-color-bluemine">
                                  savings
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="margin-bottom margin-xsmall">
                            <h2 className="heading-small">
                              <strong>All for free</strong>
                            </h2>
                          </div>
                          <p className="text-size-regular">
                            Yep, that&#x27;s correct. Give it a try,
                            doesn&#x27;t cost you anything
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src="images/about_v2_2.svg"
                  loading="lazy"
                  alt=""
                  className="home-v1-illustration-1"
                  style={{
                    marginTop: "100px",
                    filter: "brightness(0%)",
                    opacity: ".075",
                  }}
                />
                <img
                  src="images/about_v3_4.svg"
                  loading="lazy"
                  alt=""
                  className="home-v1-illustration-2"
                  style={{
                    marginTop: "50px",
                    filter: "brightness(0%)",
                    opacity: ".075",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <section
          className="section-faq"
          style={{
            backgroundColor: "#f2f0ed",
          }}
        >
          <div
            className="page-padding"
            style={{
              paddingBottom: "64px",
              paddingTop: "16px",
            }}
          >
            <div className="container-small">
              <div className="margin-bottom margin-xxlarge">
                <div className="text-align-center">
                  <div className="max-width-large">
                    <div className="margin-bottom margin-small">
                      <h2
                        data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e2b"
                        className="heading-xlarge z-index-2"
                      >
                        FAQs
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="faq-component">
                <div
                  data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e30"
                  className="faq-accordion"
                >
                  <div
                    data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e31"
                    className="faq-question"
                  >
                    <div className="text-size-medium text-weight-bold text-color-blumine">
                      “How does it work?”
                    </div>
                    <img
                      src="images/icon_chevron.svg"
                      loading="lazy"
                      alt=""
                      className="faq-icon"
                    />
                  </div>
                  <div className="faq-answer">
                    <div className="margin-bottom margin-small">
                      <div className="text-size-regular">
                        <ol>
                          <li>
                            1. You <b>fill out your details</b> and tell us what
                            job you're looking for;
                          </li>
                          <br />
                          <li>
                            2. We automatically <b>send your profile</b> to some
                            of the top employers in London, who are looking
                            exactly for someone like you;
                          </li>
                          <br />
                          <li>
                            3. All the employers who are interested in offering
                            you a job, will <b>reach out to you via WhatsApp</b>
                            . This makes it easy and fast for you;
                          </li>
                          <br />
                          <li>
                            4. Last step is to <b>just schedule an interview</b>{" "}
                            with the employer and show up for it!
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e30"
                  className="faq-accordion"
                >
                  <div
                    data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e31"
                    className="faq-question"
                  >
                    <div className="text-size-medium text-weight-bold text-color-blumine">
                      “What kinds of job opportunities do you offer?”
                    </div>
                    <img
                      src="images/icon_chevron.svg"
                      loading="lazy"
                      alt=""
                      className="faq-icon"
                    />
                  </div>
                  <div className="faq-answer">
                    <div className="margin-bottom margin-small">
                      <p className="text-size-regular">
                        We work with top bars, cafes and restaurants throughout
                        London, including everything from fast-foods to Michelin
                        starred restaurants.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e39"
                  className="faq-accordion"
                >
                  <div
                    data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e3a"
                    className="faq-question"
                  >
                    <div className="text-size-medium text-weight-bold text-color-blumine">
                      “Do you offer visa sponsorship?”
                    </div>
                    <img
                      src="images/icon_chevron.svg"
                      loading="lazy"
                      alt=""
                      className="faq-icon"
                    />
                  </div>
                  <div className="faq-answer">
                    <div className="margin-bottom margin-small">
                      <p className="text-size-regular">
                        Unfortunately at this time we DO NOT offer any visa
                        sponsorships.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e42"
                  className="faq-accordion"
                >
                  <div
                    data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e43"
                    className="faq-question"
                  >
                    <div className="text-size-medium text-weight-bold text-color-blumine">
                      “When will I hear back from employers?”
                    </div>
                    <img
                      src="images/icon_chevron.svg"
                      loading="lazy"
                      alt=""
                      className="faq-icon"
                    />
                  </div>
                  <div className="faq-answer">
                    <div className="margin-bottom margin-small">
                      <p className="text-size-regular">
                        Generally, candidates receive multiple job opportunities
                        in 1-3 days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="header-2-form-block w-form"
            style={{ paddingBottom: "128px", display: "flex" }}
          >
            <a
              href={`/candidate-form?${referrer ? `ref=${referrer}&` : ""}${
                name ? `name=${name}&` : ""
              }${number ? `number=${number}&` : ""}${
                utmSource ? `utm_source=${utmSource}&` : ""
              }`}
              className="button w-button"
              style={{ margin: "auto" }}
            >
              Sign Up for Free
              <span
                className="icon-small"
                style={{
                  display: "inline-block",
                  fontSize: "20px",
                  lineHeight: "20px",
                  height: "20px",
                  width: "20px",
                  position: "relative",
                  left: "4px",
                  top: "4px",
                  color: "white",
                }}
              >
                keyboard_arrow_right_icon
              </span>
            </a>
          </div>
          <img
            src="images/about_v2_1.svg"
            loading="lazy"
            alt=""
            className="faq-illustration"
          />
          <img
            src="images/about_v2_1.svg"
            loading="lazy"
            alt=""
            className="home-v1-illustration-2"
            style={{
              width: "170px",
              height: "150px",
              marginTop: "300px",
            }}
          />
        </section>
      </main>
      <div className="footer-component">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xxlarge">
              <div className="padding-bottom padding-xxlarge">
                <div className="w-layout-grid footer-top-wrapper">
                  <div className="footer-left-wrapper">
                    <div className="margin-bottom margin-small">
                      <a
                        href="apply.html"
                        className="footer-logo-link w-nav-brand"
                      >
                        <img
                          src="images/hirebolt-logo-white.svg"
                          loading="lazy"
                          width="150"
                          alt=""
                          className="shopscribe-logo-white"
                        />
                      </a>
                    </div>
                    <div className="row">
                      <div
                        className="text-size-small text-color-white column"
                        style={{ textAlign: "left" }}
                      >
                        <span className="text-weight-semibold">
                          Address: <br />
                        </span>
                        First Floor, 57 Greek Street
                        <br />
                        London
                        <br />
                        W1D 3DX
                        <br />
                        <br />
                        <span className="text-weight-semibold">Contact:</span>
                        <br />
                        <a
                          href="mailto:info@hirebolt.com"
                          className="text-color-white"
                        >
                          info@hirebolt.com
                        </a>
                        <br />
                        +447404165080
                      </div>
                      <div
                        className="text-size-small text-color-white column"
                        style={{ textAlign: "left" }}
                      >
                        <span className="text-weight-semibold">
                          <br />
                        </span>
                        651 N Broad St
                        <br />
                        Suite 206 Middleton, Delaware 19709
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid footer-menu-wrapper"></div>
                </div>
              </div>
              <div className="line-divider footer"></div>
              <div className="padding-top padding-medium">
                <div className="footer-bottom-wrapper">
                  <div className="footer-credits"></div>
                  <div className="w-layout-grid footer-social">
                    <a
                      href="https://www.linkedin.com/company/shopscribe"
                      className="social-link is-with-background w-inline-block"
                    >
                      <img
                        src="images/LinkedIn.svg"
                        loading="lazy"
                        alt=""
                        className="social-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateHomepage;
