import moment from "moment-timezone";
import { useEffect, useState } from "react";
import TextField from "./TextField";

const DatePicker = ({
  defaultValue,
  label,
  showFieldNames = false,
  compulsory = false,
  onChange = () => {},
  style = {},
}) => {
  const [day, setDay] = useState(
    defaultValue ? moment(defaultValue).format("DD") : null
  );
  const [month, setMonth] = useState(
    defaultValue ? moment(defaultValue).format("MM") : null
  );
  const [year, setYear] = useState(
    defaultValue ? moment(defaultValue).format("YYYY") : null
  );
  const [monthFocused, setMonthFocused] = useState(false);
  const [yearFocused, setYearFocused] = useState(false);

  useEffect(() => {
    onChange(
      day && month && year && year.length === 4
        ? moment(`${day}-${month}-${year}`, "DD-MM-YYYY").toDate()
        : null
    );
    //eslint-disable-next-line
  }, [day, month, year]);

  return (
    <div className="column" style={style}>
      <label
        className="main-label"
        style={{
          fontSize: "smaller",
          fontWeight: "bold",
          margin: "0",
        }}
      >
        <span
          style={{
            textTransform: "uppercase",
            color: "#162e3c",
          }}
        >
          {label}
        </span>
        {compulsory && (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              verticalAlign: "super",
              marginLeft: "4px",
            }}
          >
            *
          </span>
        )}
      </label>
      <div className="row-all-devices">
        <div className="column" style={{ flex: 2, marginRight: "4px" }}>
          {showFieldNames && (
            <label className="sub-label" style={{ margin: 0 }}>
              Day
            </label>
          )}
          <TextField
            placeholder="DD"
            value={day}
            max="2"
            onlyNumbers
            onChange={(value) => {
              setDay(value);
              setMonthFocused(false);
              if (value.length >= 2) {
                setMonthFocused(true);
              }
            }}
          />
        </div>
        <div className="column" style={{ flex: 2, marginRight: "4px" }}>
          {showFieldNames && (
            <label className="sub-label" style={{ margin: 0 }}>
              Month
            </label>
          )}
          <TextField
            placeholder="MM"
            value={month}
            max="2"
            onlyNumbers
            focused={monthFocused}
            onChange={(value) => {
              setMonth(value);
              setYearFocused(false);
              if (value.length >= 2) {
                setMonthFocused(false);
                setYearFocused(true);
              }
            }}
          />
        </div>
        <div className="column" style={{ flex: 4 }}>
          {showFieldNames && (
            <label className="sub-label" style={{ margin: 0 }}>
              Year
            </label>
          )}
          <TextField
            placeholder="YYYY"
            value={year}
            max="4"
            onlyNumbers
            focused={yearFocused}
            onChange={(value) => setYear(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
