import ReactModal from "react-modal";

const Modal = ({
  children,
  isOpen,
  closeModal,
  closeOnOutsideClick = true,
  style = {},
  overlayStyle = {},
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      borderRadius: "25px",
      maxHeight: "90vh",
      maxWidth: "90vw",
      ...style,
    },
    overlay: { zIndex: style.zIndex },
  };

  ReactModal.setAppElement("#root");

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onAfterOpen={() => {}}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={closeOnOutsideClick}
        style={{ ...customStyles, ...overlayStyle }}
      >
        <div stlye={{ width: "100%", maxWidth: "100%" }}>
          <div
            className="icon-small"
            style={{
              cursor: "pointer",
              zIndex: "100",
              position: "absolute",
              right: 0,
              padding: "8px",
              backgroundColor: "white",
              borderRadius: "8px",
              margin: "8px",
            }}
            onClick={() => closeModal()}
          >
            close
          </div>
        </div>
        {children}
      </ReactModal>
    </div>
  );
};

export default Modal;
