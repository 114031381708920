import { MoonLoader } from "react-spinners";

const FieldStatus = ({ status }) => {
  return (
    <>
      {status === "saved" && (
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "#53c15a",
            color: "white",
            fontSize: "15px",
            width: "18px",
            height: "18px",
            fontWeight: "bold",
            position: "absolute",
            right: "-6px",
            top: "22px",
          }}
          className="icon-medium"
        >
          check
        </div>
      )}
      {status === "loading" && (
        <div
          style={{
            borderRadius: "100%",
            backgroundColor: "white",
            width: "22px",
            height: "22px",
            fontWeight: "bold",
            position: "absolute",
            right: "-6px",
            top: "20px",
          }}
          className="icon-medium"
        >
          <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={12} />
        </div>
      )}
    </>
  );
};

export default FieldStatus;
