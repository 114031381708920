import {
  getBranch,
  getCandidateInfo,
  getInterviewSlots,
  sendInterviewConfirmation,
  trackPageView,
  getInterviewStatus,
} from "../backend";
import { getRoleFromId, getInterviewType, abTesting } from "../utils";
import {
  InterviewScheduler,
  Referrals,
  Modal,
  NotInterestedInput,
} from "../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import MoonLoader from "react-spinners/MoonLoader";
import { Slide } from "react-slideshow-image";

const InterviewScheduling = () => {
  const navigate = useNavigate();
  let { employerId, candidateId, roleId } = useParams();
  const [employer, setEmployer] = useState();
  const [candidate, setCandidate] = useState();
  const [notInterested, setNotInterested] = useState(false);
  const [interviewConfirmed, setInterviewConfirmed] = useState(false);
  const [notInterestedModalOpen, setNotInterestedModalOpen] = useState(false);
  const [role, setRole] = useState();

  useEffect(() => {
    document.dispatchEvent(new Event("readystatechange"));
    async function fetchInterviewStatus() {
      let res = await getInterviewStatus(candidateId, employerId, roleId);
      if (res.length === 0) {
        return;
      } else if (res[0]["Status"].includes("Scheduled")) {
        navigate(`/reschedule/${res[0]["id"].split("-").join("")}`);
      }
      return res;
    }
    async function fetchData() {
      let c = await getCandidateInfo(candidateId);
      let e = await getBranch(employerId);
      e.id = employerId;
      e.interviewSlots = await getInterviewSlots(e.id, roleId);
      setCandidate(c);
      setEmployer(e);
      setRole(getRoleFromId(e, roleId));
      await trackPageView(employerId, candidateId);
    }
    if (!candidate) fetchInterviewStatus();
    fetchData();
    //eslint-disable-next-line
  }, []);

  const scheduleInterview = (interviewTimestamp) => {
    setInterviewConfirmed(true);
    sendInterviewConfirmation(
      employer.id,
      candidate.Id,
      role,
      interviewTimestamp
    );
  };

  return (
    <div
      style={{
        width: "100vw",
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "#f2f0ed",
      }}
    >
      {notInterested && (
        <Modal
          isOpen={notInterestedModalOpen}
          closeModal={() => setNotInterestedModalOpen(false)}
          style={{
            backgroundColor: "#f2f0ed",
            zIndex: Number.MAX_SAFE_INTEGER,
            overflow: "revert",
          }}
        >
          <NotInterestedInput
            candidate={candidate}
            employerId={employer.id}
            style={{ width: isMobile ? "80vw" : "375px" }}
          />
        </Modal>
      )}
      {candidate && employer && role ? (
        <div
          style={{
            textAlign: "left",
            maxWidth: "100vw",
            padding: isMobile ? "32px" : "32px 64px",
          }}
        >
          <div>
            <h2 style={{ marginBottom: "1rem" }}>
              {candidate["Name"]} x {employer.employerName}
            </h2>
            <div
              style={{
                marginBottom: "3.5rem",
              }}
            >
              <p
                style={{
                  maxWidth: isMobile ? "100%" : "45%",
                }}
              >
                Hey {candidate["Name"]}! {employer.name} is very interested in
                hiring you as a {role.name}. Please schedule a{" "}
                {getInterviewType(role).toLowerCase()} using the calendar below.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div style={{ marginBottom: "3.5rem" }}>
                <h6 style={{ opacity: 0.5, marginBottom: "1rem" }}>
                  About the role
                </h6>
                <div
                  className="benefits-2-item"
                  style={{
                    backgroundColor: "white",
                    width: isMobile ? "100%" : "85%",
                  }}
                >
                  <div
                    className="benefits-2-item-text-wrapper"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="margin-bottom margin-xxsmall"
                      style={{ marginBottom: "15px" }}
                    >
                      <h5>{role.name}</h5>
                    </div>
                    {[
                      {
                        ...(role.minSalary && role.minSalary >= 10
                          ? {
                              title: "Salary Range",
                              value: [
                                "£" +
                                  role.minSalary +
                                  "-" +
                                  role.maxSalary +
                                  "/hr incl. service",
                              ],
                            }
                          : {
                              title: "Max Salary",
                              value: role.maxSalary
                                ? ["£" + role.maxSalary + "/hr incl. service"]
                                : ["-"],
                            }),
                      },
                      {
                        title: candidate["Nearby Employers"]
                          ? `Commute from ${candidate[
                              "Postcode"
                            ].toUpperCase()}`
                          : "Location",
                        value: candidate["Nearby Employers"]
                          ? [
                              candidate["Nearby Employers"][employer.id]
                                ? `${
                                    candidate["Nearby Employers"][employer.id]
                                  } mins`
                                : `>${candidate["Max Commute"]} mins`,
                            ]
                          : [employer.location],
                      },
                      {
                        title: "Type",
                        value:
                          role.jobType.length <= 0
                            ? ["Part time or Full time"]
                            : Array.from(
                                new Set(
                                  role.jobType.map((role) => {
                                    if (role.includes("Part"))
                                      return "Part Time";
                                    return "Full Time";
                                  })
                                )
                              ).length > 1
                            ? ["Part time or Full time"]
                            : Array.from(
                                new Set(
                                  role.jobType.map((role) => {
                                    if (role.includes("Part"))
                                      return "Part Time";
                                    return "Full Time";
                                  })
                                )
                              ),
                      },
                      (employer.salaryFrequency || role.salaryFrequency) && {
                        title: "Pay Frequency",
                        value: [
                          employer.salaryFrequency ?? role.salaryFrequency,
                        ],
                      },
                    ].map((info, j) => {
                      return (
                        <div>
                          {info && (
                            <div
                              key={info.title}
                              style={{
                                height: isMobile ? "65px" : "33px",
                              }}
                            >
                              <div
                                style={{
                                  width: isMobile ? "100%" : "200px",
                                  display: isMobile ? "block" : "inline-block",
                                  marginBottom: isMobile ? "3px" : 0,
                                }}
                              >
                                <p
                                  style={{
                                    display: "inline",
                                  }}
                                  className="text-color-bluemine"
                                >
                                  <b>{info.title}</b>
                                </p>
                              </div>
                              {info.value.map((value, i) => {
                                return (
                                  <div
                                    style={{
                                      backgroundColor: "#162e3c",
                                      borderRadius: "15px",
                                      display: "inline",
                                      marginRight: "6px",
                                      padding: "5px 15px",
                                      fontSize: "14px",
                                    }}
                                    className="text-color-bluemine"
                                    key={info.title + i}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "900",
                                        color: "white",
                                        textDecoration: "none",
                                        margin: "auto",
                                      }}
                                    >
                                      {value}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {employer.benefits && employer.benefits.length > 0 && (
                      <div>
                        <br />
                        <p className="text-color-bluemine">
                          <b>Benefits</b>
                        </p>
                        <ul style={{ margin: "5px 0 0" }}>
                          {employer.benefits.map((benefit, b) => {
                            return <li key={"benefit" + b}>{benefit}</li>;
                          })}
                        </ul>
                      </div>
                    )}
                    {role.additionalInfo && role.additionalInfo.length > 0 && (
                      <div>
                        <br />
                        <p className="text-color-bluemine">
                          <b>Additional Info</b>
                        </p>
                        {role.additionalInfo}
                      </div>
                    )}
                    {role.workingHours && (
                      <div>
                        <br />
                        <p className="text-color-bluemine">
                          <b>Working Hours: </b>
                          <br />
                          {role.workingHours}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: "3.5rem" }}>
                <h6 style={{ opacity: 0.5, marginBottom: "1rem" }}>
                  About the employer
                </h6>
                <div
                  style={{
                    backgroundColor: "white",
                    width: isMobile ? "100%" : "85%",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                  }}
                >
                  {employer.pictures?.[0] ? (
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "900px",
                        height: "20vh",
                        opacity: ".9",
                        zIndex: "2000",
                        padding: "0 0 24px",
                        margin: "auto",
                      }}
                    >
                      <Slide autoplay={false} transitionDuration={300}>
                        {employer.pictures.map((slideImage, index) => (
                          <div key={index}>
                            <div
                              style={{
                                borderTopLeftRadius: "16px",
                                borderTopRightRadius: "16px",
                                height: "20vh",
                                margin: 0,
                                alignItems: "center",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundImage: `url(${slideImage})`,
                              }}
                            ></div>
                          </div>
                        ))}
                      </Slide>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "20vh",
                        width: "100%",
                        maxWidth: "100vw",
                      }}
                    >
                      <div
                        className="image-banner"
                        style={{
                          background: `url(${
                            employer.pictures ??
                            "/images/pexels-rodnae-productions-4921154-p-1600.jpg"
                          })`,
                          width: "100%",
                          height: "100%",
                          backgroundSize: "cover",
                          borderTopLeftRadius: "16px",
                          borderTopRightRadius: "16px",
                          backgroundPositionY: "center",
                        }}
                      ></div>
                    </div>
                  )}
                  <div
                    className="benefits-2-item-text-wrapper"
                    style={{ padding: "32px" }}
                  >
                    <div className="margin-bottom margin-xxsmall">
                      <h6>{employer.name}</h6>
                    </div>
                    <p style={{}} className="text-color-bluemine">
                      "
                      {employer.description && employer.description.length > 275
                        ? employer.description.slice(0, 275) + "..."
                        : employer.description}
                      "
                    </p>
                    <br />
                    <p className="text-color-bluemine">
                      {employer.address && (
                        <span>
                          <strong>Address: </strong>
                          <a
                            href={`http://maps.google.com/?q=${employer.name}, ${employer.address}`.replace(
                              / /g,
                              "+"
                            )}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            {employer.address}
                          </a>
                        </span>
                      )}
                    </p>
                    <p
                      className="text-color-bluemine"
                      style={{ marginBottom: "8px" }}
                    >
                      <strong>Contact: </strong>
                      {employer.hiringManagers[0].number} (
                      {employer.hiringManagers[0].name})
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              {employer && (
                <>
                  <div style={{ marginBottom: "16px" }}>
                    <h4>Schedule the {getInterviewType(role).toLowerCase()}</h4>
                    <p style={{ fontSize: "smaller" }}>
                      {employer.trialPaid === "Yes"
                        ? `This ${getInterviewType(
                            role
                          ).toLowerCase()} is paid.`
                        : employer.trialPaid?.includes("Yes")
                        ? `This ${getInterviewType(role).toLowerCase()} is paid,
                        if hired.`
                        : ``}{" "}
                      We'll send more info upon confirmation
                    </p>
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "16px",
                      padding: "0 16px 24px",
                    }}
                  >
                    <InterviewScheduler
                      employer={employer}
                      role={role}
                      sendInterviewConfirmation={scheduleInterview}
                    />
                    {!interviewConfirmed && (
                      <div>
                        <div
                          style={{
                            padding: "15px",
                            borderRadius: "15px",
                            backgroundColor: "#ffeae6",
                            margin: "25px 0 0 0",
                            fontSize: "smaller",
                          }}
                        >
                          <p>
                            <img
                              src="../../../images/exclamation-mark.svg"
                              alt=""
                              width="15"
                              height="15"
                              style={{
                                marginTop: "-3px",
                                marginRight: "7px",
                                color: "red",
                              }}
                            />
                            {abTesting(candidate["WhatsApp Number"], "candidate-profile", 2)
                              ? `If you cancel or no-show interviews, this will be visible to all employers on your public profile.`
                              : `A max of 2
                    cancellations/no-shows are allowed, after which you'll stop
                    receiving further job opportunities.`}
                          </p>
                        </div>
                        {notInterested ? (
                          <div
                            style={{
                              width: "100%",
                              margin: "24px 0 48px",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Thanks for letting us know!
                          </div>
                        ) : (
                          <div style={{ width: "100%", display: "flex" }}>
                            <button
                              className="button w-button"
                              style={{
                                width: "100%",
                                maxWidth: "400px",
                                margin: "24px auto 0",
                                backgroundColor: "#d0312d",
                                border: "none",
                                "-webkit-tap-highlight-color": "transparent",
                              }}
                              onClick={() => {
                                setNotInterestedModalOpen(true);
                                setNotInterested(true);
                              }}
                            >
                              I'm not interested in this job
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              margin: "auto",
            }}
          >
            <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
          </div>
        </div>
      )}
      {candidate && (
        <div
          style={{
            backgroundColor: "#f2f0ed",
            padding: isMobile ? "48px 32px" : "64px 64px 16px",
          }}
        >
          <Referrals
            candidateId={candidate["Id"]}
            touchpointId={"scheduling_page"}
          />
        </div>
      )}
    </div>
  );
};

export default InterviewScheduling;
