import errorAnimation from "../Components/errorAnimation.json";
import confirmAnimation from "../Components/confirmAnimation.json";
import Lottie from "react-lottie-player";
import { useState } from "react";
import { Modal } from "../Components";
import { wait } from "../utils";
import { useSearchParams } from "react-router-dom";
import { cancelInterviewById } from "../backend";

const MaxReschedule = () => {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const interviewId = searchParams.get("id");
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [interviewCancelled, setInterviewCancelled] = useState(false);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f2f0ed",
        position: "absolute",
        top: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        alignItems: "flex-start",
      }}
    >
      {cancellationModalOpen && (
        <Modal
          isOpen={cancellationModalOpen}
          closeModal={() => setCancellationModalOpen(false)}
        >
          {interviewCancelled ? (
            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <h6 style={{ marginBottom: "1rem" }}>
                Interview Cancelled Successfully
              </h6>
              <div style={{ marginBottom: "3.5rem" }}>
                <p>You will be redirected to the homepage shortly.</p>
              </div>
              <div>
                <Lottie
                  animationData={confirmAnimation}
                  loop
                  play
                  style={{
                    width: "150px",
                    height: "150px",
                    margin: "24px auto",
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <br />
              <br />
              Do you want to cancel this interview?
              <div
                className="row-all-devices"
                style={{ marginTop: "32px", gap: "16px" }}
              >
                <button
                  className="button w-button"
                  style={{
                    padding: "8px 32px",
                    borderRadius: "8px",
                    flex: 1,
                    whiteSpace: "nowrap",
                  }}
                  onClick={async () => {
                    cancelInterviewById(interviewId, false);
                    setInterviewCancelled(true);
                    await wait(3000);
                    window.location.href =
                      "https://www.hirebolt.com/candidates";
                  }}
                >
                  Yes, Cancel
                </button>
                <button
                  className="button w-button button-tertiary"
                  style={{
                    padding: "8px 32px",
                    borderRadius: "8px",
                    flex: 1,
                  }}
                  onClick={() => setCancellationModalOpen(false)}
                >
                  Go Back
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
      <div className="navbar-container" style={{ height: "fit-content" }}>
        <a href={`/candidates`} style={{ padding: "16px", opacity: "0.25" }}>
          <img
            src="images/hirebolt-logo-black.png"
            loading="lazy"
            width="130"
            alt=""
            className="shopscribe-logo shopscribe-logo-white"
          />
        </a>
      </div>
      <div
        style={{
          textAlign: "center",
          padding: "0 24px",
          maxWidth: "700px",
          margin: "auto",
        }}
      >
        <Lottie
          animationData={errorAnimation}
          loop={false}
          play
          speed={0.75}
          style={{
            width: "200px",
            height: "200px",
            margin: "32px auto",
          }}
        />
        <p
          style={{
            fontSize: "smaller",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          We are sorry
        </p>
        <h5 style={{ fontFamily: "Spacegrotesk, sans-serif" }}>
          A max of 1 reschedule is allowed
        </h5>
        {interviewId && (
          <button
            className="button animate__animated animate__headShake animate__delay-4s"
            onClick={() => {
              setCancellationModalOpen(true);
            }}
            style={{
              borderRadius: "10px",
              padding: "0.75rem 1.5rem",
              marginTop: "24px",
              fontWeight: "bold",
              marginBottom: "128px",
            }}
          >
            Cancel Interview
          </button>
        )}
        <br />
        <p style={{ fontSize: "16px", marginBottom: "24px" }}>
          If you're subject to exceptional circumstances forcing you to
          reschedule again, please contact us on WhatsApp
        </p>
      </div>
    </div>
  );
};

export default MaxReschedule;
