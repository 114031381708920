import {
  getEmployerSecret,
  getRoleFromId,
  listTimesOfDay,
  getInterviewType,
  formatInterviewTimes, getAllRoles, getPremiumRoles,
} from "../utils";
import {
  getBranch,
  getCandidateInfo,
  recordCandidateCalled,
  sendInterviewConfirmation,
  cancelInterview,
  sendReschedulingConfirmation,
  getInterviewsThisWeek,
  sendCandidateInterest, rejectCandidate, updateBranch, getPremiumbranches,
} from "../backend";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import confirmAnimation from "../Components/confirmAnimation.json";
import errorAnimation from "../Components/errorAnimation.json";
import Lottie from "react-lottie-player";
import { MoonLoader } from "react-spinners";
import moment from "moment-timezone";
import { isMobile } from "react-device-detect";
import { Modal, NotInterestedInput } from "../Components";
import { wait } from "@testing-library/user-event/dist/utils";
import {Select, TextField} from "../Components/Forms";
import CandidateRating from "../Components/CandidateRating";
import RejectedInput from "../Components/RejectedInput";

const CandidateProfile = () => {
  let { employerId, candidateId, roleId } = useParams();
  const [experienceTimeframe, setExperienceTimeframe] = useState("months");
  const [candidate, setCandidate] = useState();
  const [employer, setEmployer] = useState();
  const [employerContacted, setEmployerContacted] = useState(false);
  const [candidateCalled, setCandidateCalled] = useState(false);
  const [interviewDay, setInterviewDay] = useState();
  const [interviewTime, setInterviewTime] = useState();
  const [interviewConfirmed, setInterviewConfirmed] = useState();
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [interviewsThisWeek, setInterviewsThisWeek] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [interviewTimestamp, setInterviewTimestamp] = useState();
  const [candidateContacted, setCandidateContacted] = useState(false);
  const [candidateRejected, setCandidateRejected] = useState(false);
  const [candidateInvited, setCandidateInvited] = useState(false);
  const [candidateAvailabilityShown, setCandidateAvailabilityShown] =
    useState(false);
  const [candidateContactedText, setCandidateContactedText] = useState("Candidate Invited");
  const [premiumBranches, setPremiumBranches] = useState([]);

  const navigate = useNavigate();

  useEffect( () => {
    const fetchData = async () => {
      Promise.all([
        getCandidateInfo(candidateId),
        getBranch(employerId),
        getInterviewsThisWeek(employerId),
      ]).then(async ([c, e, i]) => {
        setCandidate(c);
        if (
            typeof c["Interesting Employers"][employerId] === "object" &&
            Object.keys(c["Interesting Employers"][employerId]).every((date) =>
                moment(date).isSameOrBefore(moment(), "day")
            )
        ) {
          setInterviewTimestamp(
              Object.keys(c["Interesting Employers"][employerId])[0]
          );
        } else if (c["Interesting Employers"][employerId]) {
          setInterviewTimestamp(
              typeof c["Interesting Employers"][employerId] === "object"
                  ? "candidate-availability"
                  : c["Interesting Employers"][employerId]
          );
          setCandidateAvailabilityShown(
              typeof c["Interesting Employers"][employerId] === "object"
          );
        }
        //check if candidate has been contacted
        if (e.DailyContact === moment().format("YYYY-MM-DD")) {
          setCandidateContacted(true);
        }
        let pb = await getPremiumbranches(e);
        setPremiumBranches(pb);
        setEmployer(e);
        setInterviewsThisWeek(i);
      });
    };
    fetchData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (candidateRejected) {
      rejectCandidate(employerId, candidate.id, roleId);
      setCandidateContactedText("Candidate Rejected");
    }
  }, [candidateRejected]);

  const setInterviewCancelled = async () => {
    await cancelInterview(
      employerId,
      candidateId,
      interviewTimestamp,
      true
    ).then(() => {
      setEmployer({ ...employer, optOut: false });
      setInterviewConfirmed(null);
      setCancellationModalOpen(false);
    });
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f2f0ed",
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px",
        alignItems: isMobile ? "stretch" : "center",
      }}
    >
      {candidateRejected && employer && (
        <Modal
          isOpen={candidateRejected}
          closeModal={() => setCandidateRejected(false)}
          style={{
            backgroundColor: "#f2f0ed",
            zIndex: Number.MAX_SAFE_INTEGER,
            overflow: "revert",
          }}
        >
          <RejectedInput
            candidate={candidate}
            employerId={employer.id}
            employer={employer}
            roleId={roleId}
            style={{ width: isMobile ? "80vw" : "375px" }}
          ></RejectedInput>
        </Modal>
      )}
      <a
        href={`/`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          padding: "16px",
          opacity: "0.25",
        }}
      >
        <img
          src="/images/hirebolt-logo-black.png"
          loading="lazy"
          width="130"
          alt=""
          className="shopscribe-logo shopscribe-logo-white"
        />
      </a>
      {employer &&(
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "16px",
            lineHeight: "24px",
            padding: 0,
            height: "fit-content",
            position: "absolute",
            top: "24px",
            right: "16px",
            cursor: "pointer",
            color: "#162e3c",
            fontWeight: "bold",
          }}
          onClick={() =>
            navigate(
              `/${employer.employerId}/${getEmployerSecret(
                employer.employerId
              )}`
            )
          }
        >
          <p>Your settings</p>
          <span
            className="icon-small"
            style={{
              fontSize: "20px",
              position: "relative",
              top: "-4px",
              marginLeft: "4px",
              color: "#49616e",
            }}
          >
            settings
          </span>
        </div>
      )}
      {candidate && employer && candidateAvailabilityShown && (
        <div style={{ margin: "auto", padding: "24px" }}>
          <h4>
            Schedule the{" "}
            {getInterviewType(getRoleFromId(employer, roleId)).toLowerCase()}{" "}
            with {candidate["Name"]}
          </h4>
          <p style={{ marginBottom: "48px" }}>
            They provided their own availability, so you can pick a time that
            works for you.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              maxHeight: "63vh",
            }}
          >
            <div
              style={{
                display: "flex",
                margin: "15px -10px 0 -10px",
              }}
            >
              {Object.keys(candidate["Interesting Employers"][employerId])
                .filter((date) => moment(date).isAfter(moment(), "day"))
                .map((date, i) => {
                  return (
                    <div
                      style={{
                        flex: "1 0 21%",
                        margin: "10px",
                        height: "fit-content",
                        flexShrink: 0,
                      }}
                      key={"day" + i}
                    >
                      <div
                        style={{
                          height: "80px",
                          borderBottom: "1px solid rgba(26,26,26,0.1)",
                          textAlign: "center",
                          verticalAlign: "center",
                        }}
                      >
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            {moment(date)
                              .format(isMobile ? "ddd" : "dddd")
                              .toUpperCase()}
                          </span>
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            {moment(date).format("Do MMM")}
                          </span>
                          <br />
                          <span
                            style={{
                              opacity: 0.5,
                              fontSize: "12px",
                              fontWeight: "bold",
                              lineHeight: "1px",
                            }}
                          >
                            {moment(date).isSame(moment(), "day") && " (today)"}
                          </span>
                        </p>
                      </div>
                      {formatInterviewTimes(
                        candidate["Interesting Employers"][employerId][date],
                        getRoleFromId(employer, roleId).roleInterviewSlots
                          ? getRoleFromId(employer, roleId)
                              .roleInterviewSlots[0].duration
                          : employer.interviewSlots[0].duration
                      )
                        .sort(
                          (a, b) =>
                            parseInt(a.split(":")[0]) -
                            parseInt(b.split(":")[0])
                        )
                        .map((time, j) => {
                          return (
                            <div
                              className={
                                selectedSlot ===
                                moment(
                                  date + " " + time,
                                  "YYYY-MM-DD H:mm"
                                ).format()
                                  ? "interview-slot-active"
                                  : "interview-slot"
                              }
                              key={"day" + i + "slot" + j}
                              style={{
                                borderRadius: "15px",
                                padding: "15px",
                                margin: "20px 0",
                                textAlign: "center",
                                cursor: "pointer",
                                height: "55px",
                              }}
                              onClick={() => {
                                if (
                                  selectedSlot ===
                                  moment(
                                    date.substring(0, 10) + " " + time,
                                    "YYYY-MM-DD H:mm"
                                  ).format()
                                ) {
                                  sendInterviewConfirmation(
                                    employer.id,
                                    candidate["Id"],
                                    getRoleFromId(employer, roleId),
                                    moment(selectedSlot).format()
                                  );
                                  setInterviewConfirmed(
                                    moment(selectedSlot).format()
                                  );
                                  setInterviewTimestamp(
                                    moment(selectedSlot).format()
                                  );
                                  setCandidateAvailabilityShown(false);
                                } else {
                                  setSelectedSlot(
                                    moment(
                                      date.substring(0, 10) + " " + time,
                                      "YYYY-MM-DD H:mm"
                                    ).format()
                                  );
                                }
                              }}
                            >
                              {selectedSlot ===
                              moment(
                                date.substring(0, 10) + " " + time,
                                "YYYY-MM-DD H:mm"
                              ).format() ? (
                                <p
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  CONFIRM
                                </p>
                              ) : (
                                <div>
                                  <p>
                                    <b>{time}</b>
                                  </p>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {employer && candidate && !candidateAvailabilityShown && (
        <>
          {!employer.premium && interviewsThisWeek > 0 ? (
            <div
              style={{
                width: "100vw",
                height: "100vh",
                backgroundColor: "#f2f0ed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "16px 16px 128px 16px",
                }}
              >
                <Lottie
                  animationData={errorAnimation}
                  play
                  loop
                  style={{ width: "150px", height: "150px" }}
                />
                {employer.employerBranchCount > 1 && (
                  <p
                    style={{
                      fontSize: "smaller",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      oopacity: "0.66",
                    }}
                  >
                    {employer.branchName} branch
                  </p>
                )}
                <h3 style={{ textAlign: "center" }}>
                  Weekly interview limit reached
                </h3>
                <p style={{ textAlign: "center", margin: "16px auto" }}>
                  Your plan includes 1 interview/week. Upgrade to Premium to
                  schedule unlimited interviews
                </p>
                <button
                  className="button w-button animate__animated animate__headShake animate__delay-4s"
                  onClick={() => {
                    navigate(
                      `/${employer.employerId}/${getEmployerSecret(
                        employer.employerId
                      )}/upgrade`
                    );
                  }}
                  style={{
                    borderRadius: "8px",
                    fontWeight: "bold",
                    width: "fit-content",
                    border: "none",
                    opacity: 1,
                  }}
                >
                  Upgrade your Account 🚀
                </button>
              </div>
            </div>
          ) : (
            <>
              <Modal
                isOpen={candidateCalled}
                closeModal={() => setCandidateCalled(false)}
                style={{ overflow: "revert" }}
              >
                <h4>Did you just call {candidate["Name"]} to reschedule?</h4>
                <p>
                  If so, input the time you scheduled to send them a calendar
                  invite and automatic reminders
                </p>
                <div className="row-all-devices" style={{ marginTop: "16px" }}>
                  <Select
                    options={new Array(15)
                      .fill("")
                      .map((day, i) =>
                        moment().add(i, "days").format("ddd D MMM")
                      )}
                    placeholder={"Day"}
                    style={{ marginRight: "16px", flex: 3 }}
                    allowMultiple={false}
                    allowSearch={false}
                    onChange={(v) => {
                      setInterviewDay(moment(v, "ddd D MMM"));
                    }}
                    selected={interviewDay?.format("ddd D MMM")}
                  />
                  <Select
                    options={listTimesOfDay()}
                    placeholder={"Time"}
                    style={{ flex: 2 }}
                    allowMultiple={false}
                    allowSearch={false}
                    onChange={(v) => {
                      setInterviewTime(v);
                    }}
                    selected={interviewTime}
                  />
                </div>
                <button
                  className="button w-button"
                  style={{
                    borderRadius: "8px",
                    width: "100%",
                    padding: "0.5rem",
                    marginTop: "16px",
                    opacity: interviewDay && interviewTime ? 1 : 0.5,
                  }}
                  onClick={() => {
                    if (interviewDay && interviewTime) {
                      if (employer.optOut) {
                        sendReschedulingConfirmation(
                          null,
                          moment(
                            `${moment(interviewDay).format(
                              "DD-MM-YYYY"
                            )} ${interviewTime}`,
                            "DD-MM-YYYY hh:mm"
                          ).format(),
                          candidateId,
                          employerId,
                          false
                        );
                      } else {
                        sendInterviewConfirmation(
                          employer.id,
                          candidate["Id"],
                          getRoleFromId(employer, roleId),
                          moment(
                            `${moment(interviewDay).format(
                              "DD-MM-YYYY"
                            )} ${interviewTime}`,
                            "DD-MM-YYYY hh:mm"
                          ).format()
                        );
                      }
                      setInterviewConfirmed(
                        moment(
                          `${moment(interviewDay).format(
                            "DD-MM-YYYY"
                          )} ${interviewTime}`,
                          "DD-MM-YYYY hh:mm"
                        ).format()
                      );
                      setCandidateCalled(false);
                    }
                  }}
                >
                  Send{" "}
                  <span
                    className="icon-small"
                    style={{
                      display: "inline-block",
                      fontSize: "20px",
                      lineHeight: "16px",
                      margin: 0,
                      padding: 0,
                      height: "16px",
                      width: "20px",
                      position: "relative",
                      top: "4px",
                      left: "8px",
                      color: "white",
                    }}
                  >
                    send
                  </span>
                </button>
              </Modal>
              <Modal
                isOpen={cancellationModalOpen}
                closeModal={() => setCancellationModalOpen(false)}
              >
                <div style={{ marginTop: "24px" }}>
                  Are you sure you want to cancel the{" "}
                  {getInterviewType(
                    getRoleFromId(employer, roleId)
                  ).toLowerCase()}{" "}
                  with {candidate["Name"]}?
                  <br />
                  <br />
                  You can also go back and reschedule it to a later date.
                  <div
                    className="row-all-devices"
                    style={{ marginTop: "32px", gap: "16px" }}
                  >
                    <button
                      className="button w-button"
                      style={{
                        padding: "8px 32px",
                        borderRadius: "8px",
                        flex: 1,
                      }}
                      onClick={() => setInterviewCancelled()}
                    >
                      Yes, Cancel
                    </button>
                    <button
                      className="button w-button button-tertiary"
                      style={{
                        padding: "8px 32px",
                        borderRadius: "8px",
                        flex: 1,
                      }}
                      onClick={() => setCancellationModalOpen(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              </Modal>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingBottom: "64px",
                }}
              >
                <div
                  id="candidate-info"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    maxWidth: "700px",
                  }}
                >
                  <div className="row-all-devices" style={{ gap: "16px" }}>
                    <a
                      style={{
                        flex: 1,
                        height: "100px",
                        borderRadius: "16px",
                        backgroundColor: "#ebe9e6",
                        cursor: "pointer",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        textDecoration: "none",
                      }}
                      href={candidate["CV"]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p
                        className="icon-small"
                        style={{
                          margin: "0 auto",
                          fontSize: "54px",
                          height: "54px",
                          color: "#49616e",
                        }}
                      >
                        contact_page
                      </p>
                      <p style={{ fontSize: "smaller", fontWeight: "bold" }}>
                        VIEW CV
                      </p>
                    </a>
                    <div
                      style={{
                        flex: 3,
                        height: "fit-content",
                        margin: "auto 0",
                      }}
                    >
                      <h5
                        style={{
                          fontWeight: "bold",
                          color: "#162e3c",
                        }}
                      >
                        {candidate["Name"]}
                      </h5>
                      <p style={{ fontSize: "smaller", fontWeight: "bold" }}>
                        {getRoleFromId(employer, roleId).name} · Based in{" "}
                        {candidate["Postcode"].toUpperCase()}
                        {employer.employerBranchCount > 1
                          ? ` · ${employer.branchName} branch`
                          : ""}
                      </p>
                      <p
                        style={{
                          fontSize: "smaller",
                          lineHeight: "1.5rem",
                          width: "fit-content",
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          gap: "4px",
                        }}
                      >
                        <span
                          className={employer.premium ? "" : "no-select"}
                          style={{
                            textDecoration: "none",
                            borderBottom: "1px solid #d5d0ca",
                            color: "inherit",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            if (employer.premium) {
                              recordCandidateCalled(
                                employer.id,
                                candidate["Id"]
                              );
                              window.open(
                                `tel:${candidate["WhatsApp Number"]}`,
                                "_self"
                              );
                            } else {
                              window.open(
                                `/${employer.employerId}/${getEmployerSecret(
                                  employer.employerId
                                )}/upgrade`,
                                "_blank"
                              );
                            }
                          }}
                        >
                          <span
                            className="icon-small"
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                              lineHeight: "16px",
                              margin: 0,
                              padding: 0,
                              height: "16px",
                              width: "24px",
                              position: "relative",
                              top: "4px",
                              right: "8px",
                              left: "4px",
                            }}
                          >
                            call
                          </span>
                          <span
                            style={{
                              color: employer.premium
                                ? "inherit"
                                : "transparent",
                              textShadow: employer.premium
                                ? "none"
                                : "0 0 8px #000",
                              marginRight: "8px",
                            }}
                          >
                            {employer.premium
                              ? candidate["WhatsApp Number"]
                              : "+44 1234567890"}
                          </span>
                        </span>
                        {!employer.premium && (
                          <span
                            style={{
                              fontSize: "smaller",
                              fontWeight: "bold",
                              backgroundColor: "rgba(0,0,0,0.05)",
                              borderRadius: "4px",
                              padding: "0 8px",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(
                                `/${employer.employerId}/${getEmployerSecret(
                                  employer.employerId
                                )}/upgrade`,
                                "_blank"
                              );
                            }}
                          >
                            Upgrade to Premium 🚀
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "fit-content",
                    borderRadius: "16px",
                    backgroundColor: "rgb(235, 233, 230)",
                    margin: "0 16px",
                    maxWidth: "700px",
                  }}
                >
                  {interviewTimestamp &&
                  moment(interviewTimestamp).isAfter(moment()) ? (
                    <>
                      {interviewConfirmed ? (
                        <div>
                          <div style={{ textAlign: "center", margin: "16px" }}>
                            <Lottie
                              animationData={confirmAnimation}
                              loop
                              play
                              style={{
                                width: "100px",
                                height: "100px",
                                margin: "0 auto",
                              }}
                            />
                            <h5>Interview confirmed</h5>
                            <br />
                            <p>
                              {`${candidate["Name"]} will attend on `}
                              <b>{`${moment(interviewConfirmed).format(
                                "ddd D MMM"
                              )} at ${moment(interviewConfirmed).format(
                                "h:mma"
                              )}`}</b>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              padding: "24px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {employer.optOut && (
                              <Lottie
                                animationData={confirmAnimation}
                                loop
                                speed={0.75}
                                play
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  margin: "0 auto",
                                }}
                              />
                            )}
                            <p
                              style={{ margin: "0 auto", textAlign: "center" }}
                            >
                              {employer.optOut
                                ? `${getInterviewType(
                                    getRoleFromId(employer, roleId)
                                  )}${
                                    employer.employerBranchCount > 1
                                      ? ` at your ${employer.branchName} branch `
                                      : " "
                                  }on:`
                                : `Can come for an ${getInterviewType(
                                    getRoleFromId(employer, roleId)
                                  ).toLowerCase()}${
                                    employer.employerBranchCount > 1
                                      ? ` at your ${employer.branchName} branch `
                                      : " "
                                  }on:`}
                            </p>
                            <h3
                              style={{
                                margin: "auto",
                                marginTop: "8px",
                                textAlign: "center",
                                lineHeight: "2.5rem",
                                fontSize: "1.75rem",
                              }}
                            >
                              {moment(interviewTimestamp).format("ddd D MMM")} -{" "}
                              {moment(interviewTimestamp).format("h:mma")}
                            </h3>
                          </div>
                          {!isMobile && (
                            <div
                              id="buttons-container"
                              style={{
                                width: "100%",
                                height: "fit-content",
                                padding: "16px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {employer.optOut ? (
                                <button
                                  className="button w-button"
                                  style={{
                                    padding: "8px 32px",
                                    borderRadius: "8px",
                                  }}
                                  onClick={() => setCancellationModalOpen(true)}
                                >
                                  <span
                                    className="icon-small"
                                    style={{
                                      display: "inline-block",
                                      fontSize: "20px",
                                      lineHeight: "16px",
                                      margin: 0,
                                      padding: 0,
                                      height: "16px",
                                      width: "20px",
                                      position: "relative",
                                      top: "4px",
                                      right: "8px",
                                      color: "white",
                                    }}
                                  >
                                    cancel
                                  </span>
                                  Cancel{" "}
                                  {getInterviewType(
                                    getRoleFromId(employer, roleId)
                                  )}
                                </button>
                              ) : (
                                <button
                                  className="button w-button"
                                  style={{
                                    padding: "8px 32px",
                                    borderRadius: "8px",
                                  }}
                                  onClick={() => {
                                    sendInterviewConfirmation(
                                      employer.id,
                                      candidate["Id"],
                                      getRoleFromId(employer, roleId),
                                      interviewTimestamp
                                    );
                                    setInterviewConfirmed(interviewTimestamp);
                                  }}
                                >
                                  <span
                                    className="icon-small"
                                    style={{
                                      display: "inline-block",
                                      fontSize: "20px",
                                      lineHeight: "16px",
                                      margin: 0,
                                      padding: 0,
                                      height: "16px",
                                      width: "20px",
                                      position: "relative",
                                      top: "4px",
                                      right: "8px",
                                      color: "white",
                                    }}
                                  >
                                    check
                                  </span>
                                  Confirm{" "}
                                  {getInterviewType(
                                    getRoleFromId(employer, roleId)
                                  ).toLowerCase()}
                                </button>
                              )}
                              <a
                                className="button w-button"
                                style={{
                                  padding: "8px 32px",
                                  marginTop: "16px",
                                  borderRadius: "8px",
                                  textDecoration: "none",
                                }}
                                href={`/${employer.employerId}/${employer.id}/${
                                  candidate["Id"]
                                }/${getEmployerSecret(
                                  employer.employerId
                                )}/availability`}
                              >
                                <span
                                  className="icon-small"
                                  style={{
                                    display: "inline-block",
                                    fontSize: "20px",
                                    lineHeight: "16px",
                                    margin: 0,
                                    padding: 0,
                                    height: "16px",
                                    width: "20px",
                                    position: "relative",
                                    top: "4px",
                                    right: "8px",
                                    color: "white",
                                  }}
                                >
                                  calendar_today
                                </span>
                                Ask to Reschedule
                              </a>
                              {[undefined, true].includes(employer.active) && (
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/${
                                        employer.employerId
                                      }/${getEmployerSecret(
                                        employer.employerId
                                      )}?pause_branch=${employer.id}`
                                    )
                                  }
                                  style={{
                                    borderBottom: "1px solid",
                                    width: "fit-content",
                                    margin: "16px auto 8px",
                                    textDecoration: "none",
                                  }}
                                >
                                  I'm Not Hiring Now
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : interviewTimestamp &&
                    moment(interviewTimestamp).isBefore(moment()) ? (
                    <div
                      style={{
                        padding: "24px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          padding: "15px",
                          borderRadius: "16px",
                          backgroundColor: "#ffeae6",
                          margin: "0",
                          fontSize: "smaller",
                        }}
                      >
                        <p>
                          <img
                            src="/images/exclamation-mark.svg"
                            alt=""
                            width="15"
                            height="15"
                            style={{
                              marginTop: "-3px",
                              marginRight: "7px",
                              color: "red",
                            }}
                          />
                          {employer.optOut ? (
                            <span>
                              This interview was scheduled for a past date.
                              Please call {candidate["Name"]} to find a new time
                              or click below to invite them to schedule a new
                              slot.
                            </span>
                          ) : (
                            <span>
                              {" "}
                              The interview slot selected by the candidate has
                              unfortunately expired. Please call{" "}
                              {candidate["Name"]} to find a new time or click
                              below to invite them to schedule a new slot.
                            </span>
                          )}
                        </p>
                      </div>
                      <br />
                      {candidateInvited ? (
                        <p style={{ width: "100%", textAlign: "center" }}>
                          Candidate Invited{" "}
                          <span
                            className="icon-small"
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                              lineHeight: "16px",
                              margin: "auto 8px auto 0",
                              padding: 0,
                              height: "16px",
                              width: "16px",
                              position: "relative",
                            }}
                          >
                            check
                          </span>
                        </p>
                      ) : (
                        <button
                          className="button w-button"
                          style={{
                            padding: "8px 16px",
                            borderRadius: "8px",
                            flex: "1 1 0px",
                            width: "100%",
                          }}
                          onClick={() => {
                            sendCandidateInterest(
                              employer.id,
                              candidate["Id"],
                              roleId
                            );
                            setCandidateInvited(true);
                          }}
                        >
                          <span
                            className="icon-small"
                            style={{
                              display: "inline-block",
                              fontSize: "16px",
                              lineHeight: "16px",
                              margin: "auto 8px auto 0",
                              padding: 0,
                              height: "16px",
                              width: "16px",
                              position: "relative",
                            }}
                          >
                            check
                          </span>
                          Invite for Interview
                        </button>
                      )}
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          padding: "24px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ maxWidth: "500px" }}>
                          <b>
                            This candidate didn't book an interview slot, do you
                            want to invite them for an interview?
                          </b>
                        </p>
                        <br />
                        {candidateInvited ? (
                          <p style={{ width: "100%", textAlign: "center" }}>
                            {candidateContactedText}
                            <span
                              className="icon-small"
                              style={{
                                display: "inline-block",
                                fontSize: "16px",
                                lineHeight: "16px",
                                margin: "auto 8px auto 0",
                                padding: 0,
                                height: "16px",
                                width: "16px",
                                position: "relative",
                              }}
                            >
                              check
                            </span>
                          </p>
                        ) : (
                          <div>
                            <button
                              className="button w-button"
                              style={{
                                padding: "8px 16px",
                                borderRadius: "8px",
                                flex: "1 1 0px",
                                width: "100%",
                                marginBottom: "15px",
                              }}
                              onClick={() => {
                                sendCandidateInterest(
                                  employer.id,
                                  candidate["Id"],
                                  roleId
                                );
                                setCandidateInvited(true);
                              }}
                            >
                              <span
                                className="icon-small"
                                style={{
                                  display: "inline-block",
                                  fontSize: "16px",
                                  lineHeight: "16px",
                                  margin: "auto 8px auto 0",
                                  padding: 0,
                                  height: "16px",
                                  width: "16px",
                                  position: "relative",
                                }}
                              >
                                check
                              </span>
                              Invite for Interview
                            </button>
                            <button
                              className="button w-button button-tertiary"
                              style={{
                                padding: "8px 16px",
                                borderRadius: "8px",
                                flex: "1 1 0px",
                                width: "100%",
                              }}
                              onClick={() => {
                                setCandidateRejected(true);
                                setCandidateContacted(true);
                              }}
                            >
                              <span
                                className="icon-small"
                                style={{
                                  display: "inline-block",
                                  fontSize: "16px",
                                  lineHeight: "16px",
                                  margin: "auto 8px auto 0",
                                  padding: 0,
                                  height: "16px",
                                  width: "16px",
                                  position: "relative",
                                }}
                              >
                                X
                              </span>
                              Reject candidate
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isMobile && interviewTimestamp && (
                <div>
                  {!moment(interviewTimestamp).isBefore(moment()) &&
                    !interviewConfirmed && (
                      <div
                        id="buttons-container"
                        className="row-all-devices"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          margin: "16px",
                          borderRadius: "16px",
                          height: "fit-content",
                          padding: "8px 16px",
                          backgroundColor: "white",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {employer.optOut ? (
                          <button
                            style={{
                              padding: "8px 16px",
                              borderRadius: "16px",
                              flex: "1 1 0px",
                              color: "#8b0000",
                            }}
                            onClick={() => setCancellationModalOpen(true)}
                          >
                            <span
                              className="icon-small"
                              style={{
                                fontSize: "32px",
                                lineHeight: "32px",
                                margin: "auto",
                                padding: 0,
                                height: "32px",
                                width: "32px",
                                position: "relative",
                              }}
                            >
                              cancel
                            </span>
                            Reject
                          </button>
                        ) : (
                          <button
                            style={{
                              padding: "8px 16px",
                              flex: "1 1 0px",
                              borderRadius: "16px",
                              color: "green",
                            }}
                            onClick={() => {
                              sendInterviewConfirmation(
                                employer.id,
                                candidate["Id"],
                                getRoleFromId(employer, roleId),
                                interviewTimestamp
                              );
                              setInterviewConfirmed(interviewTimestamp);
                              setEmployer({ ...employer, optOut: true });
                            }}
                          >
                            <span
                              className="icon-small"
                              style={{
                                display: "inline-block",
                                fontSize: "36px",
                                lineHeight: "32px",
                                margin: "auto",
                                padding: 0,
                                height: "32px",
                                width: "32px",
                                position: "relative",
                              }}
                            >
                              check_box
                            </span>
                            Confirm
                          </button>
                        )}
                        <button
                          style={{
                            padding: "8px 16px",
                            borderRadius: "16px",
                            flex: "1 1 0px",
                          }}
                          onClick={() => {
                            navigate(
                              `/${employer.employerId}/${employer.id}/${
                                candidate["Id"]
                              }/${getEmployerSecret(
                                employer.employerId
                              )}/availability`
                            );
                          }}
                        >
                          <span
                            className="icon-small"
                            style={{
                              display: "inline-block",
                              fontSize: "28px",
                              lineHeight: "32px",
                              margin: "auto",
                              padding: 0,
                              height: "32px",
                              width: "32px",
                              position: "relative",
                            }}
                          >
                            calendar_today
                          </span>
                          Reschedule
                        </button>
                        <button
                          style={{
                            padding: "8px 16px",
                            borderRadius: "16px",
                            flex: "1 1 0px",
                            whiteSpace: "nowrap",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={async () => {
                            recordCandidateCalled(employer.id, candidate["Id"]);
                            window.open(
                              `tel:${candidate["WhatsApp Number"]}`,
                              "_self"
                            );
                            await wait(2000);
                            setCandidateCalled(true);
                          }}
                        >
                          <span
                            className="icon-small"
                            style={{
                              display: "inline-block",
                              fontSize: "36px",
                              lineHeight: "32px",
                              margin: "auto",
                              padding: 0,
                              height: "32px",
                              width: "32px",
                              position: "relative",
                            }}
                          >
                            pause
                          </span>
                          Not Hiring
                        </button>
                      </div>
                    )}
                </div>
              )}
            </>
          )}
        </>
      )}
      {(!candidate || !employer) && (
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
          }}
        >
          <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
        </div>
      )}
    </div>
  );
};

export default CandidateProfile;
