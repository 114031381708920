import { useEffect, useState } from "react";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import Select from "./Select";
import { listTimesOfDay } from "../../utils";
import { isMobile } from "react-device-detect";

// AVAILABILITY OBJECT
// [
//   {date:"", slots:[{ start:"", end:"" }]}
//   {date:"", slots:[{ start:"", end:"" }, { start:"", end:"" }]}
// ]

const Availability = ({
  availability = null,
  dates = null,
  onChange = () => {},
  style = {},
}) => {
  const [slots, setNewSlots] = useState();

  useEffect(() => {
    if (slots) return;
    if (availability) {
      setNewSlots(
        (dates ?? [...moment.weekdays().slice(1), "Sunday"]).map((day) => ({
          date: day,
          slots: availability.find((x) => x.date === day)?.slots ?? [],
        }))
      );
    } else {
      setNewSlots(
        (dates ?? [...moment.weekdays().slice(1), "Sunday"]).map((d) => ({
          date: d,
          slots: [{ start: "09:00", end: "20:00" }],
        }))
      );
    }
    // eslint-disable-next-line
  }, []);

  const setSlots = (s) => {
    if (!s || s.length === 0) return;
    setNewSlots(s);
    onChange(s);
  };

  return (
    <div
      style={{
        borderRadius: "5px",
        ...style,
      }}
    >
      {slots &&
        slots.map((day, i) => {
          return (
            <div
              key={day.date}
              style={{
                padding: "0 0 8px 0",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                borderBottom:
                  i ===
                  (dates ?? [...moment.weekdays().slice(1), "Sunday"]).length -
                    1
                    ? "none"
                    : "1px solid rgba(0, 0, 0, 0.05)",
              }}
            >
              <p
                className="sub-label"
                style={{
                  width: "100px",
                  marginTop: "24px",
                  fontSize: "14px",
                  opacity: ".66",
                }}
              >
                {day.date}
              </p>
              {day.slots.length === 0 ? (
                <div className="row-all-devices" style={{ marginTop: "8px" }}>
                  <p
                    style={{
                      fontStyle: "italic",
                      margin: "16px 0",
                      width: "100px",
                    }}
                  >
                    Unavailable
                  </p>
                  <div
                    className="icon-small"
                    data-tip="Add new slot for this day"
                    data-for="slot-interaction"
                    style={{
                      display: "inline",
                      cursor: "pointer",
                      fontSize: "24px",
                      lineHeight: "24px",
                      position: "relative",
                      height: "24px",
                      margin: "auto 0 auto 8px",
                      width: "24px",
                    }}
                    onClick={() => {
                      let s = JSON.parse(JSON.stringify(slots));
                      s[i].slots.push({
                        start: "09:00",
                        end: "20:00",
                      });
                      setSlots(s);
                    }}
                  >
                    add
                  </div>
                </div>
              ) : (
                <div>
                  {day.slots.map((slot, slotIndex) => (
                    <div
                      className="row-all-devices"
                      style={{ display: "flex" }}
                      key={"slot-" + slotIndex}
                    >
                      <Select
                        allowMultiple={false}
                        showDropdownIcon={false}
                        style={{
                          width: isMobile ? "100px" : "150px",
                          maxWidth: isMobile ? "100px" : "150px",
                          minWidth: isMobile ? "100px" : "150px",
                        }}
                        options={listTimesOfDay()}
                        selected={slot.start}
                        onChange={(value) => {
                          let s = JSON.parse(JSON.stringify(slots));
                          s[i].slots[slotIndex].start = value;
                          setSlots(s);
                        }}
                      />
                      <div style={{ margin: "auto 8px" }}>-</div>
                      <Select
                        allowMultiple={false}
                        showDropdownIcon={false}
                        style={{
                          width: isMobile ? "100px" : "150px",
                          maxWidth: isMobile ? "100px" : "150px",
                          minWidth: isMobile ? "100px" : "150px",
                        }}
                        options={listTimesOfDay().filter((t) => t > slot.start)}
                        selected={slot.end}
                        onChange={(value) => {
                          let s = JSON.parse(JSON.stringify(slots));
                          s[i].slots[slotIndex].end = value;
                          setSlots(s);
                        }}
                      />
                      <div
                        className="icon-small"
                        data-tip="Delete this slot"
                        data-for="slot-interaction"
                        style={{
                          display: "inline",
                          cursor: "pointer",
                          fontSize: "24px",
                          lineHeight: "24px",
                          position: "relative",
                          height: "24px",
                          margin: "auto 0 auto 16px",
                          width: "24px",
                        }}
                        onClick={() => {
                          let s = JSON.parse(JSON.stringify(slots));
                          s[i].slots.splice(slotIndex, 1);
                          setSlots(s);
                        }}
                      >
                        delete_outline
                      </div>
                      {slotIndex === 0 ? (
                        <div
                          className="icon-small"
                          data-tip="Add new slot for this day"
                          data-for="slot-interaction"
                          style={{
                            display: "inline",
                            cursor: "pointer",
                            fontSize: "24px",
                            lineHeight: "24px",
                            position: "relative",
                            height: "24px",
                            margin: "auto 0 auto 16px",
                            width: "24px",
                          }}
                          onClick={() => {
                            let s = JSON.parse(JSON.stringify(slots));
                            s[i].slots.push({
                              start: slot.end,
                              end: `${parseInt(slot.end.split(":")[0]) + 1}:00`,
                            });
                            setSlots(s);
                          }}
                        >
                          add
                        </div>
                      ) : (
                        <div style={{ width: "32px" }}></div>
                      )}
                      <ReactTooltip
                        effect="solid"
                        id="slot-interaction"
                        place="top"
                        delayShow={300}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Availability;
