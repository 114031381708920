import CryptoJS from "crypto-js";
import moment from "moment-timezone";

// Return all roles that can be hired for
export const getAllRoles = () => {
  return [
    "Waiter/Waitress",
    "Head Waiter/Waitress",
    "Bartender",
    "Barista",
    "Bar-Back",
    "Food Runner",
    "Host/Hostess",
    "Fast Food / Fast Casual Staff",
    "Restaurant Supervisor/Manager",
    "Cafe Supervisor/Manager",
    "Bar Supervisor/Manager",
    "General Manager",
    "Kitchen Porter",
  ];
};

export const getPremiumRoles = () => {
  return [
    "Restaurant Supervisor/Manager",
    "Cafe Supervisor/Manager",
    "Bar Supervisor/Manager",
    "General Manager",
  ];
};

// Return the role category, for some roles
export const getExperienceCategories = (roles) => {
  const allCategories = {
    "Waiter/Host": [
      "Waiter/Waitress",
      "Head Waiter/Waitress",
      "Food Runner",
      "Host/Hostess",
      "Restaurant Supervisor/Manager",
    ],
    "Bartender/Bar-Back": ["Bartender", "Bar-Back", "Bar Supervisor/Manager"],
    Barista: ["Barista", "Cafe Supervisor/Manager"],
    "Kitchen Porter": ["Kitchen Porter", "Commis Chef (Junior Chef)"],
    "Manager/Supervisor": [
      "Restaurant Supervisor/Manager",
      "Bar Supervisor/Manager",
      "Cafe Supervisor/Manager",
      "General Manager",
    ],
    "General Manager": ["General Manager"],
    "Fast Food/Fast Casual": ["Fast Food / Fast Casual Staff"],
  };
  let matchingCategories = [];
  if (roles) {
    roles.forEach((role) => {
      Object.keys(allCategories).forEach((category) => {
        if (allCategories[category].includes(role))
          matchingCategories.push(category);
      });
    });
  }
  return [...new Set(matchingCategories)];
};

// Get the role name from role id (e.g. "waiterwaitress" -> "Waiter/Waitress")
export const getRoleFromId = (employer, roleId) => {
  for (let position of employer.openPositions) {
    let role = position.role.find(
      (role) =>
        (role.includes("Supervisor") && roleId.includes("supervisor")) ||
        role.replace(/[^a-zA-Z]/g, "").toLowerCase() === roleId
    );
    if (role) return { ...position, name: role };
  }
};

export const shuffleList = (a) => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

export const wait = (ms) => new Promise((res) => setTimeout(res, ms));

export const getEmployerSecret = (employerId) => {
  return CryptoJS.HmacSHA256(employerId, "wowThisIsSoSecret")
    .toString(CryptoJS.enc.Hex)
    .substring(0, 6);
};

export const listTimesOfDay = () => {
  return new Array(24)
    .fill(0)
    .map((d, i) => {
      let h = i > 9 ? i : `0${i}`;
      return [`${h}:00`, `${h}:15`, `${h}:30`, `${h}:45`];
    })
    .flat();
};

export const getInterviewType = (role) => {
  return role?.interviewType && typeof role.interviewType === "string"
    ? role.interviewType
    : "Interview/trial";
};

export const formatInterviewTimes = (slots, duration) => {
  let startTimes = [];
  for (let slot of slots) {
    if (!slot) continue;
    let currentTime = slot.start;
    do {
      startTimes.push(currentTime);
      currentTime = moment(currentTime, "HH:mm")
        .add(duration, "minutes")
        .format("HH:mm");
    } while (
      moment(currentTime, "HH:mm")
        .add(duration, "minutes")
        .isSameOrBefore(moment(slot.end, "HH:mm"))
    );
  }
  return startTimes;
};

export const getStartEndTimes = (times, duration) => {
  if (!times.length) return [];
  //INPUT: times=["10:00", "11:00, "12:00", "15:00", "16:00"], duration=60
  //OUTPUT: [{ start:"10:00", end:"13:00" },{ start:"15:00", end:"17:00" }]
  let output = [];
  let currentSlot;
  times
    .sort((a, b) => moment(a, "H:mm").diff(moment(b, "H:mm")))
    .forEach((time, i) => {
      if (i === 0) {
        // Set the start time
        currentSlot = { start: time, end: "" };
      } else {
        // Check how much time has passed from previous slot
        let timePassed = moment(times[i], "HH:mm").diff(
          moment(times[i - 1], "HH:mm"),
          "minutes"
        );
        // If > duration, close slot
        if (timePassed > duration) {
          currentSlot.end = moment(times[i - 1], "HH:mm")
            .add(duration, "minutes")
            .format("HH:mm");
          output.push({ ...currentSlot });
          currentSlot = { start: times[i], end: "" };
        }
      }
      if (i === times.length - 1) {
        currentSlot.end = moment(time, "HH:mm")
          .add(duration, "minutes")
          .format("HH:mm");
        output.push({ ...currentSlot });
      }
    });
  return output;
};

export const updateSlots = (times, duration, newDuration) => {
  //INPUT: times=["10:00", "11:00], duration=60, newDuration=30
  //OUTPUT: ["10:00", "10:30", "11:00"]
  return formatInterviewTimes(getStartEndTimes(times, duration), newDuration);
};

export const formatAvailability = (interviewSlots) => {
  // INPUT: [{days:[], times:[], duration:""}]
  // OUTPUT: [{date:"", slots:[{ start:"", end:"" }]}]
  let availability = [];
  interviewSlots.forEach((slot) => {
    slot.days.forEach((day) => {
      // Check if there is already another slot for this day
      let i = availability.findIndex((s) => s.date === day);
      if (i >= 0) {
        availability[i].slots.push(getStartEndTimes(slot.times, slot.duration));
      } else {
        availability.push({
          date: day,
          slots: getStartEndTimes(slot.times, slot.duration),
        });
      }
    });
  });
  return availability;
};

export const formatAvailabilityToInterviewSlots = (availability, duration) => {
  // INPUT: [{date:"", slots:[{ start:"", end:"" }]}]
  // OUTPUT: [{days:[], times:[], duration:""}]+
  let interviewSlots = [];
  availability.forEach((slot) => {
    slot.slots.forEach((item) => {
      // Check if there is already another slot for this day
      let i = interviewSlots.findIndex((s) => s.days.includes(slot.date));
      if (i >= 0) {
        interviewSlots[i].times.push(
          ...getTimesFromAvailability(item.start, item.end, duration)
        );
      } else {
        interviewSlots.push({
          days: [slot.date],
          times: getTimesFromAvailability(item.start, item.end, duration),
          duration: duration.toString(),
        });
      }
    });
  });
  return interviewSlots;
};

export const getTimesFromAvailability = (start, end, duration) => {
  let startTime = moment(start, "H:mm");
  let withinRange = true;
  let times = [];
  while (withinRange) {
    times.push(startTime.format("H:mm").toString());
    startTime.add(duration, "minutes");
    if (moment(end, "H:mm").diff(startTime, "minutes") < duration) {
      withinRange = false;
    }
  }
  return times;
};

export const getColors = () => {
  return [
    ["#fa6900", "#83af9b", "#556270", "#774f38", "#e08e79"],
    ["#cdb380", "#036564", "#bd1550", "#e97f02", "#f8ca00"],
    ["#594f4f", "#547980", "#45ada8", "#9de0ad", "#e5fcc2"],
    ["#00a0b0", "#6a4a3c", "#cc333f", "#eb6841", "#edc951"],
    ["#e94e77", "#d68189", "#c6a49a", "#c6e5d9", "#f4ead5"],
    ["#3fb8af", "#7fc7af", "#dad8a7", "#ff9e9d", "#ff3d7f"],
    ["#d9ceb2", "#948c75", "#d5ded9", "#7a6a53", "#99b2b7"],
    ["#ffffff", "#cbe86b", "#f2e9e1", "#1c140d", "#cbe86b"],
    ["#efffcd", "#dce9be", "#555152", "#2e2633", "#99173c"],
    ["#343838", "#005f6b", "#008c9e", "#00b4cc", "#00dffc"],
    ["#413e4a", "#73626e", "#b38184", "#f0b49e", "#f7e4be"],
    ["#ff4e50", "#fc913a", "#f9d423", "#ede574", "#e1f5c4"],
    ["#99b898", "#fecea8", "#ff847c", "#e84a5f", "#2a363b"],
    ["#655643", "#80bca3", "#f6f7bd", "#e6ac27", "#bf4d28"],
    ["#00a8c6", "#40c0cb", "#f9f2e7", "#aee239", "#8fbe00"],
    ["#351330", "#424254", "#64908a", "#e8caa4", "#cc2a41"],
    ["#554236", "#f77825", "#d3ce3d", "#f1efa5", "#60b99a"],
    ["#5d4157", "#838689", "#a8caba", "#cad7b2", "#ebe3aa"],
    ["#8c2318", "#5e8c6a", "#88a65e", "#bfb35a", "#f2c45a"],
    ["#fad089", "#ff9c5b", "#f5634a", "#ed303c", "#3b8183"],
  ].flat();
};

export const percentToColor = (value) => {
  var r = 0;
  var g = 0;
  var b = 0;
  if (value < 50) {
    r = 240;
    g = Math.round(4.8 * value);
    b = 15;
  } else {
    r = Math.round(480 - 4.8 * value);
    g = 240;
    b = 15;
  }
  return `rgb(${r}, ${g}, ${b}, 0.66)`;
};

export const getJobType = (role, employer, roleId) => {
  if (!role) role = getRoleFromId(employer, roleId);
  return role.jobType.length <= 0 ||
    Array.from(
      new Set(
        role.jobType.map((role) => {
          if (role.includes("Part")) return "Part Time";
          return "Full Time";
        })
      )
    ).length > 1
    ? "Full/Part time"
    : Array.from(
        new Set(
          role.jobType.map((role) => {
            if (role.includes("Part")) return "Part Time";
            return "Full Time";
          })
        )
      )[0];
};

export const abTesting = (candidateId, testId, groups = 2) => {
  let hash = CryptoJS.HmacSHA256("sha256", candidateId + testId)
    .toString(CryptoJS.enc.Hex)
    .substring(0, 6);
  return parseInt(hash, 16) % groups === 0;
};

export const getInterviewDuration = (branch, position) => {
  return parseInt(
    position.roleInterviewSlots
      ? position.roleInterviewSlots[0].duration
      : branch.interviewSlots[0].duration
  );
};

// Returns similar roles to the ones provided
export const getSimilarRoles = (roles) => {
  let similarRoles = {
    "Waiter/Waitress": [
      "Head Waiter/Waitress",
      "Barista",
      "Host/Hostess",
      "Fast Food / Fast Casual Staff",
      "Food Runner",
      "Bartender",
    ],
    "Head Waiter/Waitress": [
      "Waiter/Waitress",
      "Restaurant Supervisor/Manager",
    ],
    Bartender: ["Bar-Back", "Bar Supervisor/Manager", "Waiter/Waitress"],
    Barista: ["Cafe Supervisor/Manager", "Waiter/Waitress"],
    "Bar-Back": ["Bartender", "Food Runner"],
    "Food Runner": [
      "Bar-Back",
      "Waiter/Waitress",
      "Fast Food / Fast Casual Staff",
      "Kitchen Porter",
    ],
    "Host/Hostess": ["Waiter/Waitress"],
    "Fast Food / Fast Casual Staff": ["Waiter/Waitress", "Food Runner"],
    "Restaurant Supervisor/Manager": [
      "Head Waiter/Waitress",
      "Cafe Supervisor/Manager",
      "Bar Supervisor/Manager",
      "General Manager",
    ],
    "Cafe Supervisor/Manager": [
      "Barista",
      "Bar Supervisor/Manager",
      "Restaurant Supervisor/Manager",
      "General Manager",
    ],
    "Bar Supervisor/Manager": [
      "Bartender",
      "Cafe Supervisor/Manager",
      "Restaurant Supervisor/Manager",
      "General Manager",
    ],
    "General Manager": [
      "Cafe Supervisor/Manager",
      "Restaurant Supervisor/Manager",
      "Bar Supervisor/Manager",
    ],
    "Kitchen Porter": ["Food Runner", "Commis Chef (Junior Chef)"],
  };
  let result = [];
  roles.forEach(
    (role) => (result = [...new Set([...result, ...similarRoles[role]])])
  );
  return result;
};

export const getRecommendedSalary = (roles) => {
  let ranges = {
    "Restaurant Supervisor/Manager": [14, 18],
    "Head Waiter/Waitress": [13, 17],
    "Waiter/Waitress": [10, 13],
    Bartender: [11, 17],
    "Food Runner": [10, 12],
    "Fast Food / Fast Casual Staff": [10, 12],
    Barista: [10, 14],
    "Bar-Back": [10, 12],
    "Kitchen Porter": [10, 12],
    "Bar Supervisor/Manager": [14, 18],
    "Cafe Supervisor/Manager": [12, 16],
    "Host/Hostess": [10, 13],
    "General Manager": [15, 25],
  };
  let min = [];
  let max = [];
  roles.forEach((role) => {
    min.push(ranges[role][0]);
    max.push(ranges[role][1]);
  });
  // Return average of min and max
  return [
    Math.round(min.reduce((a, b) => a + b, 0) / min.length),
    Math.round(max.reduce((a, b) => a + b, 0) / max.length),
  ];
};

export const validateCandidateAvailability = (availability) => {
  // Check if the candidate stated at least 5 hours of availability
  let totalMinutes = 0;
  for (let day of availability) {
    if (!day?.slots) continue;
    for (let slot of day.slots) {
      if (!slot) continue;
      totalMinutes += moment(slot.end, "HH:mm").diff(
        moment(slot.start, "HH:mm"),
        "minutes"
      );
    }
  }
  return totalMinutes >= 300;
};

export const formatCandidateAvailability = (availability) => {
  let result = {};
  for (let day of availability) {
    let date = moment(day.date, "ddd, D MMM").format("YYYY-MM-DD");
    if (!result[date]) {
      result[date] = day.slots;
    } else {
      result[date] = [...result[date], ...day.slots];
    }
  }
  return result;
};

export const getHiringManagers = (employer) => {
  let hiringManagers = [];
  if (employer.hiringManagers)
    hiringManagers = hiringManagers.concat(employer.hiringManagers);
  if (employer.branches) {
    for (let branch of employer.branches) {
      if (branch.hiringManagers)
        hiringManagers = hiringManagers.concat(branch.hiringManagers);
    }
  }
  return hiringManagers;
};
