import moment from "moment-timezone";
import React, { useEffect, useState, useCallback } from "react";
import {
  updateEmployer,
  getAuthEmployerInfo,
  reactivateEmployer,
  getInterviews,
  getPremiumbranches,
  updateInterview,
  getCandidateInfo,
  recordCandidateCalled,
  cancelInterview,
  getSupplyBlockers,
  getLanguages,
  getSkills, getCandidatesSentToEmployer,
} from "../backend";
import {
  getAllRoles,
  getPremiumRoles,
  getExperienceCategories,
  formatInterviewTimes,
  getColors,
  getRoleFromId,
  formatAvailability,
  updateSlots,
  getInterviewType,
  getEmployerSecret,
  getInterviewDuration,
} from "../utils";
import Switch from "react-switch";
import { Modal } from "../Components";
import Lottie from "react-lottie-player";
import ReactTooltip from "react-tooltip";
import FullCalendar from "@fullcalendar/react";
import { MoonLoader } from "react-spinners";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { isMobile } from "react-device-detect";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import confirmAnimation from "../Components/confirmAnimation.json";
import {
  TextField,
  Select,
  Availability,
  FieldStatus,
} from "../Components/Forms";
import { Slide } from "react-slideshow-image";

let previousData;
let employerLoaded = false;

const EmployerDashboard = () => {
  let { employerId, employerSecret } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const reactivate = searchParams.get("reactivate");
  const paymentParam = searchParams.get("payment");
  const page = searchParams.get("page");
  const pause_branch = searchParams.get("pause_branch");
  const pause_positions = searchParams.get("pause_positions");
  const unpause_branch_reminder = searchParams.get("unpause_branch_reminder");
  const interview_recap = searchParams.get("interview_recap");

  // Track the new employer's data
  const [data, setData] = useState();
  const [interviews, setInterviews] = useState();
  const [candidatesShown, setCandidatesShown] = useState();
  const [supplyBlockers, setSupplyBlockers] = useState();
  // Track other employer preferences
  const [isHiringCentralized, setIsHiringCentralized] = useState();
  const [experienceTimeframe, setExperienceTimeframe] = useState("months");
  // Form manipulation
  const [positionsToggled, setPositionsToggled] = useState([]);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [deletePopupCallback, setDeletePopupCallback] = useState(() => {});
  const [interviewInfo, setInterviewInfo] = useState();
  const [candidateInfo, setCandidateInfo] = useState();
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [interviewsNeedFeedback, setInterviewsNeedFeedback] = useState([]);
  const [fieldsStatus, setFieldsStatus] = useState({});
  const [settingsToggled, setSettingsToggled] = useState({});
  const [pausePositionsOpen, setPausePositionsOpen] = useState(false);
  const [cancellationConfirmationShown, setCancellationConfirmationShown] =
    useState(false);
  const [modalShown, setModalShown] = useState(null);
  const [pauseReminder, setPauseReminder] = useState();
  const [interviewRecapShown, setInterviewRecapShown] = useState(false);
  // Pause independent branches
  const [branchToPause, setBranchToPause] = useState();
  const [pauseDuration, setPauseDuration] = useState("1 month");
  // Show that the changes have been saved
  const [savedShown, setSavedShown] = useState();
  const [pageShown, setPageShown] = useState("home");
  // Track account upgrades
  const [premiumBranches, setPremiumBranches] = useState([]);
  const [languages, setLanguages] = useState();
  const [skills, setSkills] = useState();

  //eslint-disable-next-line
  let saveEmployer = useCallback(
    debounce(
      (args) => {
        updateEmployer(args);
        setSavedShown(true);
        setTimeout(() => setSavedShown(false), 7000);
      },
      1000,
      {
        trailing: true,
        leading: false,
      }
    ),
    []
  );

  //eslint-disable-next-line
  let saveInterview = useCallback(
    debounce(
      (args) => {
        updateInterview(args);
        setFieldsStatus({
          ...fieldsStatus,
          [Object.keys(args.properties)[0]]: "saved",
        });
      },
      2000,
      {
        trailing: true,
        leading: false,
      }
    ),
    []
  );

  const openDeleteConfirmation = (successCallback) => {
    setDeletePopupOpen(true);
    setDeletePopupCallback(() => successCallback);
  };

  const fetchInterviews = async () => {
    let interviews = await getInterviews(data.branches.map((b) => b.id));
    setInterviews(
      interviews.map((i, n) => ({
        ...i,
        id: i.id,
        title: i["Candidate"],
        start: i["Date"],
        backgroundColor:
          getColors()[
            data.branches.findIndex((b) => b.id === i["Employer Id"])
          ],
        borderColor:
          getColors()[
            data.branches.findIndex((b) => b.id === i["Employer Id"])
          ],
        classNames: [
          i["Status"] === "Cancelled"
            ? "interview-calendar-cancelled"
            : "interview-calendar-event",
        ],
      }))
    );
    setInterviewsNeedFeedback(
      interviews.filter(
        (i) =>
          moment(i["Date"]).isSame(moment().subtract(1, "day"), "day") &&
          ["Need Feedback", "Scheduled"].includes(i["Status"])
      )
    );
  };

  const fetchCandidatesShown = async () => {
    let interaction = await getCandidatesSentToEmployer(data.branches.map((b) => b.id));
    setCandidatesShown(
      interaction.map((i, n) => ({
        ...i,
        id: i._id,
        title: i["name"],
        start: i["received_time"],
        backgroundColor:
          getColors()[1],
        borderColor:
          getColors()[1],
        classNames: ["interview-calendar-event"],
      }))
    );
  };

  const fetchSupplyBlockers = async (employer) => {
    let blockers = await Promise.all(
      employer.branches.map((b) => getSupplyBlockers(b.id))
    );
    setSupplyBlockers(blockers);
  };

  useEffect(() => {
    async function fetchData() {
      let e = await getAuthEmployerInfo(employerId, employerSecret);
      previousData = JSON.parse(JSON.stringify(e));
      setData(e);
      fetchSupplyBlockers(e);
      let pb = await getPremiumbranches(e);
      setPremiumBranches(pb);
      // Deactivate BoH and Manager positions for free accounts
      for (let branch of e.branches) {
        if (!pb.includes(branch.id)) {
          for (let pos of branch.openPositions) {
            if (pos.role.some((r) => getPremiumRoles().includes(r))) {
              pos.active = false;
            }
          }
        }
      }
      if (reactivate) {
        setModalShown(
          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              textAlign: "center",
            }}
          >
            <Lottie
              animationData={confirmAnimation}
              loop
              play
              style={{
                width: "100px",
                height: "100px",
                margin: "0 auto",
              }}
            />
            <h5>Account Re-activated</h5>
            <br />
            <p>
              Please update your preferences, to make sure relevant candidates
              are sent!
            </p>
          </div>
        );
        reactivateEmployer(e);
        setSearchParams("");
      }
      if (page) {
        setPageShown(page);
        if (isMobile) setSideBarOpen(false);
      }
      if (pause_branch) {
        setBranchToPause(e.branches.find((b) => b.id === pause_branch));
      }
      if (unpause_branch_reminder) {
        setPauseReminder(
          e.branches.find((b) => b.id === unpause_branch_reminder)
        );
      }
      if (pause_positions) {
        setPausePositionsOpen(true);
      }
      if (interview_recap) {
        setPageShown("interviews");
        setSideBarOpen(false);
        setInterviewRecapShown(true);
        setSearchParams("");
      }
      setIsHiringCentralized(e?.hiringManagers.length > 0);
      if (paymentParam === "success") {
        searchParams.delete("payment");
        setSearchParams(searchParams);
        setModalShown(
          <div style={{ textAlign: "center" }}>
            <Lottie
              animationData={confirmAnimation}
              loop
              play
              style={{
                width: "150px",
                height: "150px",
                margin: "20px auto",
              }}
            />
            <h5>Woohoo! Congrats on upgrading</h5>
            <br />
            <p style={{ maxWidth: "500px", marginBottom: "35px" }}>
              You can now reach out to unlimited candidates, access their
              contact details, hire managers, use Autopilot Hiring and much
              more!
            </p>
          </div>
        );
      }
    }
    fetchData();
    const getFormData = async () => {
      Promise.all([getLanguages(), getSkills()]).then((values) => {
        setLanguages(values[0]);
        setSkills(values[1]);
      });
    };
    getFormData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pageShown === "interviews" && !interviews) {
      fetchInterviews();
    }
    else if (pageShown === "candidates shown" && !candidatesShown) {
      fetchCandidatesShown();
    }
    //eslint-disable-next-line
  }, [pageShown]);

  useEffect(() => {
    if (
      data &&
      employerLoaded &&
      JSON.stringify(data) !== JSON.stringify(previousData)
    ) {
      saveEmployer(data);
      previousData = JSON.parse(JSON.stringify(data));
    }
    if (data) employerLoaded = true;
    //eslint-disable-next-line
  }, [data]);

  const setBranch = (branchData, i) => {
    let employer = { ...data };
    employer.branches[i] = branchData;
    setData(employer);
    setPageShown(branchData.id);
  };

  const saveInterviewFeedback = async (interviewIndex, field, value) => {
    // Update interview value
    let interviews = [...interviewsNeedFeedback];
    interviews[interviewIndex][field] = value;
    setInterviewsNeedFeedback(interviews);
    // Set fieldsStatus: false
    setFieldsStatus({
      ...fieldsStatus,
      [field]: "loading",
    });
    // Save value
    saveInterview({
      id: interviews[interviewIndex].id,
      properties: { [field]: value },
    });
  };

  const setInterviewCancelled = async (interview) => {
    setInterviewInfo({});
    await cancelInterview(
      interview["Employer Id"],
      interview["Candidate Id"],
      interview["Date"],
      true
    ).then(() => {
      let i = [...interviews];
      i[
        interviews.findIndex(
          (i) => i["Candidate Id"] === interview["Candidate Id"]
        )
      ]["Status"] = "Cancelled";
      i[
        interviews.findIndex(
          (i) => i["Candidate Id"] === interview["Candidate Id"]
        )
      ].classNames = "interview-calendar-cancelled";
      setInterviews(i);
      setInterviewInfo(null);
      setCancellationConfirmationShown(false);
    });
  };

  const adjustExperience = (i, j) => {
    let position = [...data.branches][i].openPositions[j];
    let branches = [...data.branches];
    branches[i].openPositions[j].monthsOfExperience = Math.round(
      position.monthsOfExperience / 2
    );
    setData({
      ...data,
      branches: branches,
    });
    setModalShown(
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Lottie
          animationData={confirmAnimation}
          loop
          play
          style={{
            width: "150px",
            height: "150px",
            margin: "20px auto",
          }}
        />
        <p>
          {`The required experience for the ${position.role.join(
            ", "
          )} role has been reduced from ${
            position.monthsOfExperience
          } to ${Math.round(position.monthsOfExperience / 2)} months`}
        </p>
      </div>
    );
    // Hide this tip
    let blockers = [...supplyBlockers];
    let exp = Object.values(supplyBlockers[i])[j]?.find(
      (v) => "Experience" === Object.keys(v)[0]
    );
    exp.Experience = 100;
    setSupplyBlockers(blockers);
  };

  const adjustInterviewSettings = (i, j) => {
    let position = [...data.branches][i].openPositions[j];
    let branches = [...data.branches];
    setModalShown(
      <div>
        <p style={{ color: "inherit" }}>
          <b>
            Reduce the {getInterviewType(position).toLowerCase()} duration{" "}
            {getInterviewType(position).toLowerCase() === "trial"
              ? "and pay for it"
              : ""}
            , to receive more candidates
          </b>
        </p>
        <br />
        <div style={{ display: "flex", gap: "8px" }}>
          <Select
            allowMultiple={false}
            label="Duration (in minutes)"
            selected={[getInterviewDuration(branches[i], position)]}
            options={[15, 30, 60, 90, 120, 180, 240, 300]}
            onChange={(value) => {
              position.roleInterviewSlots = (
                position.roleInterviewSlots ?? branches[i].interviewSlots
              ).map((slot) => ({
                ...slot,
                duration: value,
                times: value
                  ? updateSlots(slot.times, slot.duration, value)
                  : slot.times,
              }));
              branches[i].openPositions[j] = position;
              setData({
                ...data,
                branches: branches,
              });
              // Hide this tip
              let blockers = [...supplyBlockers];
              let exp = Object.values(supplyBlockers[i])[j]?.find(
                (v) => "Picked by Candidates" === Object.keys(v)[0]
              );
              exp["Picked by Candidates"] = 100;
              setSupplyBlockers(blockers);
            }}
          />
          {(position.interviewType === "Trial" || !position.interviewType) && (
            <Select
              allowMultiple={false}
              label="Is the trial paid?"
              options={
                getInterviewDuration(branches[i], position) > 120 &&
                !(position.trialPaid ?? data.trialPaid)
                  ? ["Yes", "Yes, if hired"]
                  : ["No", "Yes", "Yes, if hired"]
              }
              selected={
                (data.trialPaid || position.trialPaid) && [
                  position.trialPaid ?? data.trialPaid,
                ]
              }
              onChange={(e) => {
                position.trialPaid = e;
                branches[i].openPositions[j] = position;
                setData({
                  ...data,
                  branches: branches,
                });
                // Hide this tip
                let blockers = [...supplyBlockers];
                let exp = Object.values(supplyBlockers[i])[j]?.find(
                  (v) => "Picked by Candidates" === Object.keys(v)[0]
                );
                exp["Picked by Candidates"] = 100;
                setSupplyBlockers(blockers);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const adjustInterviewSlots = (i, j) => {
    let position = [...data.branches][i].openPositions[j];
    let branch = [...data.branches][i];
    setModalShown(
      <div
        style={{
          height: "70vh",
          maxHeight: "70vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Availability
          recurring
          availability={
            position.availability ??
            formatAvailability(
              position.roleInterviewSlots ?? branch.interviewSlots
            )
          }
          onChange={(slots) => {
            // Format slots
            let formattedSlots = slots.map((slot) => ({
              days: [slot.date],
              times: formatInterviewTimes(
                slot.slots,
                (position.roleInterviewSlots ?? branch.interviewSlots)[0]
                  .duration
              ),
              duration: (position.roleInterviewSlots ??
                branch.interviewSlots)[0].duration,
            }));
            position.roleInterviewSlots = formattedSlots;
            position.availability = slots;
            let branches = [...data.branches];
            branches[i].openPositions[j] = position;
            setData({
              ...data,
              branches: branches,
            });
            // Hide this tip
            let blockers = [...supplyBlockers];
            let exp = Object.values(supplyBlockers[i])[j]?.find(
              (v) => "Picked by Candidates" === Object.keys(v)[0]
            );
            exp["Picked by Candidates"] = 100;
            setSupplyBlockers(blockers);
          }}
        />
      </div>
    );
  };

  return data && supplyBlockers ? (
    <div
      style={{ width: "100vw", height: "100vh", backgroundColor: "#f2f0ed" }}
      className="employer-admin-form row-all-devices"
    >
      <Modal
        isOpen={pauseReminder != null}
        closeModal={() => setPauseReminder(null)}
        style={{ backgroundColor: "#f2f0ed", overflow: "revert" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            maxWidth: "400px",
          }}
        >
          <h5>
            We'll resume sending you candidates for your{" "}
            {pauseReminder && pauseReminder.name} venue
          </h5>
          <br />
          <div>
            <button
              onClick={() => {
                setPauseReminder(null);
                let i = data.branches.findIndex(
                  (b) => b.id === unpause_branch_reminder
                );
                let branches = data.branches;
                branches[i] = {
                  ...pauseReminder,
                  active: true,
                  pausedUntil: undefined,
                  activeUntil: moment().add(2, "weeks").format(),
                };
                setData({ ...data, branches: [...branches] });
                setModalShown(
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px",
                      textAlign: "center",
                    }}
                  >
                    <Lottie
                      animationData={confirmAnimation}
                      loop
                      play
                      style={{
                        width: "100px",
                        height: "100px",
                        margin: "0 auto",
                      }}
                    />
                    <h5>Account Re-activated</h5>
                    <br />
                    <p>
                      Please update your preferences, to make sure relevant
                      candidates are sent!
                    </p>
                  </div>
                );
              }}
              className="button w-inline-block"
              style={{
                display: "flex",
                margin: "auto",
                padding: "12px 32px",
              }}
            >
              Reactivate Account Now
            </button>
            <button
              onClick={() => {
                setPauseReminder(null);
                setBranchToPause(pauseReminder);
              }}
              className="button-tertiary"
              style={{
                display: "flex",
                margin: "12px auto",
                padding: "12px 32px",
              }}
            >
              Keep paused
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={branchToPause != null}
        closeModal={() => setBranchToPause(null)}
        style={{ backgroundColor: "#f2f0ed", overflow: "revert" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            maxWidth: "400px",
          }}
        >
          <h5>
            How long would you like to pause your{" "}
            {branchToPause && branchToPause.name} venue for?
          </h5>
          <br />
          <div className="row-all-devices" style={{ gap: "8px" }}>
            <Select
              allowMultiple={false}
              allowSearch={false}
              selected={pauseDuration}
              placeholder="1 month"
              options={[
                "1 week",
                "2 weeks",
                "3 weeks",
                "1 month",
                "2 months",
                "3 months",
              ]}
              onChange={(value) => setPauseDuration(value)}
            />
            <button
              className="button w-button"
              style={{
                borderRadius: "8px",
                padding: "0 24px",
                height: "52px",
                marginTop: "8px",
              }}
              onClick={() => {
                let i = data.branches.findIndex(
                  (b) => b.id === branchToPause.id
                );
                let branches = data.branches;
                branches[i] = {
                  ...branchToPause,
                  active: false,
                  pausedUntil: moment()
                    .add(
                      {
                        "1 week": 7,
                        "2 weeks": 14,
                        "3 weeks": 21,
                        "1 month": 30,
                        "2 months": 61,
                        "3 months": 91,
                      }[pauseDuration],
                      "days"
                    )
                    .toDate(),
                };
                setData({ ...data, branches: [...branches] });
                setBranchToPause(null);
              }}
            >
              Go
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={pausePositionsOpen}
        closeModal={() => setPausePositionsOpen(false)}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "16px",
            overflowY: "scroll",
          }}
        >
          <h5>Which positions would you like to close?</h5>
          {data.branches.map((branch, i) => (
            <div style={{ margin: "24px 0" }}>
              {data.branches.length > 1 && (
                <p
                  style={{
                    fontSize: "smaller",
                    textTransform: "uppercase",
                    opacity: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  {branch.name}
                </p>
              )}
              {branch.openPositions.map((pos, p) => (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontWeight: "bold", maxWidth: "300px" }}>
                    {pos.role.join(", ")}
                  </p>
                  {pos.active ? (
                    <button
                      className="w-button button"
                      style={{
                        borderRadius: "8px",
                        padding: "4px 24px",
                        height: "40px",
                        margin: "8px 0",
                        position: "relative",
                        top: "-16px",
                      }}
                      onClick={() => {
                        let branches = [...data.branches];
                        branches[i].openPositions[p].active = false;
                        setData({ ...data, branches: branches });
                      }}
                    >
                      <span
                        className="icon-small"
                        style={{
                          display: "inline-block",
                          fontSize: "20px",
                          lineHeight: "16px",
                          margin: 0,
                          padding: 0,
                          height: "16px",
                          width: "20px",
                          position: "relative",
                          top: "4px",
                          right: "8px",
                          color: "white",
                        }}
                      >
                        cancel
                      </span>
                      Close Position
                    </button>
                  ) : (
                    <p
                      style={{
                        opacity: "0.66",
                        fontWeight: "bold",
                        height: "40px",
                        margin: "8px 0",
                        position: "relative",
                        top: "-16px",
                      }}
                    >
                      <span
                        className="icon-small"
                        style={{
                          display: "inline-block",
                          fontSize: "20px",
                          lineHeight: "16px",
                          margin: 0,
                          padding: 0,
                          height: "16px",
                          width: "20px",
                          position: "relative",
                          top: "4px",
                          right: "8px",
                        }}
                      >
                        check
                      </span>
                      Position Closed
                    </p>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={modalShown !== null}
        closeModal={() => setModalShown(null)}
        style={{ backgroundColor: "#f2f0ed", overflow: "revert" }}
      >
        {modalShown}
      </Modal>
      {savedShown && (
        <div
          style={{
            padding: "15px",
            borderRadius: "15px",
            backgroundColor: "#bcf5bc",
            margin: "15px",
            fontSize: "smaller",
            position: "fixed",
            zIndex: 2000,
            bottom: 0,
            right: 0,
          }}
        >
          <p
            style={{
              color: "green",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            <span
              className="icon-small"
              style={{
                paddingRight: "15px",
                fontSize: "24px",
                lineHeight: "16px",
                display: "inline",
                position: "relative",
                bottom: "-5px",
              }}
            >
              check_circle
            </span>
            Changes Saved
          </p>
        </div>
      )}
      <div
        style={{
          display: "absolute",
          left: 0,
          top: 0,
          height: "100vh",
          width: sideBarOpen
            ? isMobile
              ? "100vw"
              : "275px"
            : isMobile
            ? "0"
            : "30px",
          minWidth: isMobile ? "0" : "30px",
          maxWidth: isMobile ? "100vw" : "275px",
          backgroundColor: "rgb(235, 233, 230)",
          padding: sideBarOpen ? "24px" : 0,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "column",
            display: sideBarOpen ? "flex" : "none",
            visibility: sideBarOpen ? "visible" : "hidden",
          }}
        >
          {isMobile && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "24px",
              }}
              onClick={() => setSideBarOpen(!sideBarOpen)}
            >
              <span
                className="icon-small"
                style={{
                  fontSize: "30px",
                  height: "30px",
                  float: "right",
                  color: "black",
                  opacity: 0.5,
                }}
              >
                close
              </span>
            </div>
          )}
          <a href="/">
            <img
              src="/images/hirebolt-logo-black.png"
              loading="lazy"
              width="150"
              alt=""
              style={{
                float: "left",
                opacity: 0.5,
                marginLeft: "8px",
              }}
            />
          </a>
          <div style={{ flex: "1", margin: "64px 0" }}>
            <div
              className="sidebar-item"
              onClick={() => {
                setPageShown("home");
                if (isMobile) setSideBarOpen(false);
              }}
            >
              <span className="icon-small" style={{ fontSize: "20px" }}>
                home
              </span>
              <span style={{ margin: "auto 0" }}>{data.name}</span>
            </div>
            {data.branches.map((branch, i) => (
              <div
                className="sidebar-item"
                style={{
                  fontWeight: "normal",
                  padding: "0px 8px",
                  gap: "4px",
                  marginLeft: "40px",
                  marginTop: 0,
                  opacity: 0.4,
                }}
                onClick={() => {
                  setBranch(branch, i);
                  setPageShown(branch.id);
                  if (isMobile) setSideBarOpen(false);
                }}
              >
                <span
                  className="icon-small"
                  style={{ fontSize: "16px", marginTop: "-4px" }}
                >
                  subdirectory_arrow_right
                </span>
                <span style={{ margin: "auto 0" }}>
                  {branch.name?.length > 0
                    ? branch.name
                    : `Your venue ${i < 1 ? "" : `${i}`}`}
                </span>
              </div>
            ))}
            <div
              className="sidebar-item"
              style={{
                fontWeight: "normal",
                padding: "0px 8px",
                gap: "4px",
                marginLeft: "40px",
                marginTop: 0,
                opacity: 0.2,
              }}
              onClick={async () => {
                setData({
                  ...data,
                  branches: [
                    ...data.branches,
                    {
                      name: "New venue",
                      id: `${data.id}${data.branches.length}`,
                      hiringManagers: [{ name: "", number: "", email: "" }],
                      address: "",
                      openPositions: [
                        {
                          role: [],
                          jobType: [],
                          priorExperience: [],
                          "18+?": false,
                          minSalary: 10,
                          maxSalary: 14,
                          dressCode: "All black",
                          interviewType: "Interview",
                          workingHours:
                            "Shifts are based on a weekly rota and are maximum of 8hrs",
                          additionalInfo: "",
                          roleInterviewSlots: [
                            {
                              days: [],
                              times: [],
                              duration: 60,
                            },
                          ],
                        },
                      ],
                      created: moment().toDate(),
                    },
                  ],
                });
                if (data.subscriptionId.startsWith("free"))
                  setPremiumBranches([
                    ...premiumBranches,
                    `${data.id}${data.branches.length}`,
                  ]);
                setPageShown(`${data.id}${data.branches.length}`);
                if (isMobile) setSideBarOpen(false);
              }}
            >
              <span
                className="icon-small"
                style={{ fontSize: "16px", marginTop: "-4px" }}
              >
                add
              </span>
              <span style={{ margin: "auto 0" }}>Add venue</span>
            </div>
            <div
              className="sidebar-item"
              onClick={() => {
                setPageShown("interviews");
                if (isMobile) setSideBarOpen(false);
              }}
            >
              <span className="icon-small" style={{ fontSize: "20px" }}>
                calendar_today
              </span>
              <span style={{ margin: "auto 0" }}>Interviews</span>
            </div>
            <div
              className="sidebar-item"
              onClick={() => {
                setPageShown("candidates shown");
                if (isMobile) setSideBarOpen(false);
              }}
            >
              <span className="icon-small" style={{ fontSize: "20px", paddingLeft:"18px" }}>
                person2
              </span>
              <span style={{ margin: "auto 0" }}>Candidates Received</span>
            </div>
            <div
              className="sidebar-item"
              onClick={() => {
                setPageShown("settings");
                if (isMobile) setSideBarOpen(false);
              }}
            >
              <span className="icon-small" style={{ fontSize: "20px" }}>
                settings
              </span>
              <span style={{ margin: "auto 0" }}>Settings</span>
            </div>
          </div>
          <div
            style={{
              float: "right",
              fontSize: "smaller",
              marginLeft: "8px",
            }}
          >
            {premiumBranches.length !== data.branches.length && (
              <button
                className="button w-button animate__animated animate__headShake animate__delay-4s"
                onClick={() => {
                  navigate(`/${data.id}/${getEmployerSecret(data.id)}/upgrade`);
                }}
                style={{
                  borderRadius: "10px",
                  fontSize: "14px",
                  padding: "0.5rem 1.25rem",
                  fontWeight: "bold",
                  width: "fit-content",
                  backgroundColor: "rgba(0,0,0,0.055)",
                  border: "none",
                  opacity: 1,
                }}
              >
                <span
                  style={{
                    color: "rgba(0,0,0,0.55)",
                  }}
                >
                  Upgrade your{" "}
                  {premiumBranches.length > 0 ? "other venues " : "Account "}
                </span>{" "}
                🚀
              </button>
            )}
            <div
              style={{
                opacity: "0.33",
              }}
            >
              <br />
              Employer id: {data.id}
              <br />
              Onboarded by: {data.onboardedBy ?? "Not recorded"}
              <div style={{ display: "block" }}>
                {data.branches.find((b) => b.id === pageShown)?.created &&
                  `Created: ${moment(
                    data.branches.find((b) => b.id === pageShown)?.created
                  ).format("DD/MM/YYYY")}`}
              </div>
              <div style={{ display: "block" }}>
                {data.branches.find((b) => b.id === pageShown)?.pausedUntil &&
                  `Paused Until: ${moment(
                    data.branches.find((b) => b.id === pageShown)?.pausedUntil
                  ).format("DD/MM/YYYY")}`}
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <div
            style={{
              position: "absolute",
              top: "75px",
              left: sideBarOpen ? "245px" : "0",
              borderRadius: "100%",
              backgroundColor: "rgb(235, 233, 230)",
              width: "50px",
              height: "50px",
              cursor: "pointer",
              WebkitTapHighlightColor: "transparent",
            }}
            onClick={() => setSideBarOpen(!sideBarOpen)}
          >
            <span
              className="icon-small"
              style={{
                display: "block",
                color: "black",
                opacity: "0.5",
                fontSize: "30px",
                height: "30px",
                width: "30px",
                margin: "auto",
                position: "relative",
                top: "2px",
                left: "3px",
              }}
            >
              {sideBarOpen
                ? "keyboard_arrow_left_icon"
                : "keyboard_arrow_right_icon"}
            </span>
          </div>
        )}
      </div>
      {isMobile && sideBarOpen ? (
        <div></div>
      ) : (
        <div
          style={{
            flex: 1,
            overflowY: "scroll",
          }}
        >
          {isMobile && (
            <div
              style={{
                width: "100%",
                height: "60px",
                padding: "24px",
              }}
              onClick={() => setSideBarOpen(!sideBarOpen)}
            >
              <div style={{ width: "fit-content", float: "right" }}>
                <span
                  className="icon-small"
                  style={{
                    fontSize: "30px",
                    height: "30px",
                    width: "30px",
                  }}
                >
                  menu
                </span>
                <p style={{ fontSize: "11px" }}>
                  <b>Menu</b>
                </p>
              </div>
            </div>
          )}
          {pageShown === "home" && (
            <div>
              {data && (
                <div
                  style={{
                    height: "fit-content",
                    margin: "auto",
                    width: "100%",
                    maxWidth: "900px",
                    padding: isMobile ? "25px" : "50px",
                  }}
                >
                  <textarea
                    className="rounded form-text candidate-no-border h1"
                    value={data.name}
                    maxLength={25}
                    rows={
                      isMobile
                        ? Math.floor(
                            1 +
                              data.name.length /
                                ((window.innerWidth - 50 - 16) / 21)
                          )
                        : 1
                    }
                    cols={data.name.length + 1}
                    style={{
                      width: isMobile ? "100%" : "auto",
                      maxWidth: "100%",
                      resize: "none",
                      padding: "4px 16px",
                      whiteSpace: isMobile ? "initial" : "nowrap",
                    }}
                    onChange={(e) => {
                      setData({
                        ...data,
                        name: e.target.value,
                      });
                    }}
                  />
                  <textarea
                    className="rounded form-text candidate-no-border"
                    value={data.description}
                    rows={Math.floor(
                      1 +
                        data.description.length /
                          ((window.innerWidth - 50 - 16) / (isMobile ? 8 : 13))
                    )}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      resize: "none",
                      marginTop: "-5px",
                      padding: "4px 16px",
                    }}
                    onChange={(e) => {
                      setData({
                        ...data,
                        description: e.target.value,
                      });
                    }}
                  />
                  <div
                    id="settings"
                    style={{
                      width: isMobile ? "88vw" : "50vw",
                      padding: "16px",
                      display: "flex",
                      marginTop: "48px",
                      flexDirection: "row",
                      cursor: "pointer",
                      backgroundColor: "rgb(235, 233, 230)",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomLeftRadius: settingsToggled.benefits ? 0 : 16,
                      borderBottomRightRadius: settingsToggled.benefits
                        ? 0
                        : 16,
                    }}
                    onClick={() => {
                      setSettingsToggled({
                        ...settingsToggled,
                        benefits: !settingsToggled.benefits,
                      });
                    }}
                  >
                    <span
                      className="icon-small"
                      style={{
                        paddingRight: "15px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        float: "left",
                        display: "inline",
                        position: "relative",
                        height: "24px",
                        color: "black",
                        opacity: 0.5,
                      }}
                    >
                      {settingsToggled.benefits
                        ? "keyboard_arrow_down_icon"
                        : "keyboard_arrow_right_icon"}
                    </span>
                    <p
                      style={{
                        flex: 1,
                        fontWeight: "bold",
                        display: "inline",
                        fontSize: "16px",
                        color: "black",
                        opacity: 0.5,
                      }}
                    >
                      Employee Benefits
                    </p>
                  </div>
                  {settingsToggled.benefits && (
                    <div
                      style={{
                        width: isMobile ? "88vw" : "50vw",
                        padding: "16px 24px",
                        backgroundColor: "rgb(235, 233, 230)",
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                      }}
                    >
                      <label className="sub-label">One on each line</label>
                      <textarea
                        className="rounded form-text"
                        value={data.benefits.join("\n")}
                        rows={5}
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(250, 253, 255)",
                          boxShadow:
                            "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)",
                          borderColor: "white",
                          border: "0px none white",
                          outline: "none",
                        }}
                        onChange={(e) => {
                          let benefits = e.target.value.split("\n");
                          if (benefits.length === 1 && benefits[0].length === 0)
                            benefits = [];
                          setData({
                            ...data,
                            benefits: benefits,
                          });
                        }}
                      />
                    </div>
                  )}
                  <div
                    id="settings"
                    style={{
                      width: isMobile ? "88vw" : "50vw",
                      padding: "16px",
                      display: "flex",
                      marginTop: "16px",
                      flexDirection: "row",
                      cursor: "pointer",
                      backgroundColor: "rgb(235, 233, 230)",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomLeftRadius: settingsToggled.contacts ? 0 : 16,
                      borderBottomRightRadius: settingsToggled.contacts
                        ? 0
                        : 16,
                    }}
                    onClick={() => {
                      setSettingsToggled({
                        ...settingsToggled,
                        contacts: !settingsToggled.contacts,
                      });
                    }}
                  >
                    <span
                      className="icon-small"
                      style={{
                        paddingRight: "15px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        float: "left",
                        display: "inline",
                        position: "relative",
                        height: "24px",
                        color: "black",
                        opacity: 0.5,
                      }}
                    >
                      {settingsToggled.contacts
                        ? "keyboard_arrow_down_icon"
                        : "keyboard_arrow_right_icon"}
                    </span>
                    <p
                      style={{
                        flex: 1,
                        fontWeight: "bold",
                        display: "inline",
                        fontSize: "16px",
                        color: "black",
                        opacity: 0.5,
                      }}
                    >
                      Contact Details
                    </p>
                  </div>
                  {settingsToggled.contacts && (
                    <div
                      style={{
                        width: isMobile ? "88vw" : "50vw",
                        padding: "16px 24px",
                        backgroundColor: "rgb(235, 233, 230)",
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                      }}
                    >
                      <div
                        id="candidate-form"
                        style={{
                          margin: "16px 0",
                          borderRadius: "24px",
                        }}
                      >
                        {isHiringCentralized && (
                          <div>
                            {data.hiringManagers.map((hm, i) => {
                              return (
                                <div
                                  key={"hm" + i}
                                  className="row"
                                  style={{
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    marginTop: "8px",
                                    gap: isMobile ? "16px" : 0,
                                  }}
                                >
                                  <div style={{ marginRight: "15px" }}>
                                    <TextField
                                      label={
                                        <span>
                                          Name{" "}
                                          {i === 1 && (
                                            <span
                                              className="close-toggle"
                                              onClick={() => {
                                                setData({
                                                  ...data,
                                                  hiringManagers: [
                                                    data.hiringManagers[0],
                                                  ],
                                                });
                                              }}
                                            >
                                              x
                                            </span>
                                          )}
                                        </span>
                                      }
                                      value={hm.name}
                                      onChange={(e) => {
                                        setData({
                                          ...data,
                                          hiringManagers:
                                            data.hiringManagers.map(
                                              (current, j) => {
                                                if (i === j)
                                                  return {
                                                    ...current,
                                                    name: e,
                                                  };
                                                else return current;
                                              }
                                            ),
                                        });
                                      }}
                                    />
                                  </div>
                                  <div style={{ marginRight: "15px" }}>
                                    <TextField
                                      label="WhatsApp Number"
                                      value={hm.number || "+44"}
                                      onChange={(e) => {
                                        setData({
                                          ...data,
                                          hiringManagers:
                                            data.hiringManagers.map(
                                              (existing, j) => {
                                                if (i === j)
                                                  return {
                                                    ...existing,
                                                    number: e,
                                                  };
                                                else return existing;
                                              }
                                            ),
                                        });
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <TextField
                                      label="Email Address"
                                      value={hm.email}
                                      onChange={(e) => {
                                        setData({
                                          ...data,
                                          hiringManagers:
                                            data.hiringManagers.map(
                                              (existing, j) => {
                                                if (i === j)
                                                  return {
                                                    ...existing,
                                                    email: e,
                                                  };
                                                else return existing;
                                              }
                                            ),
                                        });
                                      }}
                                    />
                                  </div>
                                  {isMobile &&
                                    data.hiringManagers?.length > 1 && <br />}
                                </div>
                              );
                            })}
                            {data.hiringManagers.length === 1 && (
                              <div>
                                <br />
                                <button
                                  style={{ display: "flex" }}
                                  onClick={(e) => {
                                    setData({
                                      ...data,
                                      hiringManagers: [
                                        ...data.hiringManagers,
                                        { name: "", number: "", email: "" },
                                      ],
                                    });
                                  }}
                                >
                                  <span
                                    className="icon-small"
                                    style={{
                                      fontSize: "16px",
                                      height: "16px",
                                      position: "relative",
                                      top: "4px",
                                      width: "16px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    add
                                  </span>{" "}
                                  Add a Hiring Manager
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        <br />
                        <div className="row-all-devices">
                          <input
                            type="checkbox"
                            className="rounded form-checkbox"
                            defaultChecked={isHiringCentralized}
                            value={isHiringCentralized}
                            style={{
                              borderRadius: "4px",
                              borderColor: "#d5d5d5",
                              color: "rgba(0,0,0,0.5)",
                            }}
                            onChange={(e) => {
                              setIsHiringCentralized(e.target.checked);
                              if (e.target.checked) {
                                let hm =
                                  data.hiringManagers.length > 0 ||
                                  !data.branches[0]
                                    ? data.hiringManagers
                                    : data.branches[0].hiringManagers;
                                let branches = data.branches;
                                if (branches.length > 0) {
                                  branches.forEach((b) => {
                                    b.hiringManagers = [];
                                  });
                                }
                                setData({
                                  ...data,
                                  hiringManagers: hm,
                                  branches: branches,
                                });
                              } else {
                                let branches = data.branches;
                                if (branches.length > 0) {
                                  branches.forEach((b) => {
                                    b.hiringManagers = data.hiringManagers;
                                  });
                                }
                                setData({
                                  ...data,
                                  hiringManagers: [],
                                  branches: branches,
                                });
                              }
                            }}
                          />
                          <label
                            style={{
                              marginLeft: "10px",
                              marginTop: 0,
                              lineHeight: isMobile ? "20px" : "15px",
                              fontWeight: "normal",
                              fontSize: "16px",
                            }}
                            className="main-label"
                          >
                            Hiring is done by the same person for all locations
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {data.branches.some((branch, i) =>
                    branch.openPositions.some((position, j) =>
                      Object.values(supplyBlockers[i])
                        [j]?.filter((v) =>
                          ["Experience", "Picked by Candidates"].includes(
                            Object.keys(v)[0]
                          )
                        )
                        .some((v) => Object.values(v) <= 25)
                    )
                  ) && (
                    <>
                      <h5
                        style={{
                          marginTop: "64px",
                        }}
                      >
                        🪄 Tips for Success
                      </h5>
                      <div
                        style={{
                          width: isMobile ? "88vw" : "50vw",
                          padding: "32px",
                          marginTop: "16px",
                          backgroundColor: "rgb(235, 233, 230)",
                          borderRadius: "24px",
                        }}
                      >
                        {data.branches.map((branch, i) => {
                          return branch.openPositions.map((position, j) => {
                            if (
                              Object.values(supplyBlockers[i])
                                [j]?.filter((v) =>
                                  [
                                    "Experience",
                                    "Picked by Candidates",
                                    "Salary",
                                  ].includes(Object.keys(v)[0])
                                )
                                .some((v) => Object.values(v) <= 25)
                            ) {
                              return (
                                <div key={"tips-" + i + j}>
                                  <p
                                    style={{
                                      color: "#162e3c",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {data.branches.length > 1
                                        ? branch.name
                                        : ""}
                                    </span>{" "}
                                    - {position.role.join(", ")}
                                  </p>
                                  <div style={{ margin: "0 0 8px 0" }}>
                                    {Object.values(supplyBlockers[i])
                                      [j].filter(
                                        (blocker) =>
                                          Object.values(blocker)[0] <= 25
                                      )
                                      .map((blocker, b) => (
                                        <div>
                                          {Object.keys(blocker)[0] ===
                                          "Picked by Candidates" ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                padding: "8px 0",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "larger",
                                                  fontWeight: "bold",
                                                  margin: "0 16px",
                                                }}
                                              >
                                                ⏳
                                              </span>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgba(0,0,0,0.5)",
                                                    fontSize: "smaller",
                                                  }}
                                                >
                                                  {`Get up to ~${
                                                    (100 -
                                                      Object.values(
                                                        blocker
                                                      )[0]) /
                                                    2
                                                  }% more candidates by adding extra ${getInterviewType(
                                                    position
                                                  ).toLowerCase()} slots`}
                                                </span>
                                                <button
                                                  style={{
                                                    marginTop: "8px",
                                                    padding: "4px 16px",
                                                    fontSize: "smaller",
                                                    fontWeight: "bold",
                                                    borderRadius: "4px",
                                                    color: "rgba(0,0,0,0.5)",
                                                    backgroundColor:
                                                      "rgba(0,0,0,0.05)",
                                                    border: "none",
                                                    width: "fit-content",
                                                  }}
                                                  onClick={() =>
                                                    adjustInterviewSlots(i, j)
                                                  }
                                                >
                                                  Add more slots
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {Object.keys(blocker)[0] ===
                                            "Picked by Candidates" &&
                                          getInterviewDuration(
                                            branch,
                                            position
                                          ) >= 120 &&
                                          (position.trialPaid ??
                                            data.trialPaid) === "No" ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                padding: "8px 0",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "larger",
                                                  fontWeight: "bold",
                                                  margin: "0 16px",
                                                }}
                                              >
                                                🕰
                                              </span>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgba(0,0,0,0.5)",
                                                    fontSize: "smaller",
                                                  }}
                                                >
                                                  {`Get ~${Math.round(
                                                    (100 -
                                                      Object.values(
                                                        blocker
                                                      )[0]) /
                                                      3
                                                  )}% more candidates by reducing the ${getInterviewType(
                                                    position
                                                  ).toLowerCase()} duration and compensating it
                                                  `}
                                                </span>
                                                <button
                                                  style={{
                                                    marginTop: "8px",
                                                    padding: "4px 16px",
                                                    fontSize: "smaller",
                                                    fontWeight: "bold",
                                                    borderRadius: "4px",
                                                    color: "rgba(0,0,0,0.5)",
                                                    backgroundColor:
                                                      "rgba(0,0,0,0.05)",
                                                    border: "none",
                                                    width: "fit-content",
                                                  }}
                                                  onClick={() =>
                                                    adjustInterviewSettings(
                                                      i,
                                                      j
                                                    )
                                                  }
                                                >
                                                  Change{" "}
                                                  {getInterviewType(
                                                    position
                                                  ).toLowerCase()}{" "}
                                                  settings
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {Object.keys(blocker)[0] ===
                                            "Experience" &&
                                          position.monthsOfExperience >= 3 ? (
                                            <div
                                              style={{
                                                display: "flex",
                                                padding: "8px 0",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "larger",
                                                  fontWeight: "bold",
                                                  margin: "0 16px",
                                                }}
                                              >
                                                💼
                                              </span>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgba(0,0,0,0.5)",
                                                    fontSize: "smaller",
                                                  }}
                                                >
                                                  {`Get ~${
                                                    (100 -
                                                      Object.values(
                                                        blocker
                                                      )[0]) /
                                                    2
                                                  }% more candidates, by reducing the required experience from ${
                                                    position.monthsOfExperience
                                                  } to ${Math.round(
                                                    position.monthsOfExperience /
                                                      2
                                                  )} months`}
                                                </span>
                                                <button
                                                  style={{
                                                    marginTop: "8px",
                                                    padding: "4px 16px",
                                                    fontSize: "smaller",
                                                    fontWeight: "bold",
                                                    borderRadius: "4px",
                                                    color: "rgba(0,0,0,0.5)",
                                                    backgroundColor:
                                                      "rgba(0,0,0,0.05)",
                                                    border: "none",
                                                    width: "fit-content",
                                                  }}
                                                  onClick={() =>
                                                    adjustExperience(i, j)
                                                  }
                                                >
                                                  Change to{" "}
                                                  {Math.round(
                                                    position.monthsOfExperience /
                                                      2
                                                  )}{" "}
                                                  months
                                                </button>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          });
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          {pageShown === "interviews" && (
            <div style={{ width: "100%", height: "100%" }}>
              {interviewInfo && (
                <Modal
                  isOpen={interviewInfo != null}
                  closeModal={() => {
                    setInterviewInfo(null);
                    setCancellationConfirmationShown(false);
                  }}
                  style={{
                    zIndex: 3000,
                    width: isMobile ? "90%" : "100%",
                    maxWidth: "550px",
                    overflowX: "hidden",
                    backgroundColor: "#f2f0ed",
                  }}
                >
                  {interviewInfo.candidate ? (
                    <div>
                      <div
                        className="row-all-devices"
                        style={{ gap: "16px", marginBottom: "24px" }}
                      >
                        <a
                          style={{
                            flex: 1,
                            height: "100px",
                            borderRadius: "16px",
                            backgroundColor: "#ebe9e6",
                            cursor: "pointer",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            textDecoration: "none",
                          }}
                          href={interviewInfo.candidate["CV"]}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p
                            className="icon-small"
                            style={{
                              margin: "0 auto",
                              fontSize: "54px",
                              height: "54px",
                              color: "#49616e",
                            }}
                          >
                            contact_page
                          </p>
                          <p
                            style={{
                              fontSize: "smaller",
                              fontWeight: "bold",
                            }}
                          >
                            VIEW CV
                          </p>
                        </a>
                        <div
                          style={{
                            flex: 3,
                            height: "fit-content",
                            margin: "auto 0",
                          }}
                        >
                          <h5
                            style={{
                              fontWeight: "bold",
                              color: "#162e3c",
                            }}
                          >
                            {interviewInfo.candidate["Name"]}
                          </h5>
                          <p
                            style={{
                              fontSize: "smaller",
                              fontWeight: "bold",
                            }}
                          >
                            {
                              getRoleFromId(
                                interviewInfo.branch,
                                interviewInfo["Role Id"]
                              ).name
                            }{" "}
                            · Based in{" "}
                            {interviewInfo.candidate["Postcode"].toUpperCase()}
                          </p>
                          <p
                            style={{
                              fontSize: "smaller",
                              lineHeight: "1.5rem",
                              width: "fit-content",
                              display: "flex",
                              flexDirection: isMobile ? "column" : "row",
                              gap: "4px",
                            }}
                          >
                            <p
                              className={
                                premiumBranches.includes(
                                  interviewInfo["Employer Id"]
                                )
                                  ? ""
                                  : "no-select"
                              }
                              style={{
                                textDecoration: "none",
                                borderBottom: "1px solid #d5d0ca",
                                color: "inherit",
                                cursor: "pointer",
                              }}
                              onClick={async () => {
                                if (
                                  premiumBranches.includes(
                                    interviewInfo["Employer Id"]
                                  )
                                ) {
                                  recordCandidateCalled(
                                    interviewInfo.branch.id,
                                    interviewInfo.candidate["Id"]
                                  );
                                  window.open(
                                    `tel:${interviewInfo.candidate["WhatsApp Number"]}`,
                                    "_self"
                                  );
                                } else {
                                  window.open(
                                    `/${employerId}/${getEmployerSecret(
                                      employerId
                                    )}/upgrade`,
                                    "_blank"
                                  );
                                }
                              }}
                            >
                              <span
                                className="icon-small"
                                style={{
                                  display: "inline-block",
                                  fontSize: "16px",
                                  lineHeight: "16px",
                                  margin: 0,
                                  padding: 0,
                                  height: "16px",
                                  width: "24px",
                                  position: "relative",
                                  top: "4px",
                                  right: "8px",
                                  left: "4px",
                                }}
                              >
                                call
                              </span>
                              <span
                                style={{
                                  marginRight: "8px",
                                  color: premiumBranches.includes(
                                    interviewInfo["Employer Id"]
                                  )
                                    ? "inherit"
                                    : "transparent",
                                  textShadow: premiumBranches.includes(
                                    interviewInfo["Employer Id"]
                                  )
                                    ? "none"
                                    : "0 0 8px #000",
                                }}
                              >
                                {premiumBranches.includes(
                                  interviewInfo["Employer Id"]
                                )
                                  ? interviewInfo.candidate["WhatsApp Number"]
                                  : "+44 1234567890"}
                              </span>
                            </p>
                            {!premiumBranches.includes(
                              interviewInfo["Employer Id"]
                            ) && (
                              <span
                                style={{
                                  fontSize: "smaller",
                                  fontWeight: "bold",
                                  backgroundColor: "rgba(0,0,0,0.05)",
                                  borderRadius: "4px",
                                  padding: "0 8px",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(
                                    `/${employerId}/${getEmployerSecret(
                                      employerId
                                    )}/upgrade`,
                                    "_blank"
                                  );
                                }}
                              >
                                Upgrade to Premium 🚀
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          height: "fit-content",
                          borderRadius: "16px",
                          backgroundColor: "rgb(235, 233, 230)",
                          padding: "24px",
                          maxWidth: "700px",
                        }}
                      >
                        <p style={{ margin: "0 auto", textAlign: "center" }}>
                          {interviewInfo["Status"] === "Cancelled" ? (
                            <span>
                              <b>
                                {`This ${getInterviewType(
                                  getRoleFromId(
                                    interviewInfo.branch,
                                    interviewInfo["Role Id"]
                                  )
                                ).toLowerCase()} has been cancelled.`}
                              </b>
                            </span>
                          ) : (
                            <span>
                              {`${
                                moment(interviewInfo["Date"]).isAfter(moment())
                                  ? "Has"
                                  : "Had"
                              } their ${getInterviewType(
                                getRoleFromId(
                                  interviewInfo.branch,
                                  interviewInfo["Role Id"]
                                )
                              ).toLowerCase()}${
                                interviewInfo.branch.employerBranchCount > 1
                                  ? ` at your ${interviewInfo.branch.branchName} branch `
                                  : " "
                              } scheduled on:`}
                            </span>
                          )}
                        </p>
                        {interviewInfo["Status"] !== "Cancelled" && (
                          <h3
                            style={{
                              margin: "auto",
                              marginTop: "8px",
                              textAlign: "center",
                              lineHeight: "2.5rem",
                              fontSize: "1.75rem",
                            }}
                          >
                            {moment(interviewInfo["Date"]).format("ddd D MMM")}{" "}
                            - {moment(interviewInfo["Date"]).format("h:mma")}
                          </h3>
                        )}
                      </div>
                      {interviewInfo.branch.employerBranchCount > 1 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            marginTop: "8px",
                          }}
                        >
                          <span
                            className="icon-small"
                            style={{ fontSize: "24px", height: "24px" }}
                          >
                            location_on
                          </span>
                          {
                            data.branches.find(
                              (b) => b.id === interviewInfo["Employer Id"]
                            ).name
                          }
                        </div>
                      )}
                      {moment(interviewInfo["Date"]).isAfter(moment()) && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            gap: "16px",
                            marginTop: "24px",
                          }}
                        >
                          <button
                            className="button w-button"
                            style={{
                              padding: "8px 32px",
                              borderRadius: "8px",
                              transform: cancellationConfirmationShown
                                ? "scale(1.15)"
                                : "none",
                            }}
                            onClick={() => {
                              if (cancellationConfirmationShown) {
                                setInterviewCancelled(interviewInfo);
                              } else {
                                setCancellationConfirmationShown(true);
                              }
                            }}
                          >
                            <span
                              className="icon-small"
                              style={{
                                display: "inline-block",
                                fontSize: "20px",
                                lineHeight: "16px",
                                margin: 0,
                                padding: 0,
                                height: "16px",
                                width: "20px",
                                position: "relative",
                                top: "4px",
                                right: "8px",
                                color: "white",
                              }}
                            >
                              cancel
                            </span>
                            {cancellationConfirmationShown ? (
                              <span>Confirm</span>
                            ) : (
                              <span>
                                Cancel{" "}
                                {getInterviewType(
                                  getRoleFromId(
                                    interviewInfo.branch,
                                    interviewInfo["Role Id"]
                                  )
                                )}
                              </span>
                            )}
                          </button>
                          <a
                            className="button w-button"
                            style={{
                              padding: "8px 32px",
                              borderRadius: "8px",
                              textDecoration: "none",
                            }}
                            href={`/${data.id}/${interviewInfo.branch.id}/${
                              interviewInfo["Candidate Id"]
                            }/${getEmployerSecret(data.id)}/availability`}
                          >
                            <span
                              className="icon-small"
                              style={{
                                display: "inline-block",
                                fontSize: "20px",
                                lineHeight: "16px",
                                margin: 0,
                                padding: 0,
                                height: "16px",
                                width: "20px",
                                position: "relative",
                                top: "4px",
                                right: "8px",
                                color: "white",
                              }}
                            >
                              calendar_today
                            </span>
                            Ask to Reschedule
                          </a>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: "0 24px 24px",
                        width: "100%",
                        height: "300px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MoonLoader
                        color={"rgb(22, 46, 60)"}
                        loading={true}
                        size={50}
                      />
                    </div>
                  )}
                </Modal>
              )}
              {interviews ? (
                <div
                  style={{
                    flex: 1,
                    overflowY: "scroll",
                    padding: isMobile ? "0 15px 35px" : "25px",
                    margin: "24px auto",
                    width: "100%",
                    maxWidth: "900px",
                  }}
                >
                  <Modal
                    isOpen={interviewRecapShown}
                    closeModal={() => setInterviewRecapShown(false)}
                    style={{
                      zIndex: "2000",
                      backgroundColor: "#f2f0ed",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      width: "650px",
                      maxWidth: "95%",
                      padding: "24px",
                    }}
                  >
                    <h4>Your interviews today</h4>
                    <p
                      style={{
                        fontSize: "smaller",
                        opacity: 0.5,
                        marginBottom: "16px",
                      }}
                    >
                      {moment().format("Do MMM")}
                    </p>
                    {interviews.filter((i) =>
                      moment(i["Date"]).isSame(moment(), "day")
                    ).length ? (
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        {interviews
                          .filter(
                            (i) =>
                              moment(i["Date"]).isSame(moment(), "day") &&
                              i["Status"] !== "Cancelled"
                          )
                          .sort((a, b) => {
                            if (moment(a["Date"]).isBefore(moment(b["Date"]))) {
                              return -1;
                            } else {
                              return 1;
                            }
                          })
                          .map((interview, i) => (
                            <div
                              key={"interview-" + interview.id}
                              style={{
                                padding: "12px 0",
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                                cursor: "pointer",
                                borderBottom:
                                  i <
                                  interviews.filter(
                                    (i) =>
                                      moment(i["Date"]).isSame(
                                        moment(),
                                        "day"
                                      ) && i["Status"] !== "Cancelled"
                                  ).length -
                                    1
                                    ? "1px solid #d3d3d3"
                                    : "none",
                              }}
                              onClick={async () => {
                                setInterviewInfo({});
                                interview.candidate = await getCandidateInfo(
                                  interview["Candidate Id"],
                                  !premiumBranches?.includes(
                                    interview["Employer Id"]
                                  )
                                );
                                interview.branch = data.branches.find(
                                  (b) => b.id === interview["Employer Id"]
                                );
                                setInterviewInfo(interview);
                              }}
                            >
                              <div
                                style={{
                                  flexDirection: "column",
                                  flex: 1,
                                  fontSize: "smaller",
                                }}
                              >
                                <p>
                                  <b>
                                    {moment(interview["Date"]).format("h:mma")}
                                  </b>
                                </p>
                              </div>
                              <div style={{ flexDirection: "column", flex: 4 }}>
                                <h6>{interview["Candidate"]}</h6>
                                <p style={{ fontSize: "smaller" }}>{`${
                                  getRoleFromId(
                                    data.branches.find(
                                      (b) => b.id === interview["Employer Id"]
                                    ),
                                    interview["Role Id"]
                                  ).name
                                }${
                                  data.branches.length > 1
                                    ? ` - ${
                                        data.branches.find(
                                          (b) =>
                                            b.id === interview["Employer Id"]
                                        ).name
                                      } branch`
                                    : ""
                                }`}</p>
                                {!interview["Interview Reminder"].includes(
                                  "Can Attend"
                                ) && (
                                  <p
                                    style={{
                                      fontSize: "smaller",
                                      color: "#d00000",
                                    }}
                                  >
                                    <img
                                      src="/images/exclamation-mark.svg"
                                      alt=""
                                      width="15"
                                      height="15"
                                      style={{
                                        marginTop: "-3px",
                                        marginRight: "4px",
                                        color: "red",
                                      }}
                                    />{" "}
                                    Didn't confirm attendance: Higher no-show
                                    likelihood
                                  </p>
                                )}
                              </div>
                              <div
                                className="icon-small"
                                style={{
                                  width: "20px",
                                  fontSize: "20px",
                                  height: "20px",
                                  display: "block",
                                  margin: "auto",
                                }}
                              >
                                keyboard_arrow_right_icon
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "rgba(0,0,0,0.05)",
                          padding: "32px",
                          fontSize: "smaller",
                          textAlign: "center",
                          borderRadius: "16px",
                        }}
                      >
                        No interview today
                      </div>
                    )}
                    {interviewsNeedFeedback.length > 0 && (
                      <div
                        style={{
                          width: "100%",
                          marginTop: "24px",
                        }}
                      >
                        <h4>Yesterday's Interviews</h4>
                        <p
                          style={{
                            fontSize: "smaller",
                            opacity: 0.5,
                            marginBottom: "16px",
                          }}
                        >
                          Your feedback helps us send better candidates
                        </p>
                        {interviewsNeedFeedback.map((interview, i) => (
                          <div
                            style={{
                              padding: "12px 0",
                              display: "flex",
                              flexDirection: "row",
                              gap: "8px",
                              borderBottom:
                                i < interviewsNeedFeedback.length - 1
                                  ? "1px solid #d3d3d3"
                                  : "none",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 1,
                              }}
                            >
                              <h5>{interview["Candidate"]}</h5>
                              <p style={{ fontSize: "smaller" }}>{`${moment(
                                interview["Date"]
                              ).format("h:mma")}`}</p>
                              <p style={{ fontSize: "smaller" }}>{`${
                                data.branches.length > 1
                                  ? `${
                                      data.branches.find(
                                        (b) => b.id === interview["Employer Id"]
                                      ).name
                                    } branch`
                                  : ""
                              }`}</p>
                            </div>
                            <div style={{ position: "relative", flex: 1.5 }}>
                              <Select
                                allowMultiple={false}
                                allowSearch={false}
                                placeholder="Enter outcome"
                                selected={
                                  [
                                    "Hired",
                                    "Rejected",
                                    "In Progress",
                                    "No Show",
                                    "Rescheduled",
                                  ].includes(interview["Status"])
                                    ? interview["Status"]
                                    : null
                                }
                                options={[
                                  "Hired",
                                  "Rejected",
                                  "In Progress",
                                  "No Show",
                                  "Rescheduled",
                                ]}
                                onChange={(value) =>
                                  saveInterviewFeedback(i, "Status", value)
                                }
                                style={{ marginTop: "-8px" }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Modal>
                  <h2>Interviews</h2>
                  <div
                    style={{
                      margin: "48px 0",
                      padding: "0",
                      height: "70vh",
                      flex: 1,
                      maxWidth: isMobile ? "100vw" : "1000px",
                    }}
                  >
                    <FullCalendar
                      plugins={[dayGridPlugin, listPlugin]}
                      initialView={isMobile ? "listWeek" : "dayGridMonth"}
                      eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        meridiem: "short",
                      }}
                      titleFormat={
                        isMobile
                          ? { month: "short", day: "numeric" }
                          : { month: "long" }
                      }
                      headerToolbar={
                        isMobile
                          ? {
                              left: "prev,next",
                              right: "title",
                            }
                          : {
                              left: "prev,next today",
                              center: "title",
                              right: "dayGridMonth,dayGridWeek",
                            }
                      }
                      weekends
                      eventClick={async (info) => {
                        setInterviewInfo({});
                        let interview = { ...info.event.extendedProps };
                        interview.candidate = await getCandidateInfo(
                          interview["Candidate Id"],
                          !premiumBranches?.includes(interview["Employer Id"])
                        );
                        interview.branch = data.branches.find(
                          (b) => b.id === interview["Employer Id"]
                        );
                        setInterviewInfo(interview);
                      }}
                      listDaySideFormat={{
                        month: "short",
                        day: "numeric",
                      }}
                      height="100%"
                      events={interviews}
                    />
                    <div className="row-all-devices">
                      {data.branches.map((branch, i) => (
                        <div style={{ margin: "16px 16px 0 0" }}>
                          <span
                            style={{
                              border: `5px solid ${getColors()[i]}`,
                              borderRadius: "5px",
                              boxSizing: "content-box",
                              display: "inline-block",
                              height: 0,
                              width: 0,
                              margin: "0 8px 0 0",
                            }}
                          >
                            {" "}
                          </span>
                          {branch.name?.length > 0
                            ? branch.name
                            : `Your venue ${i < 1 ? "" : `${i}`}`}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{ width: "100vw", height: "100vh", display: "flex" }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      margin: "auto",
                    }}
                  >
                    <MoonLoader
                      color={"rgb(22, 46, 60)"}
                      loading={true}
                      size={50}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {pageShown === "candidates shown" && (
            <div style={{ width: "100%", height: "100%" }}>
              {candidateInfo && (
                  <Modal
                      isOpen={candidateInfo != null}
                      closeModal={() => {
                        setCandidateInfo(null);
                        setCancellationConfirmationShown(false);
                      }}
                      style={{
                        zIndex: 3000,
                        width: isMobile ? "90%" : "100%",
                        maxWidth: "550px",
                        overflowX: "hidden",
                        backgroundColor: "#f2f0ed",
                      }}
                  >
                    {candidateInfo.candidate ? (
                        <div>
                          <div
                              className="row-all-devices"
                              style={{ gap: "16px", marginBottom: "24px" }}
                          >
                            <a
                                style={{
                                  flex: 1,
                                  height: "100px",
                                  borderRadius: "16px",
                                  backgroundColor: "#ebe9e6",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  textDecoration: "none",
                                }}
                                href={candidateInfo.candidate["CV"]}
                                target="_blank"
                                rel="noreferrer"
                            >
                              <p
                                  className="icon-small"
                                  style={{
                                    margin: "0 auto",
                                    fontSize: "54px",
                                    height: "54px",
                                    color: "#49616e",
                                  }}
                              >
                                contact_page
                              </p>
                              <p
                                  style={{
                                    fontSize: "smaller",
                                    fontWeight: "bold",
                                  }}
                              >
                                VIEW CV
                              </p>
                            </a>
                            <div
                                style={{
                                  flex: 3,
                                  height: "fit-content",
                                  margin: "auto 0",
                                }}
                            >
                              <h5
                                  style={{
                                    fontWeight: "bold",
                                    color: "#162e3c",
                                  }}
                              >
                                {candidateInfo.candidate["Name"]}
                              </h5>
                              <p
                                  style={{
                                    fontSize: "smaller",
                                    fontWeight: "bold",
                                  }}
                              >
                                {candidateInfo["Role Id"]
                                }{" "}
                                · {candidateInfo.candidate["What positions are you looking for?"][0]} Based in{" "}
                                {candidateInfo.candidate["Postcode"].toUpperCase()}
                              </p>
                              <p
                                  style={{
                                    fontSize: "smaller",
                                    lineHeight: "1.5rem",
                                    width: "fit-content",
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    gap: "4px",
                                  }}
                              >
                                {!premiumBranches.includes(
                                    candidateInfo["Employer Id"]
                                ) && (
                                    <span
                                        style={{
                                          fontSize: "smaller",
                                          fontWeight: "bold",
                                          backgroundColor: "rgba(0,0,0,0.05)",
                                          borderRadius: "4px",
                                          padding: "0 8px",
                                          whiteSpace: "nowrap",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          window.open(
                                              `/${employerId}/${getEmployerSecret(
                                                  employerId
                                              )}/upgrade`,
                                              "_blank"
                                          );
                                        }}
                                    >
                                Upgrade to Premium 🚀
                              </span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                              style={{
                                height: "fit-content",
                                borderRadius: "16px",
                                backgroundColor: "rgb(235, 233, 230)",
                                padding: "24px",
                                maxWidth: "700px",
                              }}
                          >
                            <p style={{ margin: "0 auto", textAlign: "center" }}>
                            </p>
                                <h3
                                    style={{
                                      margin: "auto",
                                      marginTop: "8px",
                                      textAlign: "center",
                                      lineHeight: "2.5rem",
                                      fontSize: "1.75rem",
                                    }}
                                >
                                  {candidateInfo.interactionStatus}
                                </h3>
                          </div>
                        </div>
                    ) : (
                        <div
                            style={{
                              padding: "0 24px 24px",
                              width: "100%",
                              height: "300px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                        >
                          <MoonLoader
                              color={"rgb(22, 46, 60)"}
                              loading={true}
                              size={50}
                          />
                        </div>
                    )}
                  </Modal>
              )}
              {candidatesShown ? (
                <div
                  style={{
                    flex: 1,
                    overflowY: "scroll",
                    padding: isMobile ? "0 15px 35px" : "25px",
                    margin: "24px auto",
                    width: "100%",
                    maxWidth: "900px",
                  }}
                >
                  <h2>Candidates Received</h2>
                  <div
                    style={{
                      margin: "48px 0",
                      padding: "0",
                      height: "70vh",
                      flex: 1,
                      maxWidth: isMobile ? "100vw" : "1000px",
                    }}
                  >
                    <FullCalendar
                      plugins={[dayGridPlugin, listPlugin]}
                      initialView={isMobile ? "listWeek" : "dayGridMonth"}
                      eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        meridiem: "short",
                      }}
                      titleFormat={
                        isMobile
                          ? { month: "short", day: "numeric" }
                          : { month: "long" }
                      }
                      headerToolbar={
                        isMobile
                          ? {
                            left: "prev,next",
                            right: "title",
                          }
                          : {
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,dayGridWeek",
                          }
                      }
                      weekends
                      eventClick={async (info) => {
                        setCandidateInfo({});
                        let candidate = { ...info.event.extendedProps };
                        // check if rejected time exists
                        if(candidate.rejected_time !== undefined) {
                          candidate.interactionStatus = "Rejected"
                        }
                        else if(candidate.scheduled_time !== undefined) {
                          candidate.interactionStatus = "Interview Scheduled"
                        }
                        // check if contact time exists
                        else if(candidate.contacted_time !== undefined) {
                          candidate.interactionStatus = "Contacted"
                        }
                        else {
                          candidate.interactionStatus = "Not Contacted"
                        }
                        candidate.candidate = await getCandidateInfo(
                          candidate["candidate_id"],
                          !premiumBranches?.includes(candidate["employer_id"])
                        );
                        setCandidateInfo(candidate);
                      }}
                      listDaySideFormat={{
                        month: "short",
                        day: "numeric",
                      }}
                      height="100%"
                      events={candidatesShown}
                    />
                    <div className="row-all-devices">
                      {data.branches.map((branch, i) => (
                        <div style={{ margin: "16px 16px 0 0" }}>
                          <span
                            style={{
                              border: `5px solid ${getColors()[i]}`,
                              borderRadius: "5px",
                              boxSizing: "content-box",
                              display: "inline-block",
                              height: 0,
                              width: 0,
                              margin: "0 8px 0 0",
                            }}
                          >
                            {" "}
                          </span>
                          {branch.name?.length > 0
                            ? branch.name
                            : `Your venue ${i < 1 ? "" : `${i}`}`}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{ width: "100vw", height: "100vh", display: "flex" }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      height: "fit-content",
                      margin: "auto",
                    }}
                  >
                    <MoonLoader
                      color={"rgb(22, 46, 60)"}
                      loading={true}
                      size={50}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {pageShown === "settings" && (
            <div
              style={{
                flex: 1,
                overflowY: "scroll",
                padding: isMobile ? "0 15px 35px" : "25px 200px 25px 25px",
                margin: "24px auto",
                width: "100%",
                maxWidth: "900px",
              }}
            >
              <h2>Settings</h2>
              <div style={{ marginTop: "48px", maxWidth: "600px" }}>
                <div
                  style={{
                    backgroundColor: "rgb(235, 233, 230)",
                    borderRadius: "16px",
                    marginTop: "24px",
                  }}
                >
                  <div
                    style={{
                      padding: "16px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSettingsToggled({
                        ...settingsToggled,
                        pause: !settingsToggled.pause,
                      });
                    }}
                  >
                    <span
                      className="icon-small"
                      style={{
                        paddingRight: "15px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        float: "left",
                        display: "inline",
                        position: "relative",
                        height: "24px",
                      }}
                    >
                      {settingsToggled.pause
                        ? "keyboard_arrow_down_icon"
                        : "keyboard_arrow_right_icon"}
                    </span>
                    <p
                      style={{
                        flex: 1,
                        fontWeight: "bold",
                        display: "inline",
                        fontSize: "16px",
                      }}
                    >
                      {data.branches.some(
                        (b) => ![undefined, true].includes(b.active)
                      )
                        ? "Do you want to restart receiving candidates?"
                        : "Do you want to pause receiving candidates?"}
                    </p>
                  </div>
                  {settingsToggled.pause && (
                    <div style={{ padding: "16px 24px" }}>
                      {data.branches.map((branch, i) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "16px",
                            justifyContent: "space-between",
                            margin: "16px 0",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <p style={{ margin: isMobile ? 0 : "auto 48px" }}>
                            <span
                              className="icon-small"
                              style={{
                                display: "inline-block",
                                fontSize: "16px",
                              }}
                            >
                              subdirectory_arrow_right
                            </span>
                            {branch.name?.length > 0
                              ? branch.name
                              : `Your venue ${i < 1 ? "" : `${i}`}`}
                          </p>
                          <button
                            className="button w-button"
                            style={{
                              margin: 0,
                              borderRadius: "8px",
                              padding: "7px 25px",
                              border: "none",
                              backgroundColor: [undefined, true].includes(
                                branch.active
                              )
                                ? "rgba(0,0,0,0.05)"
                                : "#49616e",
                              color: ![undefined, true].includes(branch.active)
                                ? "rgb(235, 233, 230)"
                                : "#49616e",
                            }}
                            onClick={() => {
                              if ([undefined, true].includes(branch.active)) {
                                setBranchToPause(branch);
                              } else {
                                let branches = data.branches;
                                branches[i] = {
                                  ...branch,
                                  active: true,
                                  pausedUntil: undefined,
                                  activeUntil: moment()
                                    .add(2, "weeks")
                                    .toDate(),
                                };
                                setData({ ...data, branches: [...branches] });
                              }
                            }}
                          >
                            {[undefined, true].includes(branch.active)
                              ? "Pause this venue "
                              : "Re-activate this venue "}
                            <span
                              className="icon-small"
                              style={{
                                display: "inline",
                                fontSize: "20px",
                                marginLeft: "5px",
                                position: "relative",
                                top: "5px",
                                lineHeight: "16px",
                              }}
                            >
                              {[undefined, true].includes(branch.active)
                                ? "pause"
                                : "restart_alt"}
                            </span>
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "rgb(235, 233, 230)",
                    borderRadius: "16px",
                    marginTop: "24px",
                  }}
                >
                  <div
                    style={{
                      padding: "16px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSettingsToggled({
                        ...settingsToggled,
                        autopilot: !settingsToggled.autopilot,
                      });
                    }}
                  >
                    <span
                      className="icon-small"
                      style={{
                        paddingRight: "15px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        float: "left",
                        display: "inline",
                        position: "relative",
                        height: "24px",
                      }}
                    >
                      {settingsToggled.autopilot
                        ? "keyboard_arrow_down_icon"
                        : "keyboard_arrow_right_icon"}
                    </span>
                    <p
                      style={{
                        flex: 1,
                        fontWeight: "bold",
                        display: "inline",
                        fontSize: "16px",
                      }}
                    >
                      Do you want to use Autopilot Hiring?
                      <span
                        className="icon-large"
                        data-tip="Vetted candidates automatically show up at your
                venue during the provided time slots. You can
                reschedule/reject them in advance"
                        data-for="autopilot-tooltip"
                        style={{
                          display: "inline",
                          fontSize: "17px",
                          lineHeight: "17px",
                          height: "17px",
                          width: "17px",
                          position: "relative",
                          top: "4px",
                          left: "4px",
                          opacity: ".5",
                          cursor: "pointer",
                        }}
                      >
                        help
                      </span>
                      <ReactTooltip
                        effect="solid"
                        id="autopilot-tooltip"
                        place="top"
                      />
                    </p>
                  </div>
                  {settingsToggled.autopilot && (
                    <div style={{ padding: "16px 24px" }}>
                      <div
                        style={{
                          backgroundColor: "rgba(0,0,0,0.05)",
                          padding: "24px 32px",
                          marginBottom: "32px",
                          borderRadius: "8px",
                          display: "flex",
                          gap: "12px",
                          flexDirection: "column",
                          fontSize: "14px",
                        }}
                      >
                        <b>How does it work?</b>
                        <p>
                          🗓 Vetted candidates who meet your experience and
                          salary expectations will book an interview/trial with
                          you. You'll be notified 12+ hours in advance and can
                          cancel or reschedule them at any time
                        </p>
                        <p>
                          🔁 By default, <b>they will attend</b> the
                          interview/trial, so make sure to cancel/reschedule if
                          you can't make it.
                        </p>
                        <p>
                          😊 No need for you to pre-approve all interviews, and
                          spend time going through CVs. Candidates will show up
                          automatically unless you cancel or reschedule the
                          interview
                        </p>
                      </div>
                      {data.branches.map((branch, i) => (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "16px",
                              margin: "8px 0",
                              whiteSpace: "nowrap",
                              justifyContent: "space-between",
                              paddingLeft: "48px",
                            }}
                          >
                            <p>
                              <span
                                className="icon-small"
                                style={{
                                  display: "inline-block",
                                  fontSize: "16px",
                                }}
                              >
                                subdirectory_arrow_right
                              </span>
                              {branch.name?.length > 0
                                ? branch.name
                                : `Your venue ${i < 1 ? "" : `${i}`}`}
                            </p>
                            <Switch
                              onChange={(checked) => {
                                let branches = data.branches;
                                branches[i] = { ...branch, optOut: checked };
                                setData({ ...data, branches: [...branches] });
                              }}
                              checked={branch.optOut}
                              offColor="#9c9b99"
                              onColor="#3cb043"
                              offHandleColor="#f2f0ed"
                              onHandleColor="#f2f0ed"
                              height={28}
                              handleDiameter={24}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {data && !premiumBranches?.length ? (
                  <div
                    style={{
                      marginTop: "64px",
                      width: "100%",
                      backgroundColor: "white",
                      padding: "8px 24px",
                      borderRadius: "16px",
                    }}
                  >
                    <div
                      className="pricing-feature-list link-secondary"
                      style={{
                        textAlign: "left",
                        gap: "8px",
                        marginBottom: 0,
                        fontSize: "14px",
                      }}
                    >
                      <b
                        style={{
                          marginBottom: "16px",
                          fontSize: "20px",
                        }}
                      >
                        Upgrade your account now to:
                      </b>
                      <div className="row">
                        <div className="column">
                          <div className="pricing-feature">
                            <div
                              className="pricing-feature-icon-wrapper"
                              style={{ backgroundColor: "white" }}
                            >
                              <div className="icon-xsmall">check</div>
                            </div>
                            <div className="text-weight-semibold link-secondary">
                              Schedule unlimited interviews
                            </div>
                          </div>
                          <div className="pricing-feature">
                            <div
                              className="pricing-feature-icon-wrapper"
                              style={{ backgroundColor: "white" }}
                            >
                              <div className="icon-xsmall">check</div>
                            </div>
                            <div className="text-weight-semibold link-secondary">
                              See candidate contact details
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="pricing-feature">
                            <div
                              className="pricing-feature-icon-wrapper"
                              style={{ backgroundColor: "white" }}
                            >
                              <div className="icon-xsmall">check</div>
                            </div>
                            <div
                              className="text-weight-semibold link-secondary"
                              style={{ backgroundColor: "white" }}
                            >
                              Hire Managers
                            </div>
                          </div>
                          <div className="pricing-feature">
                            <div
                              className="pricing-feature-icon-wrapper"
                              style={{ backgroundColor: "white" }}
                            >
                              <div className="icon-xsmall">check</div>
                            </div>
                            <div className="text-weight-semibold link-secondary">
                              Use Autopilot Hiring{" "}
                              <span
                                className="icon-large"
                                data-tip="Vetted candidates automatically show up at your
                venue during the provided time slots. You can
                reschedule/reject them in advance"
                                data-for="autopilot-info"
                                style={{
                                  display: "inline",
                                  fontSize: "17px",
                                  lineHeight: "17px",
                                  height: "17px",
                                  width: "17px",
                                  position: "relative",
                                  left: "4px",
                                  opacity: ".5",
                                  cursor: "pointer",
                                }}
                              >
                                help
                              </span>
                              <ReactTooltip
                                effect="solid"
                                id="autopilot-info"
                                place="bottom"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="button animate__animated animate__headShake animate__delay-4s"
                        onClick={() => {
                          navigate(
                            `/${data.id}/${getEmployerSecret(data.id)}/upgrade`
                          );
                        }}
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          padding: "0.75rem",
                          marginTop: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        Upgrade your{" "}
                        {premiumBranches.length > 0
                          ? "other venues"
                          : "Account"}{" "}
                        🚀
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {data && (
                      <div
                        style={{
                          marginTop: "64px",
                          width: "100%",
                          backgroundColor: "white",
                          padding: "12px 32px",
                          borderRadius: "16px",
                          fontSize: "smaller",
                          fontWeight: "bold",
                        }}
                      >
                        For any feedback, or to cancel your subscription, please
                        contacts us at{" "}
                        <span style={{ borderBottom: "1px solid #efefef" }}>
                          info@hirebolt.com
                        </span>{" "}
                        or message us on WhatsApp.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          {data.branches.map((branch, i) => {
            return branch.id === pageShown ? (
              <div style={{ width: "100%", overflowY: "scroll" }}>
                {branch?.pictures && typeof branch.pictures !== "object" ? (
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "900px",
                      height: "40vh",
                      opacity: ".9",
                      zIndex: "2000",
                      padding: "24px 32px",
                      borderRadius: "16px",
                      margin: "auto",
                    }}
                  >
                    <Slide autoplay={false} transitionDuration={300}>
                      {branch.pictures.map((slideImage, index) => (
                        <div key={index}>
                          <div
                            style={{
                              borderRadius: "16px",
                              height: "32vh",
                              margin: "24px 0",
                              alignItems: "center",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundImage: `url(${slideImage})`,
                            }}
                          ></div>
                        </div>
                      ))}
                    </Slide>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "32vh",
                      width: "100%",
                      maxWidth: "100vw",
                      padding: "24px",
                    }}
                  >
                    <div
                      className="image-banner"
                      style={{
                        background: `url(${
                          data.pictures ??
                          "/images/pexels-rodnae-productions-4921154-p-1600.jpg"
                        })`,
                        width: "100%",
                        height: "100%",
                        backgroundSize: "cover",
                        borderRadius: "16px",
                        backgroundPositionY: "center",
                      }}
                    ></div>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    maxWidth: "900px",
                    margin: "24px auto",
                  }}
                >
                  <div
                    style={{
                      padding: isMobile ? "0 15px 35px" : "25px",
                    }}
                  >
                    <Modal
                      isOpen={deletePopupOpen}
                      closeModal={() => {
                        setDeletePopupOpen(false);
                        setDeletePopupCallback(() => {});
                      }}
                      style={{ zIndex: Number.MAX_SAFE_INTEGER }}
                    >
                      <div
                        style={{
                          marginTop: "32px",
                          maxWidth: "350px",
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            padding: isMobile ? 0 : "0 32px",
                            fontWeight: "bold",
                          }}
                        >
                          Do you want to permanently delete this?
                        </p>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            gap: "16px",
                            marginTop: "32px",
                          }}
                        >
                          <button
                            className="button-secondary"
                            style={{
                              margin: "auto",
                              color: "red",
                              borderColor: "red",
                              backgroundColor: "white",
                              borderRadius: "8px",
                              padding: "8px 16px",
                              flex: 1,
                            }}
                            onClick={() => {
                              deletePopupCallback();
                              setDeletePopupOpen(false);
                              setDeletePopupCallback(() => {});
                            }}
                          >
                            Delete
                          </button>
                          <button
                            className="button w-button"
                            style={{
                              margin: "auto",
                              borderRadius: "8px",
                              flex: 1,
                              padding: "8px 16px",
                            }}
                            onClick={() => {
                              setDeletePopupOpen(false);
                              setDeletePopupCallback(() => {});
                            }}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </Modal>
                    <h2>
                      <span
                        className="icon-small"
                        style={{
                          display: "inline-block",
                          marginRight: "16px",
                        }}
                      >
                        subdirectory_arrow_right
                      </span>

                      {branch.name?.length > 0
                        ? branch.name
                        : `Your venue ${i < 1 ? "" : `${i}`}`}
                      {premiumBranches &&
                        premiumBranches.includes(branch.id) && (
                          <>
                            <span
                              data-tip="Premium venue 🎉"
                              data-for="premium-branch-tooltip"
                              className="icon-small"
                              style={{
                                display: "inline-block",
                                marginLeft: "8px",
                                verticalAlign: "super",
                                fontSize: "24px",
                                cursor: "pointer",
                              }}
                            >
                              workspace_premium
                            </span>
                          </>
                        )}
                    </h2>
                    <b>
                      <ReactTooltip
                        effect="solid"
                        id="premium-branch-tooltip"
                        place="top"
                      />
                    </b>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        margin: "16px 0",
                        gap: "16px",
                      }}
                    >
                      <div
                        data-tip="Create a new duplicate venue"
                        data-for="branch-tooltip"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          padding: "2px 16px",
                          backgroundColor: "rgb(235, 233, 230)",
                          borderRadius: "8px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          let b = { ...branch };
                          b.name = data.branches[i].name + " (Copy)";
                          b.id = data.branches[i].id + data.branches.length;
                          setData({
                            ...data,
                            branches: [...data.branches, b],
                          });
                          if (data.subscriptionId.startsWith("free"))
                            setPremiumBranches([...premiumBranches, b.id]);
                          setPageShown(b.id);
                        }}
                      >
                        <span style={{ margin: "auto 0" }}>Duplicate</span>
                        <span
                          className="icon-small"
                          style={{
                            fontSize: "20px",
                            margin: 0,
                            width: "20px",
                            fontWeight: "normal",
                          }}
                        >
                          copy
                        </span>
                      </div>
                      <div
                        data-tip="Remove this venue from your account"
                        data-for="branch-tooltip"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          padding: "2px 16px",
                          backgroundColor: "rgb(235, 233, 230)",
                          borderRadius: "8px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          openDeleteConfirmation(() => {
                            let branches = data.branches;
                            branches.splice(i, 1);
                            setData({
                              ...data,
                              branches: branches,
                            });
                          });
                        }}
                      >
                        <span style={{ margin: "auto 0" }}>Delete</span>
                        <span
                          className="icon-small"
                          style={{
                            fontSize: "20px",
                            margin: 0,
                            width: "20px",
                            fontWeight: "normal",
                          }}
                        >
                          delete_outline
                        </span>
                      </div>
                      <div
                        data-tip={
                          [undefined, true].includes(branch.active)
                            ? "Stop receiving candidates for this venue"
                            : "Start receiving candidates for this venue"
                        }
                        data-for="branch-tooltip"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                          padding: "2px 16px",
                          backgroundColor: [undefined, true].includes(
                            branch.active
                          )
                            ? "rgb(235, 233, 230)"
                            : "#49616e",
                          color: ![undefined, true].includes(branch.active)
                            ? "rgb(235, 233, 230)"
                            : "#49616e",
                          borderRadius: "8px",
                          fontWeight: "bold",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          if ([undefined, true].includes(branch.active)) {
                            setBranchToPause(branch);
                          } else {
                            let branches = data.branches;
                            branches[i] = {
                              ...branch,
                              active: true,
                              pausedUntil: undefined,
                              activeUntil: moment().add(2, "weeks").toDate(),
                            };
                            setData({ ...data, branches: [...branches] });
                          }
                        }}
                      >
                        <span style={{ margin: "auto 0" }}>
                          {[undefined, true].includes(branch.active)
                            ? "Pause"
                            : "Restart"}{" "}
                          Hiring
                        </span>
                        <span
                          className="icon-small"
                          style={{
                            fontSize: "20px",
                            margin: 0,
                            width: "20px",
                            fontWeight: "normal",
                          }}
                        >
                          {[undefined, true].includes(branch.active)
                            ? "pause"
                            : "play_circle_outline"}
                        </span>
                      </div>
                      <ReactTooltip
                        delayShow={300}
                        effect="solid"
                        id="branch-tooltip"
                        place="bottom"
                      />
                    </div>
                    <h5 style={{ margin: "48px 0 16px" }}>Details</h5>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        gap: isMobile ? "16px" : "8px",
                        margin: "0 0 48px 0",
                      }}
                    >
                      <div className="row">
                        <div className="column">
                          <TextField
                            label="Venue name"
                            max={20}
                            value={branch.name}
                            onChange={(e) => {
                              setBranch(
                                {
                                  ...branch,
                                  name: e,
                                },
                                i
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="column">
                        <TextField
                          label={
                            <span>
                              Full Address{" "}
                              <a
                                href={`http://maps.google.com/?q=${data.name} (${branch.name}), ${branch.address}`.replace(
                                  / /g,
                                  "+"
                                )}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                  fontWeight: "normal",
                                  textDecoration: "none",
                                  opacity: "0.5",
                                  marginLeft: "8px",
                                }}
                              >
                                View on Map {">"}
                              </a>
                            </span>
                          }
                          value={branch.address}
                          onChange={(e) => {
                            setBranch(
                              {
                                ...branch,
                                address: e,
                              },
                              i
                            );
                          }}
                        />
                      </div>
                    </div>
                    {!isHiringCentralized && (
                      <div>
                        <h5>Hiring Manager</h5>
                        {data.branches[i].hiringManagers.map((hm, j) => {
                          return (
                            <div key={"hm" + j} className="row">
                              <div style={{ marginRight: "15px" }}>
                                <label className="main-label">
                                  Name{" "}
                                  {j === 1 && (
                                    <span
                                      className="close-toggle-dark"
                                      onClick={() => {
                                        let branch = data.branches[i];
                                        branch.hiringManagers = [
                                          branch.hiringManagers[0],
                                        ];
                                        setBranch(branch);
                                      }}
                                    >
                                      x
                                    </span>
                                  )}
                                </label>
                                <input
                                  type="text"
                                  className="rounded form-text"
                                  value={
                                    data.branches[i].hiringManagers[j].name
                                  }
                                  onChange={(e) => {
                                    setBranch(
                                      {
                                        ...data.branches[i],
                                        hiringManagers: data.branches[
                                          i
                                        ].hiringManagers.map((hm, n) => {
                                          if (n === j)
                                            return {
                                              ...hm,
                                              name: e.target.value,
                                            };
                                          else return hm;
                                        }),
                                      },
                                      i
                                    );
                                  }}
                                />
                              </div>
                              <div style={{ marginRight: "15px" }}>
                                <label className="main-label">
                                  WhatsApp Number
                                </label>
                                <input
                                  type="text"
                                  className="rounded form-text"
                                  value={
                                    data.branches[i].hiringManagers[j].number ||
                                    "+44"
                                  }
                                  onChange={(e) => {
                                    setBranch(
                                      {
                                        ...data.branches[i],
                                        hiringManagers: data.branches[
                                          i
                                        ].hiringManagers.map((hm, n) => {
                                          if (n === j)
                                            return {
                                              ...hm,
                                              number: e.target.value,
                                            };
                                          else return hm;
                                        }),
                                      },
                                      i
                                    );
                                  }}
                                />
                              </div>
                              <div style={{ marginRight: "15px" }}>
                                <label className="main-label">Email</label>
                                <input
                                  type="text"
                                  className="rounded form-text"
                                  value={
                                    data.branches[i].hiringManagers[j].email
                                  }
                                  onChange={(e) => {
                                    setBranch(
                                      {
                                        ...data.branches[i],
                                        hiringManagers: data.branches[
                                          i
                                        ].hiringManagers.map((hm, n) => {
                                          if (n === j)
                                            return {
                                              ...hm,
                                              email: e.target.value,
                                            };
                                          else return hm;
                                        }),
                                      },
                                      i
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                        {data.branches[i].hiringManagers.length === 1 && (
                          <div>
                            <br />
                            <button
                              style={{ textDecoration: "underline" }}
                              onClick={(e) => {
                                setBranch(
                                  {
                                    ...data.branches[i],
                                    hiringManagers: [
                                      ...data.branches[i].hiringManagers,
                                      { name: "", number: "", email: "" },
                                    ],
                                  },
                                  i
                                );
                              }}
                            >
                              Add another Hiring Manager
                            </button>
                          </div>
                        )}
                        <hr style={{ margin: "24px 0" }} />
                      </div>
                    )}
                    <h5>Open Positions</h5>
                    {data.branches[i].openPositions.map((pos, p) => {
                      return (
                        <div
                          style={{
                            backgroundColor: "rgb(235, 233, 230)",
                            borderRadius: "16px",
                            marginTop: "24px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                padding: "16px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let toggles = [...positionsToggled];
                                toggles[p] = !toggles[p];
                                setPositionsToggled(toggles);
                              }}
                            >
                              <span
                                className="icon-small"
                                style={{
                                  paddingRight: "15px",
                                  fontSize: "24px",
                                  lineHeight: "24px",
                                  float: "left",
                                  display: "inline",
                                  position: "relative",
                                  height: "24px",
                                  opacity: [undefined, true].includes(
                                    pos.active
                                  )
                                    ? 1
                                    : 0.66,
                                }}
                              >
                                {positionsToggled[p]
                                  ? "keyboard_arrow_down_icon"
                                  : "keyboard_arrow_right_icon"}
                              </span>
                              <p
                                style={{
                                  flex: 1,
                                  fontWeight: "bold",
                                  display: "inline",
                                  fontSize: "16px",
                                  opacity: [undefined, true].includes(
                                    pos.active
                                  )
                                    ? 1
                                    : 0.66,
                                }}
                              >
                                {pos.role?.length > 0
                                  ? pos.role.join(", ")
                                  : `Position ${p}`}
                              </p>
                            </div>
                            {pos.role.some((r) =>
                              getPremiumRoles().includes(r)
                            ) && !premiumBranches.includes(branch.id) ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px",
                                    padding: "0 16px",
                                    margin: "auto",
                                  }}
                                  data-tip="❌ Kitchen and Managerial positions are not allowed on your plan. Please Upgrade your account, to hire for this role."
                                  data-for={`premium-role-${branch.id}-${p}`}
                                >
                                  <span
                                    className="icon-small"
                                    style={{
                                      display: "inline",
                                      fontSize: "24px",
                                      lineHeight: "24px",
                                      position: "relative",
                                      height: "24px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      openDeleteConfirmation(() => {
                                        let positions =
                                          data.branches[i].openPositions;
                                        positions.splice(p, 1);
                                        setBranch(
                                          {
                                            ...data.branches[i],
                                            openPositions: positions,
                                          },
                                          i
                                        );
                                      });
                                    }}
                                  >
                                    delete_outline
                                  </span>
                                  <div
                                    style={{
                                      opacity: [undefined, true].includes(
                                        pos.active
                                      )
                                        ? 1
                                        : 0.66,
                                    }}
                                  >
                                    <Switch
                                      checked={false}
                                      disabled={true}
                                      offColor="#9c9b99"
                                      onColor="#3cb043"
                                      checkedIcon={<></>}
                                      uncheckedIcon={<></>}
                                      offHandleColor="#f2f0ed"
                                      onHandleColor="#f2f0ed"
                                      height={24}
                                      handleDiameter={20}
                                    />
                                  </div>
                                </div>
                                <ReactTooltip
                                  id={`premium-role-${branch.id}-${p}`}
                                  place="top"
                                  effect="solid"
                                />
                              </>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "8px",
                                  padding: "0 16px",
                                  margin: "auto",
                                }}
                              >
                                {pos.active === false && (
                                  <span
                                    className="icon-small"
                                    style={{
                                      display: "inline",
                                      fontSize: "24px",
                                      lineHeight: "24px",
                                      position: "relative",
                                      height: "24px",
                                      top: "3px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      openDeleteConfirmation(() => {
                                        let positions =
                                          data.branches[i].openPositions;
                                        positions.splice(p, 1);
                                        setBranch(
                                          {
                                            ...data.branches[i],
                                            openPositions: positions,
                                          },
                                          i
                                        );
                                      });
                                    }}
                                  >
                                    delete_outline
                                  </span>
                                )}
                                <div
                                  data-tip="Do you want to receive candidates for this position?"
                                  data-for="position-pause"
                                  style={{ position: "relative", top: "4px" }}
                                >
                                  <Switch
                                    onChange={(checked) => {
                                      let positions =
                                        data.branches[i].openPositions;
                                      positions[p].active = checked;
                                      setBranch(
                                        {
                                          ...data.branches[i],
                                          openPositions: positions,
                                        },
                                        i
                                      );
                                    }}
                                    checked={[undefined, true].includes(
                                      pos.active
                                    )}
                                    offColor="#9c9b99"
                                    onColor="#3cb043"
                                    checkedIcon={<></>}
                                    uncheckedIcon={<></>}
                                    offHandleColor="#f2f0ed"
                                    onHandleColor="#f2f0ed"
                                    height={24}
                                    handleDiameter={20}
                                  />
                                </div>
                                <ReactTooltip
                                  effect="solid"
                                  id="position-pause"
                                  place="top"
                                />
                              </div>
                            )}
                          </div>
                          {positionsToggled[p] && (
                            <div style={{ padding: "16px 24px" }}>
                              <Select
                                label={"Roles"}
                                allowSearch
                                selected={
                                  data.branches[i].openPositions[p].role
                                }
                                onChange={(e) => {
                                  let branch = JSON.parse(
                                    JSON.stringify(data.branches[i])
                                  );
                                  branch.openPositions[p].role = e;
                                  setBranch(branch, i);
                                }}
                                options={getAllRoles()}
                                isOptionDisabled={(option) =>
                                  !premiumBranches.includes(branch.id) &&
                                  getPremiumRoles().includes(option.value)
                                }
                                formatOptionLabel={(option) => {
                                  return (
                                    <>
                                      {option.label}
                                      {!premiumBranches.includes(branch.id) &&
                                        getPremiumRoles().includes(
                                          option.value
                                        ) && (
                                          <span
                                            style={{
                                              color: "#00000",
                                              marginLeft: "4px",
                                            }}
                                          >
                                            <b>(Premium)</b>
                                          </span>
                                        )}
                                    </>
                                  );
                                }}
                              />
                              <br />
                              <Select
                                label="Preferred Languages"
                                allowSearch
                                selected={branch.openPositions[p].languages}
                                onChange={(e) => {
                                  branch.openPositions[p].languages = e;
                                  setBranch(branch, i);
                                }}
                                options={languages}
                              />
                              <br />
                              <Select
                                label="Preferred Additional Skills"
                                allowSearch
                                selected={branch.openPositions[p].skills}
                                onChange={(e) => {
                                  branch.openPositions[p].skills = e;
                                  setBranch(branch, i);
                                }}
                                options={skills}
                              />
                              <br />
                              <Select
                                label="Job Type"
                                allowSearch
                                selected={branch.openPositions[p].jobType}
                                onChange={(e) => {
                                  branch.openPositions[p].jobType = e;
                                  setBranch(branch, i);
                                }}
                                options={[
                                  "Part Time",
                                  "Full-Time (Permanent)",
                                  "Full-Time (Summer/Winter Break Only)",
                                ]}
                              />
                              <br />
                              <label
                                style={{
                                  textTransform: "uppercase",
                                  color: "#162e3c",
                                  fontSize: "smaller",
                                  fontWeight: "bold",
                                  margin: "0",
                                }}
                              >
                                Prior Experience Needed
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: isMobile ? "column" : "row",
                                  gap: "8px",
                                }}
                              >
                                <div
                                  className="row-all-devices"
                                  style={{ gap: "8px" }}
                                >
                                  <TextField
                                    max={2}
                                    className="rounded form-text"
                                    value={
                                      branch.openPositions[p]
                                        .monthsOfExperience &&
                                      branch.openPositions[p]
                                        .monthsOfExperience !== ""
                                        ? branch.openPositions[p]
                                            .monthsOfExperience /
                                          (experienceTimeframe === "years"
                                            ? 12
                                            : 1)
                                        : ""
                                    }
                                    placeholder={0}
                                    style={{
                                      width: "70px",
                                      maxWidth: "70px",
                                    }}
                                    onChange={(e) => {
                                      if (e === "") {
                                        branch.openPositions[
                                          p
                                        ].monthsOfExperience = "";
                                      } else {
                                        branch.openPositions[
                                          p
                                        ].monthsOfExperience =
                                          e *
                                          (experienceTimeframe === "years"
                                            ? 12
                                            : 1);
                                      }
                                      setBranch(branch, i);
                                    }}
                                  />
                                  <div
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <Select
                                      allowSearch
                                      allowMultiple={false}
                                      selected={experienceTimeframe}
                                      onChange={(e) => {
                                        branch.openPositions[
                                          p
                                        ].monthsOfExperience =
                                          branch.openPositions[p]
                                            .monthsOfExperience *
                                          (e === "years" ? 12 : 1 / 12);
                                        setBranch(branch, i);
                                        setExperienceTimeframe(e);
                                      }}
                                      options={["months", "years"]}
                                    />
                                  </div>
                                </div>
                                <Select
                                  allowMultiple={false}
                                  selected={
                                    branch.openPositions[p].typeOfExperience ===
                                      "overall" ||
                                    !branch.openPositions[p].typeOfExperience
                                      ? "of overall hospitality experience"
                                      : `of ${data.branches[i].openPositions[p].typeOfExperience} experience`
                                  }
                                  onChange={(e) => {
                                    branch.openPositions[p].typeOfExperience =
                                      e.split(" ")[1];
                                    setBranch(branch, i);
                                  }}
                                  options={[
                                    "of overall hospitality experience",
                                    ...getExperienceCategories(
                                      data.branches[i].openPositions[p].role
                                    ).map((cat) => `of ${cat} experience`),
                                  ]}
                                />
                              </div>
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: isMobile ? "column" : "row",
                                  gap: "8px",
                                }}
                              >
                                <Select
                                  allowMultiple={false}
                                  label="Salary Frequency"
                                  style={{
                                    width: "200px",
                                    maxWidth: "200px",
                                    marginRight: "8px",
                                  }}
                                  options={["Weekly", "Bi-Weekly", "Monthly"]}
                                  selected={
                                    (data.salaryFrequency ||
                                      branch.openPositions[p]
                                        .salaryFrequency) && [
                                      branch.openPositions[p].salaryFrequency ??
                                        data.salaryFrequency,
                                    ]
                                  }
                                  onChange={(e) => {
                                    branch.openPositions[p].salaryFrequency = e;
                                    setBranch(branch, i);
                                  }}
                                />
                                <div
                                  className="row-all-devices"
                                  style={{
                                    gap: "8px",
                                  }}
                                >
                                  <TextField
                                    label="Salary Range"
                                    prefix={"£"}
                                    max={5}
                                    style={{
                                      width: "100px",
                                      maxWidth: "100px",
                                    }}
                                    value={branch.openPositions[p].minSalary}
                                    onChange={(e) => {
                                      branch.openPositions[p].minSalary = e;
                                      setBranch(branch, i);
                                    }}
                                  />
                                  <span
                                    style={{
                                      margin: "auto 0",
                                      marginTop: "40px",
                                    }}
                                  >
                                    -
                                  </span>
                                  <TextField
                                    max={5}
                                    prefix={"£"}
                                    style={{
                                      marginTop: "20px",
                                      width: "100px",
                                      maxWidth: "100px",
                                    }}
                                    value={branch.openPositions[p].maxSalary}
                                    onChange={(e) => {
                                      branch.openPositions[p].maxSalary = e;
                                      setBranch(branch, i);
                                    }}
                                  />
                                  <span
                                    style={{
                                      margin: "auto 0",
                                      marginTop: "40px",
                                    }}
                                  >
                                    /hour{" "}
                                    {isMobile && (
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          position: "relative",
                                          top: "-7px",
                                        }}
                                      >
                                        <br />
                                        (incl. service)
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </div>
                              <br />
                              <div className="row" style={{ gap: "8px" }}>
                                <TextField
                                  style={{ flex: 6 }}
                                  label="Specific days/times candidates must be able to work?"
                                  value={branch.openPositions[p].workingHours}
                                  onChange={(e) => {
                                    branch.openPositions[p].workingHours = e;
                                    setBranch(branch, i);
                                  }}
                                />
                              </div>
                              <br />
                              <label
                                style={{
                                  textTransform: "uppercase",
                                  color: "#162e3c",
                                  fontSize: "smaller",
                                  fontWeight: "bold",
                                  margin: "0",
                                  marginBottom: "8px",
                                }}
                              >
                                Any Additional info
                              </label>
                              <textarea
                                className="rounded form-text"
                                value={branch.openPositions[p].additionalInfo}
                                rows={3}
                                maxLength={2500}
                                style={{
                                  width: "100%",
                                  backgroundColor: "rgb(250, 253, 255)",
                                  boxShadow:
                                    "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)",
                                  borderColor: "white",
                                  border: "0px none white",
                                  outline: "none",
                                }}
                                onChange={(e) => {
                                  branch.openPositions[p].additionalInfo =
                                    e.target.value;
                                  setBranch(branch, i);
                                }}
                              />
                              <div
                                className="row"
                                style={{ marginTop: "20px" }}
                              >
                                <div className="row-all-devices">
                                  <input
                                    type="checkbox"
                                    style={{
                                      borderRadius: "4px",
                                      borderColor: "#d5d5d5",
                                      color: "rgba(0,0,0,0.5)",
                                    }}
                                    className="rounded form-checkbox"
                                    defaultChecked={
                                      branch.openPositions[p]["18+?"]
                                    }
                                    onChange={(e) => {
                                      branch.openPositions[p]["18+?"] =
                                        e.target.checked;
                                      setBranch(branch, i);
                                    }}
                                  />
                                  <label
                                    style={{
                                      textTransform: "uppercase",
                                      color: "#162e3c",
                                      fontSize: "smaller",
                                      fontWeight: "bold",
                                      margin: "0 0 8px 8px",
                                    }}
                                  >
                                    18+ years old only
                                  </label>
                                </div>
                                <div
                                  className="row-all-devices"
                                  style={{
                                    marginLeft: isMobile ? 0 : "25px",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="rounded form-checkbox"
                                    style={{
                                      borderRadius: "4px",
                                      borderColor: "#d5d5d5",
                                      color: "rgba(0,0,0,0.5)",
                                    }}
                                    defaultChecked={
                                      branch.openPositions[p]["21+?"] ?? false
                                    }
                                    onChange={(e) => {
                                      branch.openPositions[p]["21+?"] =
                                        e.target.checked;
                                      setBranch(branch, i);
                                    }}
                                  />
                                  <label
                                    style={{
                                      textTransform: "uppercase",
                                      color: "#162e3c",
                                      fontSize: "smaller",
                                      fontWeight: "bold",
                                      margin: "0 0 8px 8px",
                                    }}
                                  >
                                    21+ years old only
                                  </label>
                                </div>
                              </div>
                              <div>
                                <hr style={{ margin: "24px 0" }} />
                                <h5>Interviews/Trials</h5>
                                <br />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: isMobile ? "column" : "row",
                                    gap: isMobile ? "16px" : "8px",
                                  }}
                                >
                                  <Select
                                    allowMultiple={false}
                                    label="What's the next step of the process?"
                                    style={{ flex: 5 }}
                                    options={["Interview", "Trial"]}
                                    selected={
                                      branch.openPositions[p].interviewType
                                    }
                                    onChange={(e) => {
                                      branch.openPositions[p].interviewType = e;
                                      setBranch(branch, i);
                                    }}
                                  />
                                  <Select
                                    allowMultiple={false}
                                    label="Duration (in minutes)"
                                    style={{ flex: 3 }}
                                    selected={[
                                      getInterviewDuration(
                                        branch,
                                        branch.openPositions[p]
                                      ),
                                    ]}
                                    options={[
                                      15, 30, 60, 90, 120, 180, 240, 300,
                                    ]}
                                    onChange={(value) => {
                                      branch.openPositions[
                                        p
                                      ].roleInterviewSlots = (
                                        branch.openPositions[p]
                                          .roleInterviewSlots ??
                                        branch.interviewSlots
                                      ).map((slot) => ({
                                        ...slot,
                                        duration: value,
                                        times: value
                                          ? updateSlots(
                                              slot.times,
                                              slot.duration,
                                              value
                                            )
                                          : slot.times,
                                      }));
                                      setBranch(branch, i);
                                    }}
                                  />
                                  <TextField
                                    label="Dress code"
                                    style={{ flex: 4 }}
                                    value={branch.openPositions[p].dressCode}
                                    maxLength={150}
                                    onChange={(e) => {
                                      branch.openPositions[p].dressCode = e;
                                      setBranch(branch, i);
                                    }}
                                  />
                                </div>
                                {(branch.openPositions[p].interviewType ===
                                  "Trial" ||
                                  !branch.openPositions[p].interviewType) && (
                                  <Select
                                    style={{ marginTop: "24px" }}
                                    allowMultiple={false}
                                    label="Is the trial paid?"
                                    options={
                                      getInterviewDuration(
                                        branch,
                                        branch.openPositions[p]
                                      ) > 120 &&
                                      !(
                                        data.branches[i].openPositions[p]
                                          .trialPaid ?? data.trialPaid
                                      )
                                        ? ["Yes", "Yes, if hired"]
                                        : ["No", "Yes", "Yes, if hired"]
                                    }
                                    selected={
                                      (data.trialPaid ||
                                        data.branches[i].openPositions[p]
                                          .trialPaid) && [
                                        data.branches[i].openPositions[p]
                                          .trialPaid ?? data.trialPaid,
                                      ]
                                    }
                                    onChange={(e) => {
                                      branch.openPositions[p].trialPaid = e;
                                      setBranch(branch, i);
                                    }}
                                  />
                                )}
                                <br />
                                <label
                                  style={{
                                    textTransform: "uppercase",
                                    color: "#162e3c",
                                    fontSize: "smaller",
                                    fontWeight: "bold",
                                    margin: "0",
                                  }}
                                >
                                  Availability
                                </label>
                                <Availability
                                  recurring
                                  availability={
                                    branch.openPositions[p].availability ??
                                    formatAvailability(
                                      branch.openPositions[p]
                                        .roleInterviewSlots ??
                                        branch.interviewSlots
                                    )
                                  }
                                  onChange={(slots) => {
                                    // Format slots
                                    let formattedSlots = slots.map((slot) => ({
                                      days: [slot.date],
                                      times: formatInterviewTimes(
                                        slot.slots,
                                        (branch.openPositions[p]
                                          .roleInterviewSlots ??
                                          branch.interviewSlots)[0].duration
                                      ),
                                      duration: (branch.openPositions[p]
                                        .roleInterviewSlots ??
                                        branch.interviewSlots)[0].duration,
                                    }));
                                    branch.openPositions[p].roleInterviewSlots =
                                      formattedSlots;
                                    branch.openPositions[p].availability =
                                      slots;
                                    setBranch(branch, i);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div
                      style={{
                        backgroundColor: "rgb(235, 233, 230)",
                        borderRadius: "16px",
                        padding: "16px",
                        marginTop: "24px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      onClick={(e) => {
                        setBranch(
                          {
                            ...data.branches[i],
                            openPositions: [
                              ...data.branches[i].openPositions,
                              {
                                ...data.branches[i].openPositions[
                                  data.branches[i].openPositions.length - 1
                                ],
                                role: [],
                                jobType: [],
                                priorExperience: [],
                                active: true,
                              },
                            ],
                          },
                          i
                        );
                      }}
                    >
                      <span
                        className="icon-small"
                        style={{
                          paddingRight: "16px",
                          fontSize: "24px",
                          lineHeight: "24px",
                          float: "left",
                          display: "inline",
                          position: "relative",
                          opacity: 0.66,
                          height: "24px",
                        }}
                      >
                        add
                      </span>{" "}
                      <p
                        style={{
                          fontWeight: "bold",
                          display: "inline",
                          fontSize: "16px",
                          opacity: 0.66,
                        }}
                      >
                        Add new position
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            );
          })}
        </div>
      )}
    </div>
  ) : (
    <div
      style={{
        padding: "0 24px 24px",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(235, 233, 230)",
      }}
    >
      <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
    </div>
  );
};

export default EmployerDashboard;
