import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCandidateInfo } from "../backend";
import { NotInterestedInput } from "../Components";
import { MoonLoader } from "react-spinners";

const CandidateNotInterested = () => {
  const [candidate, setCandidate] = useState();
  let { employerId, candidateId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      let c = await getCandidateInfo(candidateId);
      setCandidate(c);
    };
    fetchData();
    //eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#f2f0ed",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        alignItems: "flex-start",
      }}
    >
      <div
        className="navbar-container"
        style={{ height: "fit-content", position: "absolute", top: 0 }}
      >
        <a href={`/candidates`} style={{ padding: "16px", opacity: "0.2" }}>
          <img
            src="/images/hirebolt-logo-black.png"
            loading="lazy"
            width="130"
            alt=""
            className="shopscribe-logo shopscribe-logo-white"
          />
        </a>
      </div>
      {candidate ? (
        <NotInterestedInput
          candidate={candidate}
          employerId={employerId}
          style={{
            margin: "auto",
            padding: "32px",
            backgroundColor: "rgba(256,256,256,0.66)",
            borderRadius: "25px",
            width: "500px",
            maxWidth: "80vw",
          }}
        />
      ) : (
        <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              margin: "auto",
            }}
          >
            <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateNotInterested;
