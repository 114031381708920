const EmployerOnboarding = () => {
  return (
    <div className="page-wrapper">
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-w-id="db5b1f4a-2449-b3fc-921f-524e4a76fbc0"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-component w-nav"
      >
        <div className="navbar-container">
          <a href="/" className="navbar-logo-link w-nav-brand">
            <img
              src="images/hirebolt-logo-white.svg"
              loading="lazy"
              width="130"
              alt=""
              className="shopscribe-logo shopscribe-logo-white"
            />
          </a>
        </div>
      </div>
      <main className="main-wrapper">
        <header className="section-header-2">
          <div
            className="page-padding"
            style={{ maxWidth: "1000px", margin: "auto" }}
          >
            <h1 style={{ color: "white", paddingBottom: "25px" }}>
              Hire staff fast without wasting hours on admin work
            </h1>
            <p style={{ color: "white", paddingBottom: "75px" }}>
              Stop scrolling through hundreds of irrelevant CVs. Get active,
              relevant candidates sent to you directly on WhatsApp.
            </p>
          </div>
          <img
            src="images/about_v3_2.svg"
            loading="lazy"
            alt=""
            className="header-2-illustration-2"
          />
          <img
            src="images/home_v1_2.svg"
            loading="lazy"
            alt=""
            className="home-v1-illustration-2"
          />
        </header>
      </main>
      <iframe
        title="employer-notionforms"
        style={{ border: "none", width: "100%", marginTop: "50px" }}
        height="1000px"
        className="signup-form-dark"
        src="https://notionforms.io/forms/hire-staff-faster-and-reduce-no-show-interviews"
      ></iframe>
    </div>
  );
};

export default EmployerOnboarding;
