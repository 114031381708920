import React, { useEffect, useState } from "react";
import {
  getLastWeeksOnboards,
  getReEngagementBranches,
  updateBranch,
} from "../backend";
import { getEmployerSecret, getHiringManagers } from "../utils";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobile } from "react-device-detect";
import { MoonLoader } from "react-spinners";
import { wait } from "@testing-library/user-event/dist/utils";
import { Modal } from "../Components";

const EmployerCalls = () => {
  const [reEngagementEmployers, setReEngagementEmployers] = useState([]);
  const [followUpEmployers, setFollowUpEmployers] = useState([]);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [showOtherOptions, setShowOtherOptions] = useState();
  const [loading, setLoading] = useState(true);
  const [clickedEmployer, setClickedEmployer] = useState();
  const [confirmNotHiring, setConfirmNotHiring] = useState();

  const { user, isAuthenticated, isLoading, loginWithRedirect, logout, error } =
    useAuth0();

  if (error) {
    logout({ returnTo: window.location.origin });
  }

  useEffect(() => {
    const getReEngagement = async () => {
      let [e, f] = await Promise.all([
        getReEngagementBranches(),
        getLastWeeksOnboards(),
      ]);
      setReEngagementEmployers(e);
      setFollowUpEmployers(f);
      setLoading(false);
    };
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ redirectUri: window.location.href });
    } else if (isAuthenticated) {
      getReEngagement();
    }
    //eslint-disable-next-line
  }, [isLoading]);

  return (
    <div
      style={{
        width: "100vw",
        height: "fit-content",
        minHeight: "100vh",
        backgroundColor: "rgb(235,235,235)",
      }}
    >
      {confirmNotHiring && (
        <Modal
          isOpen={!!confirmNotHiring}
          closeModal={() => setConfirmNotHiring(null)}
          closeOnOutsideClick={true}
        >
          <br />
          <br />
          <p
            style={{
              textAlign: "center",
            }}
          >
            Do you confirm that {confirmNotHiring.name} is currently not hiring?
          </p>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <button
              className="button button-tertiary"
              style={{
                padding: "8px 24px",
              }}
              onClick={async () => {
                setConfirmNotHiring(null);
              }}
            >
              Go Back
            </button>
            &nbsp;
            <button
              className="button"
              style={{
                color: "white",
                backgroundColor: "#8B0000",
                border: "2px solid #8B0000",
                padding: "8px 24px",
              }}
              onClick={async () => {
                await updateBranch({
                  ...confirmNotHiring,
                  active: false,
                  reEngagement: {
                    next: moment().add(6, "weeks").toDate(),
                    calledBy: user.email,
                    calledOn: moment().toDate(),
                  },
                });
                setReEngagementEmployers(
                  reEngagementEmployers.filter(
                    (r) => r.id !== confirmNotHiring.id
                  )
                );
                setConfirmNotHiring(null);
              }}
            >
              Confirm
            </button>
          </div>
        </Modal>
      )}
      {showOtherOptions && (
        <Modal
          isOpen={!!showOtherOptions}
          closeModal={() => setShowOtherOptions(null)}
          closeOnOutsideClick={true}
        >
          <p style={{ fontSize: "smaller", opacity: 0.66, marginTop: "16px" }}>
            Other options:
          </p>
          <br />
          <div
            style={{
              padding: "8px 0",
              width: "100%",
              minWidth: "250px",
              borderTop: "1px solid #dedede",
              borderBottom: "1px solid #dedede",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={async () => {
              await updateBranch({
                ...showOtherOptions,
                active: false,
                reEngagement: {
                  next: moment().add(1, "days").startOf("day").toDate(),
                  calledBy: user.email,
                  calledOn: moment().toDate(),
                },
              });
              setReEngagementEmployers(
                reEngagementEmployers.filter(
                  (r) => r.id !== showOtherOptions.id
                )
              );
              setShowOtherOptions(null);
            }}
          >
            <span
              className="icon-small"
              style={{
                width: "14px",
                fontSize: "14px",
                lineHeight: "14px",
                height: "14px",
                display: "inline-block",
                position: "relative",
                top: "2px",
                marginRight: "8px",
              }}
            >
              phone
            </span>
            Didn't answer
          </div>
          <div
            style={{
              padding: "8px 0",
              width: "100%",
              borderBottom: "1px solid #dedede",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={async () => {
              await updateBranch({
                ...showOtherOptions,
                active: false,
                reEngagement: {
                  next: moment().add(2, "years").toDate(),
                  calledBy: user.email,
                  calledOn: moment().toDate(),
                },
              });
              setReEngagementEmployers(
                reEngagementEmployers.filter(
                  (r) => r.id !== showOtherOptions.id
                )
              );
              setShowOtherOptions(null);
            }}
          >
            <span
              className="icon-small"
              style={{
                width: "14px",
                fontSize: "14px",
                lineHeight: "14px",
                height: "14px",
                display: "inline-block",
                position: "relative",
                top: "2px",
                marginRight: "8px",
              }}
            >
              close
            </span>
            Hold off calling
          </div>
        </Modal>
      )}
      <div
        style={{
          padding: "16px",
          width: "100vw",
          display: "block",
          height: "75px",
        }}
      >
        <p>
          <a href="/admin">
            <img
              src="/images/hirebolt-logo-black.png"
              loading="lazy"
              width="150"
              alt=""
              style={{
                float: "left",
                opacity: 0.5,
                marginLeft: "8px",
              }}
            />
          </a>
        </p>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div style={{ width: "5%" }}>&nbsp;</div>
        <div
          style={{
            width: "35%",
            borderTopLeftRadius: "8px",
            fontSize: "smaller",
            fontWeight: "bold",
            textAlign: "center",
            maxWidth: "300px",
            paddingTop: "4px",
            opacity: showFollowUp ? "0.5" : 1,
            cursor: "pointer",
            backgroundColor: "rgb(245,245,245)",
          }}
          onClick={() => setShowFollowUp(false)}
        >
          Re-engagement
        </div>
        <div
          style={{
            width: "35%",
            borderTopRightRadius: "8px",
            fontSize: "smaller",
            fontWeight: "bold",
            textAlign: "center",
            maxWidth: "300px",
            paddingTop: "4px",
            opacity: !showFollowUp ? "0.5" : 1,
            cursor: "pointer",
            backgroundColor: "rgb(245,245,245)",
          }}
          onClick={() => setShowFollowUp(true)}
        >
          New Onboards
        </div>
        <div style={{ width: "25%" }}>&nbsp;</div>
      </div>
      <div
        style={{
          backgroundColor: "rgb(245,245,245)",
        }}
      >
        <p
          style={{
            opacity: "0.66",
            fontSize: "smaller",
            padding: "32px 24px 24px",
          }}
        >
          {(showFollowUp ? followUpEmployers : reEngagementEmployers).length}{" "}
          employers
        </p>
        {!loading &&
        (showFollowUp ? followUpEmployers : reEngagementEmployers).length >
          0 ? (
          <div
            style={{
              padding: "16px 24px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {(showFollowUp ? followUpEmployers : reEngagementEmployers).map(
              (e) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: isMobile ? "0" : "8px",
                    borderBottom: isMobile ? "none" : "1px solid #e0e0e0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        top: "-7px",
                        flex: 1,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            `https://hirebolt.com/${
                              e.employerId
                            }/${getEmployerSecret(e.employerId)}`,
                            "_blank"
                          )
                        }
                      >
                        <span
                          style={{
                            position: "relative",
                          }}
                        >
                          {e.name.length > 20
                            ? e.name.slice(0, 20) + "..."
                            : e.name}
                        </span>
                        <span
                          className="icon-small"
                          style={{
                            width: "24px",
                            fontSize: "24px",
                            lineHeight: "24px",
                            display: "inline-block",
                            position: "relative",
                            height: "24px",
                            top: "7px",
                          }}
                        >
                          keyboard_arrow_right_icon
                        </span>
                      </p>
                      <p
                        style={{
                          opacity: "0.66",
                          fontSize: "smaller",
                          float: "left",
                          width: "fit-content",
                          marginRight: "auto",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        {showFollowUp
                          ? `${e.candidatesSent ?? "N/A"} candidates sent`
                          : `Paused ${moment().format("MMM D")}`}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: isMobile ? 0 : "33%",
                        zIndex:"1000"
                      }}
                    >
                      <a
                        href={"tel:" + getHiringManagers(e)?.[0]?.number}
                        style={{
                          textDecoration: "none",
                          borderBottom: "1px solid rgba(73,97,110, 0.25)",
                          lineHeight: "1.20",
                          marginTop: "3px",
                        }}
                      >
                        {getHiringManagers(e)?.[0]?.number}
                      </a>
                      <p
                        style={{
                          opacity: "0.66",
                          fontSize: "smaller",
                          float: "right",
                          width: "fit-content",
                          marginLeft: "auto",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        {getHiringManagers(e)?.[0]?.name}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      marginBottom: "8px",
                      flex: 1,
                    }}
                  >
                    {clickedEmployer === e.id ? (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          padding: "4px 0px",
                          fontSize: "smaller",
                          fontWeight: "bold",
                          opacity: "0.8",
                          paddingTop: isMobile ? "2px" : "10px",
                        }}
                      >
                        Loading...
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            borderRight: "1px solid #efefef",
                            width: "33%",
                            textAlign: "center",
                            padding: "4px 0px",
                            fontSize: "smaller",
                            fontWeight: "bold",
                            opacity: "0.8",
                            paddingTop: isMobile ? "2px" : "10px",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            setClickedEmployer(e.id);
                            await updateBranch({
                              ...e,
                              active: true,
                              activeUntil: moment().add(2, "weeks").toDate(),
                              reEngagement: {
                                calledBy: user.email,
                                calledOn: moment().toDate(),
                              },
                            }).then((res) => console.log(res));
                            await wait(1000);
                            window.open(
                              `https://hirebolt.com/${
                                e.employerId
                              }/${getEmployerSecret(e.employerId)}?page=${
                                e.id
                              }&reactivate=true`,
                              "_blank"
                            );
                            setReEngagementEmployers(
                              reEngagementEmployers.filter((r) => r.id !== e.id)
                            );
                            setClickedEmployer(null);
                          }}
                        >
                          <span
                            className="icon-small"
                            style={{
                              width: "14px",
                              fontSize: "14px",
                              lineHeight: "14px",
                              height: "14px",
                              display: "inline-block",
                              position: "relative",
                              top: "2px",
                              marginRight: "4px",
                            }}
                          >
                            check
                          </span>
                          <span style={{ textTransform: "uppercase" }}>
                            Hiring
                          </span>
                        </div>
                        <div
                          style={{
                            borderRight: "1px solid #efefef",
                            width: "34%",
                            textAlign: "center",
                            padding: "4px 0px",
                            fontSize: "smaller",
                            fontWeight: "bold",
                            opacity: "0.8",
                            paddingTop: isMobile ? "2px" : "10px",
                            cursor: "pointer",
                          }}
                          onClick={async () => {
                            setConfirmNotHiring(e);
                          }}
                        >
                          <span
                            className="icon-small"
                            style={{
                              width: "14px",
                              fontSize: "14px",
                              lineHeight: "14px",
                              height: "14px",
                              display: "inline-block",
                              position: "relative",
                              top: "2px",
                              marginRight: "4px",
                            }}
                          >
                            close
                          </span>
                          <span
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            Not Hiring
                          </span>
                        </div>
                        <div
                          style={{
                            width: "33%",
                            textAlign: "center",
                            padding: "4px 0px",
                            fontSize: "smaller",
                            fontWeight: "bold",
                            opacity: "0.8",
                            paddingTop: isMobile ? "2px" : "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowOtherOptions(e);
                          }}
                        >
                          <span
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            Other
                          </span>
                          <span
                            className="icon-small"
                            style={{
                              width: "14px",
                              fontSize: "14px",
                              lineHeight: "14px",
                              height: "14px",
                              display: "inline-block",
                              position: "relative",
                              top: "2px",
                              marginRight: "4px",
                            }}
                          >
                            keyboard_arrow_down_icon
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <hr style={{ margin: "24px 0" }} />
                </div>
              )
            )}
          </div>
        ) : loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
              width: "100%",
            }}
          >
            <MoonLoader size={50} color={"gray"} loading={true} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "larger",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                No Calls
              </p>
              <p
                style={{
                  fontSize: "smaller",
                  color: "gray",
                }}
              >
                You have no calls scheduled for today.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployerCalls;
