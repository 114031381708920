import { wait } from "@testing-library/user-event/dist/utils";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  recordReferralInteraction,
  fetchNearbyEmployers,
  candidateExists,
  getSkills,
  getLanguages,
  uploadFile,
} from "../backend";
import {
  DatePicker,
  CvUpload,
  Select,
  TextField,
  Availability,
} from "../Components/Forms";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import {
  abTesting,
  formatCandidateAvailability,
  getAllRoles,
  getRecommendedSalary,
  getSimilarRoles,
  shuffleList,
  validateCandidateAvailability,
} from "../utils";
import { isMobile } from "react-device-detect";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
import { Modal } from "../Components";
import { MoonLoader } from "react-spinners";
import moment from "moment";

const CandidateOnboarding = () => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const referrer = searchParams.get("ref");
  const utmSource = searchParams.get("utm_source");

  const [candidate, setCandidate] = useState({});
  const [experienceUnit, setExperienceUnit] = useState("months");
  const [nearbyEmployers, setNearbyEmployers] = useState({});
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageShown, setPageShown] = useState(false);

  const [nameCompleted, setNameCompleted] = useState(false);
  const [numberCompleted, setNumberCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDuplicateCandidate, setIsDuplicateCandidate] = useState(false);
  const [noCVModal, setNoCVModal] = useState(false);
  const [noCV, setNoCV] = useState(false);
  const [duplicateCandidate, setDuplicateCandidate] = useState("");
  const [overallExprerienceShown, setOverallExperienceShown] = useState(false);
  const [languages, setLanguages] = useState();
  const [skills, setSkills] = useState();
  const [audio, setAudio] = useState();
  const [candidateAvailability, setCandidateAvailability] = useState();

  const recorderControls = useAudioRecorder();

  useEffect(() => {
    setPageShown(true);
    if (referrer && referrer.length > 0) {
      recordReferralInteraction("open_link", "candidate_onboarding", referrer);
    }
    if ([...searchParams].length) {
      let source = searchParams.get("utm_source")
        ? {
            indeed: "Indeed",
            googleads: "Google Ads",
            reed: "Reed",
            googleboh: "Google Ads BoH",
          }[searchParams.get("utm_source")]
        : searchParams.get("ref")
        ? "From Friends/Family"
        : undefined;
      setCandidate({
        ...candidate,
        Name: searchParams.get("name") ?? "",
        "WhatsApp Number": searchParams.get("number") ?? "",
        "How did you find out about us?": source,
        Referrer: searchParams.get("ref"),
      });
      if (searchParams.get("name")) setNameCompleted(true);
      if (searchParams.get("number")) setNumberCompleted(true);
    }
    const getFormData = async () => {
      Promise.all([getLanguages(), getSkills()]).then((values) => {
        setLanguages(values[0]);
        setSkills(values[1]);
      });
    };
    getFormData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setError(null);
  }, [candidate]);

  const gtag_report_conversion = (url) => {
    if (utmSource === "googleads" || utmSource === "googleboh") {
      var callback = () => {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      // eslint-disable-next-line no-undef
      gtag("event", "conversion", {
        send_to: "AW-10974258557/95HQCM7X5IcYEP3K9_Ao",
        event_callback: callback,
      });
      return false;
    }
  };
  const addAudioElement = async (blob) => {
    setAudio(URL.createObjectURL(blob));
    await uploadAudio(blob);
  };
  const uploadAudio = async (blob) => {
    let fileUrl = await uploadFile(blob);
    setCandidate({ ...candidate, Audio: fileUrl });
  };
  const submit = async () => {
    setLoading(true);
    if (await validate()) {
      gtag_report_conversion();
      setPageShown(false);
      await wait(200);
      if (
        !noCV &&
        abTesting(candidate["WhatsApp Number"], "candidate-profile", 2)
      ) {
        navigate("/connect", {
          state: {
            candidate: {
              ...candidate,
              "Nearby Employers": nearbyEmployers,
              "Overall Experience":
                candidate["Overall Experience"] *
                (experienceUnit === "years" ? 12 : 1),
            },
          },
        });
      } else if (
        noCV &&
        abTesting(candidate["WhatsApp Number"], "candidate-profile", 2)
      ) {
        navigate("/connect");
      } else if (!noCV) {
        navigate("/candidate-opportunities", {
          state: {
            candidate: {
              ...candidate,
              "Nearby Employers": nearbyEmployers,
              "Overall Experience":
                candidate["Overall Experience"] *
                (experienceUnit === "years" ? 12 : 1),
            },
          },
        });
      } else {
        navigate("/candidate-success-nocv");
      }
    }
    setLoading(false);
  };

  const validate = async () => {
    let errors = {
      Name: "Please insert your name",
      "WhatsApp Number": "Please provide your WhatsApp number",
      "What positions are you looking for?":
        "Please state which positions you're looking for",
      "Minimum Salary": "Please state a minimum salary",
      "What job type are you looking for?":
        "Please state if you're looking for full-time or part-time",
      "Max Commute": "Please state the maximum commute",
      Postcode: "Please enter a postcode",
      Email: "Please provide your email",
      DOB: "Please insert your date of birth",
      "Raw CV": "Please upload a CV",
      "Right to Work + Contact Consent": "Please confirm your right to work",
    };
    for (let field of Object.keys(errors)) {
      if (
        field === "Availability" &&
        !candidate["Passively Looking"] &&
        !validateCandidateAvailability(candidate["Availability"])
      ) {
        setError("Please add 5+ hours of interview availability");
        return false;
      }
      if (field === "Raw CV" && noCV === true) {
        continue;
      }
      if (
        [
          "What positions are you looking for?",
          "What job type are you looking for?",
        ].includes(field) &&
        candidate[field]?.length === 0
      ) {
        setError(errors[field]);
        return false;
      }
      if (!candidate[field]) {
        setError(errors[field]);
        return false;
      }
    }
    if (!isValidNumber(candidate["WhatsApp Number"])) {
      setError("Not a valid number");
      return false;
    }
    if (!isValidEmail(candidate["Email"])) {
      setError("Not a valid email");
      return false;
    }
    let [existingCandidateNumber, existingCandidateEmail] = await Promise.all([
      candidateExists(candidate["WhatsApp Number"]),
      candidateExists(candidate["Email"]),
    ]);
    if (existingCandidateEmail) {
      setError("This email has been used before");
      setDuplicateCandidate(existingCandidateEmail);
      setIsDuplicateCandidate(true);
      return false;
    }
    if (existingCandidateNumber) {
      setError("This number has been used before");
      setDuplicateCandidate(existingCandidateNumber);
      setIsDuplicateCandidate(true);
      return false;
    }
    if (!candidate["Audio"]) {
      setError("Please upload a voice memo");
      return false;
    }
    return true;
  };
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // Check if valid number
  const isValidNumber = (number) => {
    return number.length >= 10 && number.length <= 16;
  };

  // Debounce fetchNearbyEmployers
  let getNearbyEmployers = debounce(
    async (args) => {
      await fetchNearbyEmployers(args).then((res) => setNearbyEmployers(res));
    },
    2000,
    {
      trailing: true,
      leading: false,
    }
  );

  const setPostcode = (postcode) => {
    const run = async () => {
      getNearbyEmployers({
        ...candidate,
        Postcode: postcode,
      });
    };
    setCandidate({
      ...candidate,
      Postcode: postcode,
    });
    if (postcode.replace(/\s/g, "").length >= 5 && candidate["Max Commute"]) {
      run(postcode);
    }
  };

  return (
    <div
      className="page-wrapper"
      style={{
        backgroundColor: "#f2f0ed",
        minHeight: "100vh",
      }}
    >
      <div
        className="navbar-component w-nav"
        style={{
          width: "100vw",
          backgroundColor: "#f2f0ed",
          position: "absolute",
          top: 0,
        }}
      >
        <div className="navbar-container">
          <a
            href={`/candidates?${referrer ? `ref=${referrer}&` : ""}${
              utmSource ? `utm_source=${utmSource}&` : ""
            }`}
            className="navbar-logo-link w-nav-brand"
          >
            <img
              src="images/hirebolt-logo-black.png"
              loading="lazy"
              width="130"
              alt=""
              className="shopscribe-logo"
              style={{
                filter:
                  "invert(13%) sepia(57%) saturate(562%) hue-rotate(158deg) brightness(94%) contrast(91%)",
              }}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          opacity: pageShown ? 1 : 0,
          transition: "opacity .15s cubic-bezier(0.5,0.25,0,1)",
          paddingTop: "50px",
        }}
      >
        <Modal isOpen={noCVModal} closeModal={() => setNoCVModal(false)}>
          <div>
            <h5 style={{ padding: "24px 24px 0 24px" }}>
              Upload your CV later?
            </h5>
            <div className="button-row button-row-center">
              <div
                style={{
                  padding: "16px 32px",
                  borderRadius: "15px",
                  backgroundColor: "#f5e4e1",
                  margin: "32px",
                  color: "#e03c3c",
                  maxWidth: "700px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <img
                  src="../../../images/exclamation-mark.svg"
                  alt=""
                  width="15"
                  height="15"
                  style={{
                    marginTop: "-5px",
                    marginRight: "7px",
                    color: "#e03c3c",
                  }}
                />
                You'll receive a link via email to upload your CV. Until a CV is
                uploaded, your profile CAN'T be considered for job
                opportunities.
              </div>
            </div>
            <button
              onClick={() => {
                setCandidate({
                  ...candidate,
                  "Raw CV": "",
                });
                setNoCVModal(false);
                setNoCV(true);
              }}
              className="button w-inline-block"
              style={{
                display: "flex",
                margin: "0px auto",
                padding: "12px 32px",
              }}
            >
              Upload CV Later
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={isDuplicateCandidate}
          closeModal={() => setIsDuplicateCandidate(false)}
        >
          <div>
            <p
              style={{
                color: "red",
                margin: "50px 25px 25px",
                textAlign: "center",
              }}
            >
              A user exists with the same number/email
              <button
                onClick={() =>
                  navigate("/candidate-opportunities/?id=" + duplicateCandidate)
                }
                className="button w-inline-block"
                style={{
                  display: "flex",
                  margin: "24px auto",
                  padding: "12px 32px",
                }}
              >
                Click here receive more job opportunities
              </button>
            </p>
          </div>
        </Modal>
        {currentPage === 1 && (
          <div
            id="candidate-form"
            style={{
              maxWidth: "700px",
              margin: "64px auto 12px",
              padding: "0 24px",
            }}
          >
            <div className="row">
              {nameCompleted ? (
                <div style={{ flex: 1 }}>
                  <label
                    className="main-label"
                    style={{
                      fontSize: "smaller",
                      fontWeight: "bold",
                      margin: "0 0 8px",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                        color: "#162e3c",
                      }}
                    >
                      Your name
                    </span>
                  </label>
                  <div className="row">
                    <textarea
                      className="rounded form-text candidate-no-border"
                      value={candidate["Name"]}
                      rows={1}
                      cols={
                        isMobile
                          ? candidate["Name"].length > 5
                            ? candidate["Name"].length + 3
                            : 5
                          : candidate["Name"].length > 2
                          ? candidate["Name"].length - 1
                          : 2
                      }
                      maxLength={40}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        resize: "none",
                        marginTop: "-5px",
                        height: "fit-content",
                        outline: "none",
                        zIndex: "200",
                        whiteSpace: "nowrap !important",
                        paddingRight: 0,
                        opacity: "0.8",
                      }}
                      onChange={(e) => {
                        setCandidate({ ...candidate, Name: e.target.value });
                      }}
                    />
                  </div>
                </div>
              ) : (
                <TextField
                  label="Your name"
                  placeholder="Alfredo Linguini"
                  value={candidate["Name"]}
                  compulsory
                  onChange={(value) => {
                    setCandidate({ ...candidate, Name: value });
                  }}
                />
              )}
              &nbsp;&nbsp;&nbsp;
              {numberCompleted ? (
                <div style={{ flex: 2 }}>
                  <label
                    className="main-label"
                    style={{
                      fontSize: "smaller",
                      fontWeight: "bold",
                      margin: "0 0 8px",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "uppercase",
                        color: "#162e3c",
                      }}
                    >
                      WhatsApp Number
                    </span>
                    <span
                      className="icon-large"
                      data-tip={
                        "This number MUST be connected to WhatsApp in order to receive job offers"
                      }
                      data-for="textfield-tooltip"
                      style={{
                        display: "inline",
                        fontSize: "17px",
                        lineHeight: "17px",
                        height: "17px",
                        width: "17px",
                        position: "relative",
                        top: "4px",
                        left: "4px",
                        opacity: ".5",
                        cursor: "pointer",
                      }}
                    >
                      help
                    </span>
                    <ReactTooltip
                      effect="solid"
                      id="textfield-tooltip"
                      place="bottom"
                    />
                  </label>
                  <div className="row-all-devices">
                    <textarea
                      className="rounded form-text candidate-no-border"
                      value={candidate["WhatsApp Number"]}
                      rows={1}
                      cols={
                        isMobile
                          ? candidate["WhatsApp Number"].length -
                              candidate["WhatsApp Number"].length / 5 >
                            5
                            ? candidate["WhatsApp Number"].length -
                              candidate["WhatsApp Number"].length / 5 +
                              10
                            : 5
                          : candidate["WhatsApp Number"].length -
                              candidate["WhatsApp Number"].length / 5 >
                            1
                          ? candidate["WhatsApp Number"].length -
                            candidate["WhatsApp Number"].length / 5 +
                            2
                          : 1
                      }
                      maxLength={20}
                      style={{
                        width: "auto",
                        maxWidth: "100%",
                        resize: "none",
                        marginTop: "-5px",
                        height: "fit-content",
                        outline: "none",
                        zIndex: "200",
                        paddingRight: "16px",
                        opacity: "0.8",
                      }}
                      onChange={(e) => {
                        setCandidate({
                          ...candidate,
                          "WhatsApp Number": e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              ) : (
                <TextField
                  label="WhatsApp Number"
                  placeholder="+44 7321 321000"
                  value={candidate["WhatsApp Number"]}
                  compulsory
                  tooltip="This number MUST be connected to WhatsApp in order to receive job offers"
                  onChange={(value) =>
                    setCandidate({ ...candidate, "WhatsApp Number": value })
                  }
                />
              )}
            </div>
            <hr style={{ margin: "32px 0" }} />
            <Select
              label="What role are you looking for? (max 3)"
              selected={candidate["What positions are you looking for?"]}
              placeholder="Head Waiter"
              allowSearch={false}
              options={
                utmSource === "googleboh" ? ["Kitchen Porter"] : getAllRoles()
              }
              compulsory
              onChange={(value) => {
                if (value.length <= 3) {
                  setCandidate({
                    ...candidate,
                    "What positions are you looking for?": value,
                  });
                }
              }}
              suggestion={
                candidate["What positions are you looking for?"]?.length &&
                candidate["What positions are you looking for?"]?.length < 3
                  ? `Select also '${getSimilarRoles(
                      candidate["What positions are you looking for?"]
                    )
                      .filter(
                        (v) =>
                          !candidate[
                            "What positions are you looking for?"
                          ].includes(v)
                      )
                      .map((v) => v.split("/")[0])
                      .slice(0, 2)
                      .join("' or '")}' to increase chances of getting a job`
                  : ""
              }
            />
            <div className="row" style={{ marginTop: "24px" }}>
              <TextField
                label="Minimum Hourly Pay you would accept?"
                placeholder="10"
                max="2"
                value={candidate["Minimum Salary"]}
                compulsory
                prefix="£"
                suffix="/hour"
                onChange={(value) => {
                  if ((/^\d+$/.test(value) || !value) && value <= 40) {
                    setCandidate({
                      ...candidate,
                      "Minimum Salary": value,
                    });
                  }
                }}
                suggestion={
                  candidate["What positions are you looking for?"]?.length &&
                  getRecommendedSalary(
                    candidate["What positions are you looking for?"]
                  )[1] < candidate["Minimum Salary"]
                    ? `Select a value £${getRecommendedSalary(
                        candidate["What positions are you looking for?"]
                      ).join(" - ")} to increase your chances of getting a job`
                    : ""
                }
              />
              &nbsp;&nbsp;&nbsp;
              <Select
                label="Full-Time or Part-Time?"
                selected={candidate["What job type are you looking for?"]}
                placeholder="Full-Time"
                allowSearch={false}
                options={[
                  "Part Time",
                  "Full-Time (Permanent)",
                  "Full-Time (Summer/Winter Break Only)",
                ]}
                compulsory
                onChange={(value) => {
                  setCandidate({
                    ...candidate,
                    "What job type are you looking for?": value,
                  });
                }}
                suggestion={
                  candidate["What job type are you looking for?"]?.length &&
                  !candidate["What job type are you looking for?"]?.includes(
                    "Full-Time (Permanent)"
                  )
                    ? `Select also 'Full-Time' to increase chances of getting a job`
                    : ""
                }
              />
            </div>
            <hr style={{ margin: "32px 0" }} />
            <div className="row-all-devices" style={{ marginTop: "24px" }}>
              <Select
                label={
                  isMobile ? "I can commute max" : "I'm willing to commute max"
                }
                selected={candidate["Max Commute"]}
                placeholder="30 mins"
                allowSearch={false}
                options={["15 mins", "30 mins", "45 mins", "1 hour"]}
                compulsory
                allowMultiple={false}
                onChange={(value) => {
                  setCandidate({
                    ...candidate,
                    "Max Commute": value,
                  });
                }}
                suggestion={
                  candidate["Max Commute"] &&
                  candidate["Max Commute"] === "15 mins"
                    ? "Select '30 mins' to increase chances of getting a job"
                    : ""
                }
              />
              &nbsp;&nbsp;&nbsp;
              <TextField
                label="From this postcode:"
                placeholder="Your Postcode"
                value={candidate["Postcode"]}
                compulsory
                onChange={(value) => {
                  setPostcode(value);
                }}
                onEnter={() => setCurrentPage(2)}
              />
            </div>
            <div className="button-row button-row-center">
              <div className="button-wrapper no-margin-right">
                <button
                  onClick={() => setCurrentPage(2)}
                  className="button w-inline-block"
                  style={{
                    display: "flex",
                    margin: "24px auto",
                    padding: "12px 32px",
                  }}
                >
                  <div style={{ color: "#f2f0ed" }}>Continue</div>
                  <span
                    className="icon-small"
                    style={{
                      display: "inline-block",
                      fontSize: "20px",
                      lineHeight: "20px",
                      height: "20px",
                      width: "20px",
                      position: "relative",
                      left: "8px",
                      color: "#f2f0ed",
                    }}
                  >
                    keyboard_arrow_right_icon
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
        {currentPage === 2 && (
          <div
            id="candidate-form"
            style={{
              maxWidth: "700px",
              margin: "64px auto 12px",
              padding: "0 24px",
            }}
          >
            <div className="row">
              <TextField
                label="Email Address"
                placeholder="alfredo@ratatouille.com"
                value={candidate["Email"]}
                compulsory
                onChange={(value) =>
                  setCandidate({ ...candidate, Email: value })
                }
              />
              &nbsp;&nbsp;&nbsp;
              <DatePicker
                label="Date of Birth"
                compulsory
                onChange={(value) => setCandidate({ ...candidate, DOB: value })}
              />
            </div>
            <div className="row" style={{ gap: "16px" }}>
              <Select
                style={{ marginTop: "24px" }}
                label="Languages spoken"
                selected={candidate["Languages spoken"]}
                placeholder="English"
                allowSearch={true}
                options={languages}
                onChange={(value) => {
                  setCandidate({
                    ...candidate,
                    "Languages spoken": value,
                  });
                }}
              />
              <Select
                style={{ marginTop: "24px" }}
                label="Additional skills"
                selected={candidate["Additional skills"]}
                placeholder=""
                allowSearch={true}
                options={skills}
                onChange={(value) => {
                  setCandidate({
                    ...candidate,
                    "Additional skills": value,
                  });
                }}
              />
            </div>
            {!noCV && (
              <div>
                <hr style={{ margin: "32px 0" }} />
                <CvUpload
                  label="Upload your CV"
                  value={candidate["Raw CV"]}
                  compulsory
                  onChange={(value) => {
                    if (typeof value.candidateExperience === "object") {
                      setCandidate({
                        ...candidate,
                        "Raw CV": value.url,
                        ...value.candidateExperience,
                      });
                      setOverallExperienceShown(false);
                    } else if (value.url) {
                      setCandidate({
                        ...candidate,
                        "Raw CV": value.url,
                      });
                      setOverallExperienceShown(true);
                    }
                  }}
                />
                {!candidate["Raw CV"] && (
                  <div
                    onClick={() => setNoCVModal(true)}
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    <p
                      className="underline"
                      style={{
                        marginTop: "8px",
                        cursor: "pointer",
                        textAlign: "center",
                        fontSize: "12px",
                        opacity: 0.66,
                        textDecoration: "none",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      I don't have a CV with me
                    </p>
                  </div>
                )}
              </div>
            )}
            <div>
              <br />
              <label
                className="main-label"
                style={{
                  textTransform: "uppercase",
                  fontSize: "smaller",
                  fontWeight: "bold",
                  margin: "0 0 8px",
                  color: "#162e3c",
                }}
              >
                INTRODUCE YOURSELF IN A SHORT VOICE MEMO
                <span
                  style={{
                    color: "red",
                    fontSize: "11px",
                    verticalAlign: "super",
                    marginLeft: "4px",
                  }}
                >
                  *
                </span>
              </label>
              <p
                style={{
                  marginTop: "8px",
                  textAlign: "left",
                  fontSize: "14px",
                  opacity: 0.86,
                  textDecoration: "none",
                }}
              >
                In order to get the best employers to reach out to you, we will
                just need a short voice recording of you introducing yourself
                and what you are looking for.
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "0px auto",
                  paddingTop: "12px",
                }}
              >
                <div
                  id="recorder-container"
                  style={{
                    marginBottom: "12px",
                    cursor: recorderControls.isRecording ? "auto" : "pointer",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "rgb(250,253,255)",
                    width: "100%",
                    borderRadius: "8px",
                    padding: "4px 16px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                    color: "inherit",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    !recorderControls.isRecording &&
                    recorderControls.startRecording()
                  }
                >
                  <p style={{ padding: "0", margin: "auto 0" }}>
                    {recorderControls.isRecording ? "Save" : "Record Audio"}
                  </p>
                  <AudioRecorder
                    onRecordingComplete={addAudioElement}
                    audioTrackConstraints={{
                      noiseSuppression: true,
                      echoCancellation: true,
                    }}
                    recorderControls={recorderControls}
                  />
                </div>
                {audio && (
                  <audio
                    style={{
                      width: "100%",
                      boxShadow:
                        "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)",
                      borderColor: "white",
                      border: "0px none white",
                      outline: "none",
                      padding: "14px 16px",
                      backgroundColor: "rgb(250,253,255)",
                      borderRadius: "8px",
                    }}
                    controls
                    src={audio}
                  ></audio>
                )}
              </div>
            </div>
            {overallExprerienceShown && (
              <>
                <label
                  className="main-label"
                  style={{
                    fontSize: "smaller",
                    fontWeight: "bold",
                    margin: "24px 0 0",
                  }}
                >
                  <span
                    style={{
                      textTransform: "uppercase",
                      color: "#162e3c",
                    }}
                  >
                    How much Hospitality Experience do you have?
                  </span>
                </label>
                <div className="row-all-devices">
                  <TextField
                    placeholder="3"
                    max="2"
                    value={candidate["Overall Experience"]}
                    onChange={(value) => {
                      if (!value || /^\d+$/.test(value))
                        setCandidate({
                          ...candidate,
                          "Overall Experience": value,
                        });
                    }}
                    style={{ flex: 1 }}
                  />
                  <Select
                    selected={experienceUnit}
                    allowSearch={false}
                    options={["months", "years"]}
                    allowMultiple={false}
                    onChange={(value) => {
                      setExperienceUnit(value);
                    }}
                    style={{ flex: 2, marginLeft: "4px" }}
                  />
                </div>
              </>
            )}
            <div className="button-row button-row-center">
              {!loading && (
                <div className="button-wrapper no-margin-right">
                  <button
                    onClick={() => setCurrentPage(1)}
                    className=" w-inline-block"
                    style={{ display: "flex", margin: "24px 8px 24px 0" }}
                  >
                    <span
                      className="icon-small"
                      style={{
                        display: "inline-block",
                        fontSize: "20px",
                        lineHeight: "20px",
                        height: "20px",
                        width: "20px",
                        position: "relative",
                        right: "4px",
                        top: "2px",
                      }}
                    >
                      keyboard_arrow_left_icon
                    </span>
                    <div>Previous</div>
                  </button>
                </div>
              )}
              <div className="button-wrapper no-margin-right">
                <button
                  onClick={() => setCurrentPage(3)}
                  className="button w-inline-block"
                  style={{
                    display: "flex",
                    margin: "24px auto",
                    padding: "12px 32px",
                  }}
                >
                  <div style={{ color: "#f2f0ed" }}>Continue</div>
                  <span
                    className="icon-small"
                    style={{
                      display: "inline-block",
                      fontSize: "20px",
                      lineHeight: "20px",
                      height: "20px",
                      width: "20px",
                      position: "relative",
                      left: "8px",
                      color: "#f2f0ed",
                    }}
                  >
                    keyboard_arrow_right_icon
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
        {currentPage === 3 && (
          <div
            id="candidate-form"
            style={{
              maxWidth: "700px",
              margin: "64px auto 12px",
              padding: "0 24px",
            }}
          >
            <label
              className="main-label"
              style={{
                textTransform: "uppercase",
                fontSize: "smaller",
                fontWeight: "bold",
                margin: "0 0 8px",
                color: "#162e3c",
              }}
            >
              When are you available to interview?
            </label>
            <p style={{ fontSize: "smaller", marginBottom: "16px" }}>
              Note: interviews are not confirmed until you receive a
              confirmation from the employer
            </p>
            {!candidate["Passively Looking"] && (
              <Availability
                availability={candidateAvailability}
                dates={
                  // Get the next 4 dates
                  [
                    ...Array(
                      {
                        Mon: 3,
                        Tue: 5,
                        Wed: 5,
                        Thu: 5,
                        Fri: 4,
                        Sat: 3,
                        Sun: 3,
                      }[moment().format("ddd")]
                    ),
                  ].map((_, i) => {
                    return moment()
                      .add(i + 2, "days")
                      .format("ddd, D MMM");
                  })
                }
                onChange={(value) => {
                  setCandidateAvailability(value);
                  setCandidate({
                    ...candidate,
                    Availability: formatCandidateAvailability(value),
                  });
                }}
              />
            )}
            <div className="row-all-devices" style={{ marginTop: "24px" }}>
              {candidate["Passively Looking"] && (
                <input
                  type="checkbox"
                  style={{ margin: "4px 8px 0 0", borderRadius: "8px" }}
                  checked={candidate["Passively Looking"]}
                  onChange={(e) =>
                    setCandidate({
                      ...candidate,
                      "Passively Looking": e.target.checked,
                      Availability: e.target.checked
                        ? null
                        : candidateAvailability
                        ? formatCandidateAvailability(candidateAvailability)
                        : null,
                    })
                  }
                />
              )}
              <div
                onClick={() =>
                  setCandidate({
                    ...candidate,
                    "Passively Looking": !candidate["Passively Looking"],
                  })
                }
                style={{ width: "fit-content" }}
              >
                <p
                  className="underline"
                  style={{
                    marginTop: "2px",
                    cursor: "pointer",
                    fontSize: "12px",
                    opacity: 0.66,
                    textDecoration: "none",
                    borderBottom: "1px solid grey",
                    fontWeight: candidate["Passively Looking"] ? "bold" : "",
                  }}
                >
                  I'm not able to interview in the the next few days
                </p>
              </div>
            </div>
            {!utmSource && !referrer && (
              <div>
                <hr style={{ margin: "32px 0" }} />
                <Select
                  label="How did you find out about us?"
                  allowSearch={false}
                  selected={candidate["How did you find out about us?"]}
                  placeholder="Select one"
                  options={shuffleList([
                    "Facebook / Instagram",
                    "From Friends/Family",
                    "Google for Jobs",
                    "Google Search",
                    "Indeed",
                    "JobToday",
                    "Reed",
                  ])}
                  allowMultiple={false}
                  onChange={(value) => {
                    setCandidate({
                      ...candidate,
                      "How did you find out about us?": value,
                    });
                  }}
                />
              </div>
            )}
            <div className="row-all-devices" style={{ marginTop: "24px" }}>
              <input
                type="checkbox"
                style={{ marginTop: "4px" }}
                checked={candidate["Right to Work + Contact Consent"]}
                onChange={(e) =>
                  setCandidate({
                    ...candidate,
                    "Right to Work + Contact Consent": e.target.checked,
                  })
                }
              />
              &nbsp;
              <p>
                I have the right to work in the UK and consent to allowing
                employers to contact me about job opportunities
                <span
                  style={{
                    color: "red",
                    fontSize: "11px",
                    verticalAlign: "super",
                    marginLeft: "4px",
                  }}
                >
                  *
                </span>
              </p>
            </div>
            {error && (
              <div className="button-row button-row-center">
                <div
                  style={{
                    padding: "16px 32px",
                    borderRadius: "15px",
                    backgroundColor: "#f5e4e1",
                    margin: "32px 32px 0",
                    color: "red",
                  }}
                >
                  <img
                    src="../../../images/exclamation-mark.svg"
                    alt=""
                    width="15"
                    height="15"
                    style={{
                      marginTop: "-5px",
                      marginRight: "7px",
                      color: "red",
                    }}
                  />
                  {error}
                </div>
              </div>
            )}
            <div className="button-row button-row-center">
              {!loading && (
                <div className="button-wrapper no-margin-right">
                  <button
                    onClick={() => setCurrentPage(2)}
                    className=" w-inline-block"
                    style={{ display: "flex", margin: "24px 8px 24px 0" }}
                  >
                    <span
                      className="icon-small"
                      style={{
                        display: "inline-block",
                        fontSize: "20px",
                        lineHeight: "20px",
                        height: "20px",
                        width: "20px",
                        position: "relative",
                        right: "4px",
                        top: "2px",
                      }}
                    >
                      keyboard_arrow_left_icon
                    </span>
                    <div>Previous</div>
                  </button>
                </div>
              )}
              <div className="button-wrapper no-margin-right">
                {loading ? (
                  <div style={{ width: "100%", margin: "24px" }}>
                    <MoonLoader
                      color={"rgb(22, 46, 60)"}
                      loading={true}
                      size={40}
                    />
                  </div>
                ) : (
                  <button
                    onClick={() => submit()}
                    className="button w-inline-block"
                    style={{
                      display: "flex",
                      margin: "24px",
                      padding: "12px 32px",
                    }}
                  >
                    <div style={{ color: "#f2f0ed" }}>Submit</div>
                    <span
                      className="icon-small"
                      style={{
                        display: "inline-block",
                        fontSize: "20px",
                        lineHeight: "20px",
                        height: "20px",
                        width: "20px",
                        position: "relative",
                        left: "8px",
                        color: "#f2f0ed",
                      }}
                    >
                      check
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateOnboarding;
