import {
  getBranch,
  getInterviewSlots,
  getInterviewInfo,
  sendReschedulingConfirmation,
  cancelInterview,
} from "../backend";
import { abTesting, getRoleFromId, wait } from "../utils";
import { InterviewScheduler, Modal, Referrals } from "../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import confirmAnimation from "../Components/confirmAnimation.json";
import Lottie from "react-lottie-player";
import moment from "moment-timezone";
import { MoonLoader } from "react-spinners";

const InterviewChange = () => {
  const navigate = useNavigate();
  let { interviewId } = useParams();
  const [interview, setInterview] = useState();
  const [employer, setEmployer] = useState();
  const [role, setRole] = useState();
  const [interviewType, setInterviewType] = useState("Interview");
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [interviewCancelled, setInterviewCancelled] = useState(false);

  useEffect(() => {
    document.dispatchEvent(new Event("readystatechange"));
    async function fetchData() {
      let i = await getInterviewInfo(interviewId);
      if (i["Reschedules Allowed"] === 0) {
        navigate(`/max-reschedule?id=${interviewId}`);
      }
      setInterview(i);
      let e = await getBranch(i["Employer Id"]);
      e.interviewSlots = await getInterviewSlots(
        e.id,
        getRoleFromId(e, i["Role Id"])
          .name.replace(/[^a-zA-Z]/g, "")
          .toLowerCase()
      );
      setEmployer(e);
      let r = getRoleFromId(e, i["Role Id"]);
      setRole(r);
      if (r.interviewType) {
        setInterviewType(r.interviewType);
      }
    }
    fetchData();
    //eslint-disable-next-line
  }, []);

  const rescheduleInterview = (interviewTimestamp) => {
    sendReschedulingConfirmation(
      interview.id,
      interviewTimestamp,
      null,
      null,
      interview["Sent to Employer"]
    );
  };

  return (
    <div>
      <div
        className="image-banner"
        style={{
          background:
            "url(/images/pexels-rodnae-productions-4921154-p-1600.jpg)",
          height: "25vh",
          width: "100vw",
          backgroundSize: "cover",
          backgroundPositionY: "center",
        }}
      ></div>
      {interview && employer ? (
        <div
          className="margin-interview-scheduler"
          style={{ textAlign: "left" }}
        >
          {cancellationModalOpen && (
            <Modal
              isOpen={cancellationModalOpen}
              closeModal={() => setCancellationModalOpen(false)}
            >
              {interviewCancelled ? (
                <div style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <h6 style={{ marginBottom: "1rem" }}>
                    Interview Cancelled Successfully
                  </h6>
                  <div style={{ marginBottom: "3.5rem" }}>
                    <p>You will be redirected to the homepage shortly.</p>
                  </div>
                  <div>
                    <Lottie
                      animationData={confirmAnimation}
                      loop
                      play
                      style={{
                        width: "150px",
                        height: "150px",
                        margin: "24px auto",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <br />
                  <br />
                  Do you want to cancel this interview?
                  <div
                    className="row-all-devices"
                    style={{ marginTop: "32px", gap: "16px" }}
                  >
                    <button
                      className="button w-button"
                      style={{
                        padding: "8px 32px",
                        borderRadius: "8px",
                        flex: 1,
                        whiteSpace: "nowrap",
                      }}
                      onClick={async () => {
                        cancelInterview(
                          interview["Employer Id"],
                          interview["Candidate Id"],
                          interview["Date"],
                          false
                        );
                        setInterviewCancelled(true);
                        await wait(3000);
                        window.location.href =
                          "https://www.hirebolt.com/candidates";
                      }}
                    >
                      Yes, Cancel
                    </button>
                    <button
                      className="button w-button button-tertiary"
                      style={{
                        padding: "8px 32px",
                        borderRadius: "8px",
                        flex: 1,
                      }}
                      onClick={() => setCancellationModalOpen(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              )}
            </Modal>
          )}
          <h2 style={{ marginBottom: "1rem" }}>
            {interview["Candidate"]} x {employer.name}
          </h2>
          <div style={{ marginBottom: "3.5rem" }} className="mobile-width">
            <p className="text-size-large">
              Reschedule your {interviewType} using the calendar below.
            </p>
          </div>
          <div className="row" style={{ gap: "48px" }}>
            <div className="column">
              <p
                style={{
                  fontSize: "smaller",
                  color: "#d00000",
                }}
              >
                <img
                  src="/images/exclamation-mark.svg"
                  alt=""
                  width="15"
                  height="15"
                  style={{
                    marginTop: "-3px",
                    marginRight: "4px",
                    color: "red",
                  }}
                />{" "}
                {abTesting(interview["WhatsApp Number"], "candidate-profile", 2)
                  ? `If you cancel or no-show interviews, this will be visible to all employers on your public profile.`
                  : `A max of 2
                    cancellations/no-shows are allowed, after which you'll stop
                    receiving further job opportunities.`}
              </p>
              {employer && (
                <InterviewScheduler
                  employer={employer}
                  role={role}
                  sendInterviewConfirmation={rescheduleInterview}
                />
              )}
            </div>
            <div className="column" style={{ marginBottom: "50px" }}>
              <div style={{ marginBottom: "3.5rem" }}>
                <h6 style={{ opacity: 0.5, marginBottom: "1rem" }}>
                  Current {interviewType}
                </h6>
                <div
                  className="benefits-2-item scheduler-container"
                  style={{ width: "100%" }}
                >
                  <div
                    className="benefits-2-item-text-wrapper"
                    style={{ width: "100%" }}
                  >
                    {[
                      {
                        title: "Date & Time",
                        value: [
                          moment(interview["Date"]).format("Do MMMM, h:mma"),
                        ],
                      },
                      employer.address && {
                        title: "Address",
                        value: [employer.address],
                      },
                    ].map((info, j) => {
                      return (
                        <div>
                          {info && (
                            <div
                              key={info.title}
                              style={{ marginBottom: "15px" }}
                            >
                              <div
                                style={{
                                  width: "150px",
                                  marginBottom: isMobile ? "3px" : 0,
                                }}
                              >
                                <p className="text-color-bluemine">
                                  <b>{info.title}:</b>
                                </p>
                              </div>
                              {info.value.map((value, i) => {
                                return (
                                  <div
                                    style={{
                                      backgroundColor: "#162e3c",
                                      borderRadius: "15px",
                                      display: "block",
                                      marginRight: "6px",
                                      padding: "5px 15px",
                                      fontSize: "13px",
                                      width: "fit-content",
                                    }}
                                    className="text-color-bluemine"
                                    key={info.title + i}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "900",
                                        color: "#ddf0a7",
                                        textDecoration: "none",
                                        margin: "auto",
                                      }}
                                    >
                                      {value}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <button
                  className="button w-button"
                  style={{
                    margin: "24px 0",
                    borderRadius: "8px",
                    padding: "8px 24px",
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                  }}
                  onClick={() => setCancellationModalOpen(true)}
                >
                  <span>Cancel Interview</span>
                  <span
                    className="icon-small"
                    style={{ fontSize: "20px", height: "20px" }}
                  >
                    cancel
                  </span>
                </button>
              </div>
            </div>
          </div>
          <Referrals
            candidateId={interview["Candidate Id"]}
            touchpointId={"rescheduling_page"}
            style={{ marginTop: "50px" }}
          />
        </div>
      ) : (
        <div style={{ height: "500px", width: "100vw", display: "flex" }}>
          <div
            style={{
              width: "fit-content",
              height: "fit-content",
              margin: "auto",
            }}
          >
            <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InterviewChange;
