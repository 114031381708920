import { isMobile } from "react-device-detect";
import { TextField } from "./Forms";
import { useState } from "react";
import { loginCandidate } from "../backend";

const CandidateLogin = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorShown, setErrorShown] = useState();

  const login = async () => {
    // Check if candidate exists with email and whatsapp
    setLoading(true);
    let candidate = await loginCandidate(email, whatsapp);
    setLoading(false);
    // Return candidate info
    if (candidate) {
      onLogin(candidate);
    } else {
      // Show error message
      setErrorShown("Invalid email or WhatsApp number");
    }
  };

  return (
    <div
      style={{
        textAlign: isMobile ? "left" : "center",
        margin: "24px 0",
        width: isMobile ? "80%" : "fit-content",
      }}
    >
      <div className="row">
        <div className="column">
          <h5>Welcome back!</h5>
          <p style={{ fontSize: "smaller", marginBottom: "16px" }}>
            Please login to access your profile
          </p>
          <div className="row">
            <div className="column">
              <TextField
                label="Email address"
                focused={true}
                value={email}
                onChange={(email) => setEmail(email)}
                style={{
                  width: isMobile ? "100%" : "400px",
                  marginBottom: "16px",
                  textAlign: "left",
                }}
              />
              <TextField
                label="WhatsApp Number"
                tooltip="The number you've been receiving job opportunities on"
                value={whatsapp}
                onChange={(whatsapp) => setWhatsapp(whatsapp)}
                style={{
                  width: isMobile ? "100%" : "400px",
                  textAlign: "left",
                }}
              />
              {errorShown && (
                <p
                  style={{
                    marginTop: "16px",
                    color: "#cc3e3e",
                    fontSize: "smaller",
                    fontWeight: "bold",
                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                >
                  {errorShown}
                </p>
              )}
              <button
                className="button w-button"
                style={{
                  marginTop: "24px",
                  borderRadius: "8px",
                  padding: "8px 32px",
                }}
                onClick={() => {
                  if (email.length & whatsapp.length) {
                    login();
                  } else {
                    setErrorShown(
                      "Please enter your email and whatsapp number"
                    );
                  }
                }}
              >
                {loading ? "Loading..." : "Sign in"}
              </button>
              <p
                className="main-paragraph"
                style={{ fontSize: "smaller", marginTop: "16px" }}
              >
                <a href="mailto:info@hirebolt.com">info@hirebolt.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateLogin;
