import moment from "moment-timezone";
import { useEffect, useState } from "react";
import {
  setNewEmployer,
  sendEmployerWelcomeMessage,
  getLanguages,
  getSkills,
} from "../backend";
import {
  formatAvailability,
  getAllRoles,
  getExperienceCategories,
  updateSlots,
} from "../utils";
import { Modal } from "../Components";
import { Availability, Select, TextField } from "../Components/Forms";
import ReactTooltip from "react-tooltip";
import { isMobile } from "react-device-detect";
import { getEmployerSecret, formatInterviewTimes } from "../utils";
import { DatePicker } from "../Components/Forms";
import {useNavigate} from "react-router-dom";

const EmployerOnboardingForm = () => {
  // Track the new employer's data
  const [data, setData] = useState({
    name: "New Employer",
    id: "newemployer",
    hiringManagers: [],
    active: true,
    branches: [],
    description:
      "EMPLOYER DESCRIPTION: Make it short and APPEALING for candidates! Example: 'Ampéli is a gorgeous, very popular Eastern Mediterranean restaurant - listed by Michelin 2022!'",
    trialPaid: "",
    benefits: ["Share of service charge", "Fixed shifts", "Paid breaks"],
    pausedUntil: moment().format(),
    created: moment(),
  });
  // Track other employer preferences
  const [isHiringCentralized, setIsHiringCentralized] = useState(
    data.hiringManagers.length > 0
  );
  const [experienceTimeframe, setExperienceTimeframe] = useState("months");
  // Track which branch is being created/edited
  const [branchOpen, setBranchOpen] = useState(false);
  const [newBranch, setNewBranch] = useState();
  const [newBranchIndex, setNewBranchIndex] = useState();
  // Form manipulation
  const [positionToggled, setPositionToggled] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState({
    value: "",
  });
  const [messageSent, setMessageSent] = useState(false);
  const [onboardedBy, setOnboardedBy] = useState("Lorenzo");
  const [errorMessage, setErrorMessage] = useState();
  const [salaryType, setSalaryType] = useState("Range");
  const [languages, setLanguages] = useState();
  const [skills, setSkills] = useState();
  const navigate = useNavigate();

  const validateInputs = () => {
    let error;
    // Validate employer name
    if (["New Employer", ""].includes(data.name))
      error = "Please enter the name of this employer";
    // Validate employer description
    if (
      [
        "EMPLOYER DESCRIPTION: Make it short and APPEALING for candidates! Example: 'Ampéli is a gorgeous, very popular Eastern Mediterranean restaurant - listed by Michelin 2022!'",
        "",
      ].includes(data.description)
    )
      error = "Please enter a description for this employer";
    if (!data.branches.length) error = "Please add at least 1 branch";
    for (let branch of data.branches) {
      // Validate branch ID
      branch.id.replace("newemployer", data.id);
      if (
        data.branches.length === 1 &&
        branch.id[branch.id.length - 1] === "0"
      ) {
        branch.id = branch.id.slice(0, -1);
      }
      // Validate branch address
      if (!branch.address || branch.address.length < 5)
        error = "Please enter a valid branch address";
      // Validate open positions
      if (!branch.openPositions?.length)
        error = "Please add at least 1 position";
      for (let position of branch.openPositions) {
        if (!position.role?.length)
          error = "Please enter at least 1 role for each position";
        if (!position.salaryFrequency)
          error = "Please select salary frequencies";
        if (
          position.interviewType === "Interview" &&
          position.roleInterviewSlots.duration > 60
        )
          error =
            "Interview duration cannot be longer than 60 minutes. Make it a Trial instead";
        if (
          position.roleInterviewSlots.duration > 120 &&
          position.trialPaid === "No"
        )
          error = "Trials longer than 120 minutes must be paid";
      }
      // Validate hiring managers
      if (
        ![data.hiringManagers[0], data.branches[0].hiringManagers[0]].some(
          (hm) => hm && hm.number?.length > 5 && hm.name?.length
        )
      )
        error = "Please enter the hiring manager's name and contact details";
      if (!data.onboardedBy)
        error = "Please enter your name under 'Onboarded by'";
    }
    setErrorMessage(error);
    return !error;
  };

  const submit = async () => {
    if (validateInputs()) {
      let result = setNewEmployer({
        ...data,
        created: moment().toDate(),
        subscriptionId: `free_trial_${data.id}`,
        freeTrialExpiration: moment().add(14, "days").add(1, "hour").toDate(),
        branches: data.branches.map((b) => ({
          ...b,
          openPositions: b.openPositions.map((op) => ({
            ...op,
            minSalary: op.minSalary
              ? parseFloat(op.minSalary).toFixed(2)
              : null,
            maxSalary: op.maxSalary
              ? parseFloat(op.maxSalary).toFixed(2)
              : null,
          })),
        })),
      });
      setWelcomeMessage({
        value: `Hi ${
          data.hiringManagers[0]?.name ??
          data.branches[0]?.hiringManagers[0]?.name
        }, my name is ${onboardedBy}, I came recently to ${
          data.name
        } to talk about hiring.\n\nAs mentioned, we'll start sending vetted candidates who are actively interested in working with you!\n\n✅ Candidates will meet your experience and salary expectations, and have a short commute to your venue\n\n🗓 They will book an interview/trial with you. You'll be notified 12+ hours in advance and can cancel or reschedule them at any time\n\n🔁 By default, they will attend the interview/trial, so make sure to cancel/reschedule if you can't make it\n\nYou can update or close your open positions at: ${`https://hirebolt.com/${
          data.id
        }/${getEmployerSecret(data.id)}`}\n\nAny questions, let me know 😊`,
      });
      if (result) setSubmitted(true);
    }
  };

  const sendWelcomeMessage = () => {
    setMessageSent(true);
    sendEmployerWelcomeMessage(data, onboardedBy);
  };

  useEffect(() => {
    const getFormData = async () => {
      Promise.all([getLanguages(), getSkills()]).then((values) => {
        setLanguages(values[0]);
        setSkills(values[1]);
      });
    };
    getFormData();
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={{
        width: "100vw",
        height: "100%",
        paddingBottom: "128px",
        backgroundColor: "#f2f0ed",
      }}
      className="employer-admin-form"
    >
      <div
        className="image-banner"
        style={{
          background:
            "url(/images/pexels-rodnae-productions-4921154-p-1600.jpg)",
          height: "25vh",
          width: "100vw",
          maxWidth: "100vw",
          backgroundSize: "cover",
          backgroundPositionY: "center",
        }}
      ></div>
      <Modal
        isOpen={errorMessage != null}
        closeModal={() => setErrorMessage(null)}
      >
        <div>
          <p style={{ color: "red", margin: "50px 25px 25px" }}>
            {errorMessage}
          </p>
        </div>
      </Modal>
      {newBranch && (
        <Modal
          isOpen={branchOpen}
          closeOnOutsideClick={false}
          closeModal={() => {
            setBranchOpen(false);
            setNewBranch(undefined);
            setNewBranchIndex(undefined);
          }}
          style={{
            backgroundColor: "#f2f0ed",
          }}
        >
          <div>
            <div
              style={{
                padding: isMobile ? "0 15px 35px" : "25px",
              }}
            >
              <br />
              <h4>Info</h4>
              <div className="row">
                <div className="row">
                  <div className="column" style={{ marginRight: "16px" }}>
                    <label className="main-label">Location name</label>
                    <label className="sub-label">
                      Leave empty if only 1 location
                    </label>
                    <TextField
                      max={20}
                      value={newBranch.name}
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setNewBranch({
                          ...newBranch,
                          name: value,
                          id: (value === ""
                            ? data.name + data.branches.length
                            : data.name + value
                          )
                            .replace(/[^a-zA-Z0-9]+/g, "")
                            .toLowerCase(),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="column">
                  <label className="main-label">
                    Address
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                        verticalAlign: "super",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <label className="sub-label">
                    Full address of this branch/location
                  </label>
                  <TextField
                    value={newBranch.address}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      setNewBranch({ ...newBranch, address: value });
                    }}
                  />
                </div>
              </div>
              <hr style={{ margin: "50px 0" }} />
              {!isHiringCentralized && (
                <div>
                  <h4>Hiring Manager</h4>
                  {newBranch.hiringManagers.map((hm, j) => {
                    return (
                      <div key={"hm" + j} className="row">
                        <div style={{ marginRight: "15px" }}>
                          <label className="main-label">
                            Name
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                verticalAlign: "super",
                              }}
                            >
                              *
                            </span>{" "}
                            {j === 1 && (
                              <span
                                className="close-toggle-dark"
                                onClick={() => {
                                  let branch = JSON.parse(
                                    JSON.stringify(newBranch)
                                  );
                                  branch.hiringManagers = [
                                    branch.hiringManagers[0],
                                  ];
                                  setNewBranch(branch);
                                }}
                              >
                                x
                              </span>
                            )}
                          </label>
                          <TextField
                            value={newBranch.hiringManagers[j].name}
                            onChange={(value) => {
                              setNewBranch({
                                ...newBranch,
                                hiringManagers: newBranch.hiringManagers.map(
                                  (hm, n) => {
                                    if (n === j) return { ...hm, name: value };
                                    else return hm;
                                  }
                                ),
                              });
                            }}
                          />
                        </div>
                        <div style={{ marginRight: "15px" }}>
                          <label className="main-label">
                            WhatsApp Number
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                verticalAlign: "super",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <TextField
                            value={newBranch.hiringManagers[j].number || "+44"}
                            onChange={(value) => {
                              setNewBranch({
                                ...newBranch,
                                hiringManagers: newBranch.hiringManagers.map(
                                  (hm, n) => {
                                    if (n === j)
                                      return {
                                        ...hm,
                                        number: value.replace(/\s/g, ""),
                                      };
                                    else return hm;
                                  }
                                ),
                              });
                            }}
                          />
                        </div>
                        <div>
                          <label className="main-label">Email</label>
                          <TextField
                            value={newBranch.hiringManagers[j].email}
                            onChange={(value) => {
                              setNewBranch({
                                ...newBranch,
                                hiringManagers: newBranch.hiringManagers.map(
                                  (hm, n) => {
                                    if (n === j) return { ...hm, email: value };
                                    else return hm;
                                  }
                                ),
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {newBranch.hiringManagers.length === 1 && (
                    <div>
                      <br />
                      <button
                        style={{ textDecoration: "underline" }}
                        onClick={(e) => {
                          setNewBranch({
                            ...newBranch,
                            hiringManagers: [
                              ...newBranch.hiringManagers,
                              { name: "", number: "", email: "" },
                            ],
                          });
                        }}
                      >
                        Add another Hiring Manager
                      </button>
                    </div>
                  )}
                  <hr style={{ margin: "50px 0" }} />
                </div>
              )}
              <h4 style={{ marginTop: "50px" }}>Open Positions</h4>
              {newBranch.openPositions.map((pos, p) => {
                return (
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      borderRadius: "25px",
                      padding: "15px",
                      marginTop: "25px",
                    }}
                    key={"pos" + p}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        display: "inline",
                      }}
                      onClick={() => {
                        setPositionToggled(positionToggled === p ? null : p);
                      }}
                    >
                      <span
                        className="icon-small"
                        style={{
                          paddingRight: "15px",
                          fontSize: "24px",
                          lineHeight: "24px",
                          float: "left",
                          display: "inline",
                          position: "relative",
                          top: "3px",
                        }}
                      >
                        {positionToggled === p
                          ? "keyboard_arrow_down_icon"
                          : "keyboard_arrow_right_icon"}
                      </span>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "larger",
                          display: "inline",
                        }}
                      >
                        {pos.role?.length > 0
                          ? pos.role.join(", ")
                          : `Position ${p}`}
                      </p>
                    </div>
                    <span
                      className="icon-small"
                      style={{
                        cursor: "pointer",
                        display: "inline",
                        float: "right",
                        paddingRight: "15px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        position: "relative",
                        top: "3px",
                      }}
                      onClick={() => {
                        let positions = newBranch.openPositions;
                        positions.splice(p, 1);
                        setNewBranch({
                          ...newBranch,
                          openPositions: positions,
                        });
                        setPositionToggled(
                          positionToggled === p ? null : positionToggled
                        );
                      }}
                    >
                      delete
                    </span>
                    <span
                      className="icon-small"
                      style={{
                        display: "inline",
                        cursor: "pointer",
                        float: "right",
                        paddingRight: "15px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        position: "relative",
                        top: "3px",
                      }}
                      onClick={() => {
                        let positions = JSON.parse(
                          JSON.stringify(newBranch.openPositions)
                        );
                        positions.push(newBranch.openPositions[p]);
                        setNewBranch({
                          ...newBranch,
                          openPositions: positions,
                        });
                        setPositionToggled(positions.length - 1);
                      }}
                    >
                      copy
                    </span>
                    {positionToggled === p && (
                      <div>
                        <label className="main-label">
                          Role
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              verticalAlign: "super",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          allowMultiple
                          allowSearch
                          selected={newBranch.openPositions[p].role}
                          onChange={(value) => {
                            let branch = JSON.parse(JSON.stringify(newBranch));
                            branch.openPositions[p].role = value;
                            branch.openPositions[p].typeOfExperience =
                              "overall";
                            setNewBranch(branch);
                          }}
                          options={getAllRoles()}
                        ></Select>
                        <label className="main-label">
                          Part Time or Full Time?
                        </label>
                        <Select
                          allowMultiple
                          allowSearch
                          selected={newBranch.openPositions[p].jobType}
                          onChange={(value) => {
                            let branch = JSON.parse(JSON.stringify(newBranch));
                            branch.openPositions[p].jobType = value;
                            setNewBranch(branch);
                          }}
                          options={[
                            "Part Time",
                            "Full-Time (Permanent)",
                            "Full-Time (Summer/Winter Break Only)",
                          ]}
                        ></Select>
                        <label className="main-label">
                          Preferred Languages
                        </label>
                        <Select
                          allowMultiple
                          selected={newBranch.openPositions[p].languages}
                          onChange={(value) => {
                            let branch = JSON.parse(JSON.stringify(newBranch));
                            branch.openPositions[p].languages = value;
                            setNewBranch(branch);
                          }}
                          options={languages}
                        ></Select>
                        <label className="main-label">
                          Preferred Additional Skills
                        </label>
                        <Select
                          allowMultiple
                          allowSearch
                          selected={newBranch.openPositions[p].skills}
                          onChange={(value) => {
                            let branch = JSON.parse(JSON.stringify(newBranch));
                            branch.openPositions[p].skills = value;
                            setNewBranch(branch);
                          }}
                          options={skills}
                        ></Select>
                        <label className="main-label">
                          Prior Experience Needed
                        </label>
                        <div className="row">
                          <TextField
                            max={"2"}
                            value={
                              newBranch.openPositions[p].monthsOfExperience !==
                              ""
                                ? newBranch.openPositions[p]
                                    .monthsOfExperience /
                                  (experienceTimeframe === "years" ? 12 : 1)
                                : ""
                            }
                            placeholder={0}
                            style={{
                              width: "70px",
                              marginRight: "10px",
                              flex: 2,
                            }}
                            onChange={(value) => {
                              let branch = JSON.parse(
                                JSON.stringify(newBranch)
                              );
                              if (value === "") {
                                branch.openPositions[p].monthsOfExperience = "";
                              } else {
                                branch.openPositions[p].monthsOfExperience =
                                  value *
                                  (experienceTimeframe === "years" ? 12 : 1);
                              }
                              setNewBranch(branch);
                            }}
                          />
                          <Select
                            allowSearch
                            allowMultiple={false}
                            selected={experienceTimeframe}
                            onChange={(value) => {
                              let branch = JSON.parse(
                                JSON.stringify(newBranch)
                              );
                              branch.openPositions[p].monthsOfExperience =
                                branch.openPositions[p].monthsOfExperience *
                                (value === "years" ? 12 : 1 / 12);
                              setNewBranch(branch);
                              setExperienceTimeframe(value);
                            }}
                            options={["months", "years"]}
                            style={{ flex: 3, marginRight: "8px" }}
                          ></Select>
                          <Select
                            allowSearch
                            allowMultiple={false}
                            selected={
                              newBranch.openPositions[p].typeOfExperience ===
                              "overall"
                                ? "of overall hospitality experience"
                                : `of ${newBranch.openPositions[p].typeOfExperience} experience`
                            }
                            onChange={(value) => {
                              let branch = JSON.parse(
                                JSON.stringify(newBranch)
                              );
                              branch.openPositions[p].typeOfExperience =
                                value === "of overall hospitality experience"
                                  ? "overall"
                                  : value.split(" ")[1];
                              setNewBranch(branch);
                            }}
                            options={[
                              "of overall hospitality experience",
                              ...getExperienceCategories(
                                newBranch.openPositions[p].role
                              ).map((cat) => `of ${cat} experience`),
                            ]}
                            style={{ flex: 7 }}
                          ></Select>
                        </div>
                        <label className="main-label">Salary</label>
                        <div className="row">
                          <Select
                            allowMultiple={false}
                            selected={salaryType}
                            options={[
                              "Range",
                              "Fixed",
                              "Annual",
                              "Annual Range",
                            ]}
                            onChange={(value) => {
                              setSalaryType(value);
                              let branch = JSON.parse(
                                JSON.stringify(newBranch)
                              );
                              if (value === "Fixed")
                                branch.openPositions[p].minSalary = null;
                              setNewBranch(branch);
                            }}
                            style={{ marginRight: "16px", flex: 2 }}
                          />
                          {salaryType.includes("Annual") ? (
                            <div
                              className="row-all-devices"
                              style={{ lineHeight: "70px", flex: 6 }}
                            >
                              £
                              {salaryType === "Annual Range" && (
                                <div className="row-all-devices">
                                  <TextField
                                    max={6}
                                    style={{
                                      margin: "0 10px",
                                      width: "160px",
                                      maxWidth: "160px",
                                    }}
                                    value={Math.round(
                                      newBranch.openPositions[p].minSalary *
                                        12 *
                                        4 *
                                        45
                                    )}
                                    onChange={(value) => {
                                      let branch = JSON.parse(
                                        JSON.stringify(newBranch)
                                      );
                                      if (!value) {
                                        branch.openPositions[p].minSalary =
                                          null;
                                        setNewBranch(branch);
                                      } else if (/^\d+$/.test(value)) {
                                        if (salaryType === "Annual") {
                                          branch.openPositions[p].minSalary =
                                            null;
                                        }
                                        branch.openPositions[p].minSalary =
                                          value / 12 / 4 / 45;
                                        setNewBranch(branch);
                                      }
                                    }}
                                  />
                                  -
                                </div>
                              )}
                              <div
                                className="row-all-devices"
                                style={{ width: "100%" }}
                              >
                                <TextField
                                  max={6}
                                  style={{
                                    margin: "0 10px",
                                    width: "160px",
                                    maxWidth: "160px",
                                  }}
                                  value={Math.round(
                                    newBranch.openPositions[p].maxSalary *
                                      12 *
                                      4 *
                                      45
                                  )}
                                  onChange={(value) => {
                                    let branch = JSON.parse(
                                      JSON.stringify(newBranch)
                                    );
                                    if (!value) {
                                      branch.openPositions[p].maxSalary = null;
                                      setNewBranch(branch);
                                    } else if (/^\d+$/.test(value)) {
                                      branch.openPositions[p].minSalary = null;
                                      branch.openPositions[p].maxSalary =
                                        value / 12 / 4 / 45;
                                      setNewBranch(branch);
                                    }
                                  }}
                                />
                                /year
                              </div>
                            </div>
                          ) : (
                            <div
                              className="row-all-devices"
                              style={{ lineHeight: "70px", flex: 6 }}
                            >
                              £
                              {salaryType === "Range" && (
                                <div className="row-all-devices">
                                  <TextField
                                    max={5}
                                    style={{ margin: "0 10px", width: "80px" }}
                                    value={newBranch.openPositions[p].minSalary}
                                    onChange={(value) => {
                                      let branch = JSON.parse(
                                        JSON.stringify(newBranch)
                                      );
                                      branch.openPositions[p].minSalary = value;
                                      setNewBranch(branch);
                                    }}
                                  />
                                  -
                                </div>
                              )}
                              <div
                                className="row-all-devices"
                                style={{ width: "100%" }}
                              >
                                <TextField
                                  max={5}
                                  style={{
                                    margin: "0 10px",
                                    width: "80px",
                                    maxWidth: "80px",
                                  }}
                                  value={newBranch.openPositions[p].maxSalary}
                                  onChange={(value) => {
                                    let branch = JSON.parse(
                                      JSON.stringify(newBranch)
                                    );
                                    branch.openPositions[p].maxSalary = value;
                                    setNewBranch(branch);
                                  }}
                                />
                                /hour incl. service
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div
                            className="column"
                            style={{ flex: 8, marginRight: "16px" }}
                          >
                            <label
                              className="main-label"
                              style={{ marginTop: "16px" }}
                            >
                              Specific days/times candidate must work?
                            </label>
                            <TextField
                              max={50}
                              value={newBranch.openPositions[p].workingHours}
                              onChange={(value) => {
                                let branch = JSON.parse(
                                  JSON.stringify(newBranch)
                                );
                                branch.openPositions[p].workingHours = value;
                                setNewBranch(branch);
                              }}
                            />
                          </div>
                          <div className="column" style={{ flex: 4 }}>
                            <label
                              className="main-label"
                              style={{ marginTop: "16px" }}
                            >
                              Salary Frequency
                            </label>
                            <Select
                              allowMultiple={false}
                              style={{ width: "100%" }}
                              options={["Weekly", "Bi-Weekly", "Monthly"]}
                              selected={
                                newBranch.openPositions[p].salaryFrequency && [
                                  newBranch.openPositions[p].salaryFrequency,
                                ]
                              }
                              onChange={(value) => {
                                let branch = JSON.parse(
                                  JSON.stringify(newBranch)
                                );
                                branch.openPositions[p].salaryFrequency = value;
                                setNewBranch(branch);
                              }}
                            />
                          </div>
                        </div>
                        <label className="main-label">
                          Any Additional Info?
                        </label>
                        <label className="sub-label">
                          Leave empty if not. The content of this field is shown
                          to candidates
                        </label>
                        <TextField
                          value={newBranch.openPositions[p].additionalInfo}
                          max={200}
                          onChange={(value) => {
                            let branch = JSON.parse(JSON.stringify(newBranch));
                            branch.openPositions[p].additionalInfo = value;
                            setNewBranch(branch);
                          }}
                        ></TextField>
                        <div
                          className="row"
                          style={{ paddingTop: "16px", margin: "16px 0" }}
                        >
                          <div
                            className="row-all-devices"
                            style={{ marginRight: "15px" }}
                          >
                            <input
                              type="checkbox"
                              className="rounded form-checkbox"
                              defaultChecked={
                                newBranch.openPositions[p]["18+?"]
                              }
                              onChange={(e) => {
                                let branch = JSON.parse(
                                  JSON.stringify(newBranch)
                                );
                                branch.openPositions[p]["18+?"] =
                                  e.target.checked;
                                setNewBranch(branch);
                              }}
                            />
                            <label
                              style={{
                                margin: "0 0 10px 10px",
                                lineHeight: "15px",
                              }}
                              className="main-label"
                            >
                              Must be 18+ years old
                            </label>
                          </div>
                          <div className="row-all-devices">
                            <input
                              type="checkbox"
                              className="rounded form-checkbox"
                              defaultChecked={
                                newBranch.openPositions[p]["21+?"]
                              }
                              onChange={(e) => {
                                let branch = JSON.parse(
                                  JSON.stringify(newBranch)
                                );
                                branch.openPositions[p]["21+?"] =
                                  e.target.checked;
                                setNewBranch(branch);
                              }}
                            />
                            <label
                              style={{
                                margin: "0 0 10px 10px",
                                lineHeight: "15px",
                              }}
                              className="main-label"
                            >
                              Must be 21+ years old
                            </label>
                          </div>
                        </div>
                        <hr
                          style={{
                            margin: "32px 0px",
                            borderColor: "rgba(0, 0, 0, 0.05)",
                          }}
                        />
                        <h5>Trials & Interviews</h5>
                        <label className="main-label">Interview Type</label>
                        <Select
                          allowMultiple={false}
                          style={{ width: "100%" }}
                          options={["Interview", "Trial"]}
                          selected={
                            newBranch.openPositions[p].interviewType && [
                              newBranch.openPositions[p].interviewType,
                            ]
                          }
                          onChange={(value) => {
                            let branch = JSON.parse(JSON.stringify(newBranch));
                            branch.openPositions[p].interviewType = value;
                            setNewBranch(branch);
                          }}
                        ></Select>
                        <div className="row">
                          <div
                            className="column"
                            style={{ flex: 5, marginRight: "16px" }}
                          >
                            <label className="main-label">
                              Duration
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  verticalAlign: "super",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <label className="sub-label">In minutes</label>
                            <Select
                              selected={[
                                newBranch.openPositions[p].roleInterviewSlots[0]
                                  .duration,
                              ]}
                              allowMultiple={false}
                              options={[30, 60, 90, 120, 180, 240, 300].filter(
                                (n) =>
                                  newBranch.openPositions[p].interviewType ===
                                    "Trial" || n <= 60
                              )}
                              onChange={(value) => {
                                let branch = JSON.parse(
                                  JSON.stringify(newBranch)
                                );
                                branch.openPositions[p].roleInterviewSlots =
                                  branch.openPositions[
                                    p
                                  ].roleInterviewSlots.map((slot) => ({
                                    ...slot,
                                    duration: value,
                                    times: value
                                      ? updateSlots(
                                          slot.times,
                                          slot.duration,
                                          value
                                        )
                                      : slot.times,
                                  }));
                                setNewBranch(branch);
                              }}
                            />
                          </div>
                          {newBranch.openPositions[p].interviewType ===
                            "Trial" && (
                            <div
                              className="column"
                              style={{ flex: 3, marginRight: "16px" }}
                            >
                              <label className="main-label">Is it paid?</label>
                              <label className="sub-label">
                                Empty if not specified
                              </label>
                              <Select
                                allowMultiple={false}
                                options={
                                  newBranch.openPositions[p]
                                    .roleInterviewSlots[0].duration > 120
                                    ? ["Yes", "Yes, if hired"]
                                    : ["No", "Yes", "Yes, if hired"]
                                }
                                selected={
                                  newBranch.openPositions[p].trialPaid && [
                                    newBranch.openPositions[p].trialPaid,
                                  ]
                                }
                                onChange={(value) => {
                                  let branch = JSON.parse(
                                    JSON.stringify(newBranch)
                                  );
                                  branch.openPositions[p].trialPaid = value;
                                  setNewBranch(branch);
                                }}
                              />
                            </div>
                          )}
                          <div className="column" style={{ flex: 4 }}>
                            <label className="main-label">Dress code</label>
                            <label className="sub-label">
                              Leave empty if no dress code
                            </label>
                            <TextField
                              value={newBranch.openPositions[p].dressCode}
                              max={150}
                              onChange={(value) => {
                                let branch = JSON.parse(
                                  JSON.stringify(newBranch)
                                );
                                branch.openPositions[p].dressCode = value;
                                setNewBranch(branch);
                              }}
                            />
                          </div>
                        </div>
                        <label className="main-label">
                          Availability
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              verticalAlign: "super",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Availability
                          recurring
                          onChange={(slots) => {
                            if (slots.length === 0) return;
                            // Format slots
                            let branch = JSON.parse(JSON.stringify(newBranch));
                            let formattedSlots = slots.map((slot) => ({
                              days: [slot.date],
                              times: formatInterviewTimes(
                                slot.slots,
                                branch.openPositions[0].roleInterviewSlots[0]
                                  .duration
                              ),
                              duration:
                                branch.openPositions[0].roleInterviewSlots[0]
                                  .duration,
                            }));
                            branch.openPositions[p].roleInterviewSlots =
                              formattedSlots;
                            branch.openPositions[p].availability = slots;
                            setNewBranch(branch);
                          }}
                          availability={
                            newBranch.openPositions[p].availability ??
                            formatAvailability(
                              newBranch.openPositions[p].roleInterviewSlots ??
                                newBranch.interviewSlots
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              <div
                className="main-label"
                style={{
                  marginTop: "25px",
                  cursor: "pointer",
                  color: "gray",
                  display: "flex",
                  fontSize: "18px",
                  lineHeight: "30px",
                  width: "fit-content",
                }}
                onClick={(e) => {
                  setNewBranch({
                    ...newBranch,
                    openPositions: [
                      ...newBranch.openPositions,
                      {
                        role: [],
                        jobType: [],
                        priorExperience: [],
                        "18+?": false,
                        minSalary: 10,
                        maxSalary: 14,
                        dressCode: undefined,
                        interviewType: "Interview",
                        workingHours: "Shifts are based on a weekly rota",
                        monthsOfExperience: 0,
                        typeOfExperience: "overall",
                        roleInterviewSlots: [
                          {
                            days: [
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday",
                            ],
                            times: [
                              "09:00",
                              "10:00",
                              "11:00",
                              "12:00",
                              "13:00",
                              "14:00",
                              "15:00",
                              "16:00",
                              "17:00",
                              "18:00",
                              "19:00",
                            ],
                            duration: 120,
                          },
                        ],
                      },
                    ],
                  });
                }}
              >
                <span
                  className="icon-small"
                  style={{
                    fontSize: "23px",
                    position: "relative",
                    bottom: "2px",
                    lineHeight: "18px",
                  }}
                >
                  add
                </span>{" "}
                Add More Positions
              </div>
              <hr style={{ margin: "30px 0 0" }} />
              <button
                className="button button-small"
                style={{ margin: "25px 0 0" }}
                onClick={(e) => {
                  let newBranches = data.branches;
                  newBranches[newBranchIndex] = newBranch;
                  setData({
                    ...data,
                    branches: newBranches,
                  });
                  setBranchOpen(false);
                  setNewBranch(undefined);
                  setNewBranchIndex(undefined);
                }}
              >
                Done{" "}
                <span
                  className="icon-small"
                  style={{
                    display: "inline-block",
                    fontSize: "20px",
                    lineHeight: "20px",
                    height: "20px",
                    width: "20px",
                    position: "relative",
                    top: "4px",
                  }}
                >
                  check
                </span>
              </button>
            </div>
          </div>
        </Modal>
      )}
      {submitted ? (
        <div
          style={{
            height: "fit-content",
            margin: "auto",
            width: "100%",
            maxWidth: "800px",
            padding: "50px",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              margin: "25px 0",
            }}
          >
            Send a welcome message to {data.name}
          </p>
          <label className="sub-label">Your Name</label>
          <input
            value={onboardedBy}
            onChange={(e) => {
              setOnboardedBy(e.target.value);
              setWelcomeMessage({
                value: `Hi ${
                  data.hiringManagers[0]?.name ??
                  data.branches[0]?.hiringManagers[0]?.name
                }, my name is ${e.target.value}, I came recently to ${
                  data.name
                } to talk about hiring.\n\nWe'll start sending relevant CVs of candidates willing to commute to your venue. If any candidate is interesting for you, just click 'Interested' on them and we'll automatically reach out to them asking to schedule a trial!\n\nYou can update your open positions and preferences at: ${`https://hirebolt.com/${
                  data.id
                }/${getEmployerSecret(
                  data.id
                )}`}\n\nAny questions, let me know 😊`,
              });
            }}
            style={{
              width: "70%",
              border: "none",
              margin: "0 0 15px",
              borderBottom: "#f0f0f0 solid 2px",
            }}
          />
          <div
            data-tip="Editing disabled due to MessageBird restrictions. Please ask Lorenzo to change or send a different message"
            data-for="welcome-message"
            style={{ display: "block", background: "transparent" }}
          >
            <textarea
              rows="10"
              value={welcomeMessage.value}
              disabled={true}
              onChange={(e) => {}}
              style={{
                width: "100%",
                maxWidth: "800px",
                margin: "0 0 15px",
                borderBottom: "#f0f0f0 solid 2px",
                fontSize: "1rem",
              }}
            ></textarea>
          </div>
          <ReactTooltip effect="solid" id="welcome-message" place="top" />
          <div>
            {messageSent ? (
              <p>
                Message sent!{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    navigate(`../admin/${data.id}/${getEmployerSecret(data.id)}/pictures`);
                  }}
                >
                  View Employer Pictures
                </span>
              </p>
            ) : (
                <div>
              <button
                onClick={() => sendWelcomeMessage()}
                style={{
                  fontWeight: "bold",
                  padding: "15px 35px",
                  backgroundColor: "#162e3c",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                SEND{" "}
                <span
                  className="icon-small"
                  style={{
                    display: "inline",
                    fontSize: "16px",
                    marginLeft: "5px",
                    position: "relative",
                    top: "2px",
                    transform: "rotate(45deg)",
                  }}
                >
                  send
                </span>
              </button>
              <button
                  onClick={() => {
                    navigate(`../admin/${data.id}/${getEmployerSecret(data.id)}/pictures`);
                  }}
            style={{
              fontWeight: "bold",
              padding: "15px 35px",
              backgroundColor: "#162e3c",
              color: "white",
              borderRadius: "5px",
              marginLeft: "15px",
            }}
          >
            Don't SEND{" "}
            <span
                className="icon-small"
                style={{
                  display: "inline",
                  fontSize: "16px",
                  marginLeft: "5px",
                  position: "relative",
                  transform: "rotate(45deg)",
                }}
            >
                  X
                </span>
          </button>
                </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "fit-content",
            margin: "auto",
            maxWidth: "800px",
            padding: isMobile ? "25px" : "50px",
          }}
        >
          <textarea
            className="rounded form-text candidate-no-border h1"
            value={data.name}
            maxLength={25}
            rows={
              isMobile
                ? Math.floor(
                    1 + data.name.length / ((window.innerWidth - 50 - 16) / 24)
                  )
                : 1
            }
            cols={data.name.length}
            style={{
              width: isMobile ? "100%" : "auto",
              maxWidth: "100%",
              resize: "none",
              whiteSpace: isMobile ? "initial" : "nowrap",
            }}
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
                id: e.target.value.replace(/[^a-zA-Z0-9]+/g, "").toLowerCase(),
              });
            }}
          />
          <textarea
            className="rounded form-text candidate-no-border"
            value={data.description}
            rows={Math.floor(
              1 +
                data.description.length /
                  ((window.innerWidth - 50 - 16) / (isMobile ? 8 : 18))
            )}
            style={{
              width: "100%",
              maxWidth: "100%",
              resize: "none",
            }}
            onChange={(e) => {
              setData({
                ...data,
                description: e.target.value.slice(0, 175),
              });
            }}
          />
          <div
            className="row"
            style={{
              overflowY: "scroll",
              marginTop: "25px",
              padding: "5px 25px 20px",
              position: "relative",
              left: "-25px",
            }}
          >
            {data.branches.map((branch, i) => {
              return (
                <div
                  className="branch-card-active"
                  key={branch.id}
                  style={{
                    backgroundColor: "rgb(250, 253, 255)",
                    boxShadow:
                      "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)",
                  }}
                >
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "5px",
                      fontWeight: "bold",
                      fontSize: "larger",
                    }}
                  >
                    {branch.name && branch.name.length > 1
                      ? branch.name
                      : branch.address && branch.address.length > 1
                      ? branch.address.split(",")[0]
                      : `Location ${i + 1}`}
                  </p>
                  <p>
                    {`${branch.openPositions.length} open position${
                      branch.openPositions.length > 1 ? "s" : ""
                    }`}
                  </p>
                  <div
                    className="row"
                    style={{
                      margin: "15px",
                      verticalAlign: "bottom",
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: "fit-content",
                    }}
                  >
                    <div
                      className="icon-small card-icon"
                      data-tip="Edit branch"
                      data-for="branch-manipulation"
                      onClick={() => {
                        setNewBranchIndex(i);
                        setNewBranch(JSON.parse(JSON.stringify(branch)));
                        setBranchOpen(true);
                      }}
                    >
                      edit
                    </div>
                    <div
                      className="icon-small card-icon"
                      data-tip="Duplicate branch"
                      data-for="branch-manipulation"
                      onClick={() => {
                        let newBranch = JSON.parse(JSON.stringify(branch));
                        newBranch.name = newBranch.name + " (Copy)";
                        setData({
                          ...data,
                          branches: [...data.branches, newBranch],
                        });
                      }}
                    >
                      copy
                    </div>
                    <div
                      className="icon-small card-icon"
                      data-tip="Delete branch"
                      data-for="branch-manipulation"
                      onClick={() => {
                        let branches = data.branches;
                        branches.splice(i, 1);
                        setData({
                          ...data,
                          branches: branches,
                        });
                      }}
                    >
                      delete
                    </div>
                    <ReactTooltip
                      effect="solid"
                      id="branch-manipulation"
                      place="top"
                    />
                  </div>
                </div>
              );
            })}
            <button
              className="branch-card-inactive"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                display: "flex",
              }}
              onClick={async () => {
                setNewBranchIndex(data.branches.length);
                setNewBranch({
                  name: "",
                  id: `${data.id}${data.branches.length}`,
                  optOut: false,
                  hiringManagers: isHiringCentralized
                    ? []
                    : [{ name: "", number: "", email: "" }],
                  address: "",
                  openPositions: [
                    {
                      role: [],
                      jobType: [],
                      interviewType: "Interview",
                      priorExperience: [],
                      "18+?": false,
                      minSalary: 10,
                      maxSalary: 14,
                      dressCode: undefined,
                      workingHours: "Shifts are based on a weekly rota",
                      monthsOfExperience: 0,
                      typeOfExperience: "overall",
                      roleInterviewSlots: [
                        {
                          days: [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                            "Sunday",
                          ],
                          times: [
                            "09:00",
                            "10:00",
                            "11:00",
                            "12:00",
                            "13:00",
                            "14:00",
                            "15:00",
                            "16:00",
                            "17:00",
                            "18:00",
                            "19:00",
                          ],
                          duration: 120,
                        },
                      ],
                    },
                  ],
                  created: moment().toDate(),
                });
                setBranchOpen(true);
              }}
            >
              <p
                className="main-label"
                style={{ color: "gray", margin: "auto", width: "fit-content" }}
              >
                Add a Location
                <span
                  className="icon-small"
                  style={{ margin: "5px auto 0", fontSize: "25px" }}
                >
                  add
                </span>
              </p>
            </button>
          </div>
          <div className="row-all-devices" style={{ marginTop: "16px" }}>
            <input
              type="checkbox"
              className="rounded form-checkbox"
              defaultChecked={isHiringCentralized}
              value={isHiringCentralized}
              onChange={(e) => {
                setIsHiringCentralized(e.target.checked);
                if (e.target.checked) {
                  let hm =
                    data.hiringManagers.length > 0
                      ? data.hiringManagers
                      : [{ name: "", number: "", email: "" }];
                  let branches = data.branches;
                  if (branches.length > 0) {
                    branches.forEach((b) => {
                      b.hiringManagers = [];
                    });
                  }
                  setData({ ...data, hiringManagers: hm, branches: branches });
                } else {
                  let branches = data.branches;
                  if (branches.length > 0) {
                    branches.forEach((b) => {
                      b.hiringManagers = [{ name: "", number: "", email: "" }];
                    });
                  }
                  setData({ ...data, hiringManagers: [], branches: branches });
                }
              }}
            />
            <label
              style={{
                marginLeft: "10px",
                marginTop: 0,
                lineHeight: isMobile ? "20px" : "15px",
              }}
              className="main-label"
            >
              Hiring is done by the same person for all locations
            </label>
          </div>
          {isHiringCentralized && (
            <div
              style={{
                marginTop: "25px",
                padding: "25px 0",
              }}
            >
              <h5>Hiring Manager</h5>
              {data.hiringManagers.map((hm, i) => {
                return (
                  <div key={"hm" + i} className="row">
                    <div style={{ marginRight: "15px" }}>
                      <label className="main-label">
                        Name
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            verticalAlign: "super",
                          }}
                        >
                          *
                        </span>{" "}
                        {i === 1 && (
                          <span
                            className="close-toggle-dark"
                            onClick={() => {
                              setData({
                                ...data,
                                hiringManagers: [data.hiringManagers[0]],
                              });
                            }}
                          >
                            x
                          </span>
                        )}
                      </label>
                      <TextField
                        value={data.hiringManagers[i].name}
                        onChange={(value) => {
                          setData({
                            ...data,
                            hiringManagers: data.hiringManagers.map(
                              (current, j) => {
                                if (i === j)
                                  return {
                                    ...current,
                                    name: value,
                                  };
                                else return current;
                              }
                            ),
                          });
                        }}
                      />
                    </div>
                    <div style={{ marginRight: "15px" }}>
                      <label className="main-label">
                        WhatsApp Number
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            verticalAlign: "super",
                          }}
                        >
                          *
                        </span>
                      </label>
                      <TextField
                        value={data.hiringManagers[i].number || "+44"}
                        onChange={(value) => {
                          setData({
                            ...data,
                            hiringManagers: data.hiringManagers.map(
                              (existing, j) => {
                                if (i === j)
                                  return {
                                    ...existing,
                                    number: value.replace(/\s/g, ""),
                                  };
                                else return existing;
                              }
                            ),
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label className="main-label">Email Address</label>
                      <TextField
                        value={data.hiringManagers[i].email}
                        onChange={(value) => {
                          setData({
                            ...data,
                            hiringManagers: data.hiringManagers.map(
                              (existing, j) => {
                                if (i === j)
                                  return {
                                    ...existing,
                                    email: value,
                                  };
                                else return existing;
                              }
                            ),
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {data.hiringManagers.length === 1 && (
                <div>
                  <br />
                  <button
                    style={{ textDecoration: "underline" }}
                    onClick={(e) => {
                      setData({
                        ...data,
                        hiringManagers: [
                          ...data.hiringManagers,
                          { name: "", number: "", email: "" },
                        ],
                      });
                    }}
                  >
                    Add another Hiring Manager
                  </button>
                </div>
              )}
            </div>
          )}
          <hr style={{ margin: "50px 0" }} />
          <h4>Other Info</h4>
          <label className="main-label">Employer Benefits</label>
          <label className="sub-label">One on each line</label>
          <div
            className="rounded"
            style={{
              width: "100%",
              height: "fit-content",
              padding: 0,
              display: "flex",
              backgroundColor: "rgb(250, 253, 255)",
              boxShadow:
                "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)",
              borderColor: "white",
              border: "0px none white",
              outline: "none",
            }}
          >
            <textarea
              className="rounded form-text no-webkit"
              value={data.benefits.join("\n")}
              rows={6}
              style={{
                backgroundColor: "rgb(250, 253, 255)",
                margin: 0,
                width: "100%",
                border: "none",
                outline: "none",
              }}
              onChange={(e) => {
                let benefits = e.target.value.split("\n");
                if (benefits.length === 1 && benefits[0].length === 0)
                  benefits = [];
                setData({
                  ...data,
                  benefits: benefits,
                });
              }}
            />
          </div>
          <div
            className="row"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div style={{ flex: 4 }}>
              <label className="main-label">
                Onboarded By
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    verticalAlign: "super",
                  }}
                >
                  *
                </span>
              </label>
              <label className="sub-label" style={{ paddingTop: "4px" }}>
                Please select your name
              </label>
              <Select
                style={{ width: "100%" }}
                options={[
                  "Fred Herbert",
                  "Alexander Pandya",
                  "Alex Zhou",
                  "Lorenzo Sani",
                ]}
                allowMultiple={false}
                selected={data.onboardedBy}
                onChange={(value) => {
                  setData({ ...data, onboardedBy: value });
                }}
              />
            </div>
            <div style={{ flex: 2, marginLeft: "16px" }}>
              <label className="main-label">
                Autopilot?
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    verticalAlign: "super",
                  }}
                >
                  *
                </span>
              </label>
              <label className="sub-label" style={{ paddingTop: "4px" }}>
                Add branches first
              </label>
              <Select
                style={{ width: "100%" }}
                options={["Yes", "No"]}
                allowMultiple={false}
                selected={data.branches?.[0]?.optOut ? "Yes" : "No"}
                onChange={(value) => {
                  setData({
                    ...data,
                    branches: data.branches.map((b) => ({
                      ...b,
                      optOut: value === "Yes",
                    })),
                  });
                }}
              />
            </div>
            <div style={{ flex: 4, marginLeft: "16px" }}>
              <label className="main-label">Start Sending Candidates</label>
              <DatePicker
                showFieldNames
                defaultValue={
                  data.branches.length ? data.branches[0].pausedUntil : null
                }
                onChange={(d) => {
                  moment(d).format() !== "Invalid date" &&
                    setData({
                      ...data,
                      branches: data.branches.map((b) => ({
                        ...b,
                        pausedUntil: moment(d).format(),
                      })),
                    });
                }}
              ></DatePicker>
            </div>
          </div>
          <div className="row-all-devices" style={{ marginTop: "24px" }}>
            <input
              type="checkbox"
              style={{ marginTop: "4px" }}
              checked={!data.passivelyLooking}
              onChange={(e) =>
                setData({
                  ...data,
                  passivelyLooking: !e.target.checked,
                })
              }
            />
            &nbsp;
            <p>
              Are they actively hiring right now?
              <span
                style={{
                  color: "red",
                  fontSize: "11px",
                  verticalAlign: "super",
                  marginLeft: "4px",
                }}
              >
                *
              </span>
            </p>
          </div>
          <div className="row">
            {data.branches.length > 0 && (
              <div style={{ width: "100%" }}>
                <hr style={{ margin: "50px 0" }} />
                <button
                  className="button"
                  style={{
                    marginRight: "10px",
                    transform: confirmed ? "scale(1.1)" : "",
                  }}
                  onClick={(e) => {
                    !confirmed ? setConfirmed(true) : submit();
                  }}
                >
                  {confirmed ? "Confirm " : "Submit "}
                  <span
                    className="icon-small"
                    style={{
                      display: "inline-block",
                      fontSize: "20px",
                      lineHeight: "20px",
                      height: "20px",
                      width: "15px",
                      position: "relative",
                      top: "4px",
                    }}
                  >
                    {confirmed ? "check" : "keyboard_arrow_right_icon"}
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployerOnboardingForm;
