import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobile } from "react-device-detect";
import { getOnboardingData } from "../backend";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment-timezone";
import { MoonLoader } from "react-spinners";
import { getEmployerSecret } from "../utils";

let colours = { "Fred Herbert": "#3788d8", "Alexander Pandya": "#EE7621" };

const SalesTracker = () => {
  const [events, setEvents] = useState();

  const { isAuthenticated, isLoading, loginWithRedirect, logout, error } =
    useAuth0();

  if (error) {
    logout({ returnTo: window.location.origin });
  }

  useEffect(() => {
    const getData = async () => {
      let branches = await getOnboardingData();
      setEvents(
        branches
          .filter((b) => b.created !== undefined)
          .map((b) => ({
            id: b.id,
            title: b.name,
            start: moment(b.created).toDate(),
            url: `https://hirebolt.com/${b.employerId}/${getEmployerSecret(
              b.employerId
            )}`,
            backgroundColor: colours[b.onboardedBy],
            borderColor: colours[b.onboardedBy],
          }))
      );
    };
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ redirectUri: window.location.href });
    } else if (isAuthenticated) {
      getData();
    }
    //eslint-disable-next-line
  }, [isLoading]);

  return (
    <div
      style={{
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh",
        backgroundColor: "#f2f0ed",
        display: "flex",
      }}
    >
      {isAuthenticated && events ? (
        <div
          style={{
            margin: "auto",
            padding: "32px",
            height: "90vh",
            flex: 1,
            maxWidth: isMobile ? "100vw" : "1000px",
          }}
        >
          <FullCalendar
            plugins={[dayGridPlugin, listPlugin]}
            initialView={isMobile ? "listWeek" : "dayGridMonth"}
            titleFormat={
              isMobile ? { month: "short", day: "numeric" } : { month: "long" }
            }
            headerToolbar={
              isMobile
                ? {
                    left: "prev,next",
                    right: "title",
                  }
                : {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,dayGridWeek",
                  }
            }
            weekends
            defaultAllDay
            listDaySideFormat={{
              month: "short",
              day: "numeric",
            }}
            height="100%"
            events={events}
            eventContent={renderEventContent}
          />
          <div className="row-all-devices">
            {Object.keys(colours).map((key) => (
              <div style={{ margin: "16px 16px 0 0" }}>
                <span
                  style={{
                    border: `5px solid ${colours[key]}`,
                    borderRadius: "5px",
                    boxSizing: "content-box",
                    display: "inline-block",
                    height: 0,
                    width: 0,
                    margin: "0 8px 0 0",
                  }}
                >
                  {" "}
                </span>
                {key}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
          }}
        >
          <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
        </div>
      )}
    </div>
  );
};

const renderEventContent = (eventInfo) => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <a
        href={eventInfo.event.url}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none" }}
      >
        <i
          style={
            isMobile
              ? {}
              : {
                  color: "white",
                  fontSize: "12px",
                  fontStyle: "normal",
                  margin: "0 8px",
                }
          }
        >
          {eventInfo.event.title}
        </i>
      </a>
    </div>
  );
};

export default SalesTracker;
