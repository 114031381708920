import thumbsUpAnimation from "../Components/thumbsUpAnimation.json";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState, useCallback } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import {
  InterviewScheduler,
  Modal,
  NotInterestedInput,
  EmployersMap,
} from "../Components";
import { Slide } from "react-slideshow-image";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie-player";
import { debounce } from "lodash";
import { MoonLoader } from "react-spinners";
import {
  abTesting,
  wait,
  getJobType,
  validateCandidateAvailability,
  formatCandidateAvailability,
  getInterviewType,
} from "../utils";
import { Line } from "rc-progress";
import ReactStars from "react-stars";
import moment from "moment-timezone";
import {
  createNewCandidate,
  getInterviewSlots,
  getOpportunitiesById,
  recordCandidateInterested,
  updateCandidate,
} from "../backend";
import { Availability } from "../Components/Forms";

const CandidateOnboardingOpportunities = () => {
  const navigate = useNavigate();
  //eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const candidateId = searchParams.get("id");
  const [totalOpportunities, setTotalOpportunities] = useState();
  const [employers, setEmployers] = useState(isMobile ? [] : [{}, {}]);
  const [candidate, setCandidate] = useState();
  const [interestingEmployers, setIntererestingEmployers] = useState({});
  const [notInterestedEmployer, setNotInterestedEmployer] = useState();
  const [selectedEmployer, setSelectedEmployer] = useState();
  const [confirmedEmployer, setConfirmedEmployer] = useState();
  const [blockedSlots, setBlockedSlots] = useState([]);
  const [pageShown, setPageShown] = useState(false);
  const [feedbackModalShown, setFeedbackModalShown] = useState(false);
  const [moreInfoShown, setMoreInfoShown] = useState(isMobile);
  const [imageHeight, setImageHeight] = useState(null);
  const [optOutPicks, setOptOutPicks] = useState(0);
  const [candidateAvailability, setCandidateAvailability] = useState(null);
  const [showMap, setShowMap] = useState(false);

  const showNextEmployer = () => {
    // Put the first employer at the end of the list
    let newEmployers = [...employers];
    let ie = { ...interestingEmployers, [newEmployers[0].id]: "" };
    saveInterest({ candidate, interestingEmployers: ie });
    setIntererestingEmployers(ie);
    newEmployers.push(newEmployers.shift());
    setEmployers(newEmployers);
  };

  const showPreviousEmployer = () => {
    // Put the last employer at the start of the list
    let newEmployers = [...employers];
    let ie = { ...interestingEmployers, [newEmployers[0].id]: "" };
    saveInterest({ candidate, interestingEmployers: ie });
    setIntererestingEmployers(ie);
    newEmployers.unshift(newEmployers.pop());
    setEmployers(newEmployers);
  };

  const hasUpcomingAvailability = (candidate) => {
    if (!candidate || !candidate["Availability"]) return false;
    let availability = Object.keys(candidate["Availability"]).map((key) => ({
      date: key,
      slots: candidate["Availability"][key],
    }));
    let upcomingAvailability = availability.filter(
      (day) =>
        moment(day.date).isAfter(moment().add(36, "hours")) && day.slots.length
    );
    return upcomingAvailability.length > 0;
  };

  const div = useCallback(async (node) => {
    if (node !== null) {
      // Loop so it run this code every 200ms for 10 times
      for (let i = 0; i < 3; i++) {
        setImageHeight(node.getBoundingClientRect().height);
        await wait(500);
      }
    }
  }, []);

  useEffect(() => {
    const fetchOpportunities = async () => {
      if (state?.candidate) {
        let { candidate, opportunities } = await createNewCandidate(
          state.candidate
        );
        if (opportunities.length === 0)
          navigate(
            `/candidate-dashboard?id=${candidate["Id"]}&show=no-opportunities`
          );
        setEmployers(opportunities);
        setCandidate(candidate);
        setTotalOpportunities(opportunities.length);
      } else if (candidateId) {
        let { candidate, opportunities } = await getOpportunitiesById(
          candidateId
        );
        if (opportunities.length === 0)
          navigate(
            `/candidate-dashboard?id=${candidate["Id"]}&show=no-opportunities`
          );
        setEmployers(opportunities);
        setCandidate(candidate);
        setTotalOpportunities(opportunities.length);
      }
    };
    fetchOpportunities();
    // Show alert message if the user tries to leave
    window.addEventListener("beforeunload", showAlert);
    return () => {
      window.removeEventListener("beforeunload", showAlert);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPageShown(true);
    if (candidate) saveInterest({ candidate, interestingEmployers });
    // If there are no more employers, go to the success page
    if (employers.length < 1 && candidate)
      navigate(
        Object.keys(interestingEmployers).filter(
          (key) => interestingEmployers[key]
        ).length > 0
          ? "/candidate-success"
          : "/candidate-success?id=" + candidate["Id"]
      );
    //eslint-disable-next-line
  }, [interestingEmployers]);

  const showAlert = useCallback((e) => {
    e.preventDefault();
    e.returnValue = "";
  }, []);

  useEffect(() => {
    if (employers.length < 2)
      window.removeEventListener("beforeunload", showAlert);
  }, [employers, showAlert]);

  //eslint-disable-next-line
  let saveInterest = useCallback(
    debounce(
      (args) => {
        recordCandidateInterested(args);
      },
      2000,
      {
        trailing: true,
        leading: false,
      }
    ),
    []
  );

  const parseInterviewDuration = (duration) => {
    if (duration <= 60) return `${duration} minutes`;
    return `${Math.floor(duration / 60)}${
      Math.floor(duration % 60) > 0 ? `.${Math.floor(duration % 60)}` : ""
    } hours`;
  };

  const getCommonPosition = (candidate, employer) => {
    return employer.position;
  };

  const getSalaryFormatted = (candidate, employer) => {
    let role = employer.position;
    return role.minSalary && role.minSalary >= 10
      ? "£" + role.minSalary + "-" + role.maxSalary + "/hr"
      : role.maxSalary
      ? ["£" + role.maxSalary + "/hr"]
      : ["-"];
  };

  const setInterested = (
    interested,
    employer,
    interviewTimestamp = null,
    duration = null,
    candidateAvailability = null
  ) => {
    // Go to next employer
    let e = JSON.parse(JSON.stringify(employers));
    e.shift();
    setEmployers(e);
    setMoreInfoShown(isMobile);
    let ie = JSON.parse(JSON.stringify(interestingEmployers));
    if (interested) {
      // Record either the interview time or the candidate availability
      ie[employer.id] = hasUpcomingAvailability(candidate)
        ? candidate["Availability"]
        : interviewTimestamp ?? candidateAvailability;
      setIntererestingEmployers(ie);
      // If the candidate has selected 2 optOut employers, go to the success page
      if (optOutPicks + 1 === 2) navigate("/candidate-success");
      if (employer.optOut) setOptOutPicks(optOutPicks + 1);
      // If candidate has selected an interview time, block that time slot
      if (interviewTimestamp) {
        let bs = JSON.parse(JSON.stringify(blockedSlots));
        bs.push({
          timestamp: interviewTimestamp,
          duration: parseInt(duration) + 60,
        });
        setBlockedSlots(bs);
      }
    } else if (!interested) {
      ie[employer.id] = "";
      setIntererestingEmployers(ie);
      // If candidate said no to 3 employers, ask why
      if (Object.keys(ie).filter((key) => !ie[key]).length === 3)
        setFeedbackModalShown(true);
      if (e.length >= 1) setNotInterestedEmployer(employer);
    }
  };

  const scheduleInterview = (
    employer,
    interviewTimestamp,
    duration,
    candidateAvailability = null
  ) => {
    setInterested(
      true,
      employer,
      interviewTimestamp,
      duration,
      candidateAvailability
    );
    setConfirmedEmployer(employer);
  };

  return (
    <div
      style={{
        backgroundColor: "#f2f0ed",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        className="navbar-component w-nav"
        style={{
          width: "100vw",
          backgroundColor: "#f2f0ed",
          position: "absolute",
          top: 0,
        }}
      >
        <div className="navbar-container">
          <a href={`/candidates`} className="navbar-logo-link w-nav-brand">
            <img
              src="images/hirebolt-logo-black.png"
              loading="lazy"
              width="130"
              alt=""
              className="shopscribe-logo"
              style={{
                filter:
                  "invert(13%) sepia(57%) saturate(562%) hue-rotate(158deg) brightness(94%) contrast(91%)",
              }}
            />
          </a>
        </div>
      </div>
      {notInterestedEmployer &&
        Object.keys(interestingEmployers).length > 0 && (
          <Modal
            isOpen={feedbackModalShown}
            closeModal={() => setFeedbackModalShown(false)}
            style={{ backgroundColor: "#f2f0ed", zIndex: "999999999" }}
          >
            <div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "24px",
                  padding: "24px",
                  marginTop: "40px",
                }}
              >
                <p
                  style={{
                    fontSize: "smaller",
                    fontWeight: "bold",
                    marginBottom: "-2px",
                  }}
                >
                  <span
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    {isMobile && notInterestedEmployer.employerName}
                  </span>
                  {isMobile && notInterestedEmployer.name && (
                    <a
                      className="icon-small"
                      href={`https://www.google.com/search?q=${notInterestedEmployer.name.replace(
                        " ",
                        "+"
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        fontWeight: "normal",
                        display: "inline-block",
                        fontSize: "16px",
                        height: "16px",
                        position: "relative",
                        marginTop: "-6px",
                        top: "3px",
                        pointer: "cursor",
                        left: "8px",
                      }}
                    >
                      launch
                    </a>
                  )}
                </p>
                <p
                  style={{
                    fontSize: "1.25rem",
                    color: "#162e3c",
                    fontFamily: "Fraunces",
                  }}
                >
                  <span>
                    <span
                      style={{
                        fontSize: isMobile ? "1.25rem" : "1.5rem",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        getCommonPosition(candidate, notInterestedEmployer)
                          ?.name
                      }
                    </span>
                    {!isMobile && ` - ${notInterestedEmployer.employerName}`}
                  </span>
                </p>
                <p>
                  {getSalaryFormatted(candidate, notInterestedEmployer)} -{" "}
                  {(candidate["Nearby Employers"][notInterestedEmployer.id] ??
                    `${candidate["Max Commute"]}`) + " min commute"}
                </p>
              </div>
              <br />
              <NotInterestedInput
                candidate={candidate}
                employerId={notInterestedEmployer.id}
              />
            </div>
          </Modal>
        )}
      {selectedEmployer && (
        <Modal
          isOpen={selectedEmployer != null}
          closeModal={() => setSelectedEmployer(null)}
          style={{
            zIndex: Number.MAX_SAFE_INTEGER,
            padding: "16px",
            maxWidth: "750px",
            marginRight: isMobile ? "-40%" : "auto",
            backgroundColor: "#f2f0ed",
            overflow: "scroll",
          }}
        >
          <div>
            {confirmedEmployer?.name !== selectedEmployer?.name && (
              <div>
                <h5 style={{ marginTop: "32px" }}>
                  When can you attend the{" "}
                  {getInterviewType(
                    getCommonPosition(candidate, selectedEmployer)
                  ).toLowerCase()}{" "}
                  at {selectedEmployer.employerName}?
                </h5>
                <p style={{ fontSize: "smaller", marginBottom: "24px" }}>
                  <span
                    className="icon-small"
                    style={{
                      fontSize: "16px",
                      height: "16px",
                      position: "relative",
                      marginTop: "-6px",
                      top: "3px",
                      pointer: "cursor",
                      display: "inline",
                      marginRight: "4px",
                    }}
                  >
                    location_on
                  </span>
                  <a
                    href={`http://maps.google.com/?q=${selectedEmployer.name}, ${selectedEmployer.address}`.replace(
                      / /g,
                      "+"
                    )}
                    target={"_blank"}
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#162e3c",
                      borderBottom: "1px solid",
                    }}
                  >
                    {selectedEmployer.address}
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "smaller",
                    color: "#d00000",
                  }}
                >
                  <img
                    src="/images/exclamation-mark.svg"
                    alt=""
                    width="15"
                    height="15"
                    style={{
                      marginTop: "-3px",
                      marginRight: "4px",
                      color: "red",
                    }}
                  />{" "}
                  {abTesting(
                    candidate["WhatsApp Number"],
                    "candidate-profile",
                    2
                  )
                    ? `If you cancel or no-show interviews, this will be visible to all employers on your public profile.`
                    : `A max of 2
                    cancellations/no-shows are allowed, after which you'll stop
                    receiving further job opportunities.`}
                </p>
              </div>
            )}
            {selectedEmployer.interviewSlots ? (
              <>
                <div
                  style={{
                    backgroundColor:
                      selectedEmployer === confirmedEmployer
                        ? "transparent"
                        : "white",
                    borderRadius: "24px",
                    padding: "0 8px",
                    margin: "16px 0",
                  }}
                >
                  <InterviewScheduler
                    employer={selectedEmployer}
                    role={getCommonPosition(candidate, selectedEmployer)}
                    sendInterviewConfirmation={(timeStamp, duration) =>
                      scheduleInterview(selectedEmployer, timeStamp, duration)
                    }
                  />
                </div>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  maxWidth: "532px",
                  height: "500px",
                  display: "flex",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    margin: "auto",
                    display: "flex",
                  }}
                >
                  <MoonLoader
                    color={"rgb(22, 46, 60)"}
                    loading={true}
                    size={75}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      {showMap && (
        <Modal
          isOpen={showMap}
          closeModal={() => setShowMap(false)}
          style={{
            zIndex: Number.MAX_SAFE_INTEGER,
            padding: 0,
          }}
          overlayStyle={{ padding: 0 }}
        >
          <div>
            <EmployersMap
              candidate={candidate}
              employers={employers}
              showEmployer={(id) => {
                setShowMap(false);
                // Put the employer whose id is provided at the top of the list
                const employer = employers.find((e) => e.id === id);
                const newEmployers = employers.filter((e) => e.id !== id);
                newEmployers.unshift(employer);
                setEmployers(newEmployers);
              }}
            />
          </div>
        </Modal>
      )}
      <div
        style={{
          height: "100vh",
          width: "100vw",
          opacity: pageShown ? 1 : 0,
          transition: "opacity .15s cubic-bezier(0.5,0.25,0,1)",
          position: "absolute",
          top: 0,
          display: "flex",
          padding: isMobile ? "50px 0 0" : 0,
          flexDirection: isMobile ? "column" : "row",
          overflow: "hidden",
          justifyContent: "center",
        }}
      >
        {(!candidate ||
          hasUpcomingAvailability(candidate) ||
          candidate["Passively Looking"]) && (
          <>
            <div
              style={{
                textAlign: "center",
                padding: isMobile ? "0 24px" : "0 0 150px 24px",
                width: isMobile ? "100%" : "55%",
                minWidth: isMobile ? "100%" : "55%",
                margin: isMobile ? 0 : "auto",
              }}
            >
              {candidate && !isMobile && (
                <Lottie
                  animationData={thumbsUpAnimation}
                  loop
                  play
                  style={{
                    width: "200px",
                    height: "200px",
                    margin: "35px auto 0",
                  }}
                />
              )}
              {!candidate && (
                <div
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    margin: isMobile ? "32px auto" : "80px auto 48px",
                    display: "flex",
                    paddingTop: 0,
                  }}
                >
                  <MoonLoader
                    color={"rgb(22, 46, 60)"}
                    loading={true}
                    size={75}
                  />
                </div>
              )}
              <p
                style={{
                  fontSize: "smaller",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  marginTop: !isMobile
                    ? 0
                    : employers && employers[0]?.name
                    ? 0
                    : "50px",
                }}
              >
                {isMobile ? "" : "Top employers in London"}
              </p>
              {employers && employers[0]?.name ? (
                <div>
                  <h4
                    style={
                      isMobile
                        ? {
                            fontFamily: "Spacegrotesk, sans-serif",
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "gray",
                            marginBottom: "8px",
                            position: "absolute",
                            top: "9vh",
                            width: "87.5vw",
                            textAlign: "center",
                          }
                        : {
                            fontFamily: "Spacegrotesk, sans-serif",
                            fontWeight: "bold",
                          }
                    }
                  >
                    Here's the perfect roles for you,
                    <br />
                    based on your experience!
                  </h4>
                  {!isMobile && <br />}
                  {!isMobile && (
                    <p style={{ fontSize: "16px" }}>
                      Say Yes/No to the following opportunities, in order to be
                      considered.
                    </p>
                  )}
                  {!isMobile && totalOpportunities && (
                    <div
                      style={{
                        width: "60%",
                        maxWidth: "250px",
                        margin: "24px auto",
                      }}
                    >
                      <Line
                        percent={
                          (100 * (totalOpportunities - employers.length)) /
                            totalOpportunities +
                          5
                        }
                        strokeWidth={3}
                        trailWidth={3}
                        strokeColor="rgb(23,62,144)"
                      />
                    </div>
                  )}
                  <button
                    className="button w-button button-primary"
                    style={{
                      padding: isMobile ? "0 16px 4px" : "4px 16px 8px",
                      position: isMobile ? "absolute" : "relative",
                      top: isMobile ? "16px" : "0",
                      right: isMobile ? "8px" : "0",
                      zIndex: 100000,
                    }}
                    onClick={() => setShowMap(!showMap)}
                  >
                    Show Map{" "}
                    <span
                      style={{
                        fontSize: "18px",
                        width: "fit-content",
                        height: "16px",
                        margin: "0",
                        display: "inline",
                        position: "relative",
                        top: "4px",
                      }}
                      className="icon-small"
                    >
                      map
                    </span>
                  </button>
                </div>
              ) : (
                <div>
                  <div>
                    <h4 style={{ fontFamily: "Spacegrotesk, sans-serif" }}>
                      Finding the best job opportunities for you...
                    </h4>
                    <br />
                    <p style={{ fontSize: "16px" }}>
                      You'll be able to pick the ones you want to be considered
                      for!
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div
              id="opportunities"
              className="row-all-devices"
              style={{
                margin: isMobile ? "8px" : "auto 0px",
                display: "flex",
                maxWidth: "800px",
              }}
            >
              {employers &&
                employers.map((employer, i) => {
                  return (
                    <div
                      className="hide-scrollbar"
                      style={
                        isMobile
                          ? {
                              width: "90vw",
                              minWidth: "90vw",
                              maxWidth: "90vw",
                              height: "fit-content",
                              opacity: i === 0 ? 1 : i === 1 ? 0.33 : 0,
                              pointerEvents: i === 0 ? "all" : "none",
                              position: "absolute",
                              left: 2.5 + i * 5 + "vw",
                              top: 17 + i * 3.5 + "vh",
                              zIndex: 100 - i,
                              filter:
                                i > 0 ? `grayscale(${100 - i * 25}%)` : "none",
                              overflowY: i > 0 ? "hidden" : "inherit",
                              display: "flex",
                            }
                          : {
                              width: "85%",
                              minWidth: "85%",
                              maxWidth: "85%",
                              marginTop: 0,
                              height: "fit-content",
                              opacity: i === 0 ? 1 : 0.5,
                              pointerEvents: i === 0 ? "all" : "none",
                              display: "flex",
                            }
                      }
                    >
                      {candidate && employers.length && i === 0 && (
                        <div
                          style={{
                            borderRadius: "100%",
                            backgroundColor: "white",
                            padding: "8px",
                            cursor: "pointer",
                            marginTop: isMobile ? "75%" : "47.5%",
                            left: isMobile ? "0" : "auto",
                            position: isMobile ? "absolute" : "relative",
                            width: "fit-content",
                            height: "fit-content",
                            zIndex: "3000",
                          }}
                          onClick={() => {
                            showPreviousEmployer();
                          }}
                        >
                          <div
                            className="icon-small"
                            style={{
                              position: "relative",
                              display: "block",
                              width: "32px",
                              maxWidth: "32px",
                              height: "32px",
                              top: "-7px",
                            }}
                          >
                            keyboard_arrow_left_icon
                          </div>
                        </div>
                      )}
                      <AnimatePresence>
                        <motion.div
                          key={employer.id}
                          style={{
                            backgroundColor: "white",
                            borderRadius: "24px",
                            flexGrow: 1,
                            display: isMobile ? "block" : "flex",
                            flexDirection: "column",
                            height: isMobile ? "69vh" : "80vh",
                            minHeight: isMobile ? "69vh" : "500px",
                            maxHeight: isMobile ? "69vh" : "1000px",
                            overflowY: isMobile ? "scroll" : "hidden",
                            margin: "0 16px",
                            position: "relative",
                            justifyContent: "flex-end",
                          }}
                          initial={{
                            x:
                              employers.length === totalOpportunities
                                ? 0
                                : window.innerWidth - 50,
                          }}
                          animate={{
                            x: 0,
                          }}
                        >
                          {employer.name && (
                            <>
                              {employer?.pictures?.[0] ? (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "33%",
                                    minHeight: "33%",
                                    borderTopLeftRadius: "24px",
                                    borderTopRightRadius: "24px",
                                    opacity: ".9",
                                    zIndex: "2000",
                                    display: "block",
                                  }}
                                  ref={i === 0 ? div : null}
                                >
                                  <Slide
                                    autoplay={false}
                                    transitionDuration={300}
                                    nextArrow={i === 0 ? null : <></>}
                                  >
                                    {employer.pictures.map(
                                      (slideImage, index) => (
                                        <div key={index}>
                                          <div
                                            style={{
                                              height: imageHeight + "px",
                                              alignItems: "center",
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                              backgroundImage: `url(${slideImage})`,
                                            }}
                                          ></div>
                                        </div>
                                      )
                                    )}
                                  </Slide>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "33%",
                                    borderTopLeftRadius: "24px",
                                    borderTopRightRadius: "24px",
                                    opacity: ".9",
                                    zIndex: "2000",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundImage:
                                      "url(/images/pexels-rodnae-productions-4921154-p-1600.jpg)",
                                  }}
                                ></div>
                              )}
                            </>
                          )}
                          <div
                            style={{
                              padding: isMobile
                                ? "16px 32px 0"
                                : "24px 32px 0 32px",
                              width: "100%",
                              flex: 1,
                              minWidth: "100%",
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "smaller",
                                  fontWeight: "bold",
                                  marginBottom: "-2px",
                                }}
                              >
                                <span
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {isMobile && employer.employerName}
                                </span>
                                {isMobile && employer.name && (
                                  <a
                                    className="icon-small"
                                    href={`https://www.google.com/search?q=${employer.name.replace(
                                      " ",
                                      "+"
                                    )}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      fontWeight: "normal",
                                      display: "inline-block",
                                      fontSize: "16px",
                                      height: "16px",
                                      position: "relative",
                                      marginTop: "-6px",
                                      top: "3px",
                                      pointer: "cursor",
                                      left: "8px",
                                    }}
                                  >
                                    launch
                                  </a>
                                )}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "1.25rem",
                                    color: "#162e3c",
                                    fontFamily: "Fraunces",
                                  }}
                                >
                                  {employer.employerName ? (
                                    <span>
                                      <span
                                        style={{
                                          fontSize: isMobile
                                            ? "1.75rem"
                                            : "1.5rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {
                                          getCommonPosition(candidate, employer)
                                            ?.name
                                        }
                                      </span>
                                      {getCommonPosition(candidate, employer)
                                        ?.name.length <= 17 &&
                                        employer.employerName.length <= 17 &&
                                        !isMobile &&
                                        ` - ${employer.employerName}`}
                                      {(getCommonPosition(candidate, employer)
                                        ?.name.length > 17 ||
                                        employer.employerName.length > 17) &&
                                        !isMobile && (
                                          <span>
                                            <br />
                                            {`${employer.employerName}`}
                                          </span>
                                        )}
                                    </span>
                                  ) : (
                                    <Skeleton />
                                  )}
                                </p>
                                {!isMobile && moreInfoShown && (
                                  <div
                                    style={{
                                      borderRadius: "100%",
                                      backgroundColor: "white",
                                      zIndex: 2000,
                                      cursor: "pointer",
                                      position: "absolute",
                                      right: "47%",
                                      marginTop: "-32px",
                                    }}
                                    onClick={() => {
                                      setMoreInfoShown(false);
                                    }}
                                  >
                                    <div
                                      className="icon-small"
                                      style={{
                                        position: "relative",
                                        display: "block",
                                        width: "32px",
                                        height: "32px",
                                        top: "-5px",
                                      }}
                                    >
                                      keyboard_arrow_down_icon
                                    </div>
                                  </div>
                                )}
                                {employer.googleRating &&
                                  typeof employer.googleRating !== "object" && (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: isMobile ? "-18px" : "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        window.open(
                                          `https://www.google.com/search?q=${(
                                            employer.name +
                                            " " +
                                            employer.address
                                          ).replace(" ", "+")}`
                                        );
                                      }}
                                    >
                                      <div
                                        style={
                                          isMobile
                                            ? {
                                                marginTop: "1px",
                                                cursor: "pointer",
                                                position: "absolute",
                                                display: "flex",
                                                flexDirection: "row",
                                                marginLeft: "-60px",
                                              }
                                            : {
                                                marginTop: "1px",
                                                cursor: "pointer",
                                              }
                                        }
                                      >
                                        <ReactStars
                                          count={5}
                                          value={employer.googleRating}
                                          edit={false}
                                          size={12}
                                          color2={"#b2afa6"}
                                          color1={"#e1e0e0"}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          fontWeight: "700",
                                          opacity: "0.7",
                                          marginLeft: "4px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {employer.googleRating + ""}
                                      </div>
                                      {!isMobile && (
                                        <div
                                          className="icon-small"
                                          style={{
                                            fontWeight: "bold",
                                            textDecoration: "none",
                                            display: "inline-block",
                                            fontSize: "12px",
                                            width: "12px",
                                            height: "12px",
                                            position: "relative",
                                            pointer: "cursor",
                                            left: "8px",
                                            opacity: "0.66",
                                            top: "2px",
                                          }}
                                        >
                                          launch
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </div>
                              <p
                                style={
                                  isMobile
                                    ? {
                                        fontSize: "12px",
                                        lineHeight: "1.1rem",
                                        marginBottom: "32px",
                                      }
                                    : {
                                        fontSize: "smaller",
                                        marginBottom: "32px",
                                      }
                                }
                              >
                                {(employer.description &&
                                employer.description.length >
                                  (isMobile ? 150 : 275)
                                  ? employer.description.slice(
                                      0,
                                      isMobile ? 150 : 275
                                    ) + "..."
                                  : employer.description) || <Skeleton />}
                              </p>
                              {employer.openPositions ? (
                                <div>
                                  <p
                                    style={{
                                      color: "#162e3c",
                                      fontWeight: "bold",
                                      fontSize: isMobile ? "18px" : "20px",
                                      lineHeight: "24px",
                                      marginBottom: "0",
                                      fontFamily: "Fraunces",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "normal",
                                        display: "inline-block",
                                        width: isMobile ? "32px" : "40px",
                                        fontSize: isMobile ? "20px" : "24px",
                                        position: "relative",
                                        top: isMobile ? "0" : "2px",
                                      }}
                                    >
                                      {" "}
                                      💰
                                    </span>
                                    {getSalaryFormatted(
                                      candidate,
                                      employer
                                    ) || <Skeleton />}
                                    <span style={{ fontSize: "smaller" }}>
                                      {getSalaryFormatted(candidate, employer)
                                        ? ` - ${getJobType(
                                            getCommonPosition(
                                              candidate,
                                              employer
                                            )
                                          )}`
                                        : ""}
                                    </span>
                                  </p>
                                  <p
                                    style={
                                      isMobile
                                        ? {
                                            fontSize: "12px",
                                            lineHeight: "1.1rem",
                                            paddingLeft: "32px",
                                            marginBottom: "8px",
                                          }
                                        : {
                                            fontSize: "smaller",
                                            paddingLeft: "40px",
                                            marginBottom: "8px",
                                          }
                                    }
                                  >
                                    {getCommonPosition(candidate, employer)
                                      ?.salaryFrequency
                                      ? `Including service. Salary is paid ${getCommonPosition(
                                          candidate,
                                          employer
                                        )?.salaryFrequency.toLowerCase()}`
                                      : `Including service`}
                                  </p>
                                  <p
                                    style={{
                                      color: "#162e3c",
                                      fontFamily: "Fraunces",
                                      fontWeight: "bold",
                                      fontSize: isMobile ? "18px" : "20px",
                                      lineHeight: "24px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: "normal",
                                          display: "inline-block",
                                          width: isMobile ? "32px" : "40px",
                                          fontSize: isMobile ? "20px" : "24px",
                                          position: "relative",
                                          top: isMobile ? "0" : "2px",
                                        }}
                                      >
                                        🚌
                                      </span>
                                      {(candidate["Nearby Employers"][
                                        employer.id
                                      ] ?? `>${candidate["Max Commute"]}`) +
                                        " min"}
                                    </span>
                                  </p>
                                  <p
                                    style={
                                      isMobile
                                        ? {
                                            fontSize: "12px",
                                            lineHeight: "1.1rem",
                                            paddingLeft: "32px",
                                            marginBottom: "8px",
                                          }
                                        : {
                                            fontSize: "smaller",
                                            paddingLeft: "40px",
                                            marginBottom: "8px",
                                          }
                                    }
                                  >
                                    Commute to{" "}
                                    <a
                                      href={`http://maps.google.com/?q=${employer.employerName} (${employer.branchName}), ${employer.address}`.replace(
                                        / /g,
                                        "+"
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                        fontWeight: "normal",
                                        textDecoration: "none",
                                        borderBottom: "1px solid #162e3c",
                                        opacity: 0.8,
                                      }}
                                    >
                                      {employer.address
                                        .split(",")
                                        [
                                          employer.address.split(",").length - 2
                                        ].trim()}
                                    </a>
                                  </p>
                                  {employer.benefits && (
                                    <>
                                      <p
                                        style={{
                                          color: "#162e3c",
                                          fontFamily: "Fraunces",
                                          fontWeight: "bold",
                                          fontSize: isMobile ? "18px" : "20px",
                                          lineHeight: "24px",
                                          marginBottom: "0",
                                        }}
                                      >
                                        <span>
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                              display: "inline-block",
                                              width: isMobile ? "32px" : "40px",
                                              fontSize: isMobile
                                                ? "20px"
                                                : "24px",
                                              position: "relative",
                                              top: isMobile ? "0" : "2px",
                                            }}
                                          >
                                            🎁
                                          </span>
                                          {employer.benefits.slice(0, 5).length}{" "}
                                          Benefits
                                        </span>
                                      </p>
                                      <p
                                        style={
                                          isMobile
                                            ? {
                                                fontSize: "12px",
                                                lineHeight: "1.1rem",
                                                paddingLeft: "32px",
                                                marginBottom: "8px",
                                              }
                                            : {
                                                fontSize: "smaller",
                                                paddingLeft: "40px",
                                                marginBottom: "8px",
                                              }
                                        }
                                      >
                                        {employer.benefits
                                          .filter((b) => b !== "" && b !== " ")
                                          .slice(0, 5)
                                          .join(" - ")}
                                      </p>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <Skeleton count={2} height="24px" />
                              )}
                            </div>
                            {moreInfoShown && i === 0 ? (
                              <div
                                id="more-info"
                                style={{ marginBottom: "24px" }}
                              >
                                {employer.openPositions && (
                                  <div style={{ marginTop: "24px" }}>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "smaller",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      NEXT STEPS
                                    </p>
                                    <p style={{ fontSize: "smaller" }}>
                                      In person{" "}
                                      {(
                                        employer.interviewType ??
                                        employer.openPositions[0]
                                          .interviewType ??
                                        "interview/trial"
                                      ).toLowerCase()}{" "}
                                      {(employer.openPositions[0]
                                        .roleInterviewSlots ||
                                        employer.interviewSlots) &&
                                        `for ${parseInterviewDuration(
                                          parseInt(
                                            employer.openPositions[0]
                                              .roleInterviewSlots
                                              ? employer.openPositions[0]
                                                  .roleInterviewSlots[0]
                                                  .duration
                                              : employer.interviewSlots[0]
                                                  .duration
                                          )
                                        )} `}
                                      {(employer.trialPaid ??
                                        employer.openPositions[0].trialPaid) ===
                                      "No"
                                        ? "(not paid)"
                                        : (employer.trialPaid ??
                                            employer.openPositions[0]
                                              .trialPaid) === "Yes"
                                        ? "(paid)"
                                        : "(paid, if hired)"}
                                    </p>
                                  </div>
                                )}
                                <div style={{ marginTop: "24px" }}>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "smaller",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    Other Info
                                  </p>
                                  {
                                    <p style={{ fontSize: "smaller" }}>
                                      {getCommonPosition(candidate, employer)
                                        ?.workingHours
                                        ? getCommonPosition(candidate, employer)
                                            ?.workingHours
                                        : `Standard working hours`}
                                      {getCommonPosition(candidate, employer)
                                        ?.additionalInfo?.length > 10
                                        ? ` - ${
                                            getCommonPosition(
                                              candidate,
                                              employer
                                            ).additionalInfo
                                          }`
                                        : ""}
                                    </p>
                                  }
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  marginTop: "24px",
                                  marginBottom: "8px",
                                }}
                              >
                                {employer.name && (
                                  <p
                                    style={{
                                      fontSize: "smaller",
                                      margin: "auto",
                                      fontWeight: "bold",
                                      opacity: "0.66",
                                      cursor: "pointer",
                                      width: "fit-content",
                                    }}
                                    onClick={() => {
                                      setMoreInfoShown(true);
                                    }}
                                  >
                                    See More Info
                                    <br />
                                    <span
                                      className="icon-small"
                                      style={{
                                        display: "inline-block",
                                        width: "16px",
                                        height: "10px",
                                        fontSize: "16px",
                                        position: "relative",
                                        margin: "0",
                                        top: "-7px",
                                      }}
                                    >
                                      keyboard_arrow_down_icon
                                    </span>
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                          {!isMobile && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                                fontWeight: "bold",
                                padding: "16px 0",
                                borderTop: "1px solid #efefef",
                                fontSize: "20px",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  padding: "0 16px",
                                }}
                                onClick={async () => {
                                  if (
                                    candidate["Passively Looking"] &&
                                    employers[0].openPositions
                                  ) {
                                    setSelectedEmployer({
                                      ...employers[0],
                                      interviewSlots: undefined,
                                    });
                                    employers[0].interviewSlots =
                                      await getInterviewSlots(
                                        employers[0].id,
                                        getCommonPosition(
                                          candidate,
                                          employers[0]
                                        )
                                          .name.replace(/[^a-zA-Z]/g, "")
                                          .toLowerCase()
                                      );
                                    setSelectedEmployer(
                                      JSON.parse(JSON.stringify(employers[0]))
                                    );
                                  } else {
                                    scheduleInterview(
                                      employers[0],
                                      null,
                                      null,
                                      candidateAvailability
                                    );
                                  }
                                }}
                              >
                                <span
                                  className="icon-small"
                                  style={{
                                    display: "inline-block",
                                    fontSize: "32px",
                                    width: "32px",
                                    lineHeight: "20px",
                                    height: "20px",
                                    position: "relative",
                                    top: "8px",
                                    color: "#03ac13",
                                  }}
                                >
                                  check
                                </span>{" "}
                                Yes
                              </div>
                              <div
                                style={{
                                  cursor: "pointer",
                                  padding: "0 16px",
                                }}
                                onClick={() => {
                                  if (employer.openPositions) {
                                    setInterested(false, employer);
                                  }
                                }}
                              >
                                <span
                                  className="icon-small"
                                  style={{
                                    display: "inline-block",
                                    fontSize: "32px",
                                    width: "32px",
                                    lineHeight: "20px",
                                    height: "20px",
                                    position: "relative",
                                    top: "8px",
                                    color: "red",
                                  }}
                                >
                                  close
                                </span>{" "}
                                No
                              </div>
                            </div>
                          )}
                        </motion.div>
                      </AnimatePresence>
                      {candidate && employers.length && i === 0 && (
                        <div
                          style={{
                            borderRadius: "100%",
                            backgroundColor: "white",
                            padding: "8px",
                            cursor: "pointer",
                            marginTop: isMobile ? "75%" : "47.5%",
                            right: isMobile ? "0" : "auto",
                            position: isMobile ? "absolute" : "relative",
                            height: "fit-content",
                            width: "fit-content",
                            zIndex: "3000",
                          }}
                          onClick={() => {
                            showNextEmployer();
                          }}
                        >
                          <div
                            className="icon-small"
                            style={{
                              position: "relative",
                              display: "block",
                              width: "32px",
                              maxWidth: "32px",
                              height: "32px",
                              top: "-7px",
                            }}
                          >
                            keyboard_arrow_right_icon
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </>
        )}
        {candidate &&
          !hasUpcomingAvailability(candidate) &&
          !candidate["Passively Looking"] && (
            <div
              style={{
                flexDirection: "column",
                height: "100%",
                justifyContent: isMobile ? "start" : "center",
                alignItems: "start",
                display: "flex",
                textAlign: "left",
                margin: "auto",
                overflow: "scroll",
                padding: isMobile ? "48px 16px" : 0,
              }}
            >
              <p
                style={{
                  fontSize: "smaller",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                One last thing, before job opportunities
              </p>
              <h4
                style={{
                  fontWeight: "bold",
                  margin: "0 0 8px",
                  color: "#162e3c",
                }}
              >
                When are you available to interview?
              </h4>
              <p
                style={{
                  fontSize: "smaller",
                  maxWidth: isMobile ? "80vw" : "50%",
                  margin: "16px 0",
                }}
              >
                {abTesting(candidate["WhatsApp Number"], "candidate-profile", 2)
                  ? `Please list 5+ hours. Interviews aren't finalized until you get confirmation from employers. If you cancel or no-show interviews, this will be visible to all employers on your public profile.`
                  : `Please list 5+ hours. Interview aren't finalized until you get
                confirmation from employers. After 2 cancellations/no-shows, you'll stop
                    receiving further job opportunities.`}
              </p>
              {!candidate["Passively Looking"] && (
                <Availability
                  availability={candidateAvailability}
                  dates={
                    // Get the next 4 dates
                    [
                      ...Array(
                        {
                          Mon: 3,
                          Tue: 5,
                          Wed: 5,
                          Thu: 5,
                          Fri: 4,
                          Sat: 3,
                          Sun: 3,
                        }[moment().format("ddd")]
                      ),
                    ].map((_, i) => {
                      return moment()
                        .add(i + 2, "days")
                        .format("ddd, D MMM");
                    })
                  }
                  onChange={(value) => {
                    setCandidateAvailability(value);
                  }}
                />
              )}
              <div className="row-all-devices" style={{ marginTop: "24px" }}>
                {candidate["Passively Looking"] && (
                  <input
                    type="checkbox"
                    style={{ margin: "4px 8px 0 0", borderRadius: "8px" }}
                    checked={candidate["Passively Looking"]}
                    onChange={(e) =>
                      setCandidate({
                        ...candidate,
                        "Passively Looking": e.target.checked,
                      })
                    }
                  />
                )}
                <div
                  onClick={() =>
                    setCandidate({
                      ...candidate,
                      "Passively Looking": !candidate["Passively Looking"],
                    })
                  }
                  style={{ width: "fit-content" }}
                >
                  <p
                    className="underline"
                    style={{
                      marginTop: "2px",
                      cursor: "pointer",
                      fontSize: "12px",
                      opacity: 0.66,
                      textDecoration: "none",
                      borderBottom: "1px solid grey",
                      fontWeight: candidate["Passively Looking"] ? "bold" : "",
                    }}
                  >
                    I'm not able to interview in the the next few days
                  </p>
                </div>
              </div>
              {candidateAvailability && (
                <div className="button-wrapper no-margin-right">
                  <button
                    onClick={() => {
                      if (
                        validateCandidateAvailability(candidateAvailability)
                      ) {
                        setCandidate({
                          ...candidate,
                          Availability: formatCandidateAvailability(
                            candidateAvailability
                          ),
                        });
                        updateCandidate({
                          id: candidate["Id"],
                          properties: {
                            Availability: formatCandidateAvailability(
                              candidateAvailability
                            ),
                          },
                        });
                      } else {
                        alert("Please select at least 5 hours of availability");
                      }
                    }}
                    className="button w-inline-block"
                    style={{
                      display: "flex",
                      margin: "24px auto",
                      padding: "8px 32px",
                      borderRadius: "8px",
                    }}
                  >
                    <div style={{ color: "#f2f0ed" }}>Continue</div>
                    <span
                      className="icon-small"
                      style={{
                        display: "inline-block",
                        fontSize: "20px",
                        lineHeight: "20px",
                        height: "20px",
                        width: "20px",
                        position: "relative",
                        left: "8px",
                        color: "#f2f0ed",
                      }}
                    >
                      keyboard_arrow_right_icon
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}
        {isMobile &&
          employers.length > 0 &&
          candidate &&
          (hasUpcomingAvailability(candidate) ||
            candidate["Passively Looking"]) && (
            <div>
              {totalOpportunities && (
                <div style={{ width: "100vh", margin: "0" }}>
                  <Line
                    percent={
                      (100 * (totalOpportunities - employers.length)) /
                        totalOpportunities +
                      5
                    }
                    strokeWidth={1}
                    trailWidth={1}
                    strokeColor="#49616e"
                    trailColor="#ebe9e6"
                    style={{
                      position: "fixed",
                      bottom: "66px",
                      left: "-5px",
                      right: "-5px",
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  fontWeight: "bold",
                  padding: "16px 0",
                  borderTop: "1px solid #efefef",
                  fontSize: "20px",
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  width: "100vw",
                  backgroundColor: "white",
                  height: "66px",
                  border: "none",
                  zIndex: 200,
                }}
              >
                <div
                  style={{ cursor: "pointer", padding: "0 16px" }}
                  onClick={async () => {
                    if (
                      candidate["Passively Looking"] &&
                      employers[0].openPositions
                    ) {
                      setSelectedEmployer({
                        ...employers[0],
                        interviewSlots: undefined,
                      });
                      employers[0].interviewSlots = await getInterviewSlots(
                        employers[0].id,
                        getCommonPosition(candidate, employers[0])
                          .name.replace(/[^a-zA-Z]/g, "")
                          .toLowerCase()
                      );
                      setSelectedEmployer(
                        JSON.parse(JSON.stringify(employers[0]))
                      );
                    } else {
                      scheduleInterview(
                        employers[0],
                        null,
                        null,
                        candidateAvailability
                      );
                    }
                  }}
                >
                  <span
                    className="icon-small"
                    style={{
                      display: "inline-block",
                      fontSize: "28px",
                      lineHeight: "20px",
                      height: "20px",
                      width: "28px",
                      position: "relative",
                      top: "6px",
                      color: "#03ac13",
                    }}
                  >
                    check
                  </span>{" "}
                  Yes
                </div>
                <div
                  style={{ cursor: "pointer", padding: "0 16px" }}
                  onClick={() => {
                    if (employers[0].openPositions) {
                      setInterested(false, employers[0]);
                    }
                  }}
                >
                  <span
                    className="icon-small"
                    style={{
                      display: "inline-block",
                      fontSize: "28px",
                      lineHeight: "20px",
                      height: "20px",
                      width: "28px",
                      position: "relative",
                      top: "6px",
                      color: "red",
                    }}
                  >
                    close
                  </span>{" "}
                  No
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default CandidateOnboardingOpportunities;
