import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Pages from "./Pages";
import * as Admin from "./Admin";
import moment from "moment-timezone";

function App() {
  moment.tz.setDefault("GMT");
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route index element={<Pages.Homepage />} />
          <Route path="/candidates" element={<Pages.CandidateHomepage />} />
          <Route
            path="/candidate-form"
            element={<Pages.CandidateOnboarding />}
          />
          <Route path="/upload-cv/:candidateId" element={<Pages.UploadCV />} />
          <Route
            path="/candidate-opportunities"
            element={<Pages.CandidateOnboardingOpportunities />}
          />
          <Route
            path="/candidate-success"
            element={<Pages.CandidateOnboardingSuccess />}
          />
          <Route
            path="/candidate-success-nocv"
            element={<Pages.CandidateOnboardingNoCvSuccess />}
          />
          <Route
            path="/candidate-pause-success"
            element={<Pages.CandidatePauseSuccess />}
          />
          <Route
            path="/candidate-found-job-success/:candidateId"
            element={<Pages.CandidateFoundJobSuccess />}
          />
          <Route
            path="/candidate--success"
            element={<Pages.CandidatePauseSuccess />}
          />
          <Route path="connect" element={<Pages.SocialAccountability />} />
          <Route
            path="candidate-dashboard"
            element={<Pages.CandidateDashboard />}
          />
          <Route path="/max-reschedule" element={<Pages.MaxReschedule />} />
          <Route
            path="/:branchId/:candidateId/candidate-success"
            element={<Pages.CandidateSentSuccess />}
          />
          <Route path="/employer-form" element={<Pages.EmployerOnboarding />} />
          <Route
            path="/candidate-contacted/:employerId/:candidateId/:roleId"
            element={<Pages.CandidateProfile />}
          />
          <Route
            path="/candidate-contacted/:employerId/:candidateId/:roleId/:interviewTimestamp"
            element={<Pages.CandidateProfile />}
          />
          <Route
            path="/schedule-interview/:employerId/:candidateId/:roleId"
            element={<Pages.InterviewScheduling />}
          />
          <Route
            path="/reschedule/:employerId/:candidateId/:roleId"
            element={<Pages.InterviewScheduling />}
          />
          <Route
            path="/reschedule/:interviewId"
            element={<Pages.InterviewChange />}
          />
          <Route
            path="/confirm-interview/:interviewId"
            element={<Pages.InterviewConfirmation />}
          />
          <Route
            path="/not-interested/:employerId/:candidateId"
            element={<Pages.CandidateNotInterested />}
          />
          <Route
            path="/candidate-pause/:candidateId"
            element={<Pages.PauseContact />}
          />
          <Route path="/refer/:candidateId" element={<Pages.ReferAFriend />} />
          <Route
            path="/:employerId/:branchId/:candidateId/:employerSecret/availability"
            element={<Pages.EmployerSlots />}
          />
          <Route
            path="/:employerId/:employerSecret"
            element={<Pages.EmployerDashboard />}
          />
          <Route
            path="/:employerId/:employerSecret/upgrade"
            element={<Pages.EmployerUpgrade />}
          />
          <Route path="/admin" element={<Admin.AdminDashboard />} />
          <Route path="/admin/control-panel" element={<Admin.ControlPanel />} />
          <Route
            path="/admin/onboard-employer"
            element={<Admin.EmployerOnboardingForm />}
          />
          <Route
            path="/admin/:employerId/:employerSecret/pictures"
            element={<Admin.EmployerPictures />}
          />
          <Route path="/admin/sales-tracker" element={<Admin.SalesTracker />} />
          <Route
            path="/admin/employer-calls"
            element={<Admin.EmployerCalls />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
