import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import "animate.css";

const Homepage = () => {
  const enableAccordions = () => {
    let acc = document.getElementsByClassName("faq-question");
    let i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  };

  const handleScroll = (event) => {
    if (window.scrollY >= 300 && window.scrollY <= 400) {
      let image = document.getElementById("animated_image");
      image.style.opacity = 1;
      image.classList.add("animate__fadeIn");
    }
  };

  useEffect(() => {
    setTimeout(function () {
      var elements = document.getElementsByClassName("late-load");
      for (let el of elements) el.style.opacity = 1;
    }, 400);
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
    enableAccordions();
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="page-wrapper">
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-w-id="db5b1f4a-2449-b3fc-921f-524e4a76fbc0"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar-component w-nav"
      >
        <div className="navbar-container">
          <a
            href="/"
            aria-current="page"
            className="navbar-logo-link w-nav-brand w--current"
          >
            <img
              src="images/hirebolt-logo-white.svg"
              loading="lazy"
              width="130"
              alt=""
              className="shopscribe-logo shopscribe-logo-white"
            />
          </a>
          <div className="navbar-manually-added">
            <a
              className="button w-button"
              href="/candidates"
              style={{ padding: "4px 16px" }}
            >
              Looking for a job?
              <span
                className="icon-small"
                style={{
                  display: "inline-block",
                  fontSize: "20px",
                  lineHeight: "20px",
                  height: "20px",
                  width: "20px",
                  position: "relative",
                  left: "4px",
                  top: "4px",
                }}
              >
                keyboard_arrow_right_icon
              </span>
            </a>
          </div>
        </div>
      </div>
      <main className="main-wrapper">
        <div className="header-1-wrapper">
          <section className="section-header-1">
            <div className="page-padding">
              <div className="container-large">
                <div className="padding-top" style={{ paddingTop: "5vh" }}>
                  <div className="header-1-component">
                    <div className="margin-bottom margin-xxlarge landing-margin-bottom">
                      <div className="text-align-center">
                        <div className="header-1-heading-wrapper">
                          <div className="max-width-large tablet-medium">
                            <div className="margin-bottom margin-small">
                              <h1
                                data-w-id="27d5740f-2623-c299-0a89-dfc1ee53ff8e"
                                className="heading-xxlarge text-color-white"
                              >
                                <strong className="bold-text">
                                  Hire staff{" "}
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      borderBottom: "3px solid #ddf0a7",
                                      display: "inline-block",
                                    }}
                                    className="animate__animated animate__faster"
                                    onMouseEnter={(e) => {
                                      e.target.classList.add(
                                        "animate__headShake"
                                      );
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.classList.remove(
                                        "animate__headShake"
                                      );
                                    }}
                                  >
                                    fast
                                  </span>{" "}
                                  without wasting hours on admin work
                                </strong>
                              </h1>
                            </div>
                            <div className="margin-bottom margin-small late-load">
                              <p
                                data-w-id="27d5740f-2623-c299-0a89-dfc1ee53ff91"
                                className="text-size-large text-color-white"
                              >
                                Our tech finds and selects the best candidates,
                                so you don't have to.
                                <br />
                                Just get active, relevant candidates sent to you
                                directly on WhatsApp.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="button-row button-row-center late-load">
                          <div className="button-wrapper">
                            <a
                              data-w-id="27d5740f-2623-c299-0a89-dfc1ee53ff9f"
                              href="/employer-form"
                              className="button-secondary w-button"
                            >
                              Start hiring
                            </a>
                          </div>
                          <div className="button-wrapper no-margin-right">
                            <a
                              data-w-id="9f05ef66-55bb-8381-9ca4-a8a03fb82254"
                              href="https://wa.me/+447404165080?text=Hey, I was on your website and would be interested in finding out more about Hirebolt."
                              className="link-secondary w-inline-block"
                            >
                              <div className="text-color-white">Chat to us</div>
                              <img
                                src="images/Chevron-Down.svg"
                                loading="lazy"
                                alt=""
                                className="button-tertiary-icon"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="margin-vertical margin-small late-load">
                          <div
                            className="header-1-logo-row justify-center"
                            style={{
                              paddingBottom: "1.5rem",
                              paddingTop: "5rem",
                            }}
                          >
                            <a
                              href="https://www.simmonsbar.co.uk/"
                              className="w-inline-block"
                            >
                              <div className="header-1-logo-wrapper">
                                <img
                                  className="header-1-logo white-image"
                                  src="images/Simmons.png"
                                  alt="Simmons logo"
                                  sizes="39.208335876464844px"
                                  data-w-id="2d7db3fb-b214-3803-7685-12dc8bdf0038"
                                  loading="lazy"
                                  style={{ width: "150px" }}
                                />
                              </div>
                            </a>
                            <a
                              href="https://www.instagram.com/acaiberryfoods/?hl=en"
                              className="w-inline-block"
                            >
                              <div className="header-1-logo-wrapper">
                                <img
                                  src="images/AcaiBerry.png"
                                  loading="lazy"
                                  alt="AcaiBerry logo"
                                  height=""
                                  data-w-id="acb829f1-fbab-4b10-f301-41c9597d4f71"
                                  className="header-1-logo white-image"
                                />
                              </div>
                            </a>
                            {!isMobile && (
                              <a
                                href="https://shop.wingmans.co.uk/"
                                className="w-inline-block"
                              >
                                <div className="header-1-logo-wrapper">
                                  <img
                                    className="header-1-logo white-image"
                                    src="images/Wingmans.png"
                                    width="120"
                                    alt="Wingmans logo"
                                    sizes="120px"
                                    data-w-id="4e8ae3c7-1fb5-0071-2ec8-dd0a369e1bb8"
                                    loading="lazy"
                                    style={{ width: "125px", height: "auto" }}
                                  />
                                </div>
                              </a>
                            )}
                          </div>
                          <div
                            className="header-1-logo-row justify-center"
                            style={{ paddingTop: isMobile ? 0 : "16px" }}
                          >
                            {!isMobile && (
                              <a
                                href="https://www.ampeli.london/"
                                className="w-inline-block"
                              >
                                <div className="header-1-logo-wrapper">
                                  <img
                                    src="images/Ampeli-logo-black.webp"
                                    loading="lazy"
                                    alt="Ampeli logo"
                                    width="150"
                                    data-w-id="acb829f1-fbab-4b10-f301-41c9597d4f75"
                                    className="header-1-logo white-image"
                                  />
                                </div>{" "}
                              </a>
                            )}
                            <a
                              href="https://www.honestburgers.co.uk/"
                              className="w-inline-block"
                            >
                              <div className="header-1-logo-wrapper">
                                <img
                                  className="header-1-logo white-image"
                                  src="images/HonestBurgers.png"
                                  width="120"
                                  alt="Honest Burgers logo"
                                  sizes="120px"
                                  data-w-id="4e8ae3c7-1fb5-0071-2ec8-dd0a369e1bb8"
                                  loading="lazy"
                                  style={{ width: "125px", height: "auto" }}
                                />
                              </div>
                            </a>
                            <a
                              href="https://www.beatone.co.uk/"
                              className="w-inline-block"
                            >
                              <div className="header-1-logo-wrapper">
                                <img
                                  src="images/BeAtOne.svg"
                                  loading="lazy"
                                  alt="Be At One logo"
                                  height=""
                                  data-w-id="acb829f1-fbab-4b10-f301-41c9597d4f71"
                                  className="header-1-logo white-image"
                                  style={{ width: "150px", height: "auto" }}
                                />
                              </div>
                            </a>
                          </div>
                          <p
                            style={{
                              color: "white",
                              opacity: 0.5,
                              fontSize: "small",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            and 500+ more hospitality employers
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-header-1-video">
            <div className="header-1-bg"></div>
            <div className="page-padding landing-image-padding">
              <div className="container-large">
                <div className="header-1-component">
                  <img
                    src="images/pexels-rodnae-productions-4921154.jpg"
                    loading="lazy"
                    alt="Staff infront of store"
                    srcSet="
                        images/pexels-rodnae-productions-4921154-p-500.jpg   500w,
                        images/pexels-rodnae-productions-4921154-p-800.jpg   800w,
                        images/pexels-rodnae-productions-4921154-p-1080.jpg 1080w,
                        images/pexels-rodnae-productions-4921154-p-1600.jpg 1600w,
                        images/pexels-rodnae-productions-4921154-p-2000.jpg 2000w,
                        images/pexels-rodnae-productions-4921154-p-2600.jpg 2600w,
                        images/pexels-rodnae-productions-4921154-p-3200.jpg 3200w
                      "
                    sizes="(max-width: 479px) 100vw, 72vw"
                    style={{
                      maxHeight: "60vh",
                      borderRadius: "40px",
                      opacity: 0,
                    }}
                    className="animate__animated animate__faster header-1-lightbox-image"
                    id="animated_image"
                  />
                </div>
              </div>
            </div>
          </section>
          <img
            src="images/home_v1_1.svg"
            loading="lazy"
            alt=""
            className="home-v1-illustration-1 late-load"
          />
          <img
            src="images/home_v1_2.svg"
            loading="lazy"
            alt=""
            className="home-v1-illustration-2 late-load"
          />
        </div>
        <section className="section-feature-1">
          <div className="page-padding second-section-padding">
            <div className="container-large">
              <div
                className="padding-vertical padding-huge"
                style={{ paddingTop: "150px" }}
              >
                <div className="w-layout-grid benefits-2-component">
                  <div className="benefits-2-content-left">
                    <div className="margin-bottom margin-xsmall">
                      <div className="align-left">
                        <div
                          data-w-id="1958ae42-cb83-7199-4005-e063addab2d6"
                          className="tagline-wrapper"
                        >
                          <div className="text-weight-semibold">
                            Sounds like you?
                          </div>
                        </div>
                      </div>
                    </div>
                    <h2
                      data-w-id="1958ae42-cb83-7199-4005-e063addab2d9"
                      className="heading-xlarge"
                    >
                      Hiring is the biggest challenge for most hospitality
                      businesses.
                    </h2>
                    <img
                      src="images/home_v1_8.svg"
                      loading="lazy"
                      alt=""
                      className="late-load"
                      style={{
                        marginBottom: "-300px",
                        transform: "scale(2)",
                      }}
                    />
                  </div>
                  <div className="benefits-2-content-right z-index-2">
                    <div className="benefits-2-item-list">
                      <div
                        id="w-node-_1958ae42-cb83-7199-4005-e063addab2e0-cde11faf"
                        data-w-id="1958ae42-cb83-7199-4005-e063addab2e0"
                        className="benefits-2-item"
                      >
                        <div className="benefits-2-item-text-wrapper">
                          <div
                            className="margin-bottom margin-xxsmall"
                            style={{ display: "flex" }}
                          >
                            <h1>1.</h1>
                            <h3
                              className="heading-xsmall"
                              style={{
                                margin: "5px 0 0 25px",
                                fontFamily: "Spacegrotesk, sans-serif",
                              }}
                            >
                              Job boards are clogged up by irrelevant
                              candidates. It takes hours to filter through CVs.
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_1958ae42-cb83-7199-4005-e063addab2e0-cde11faf"
                        data-w-id="1958ae42-cb83-7199-4005-e063addab2e0"
                        className="benefits-2-item"
                      >
                        <div className="benefits-2-item-text-wrapper">
                          <div
                            className="margin-bottom margin-xxsmall"
                            style={{ display: "flex" }}
                          >
                            <h1>2.</h1>
                            <h3
                              className="heading-xsmall"
                              style={{
                                margin: "5px 0 0 25px",
                                fontFamily: "Spacegrotesk, sans-serif",
                              }}
                            >
                              Recruitment agencies with selected candidates are
                              more expensive than ever.
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div
                        id="w-node-_1958ae42-cb83-7199-4005-e063addab2e0-cde11faf"
                        data-w-id="1958ae42-cb83-7199-4005-e063addab2e0"
                        className="benefits-2-item"
                      >
                        <div className="benefits-2-item-text-wrapper">
                          <div
                            className="margin-bottom margin-xxsmall"
                            style={{ display: "flex" }}
                          >
                            <h1>3.</h1>
                            <h3
                              className="heading-xsmall"
                              style={{
                                margin: "5px 0 0 25px",
                                fontFamily: "Spacegrotesk, sans-serif",
                              }}
                            >
                              Up to 80% of candidates who get invited to an
                              interview, won't even show up.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-feature-1-right">
          <div className="page-padding">
            <div className="container-large">
              <div
                className="padding-vertical"
                style={{ padding: "50px 0 100px" }}
              >
                <div
                  className="w-layout-grid feature-1-right-component"
                  style={{ gridTemplateColumns: isMobile ? "" : "2fr 1fr" }}
                >
                  <div className="feature-1-right-content">
                    <div className="row">
                      <div
                        className="column"
                        style={{
                          marginRight: isMobile ? 0 : "50px",
                          marginBottom: isMobile ? "50px" : 0,
                        }}
                      >
                        {isMobile ? (
                          <h3>Before Hirebolt</h3>
                        ) : (
                          <h4>Before Hirebolt</h4>
                        )}
                        <br />
                        <div style={{ fontSize: "15px" }}>
                          <div className="before-list">
                            <b>1.</b> Create job listings for each role and post
                            them on multiple job boards
                          </div>
                          <div className="before-list">
                            <b>2.</b> Wait for candidates to apply
                          </div>
                          <div className="before-list">
                            <b>3.</b> Scroll through dozens (or hundreds) of
                            irrelevant applications
                          </div>
                          <div className="before-list">
                            <b>4.</b> Message or call the few relevant
                            candidates - most won't respond
                          </div>
                          <div className="before-list">
                            <b>5.</b> Find a time that works and schedule an
                            interview
                          </div>
                          <div className="before-list">
                            <b>6.</b> Remind them of the interview - most won't
                            show up
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        {isMobile ? (
                          <h3>After Hirebolt</h3>
                        ) : (
                          <h4>After Hirebolt</h4>
                        )}
                        <br />
                        <div
                          className="margin-bottom margin-small"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="feature-1-right-item-list">
                            <div
                              data-w-id="6928e259-6c93-d083-c3dc-25429e914401"
                              className="feature-1-right-item after-hirebolt-item"
                            >
                              <div style={{ display: "flex" }}>
                                <div style={{ marginBottom: "10px" }}>
                                  <div className="feature-1-right-icon-wrapper">
                                    <div className="icon-small text-color-bluemine">
                                      description
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{ height: "55px", display: "flex" }}
                                >
                                  <h3
                                    className="heading-xsmall"
                                    style={{
                                      margin: "auto 15px",
                                    }}
                                  >
                                    Tell us what you need
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div
                              data-w-id="6928e259-6c93-d083-c3dc-25429e914401"
                              className="feature-1-right-item after-hirebolt-item"
                            >
                              <div style={{ display: "flex" }}>
                                <div style={{ marginBottom: "10px" }}>
                                  <div className="feature-1-right-icon-wrapper">
                                    <div className="icon-small text-color-bluemine">
                                      send
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{ height: "55px", display: "flex" }}
                                >
                                  <h3
                                    className="heading-xsmall"
                                    style={{
                                      margin: "auto 15px",
                                    }}
                                  >
                                    Receive relevant candidates the same day
                                  </h3>
                                </div>
                              </div>
                            </div>{" "}
                            <div
                              data-w-id="6928e259-6c93-d083-c3dc-25429e914401"
                              className="feature-1-right-item"
                            >
                              <div style={{ display: "flex" }}>
                                <div style={{ marginBottom: "10px" }}>
                                  <div className="feature-1-right-icon-wrapper">
                                    <div className="icon-small text-color-bluemine">
                                      touch_app
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{ height: "55px", display: "flex" }}
                                >
                                  <h3
                                    className="heading-xsmall"
                                    style={{
                                      margin: "auto 15px",
                                    }}
                                  >
                                    Click on candidates you like. We take care
                                    of the rest
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-13-client-image-wrapper">
                    <div
                      data-w-id="9b4c8a0b-d10f-75ce-f9c7-791a2b01d3fb"
                      className="image-wrapper-1-1 home-v1-feature-2"
                    ></div>
                    <div
                      data-w-id="8694e926-f944-91d3-f5fb-00dff0284da7"
                      className="feature-1-right-list-wrapper"
                    >
                      <div className="feature-1-list">
                        <div className="heading-medium text-color-bluemine">
                          We eliminate all your admin work
                        </div>
                        <div className="text-size-large text-weight-medium text-color-bluemine"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-benefits-1">
          <div className="page-padding">
            <div className="container-large">
              <div
                data-w-id="a4b86ad7-3e72-02da-6d7d-15535973ce14"
                className="benefits-1-wrapper"
              >
                <div className="benefits-1-component">
                  <div className="margin-bottom margin-xlarge">
                    <div className="text-align-center">
                      <div className="max-width-large">
                        <div className="margin-bottom margin-small">
                          <h2
                            data-w-id="27ab8bff-a5ad-31eb-3c7a-03573e1d585b"
                            className="heading-large"
                          >
                            How it works
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="margin-bottom margin-large">
                    <div className="w-layout-grid benefits-1-list">
                      <div
                        data-w-id="27ab8bff-a5ad-31eb-3c7a-03573e1d5861"
                        className="benefits-1-item"
                      >
                        <div className="padding-medium">
                          <div className="margin-bottom margin-small">
                            <div className="benefits-1-icon-wrapper">
                              <img
                                src="images/Shape-1.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="margin-bottom margin-xsmall">
                            <h3 className="heading-xsmall text-color-white">
                              Vetted candidates daily
                            </h3>
                          </div>
                          <p className="text-color-white">
                            Get a selection of actively-looking, relevant
                            candidates on a daily basis
                          </p>
                        </div>
                      </div>
                      <div
                        id="w-node-ca4b538c-4074-949a-de54-f2aa8192e7e8-cde11faf"
                        data-w-id="ca4b538c-4074-949a-de54-f2aa8192e7e8"
                        className="benefits-1-item"
                      >
                        <div className="padding-medium">
                          <div className="margin-bottom margin-small">
                            <div className="benefits-1-icon-wrapper">
                              <img
                                src="images/Shape-2.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="margin-bottom margin-xsmall">
                            <h3 className="heading-xsmall text-color-white">
                              Just press a button
                            </h3>
                          </div>
                          <p className="text-color-white">
                            We constantly send vetted CVs your way via WhatsApp.
                            Just click on the ones you like and we'll
                            automatically contact them
                          </p>
                        </div>
                      </div>
                      <div
                        data-w-id="34a07b2a-5d87-d4a9-20ec-5c071bedc161"
                        className="benefits-1-item"
                      >
                        <div className="padding-medium">
                          <div className="margin-bottom margin-small">
                            <div className="benefits-1-icon-wrapper">
                              <img
                                src="images/Shape-3.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="margin-bottom margin-xsmall">
                            <h3 className="heading-xsmall text-color-white">
                              We chase them for you
                            </h3>
                          </div>
                          <p className="text-color-white">
                            We remind candidates to show up for interviews +
                            Give reasoning behind no-shows
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-row button-row-center">
                    <a
                      data-w-id="27ab8bff-a5ad-31eb-3c7a-03573e1d5883"
                      href="/employer-form"
                      className="button-tertiary w-button"
                    >
                      Start hiring
                    </a>
                    <a
                      data-w-id="76373b81-39fb-7e78-3e99-d446dd790277"
                      href="https://cal.frontapp.com/shopscribec6bb/alexzhou/7b9a60a7"
                      className="link-secondary w-inline-block"
                    >
                      <div>Chat to us</div>
                      <img
                        src="images/Chevron_black.svg"
                        loading="lazy"
                        alt=""
                        className="button-tertiary-icon"
                      />
                    </a>
                  </div>
                </div>
                <img
                  src="images/home_v1_7.svg"
                  loading="lazy"
                  data-w-id="effe8120-53c5-4e30-d7bf-43f2b93f4d37"
                  alt=""
                  className="home-v1-benefits-1-illustration-1"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section-faq">
          <div className="page-padding remove-top-padding">
            <div className="container-small">
              <div className="padding-vertical padding-xhuge">
                <div className="margin-bottom margin-xxlarge">
                  <div className="text-align-center">
                    <div className="max-width-large">
                      <div className="margin-bottom margin-small">
                        <h2
                          data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e2b"
                          className="heading-xlarge z-index-2"
                        >
                          FAQs
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="faq-component">
                  <div
                    data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e30"
                    className="faq-accordion"
                  >
                    <div
                      data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e31"
                      className="faq-question"
                    >
                      <div className="text-size-medium text-weight-bold text-color-blumine">
                        “We have someone doing hiring already – why should we
                        use you?”
                      </div>
                      <img
                        src="images/icon_chevron.svg"
                        loading="lazy"
                        alt=""
                        className="faq-icon"
                      />
                    </div>
                    <div className="faq-answer">
                      <div className="margin-bottom margin-small">
                        <p className="text-size-regular">
                          Your hiring person is drowning in admin work –
                          screening hundreds of terrible CVs per role, calling
                          to schedule interviews, sending reminders, and dealing
                          with tons of cancellations &amp; no-shows.
                          <br />
                          <br />
                          If you’re doing the hiring, we remove all your admin
                          work. If it’s someone else, they’re likely struggling;
                          using this will help them hire good staff faster.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e39"
                    className="faq-accordion"
                  >
                    <div
                      data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e3a"
                      className="faq-question"
                    >
                      <div className="text-size-medium text-weight-bold text-color-blumine">
                        “How are you different than Indeed, Job Today, etc? We
                        already get candidates from them.”
                      </div>
                      <img
                        src="images/icon_chevron.svg"
                        loading="lazy"
                        alt=""
                        className="faq-icon"
                      />
                    </div>
                    <div className="faq-answer">
                      <div className="margin-bottom margin-small">
                        <p className="text-size-regular">
                          Job boards give you hundreds of terrible candidates.
                          More bad candidates = More hours wasted reviewing them
                          to find the good ones. By the time you’ve found them,
                          they’ve found jobs elsewhere.
                          <br />
                          <br />
                          Instead, we build tech to automatically vet candidates
                          and send the best, actively-looking ones to you daily.
                          Our speed-to-hire is faster than humans, so you’ll
                          find and extend offers to candidates before other
                          places do.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e42"
                    className="faq-accordion"
                  >
                    <div
                      data-w-id="5adccdc1-1c33-cb4f-ddd8-20bdcc193e43"
                      className="faq-question"
                    >
                      <div className="text-size-medium text-weight-bold text-color-blumine">
                        “Are you an agency? We’d prefer to hire ourselves.”
                      </div>
                      <img
                        src="images/icon_chevron.svg"
                        loading="lazy"
                        alt=""
                        className="faq-icon"
                      />
                    </div>
                    <div className="faq-answer">
                      <div className="margin-bottom margin-small">
                        <p className="text-size-regular">
                          We’re a tech platform, not an agency. We’re ~26x
                          cheaper, and give you ownership of the candidates so
                          you can hire them yourself.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="images/about_v3_4.svg"
            loading="lazy"
            alt=""
            className="faq-illustration"
          />
        </section>
        <section className="section-header-5">
          <div className="page-padding additional-page-padding">
            <div className="container-large">
              <div className="header-5-content-wrapper">
                <div className="w-layout-grid header-5-component">
                  <div className="header-5-content-left">
                    <div className="margin-bottom margin-xsmall">
                      <div className="align-left">
                        <div
                          data-w-id="17a7e70c-5374-fbca-29e2-08b4bbb34580"
                          className="tagline-wrapper"
                        >
                          <div className="text-weight-semibold">About us</div>
                        </div>
                      </div>
                    </div>
                    <h1
                      data-w-id="17a7e70c-5374-fbca-29e2-08b4bbb34583"
                      className="heading-xxlarge text-color-white"
                    >
                      About Hirebolt
                    </h1>
                  </div>
                  <div className="header-5-content-right">
                    <p
                      data-w-id="17a7e70c-5374-fbca-29e2-08b4bbb34586"
                      className="text-size-large text-color-white"
                    >
                      Hey, we're{" "}
                      <a
                        href="https://www.linkedin.com/in/alexanderzhou/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Alex
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.linkedin.com/in/lorenzo-sani/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Lorenzo
                      </a>
                      ; we've been building tech for local businesses the past
                      year.
                      <br />
                      <br />
                      Recently hiring for:{" "}
                      <a
                        href="https://inception-group.com/venue-hire-london/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Inception Group
                      </a>
                      ,{" "}
                      <a
                        href="https://www.mr-foggs.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Mr Fogg's
                      </a>
                      ,{" "}
                      <a
                        href="https://londonginclub.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        London Gin Club
                      </a>
                      ,{" "}
                      <a
                        href="https://www.simmonsbar.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Le Bab
                      </a>
                      ,{" "}
                      <a
                        href="https://bonedaddies.com/shack-fuyu/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Shack Fuyu
                      </a>
                      ,{" "}
                      <a
                        href="https://www.wherethepancakesare.com/restaurants/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textDecoration: "none",
                        }}
                      >
                        Where the Pancakes Are
                      </a>
                      , Michelin-recommended restaurants, &amp; more!
                    </p>
                  </div>
                </div>
                <img
                  src="images/about_v3_1.svg"
                  loading="lazy"
                  alt=""
                  className="header-5-illustration"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="footer-component">
        <div className="page-padding">
          <div className="container-large">
            <div className="padding-vertical padding-xxlarge">
              <div className="padding-bottom padding-xxlarge">
                <div className="w-layout-grid footer-top-wrapper">
                  <div className="footer-left-wrapper">
                    <div className="margin-bottom margin-small">
                      <a
                        href="hiring.html"
                        aria-current="page"
                        className="footer-logo-link w-nav-brand w--current"
                      >
                        <img
                          src="images/hirebolt-logo-white.svg"
                          loading="lazy"
                          width="150"
                          alt=""
                          className="shopscribe-logo-white"
                        />
                      </a>
                    </div>
                    <div className="row">
                      <div
                        className="text-size-small text-color-white column"
                        style={{ textAlign: "left" }}
                      >
                        <span className="text-weight-semibold">
                          Address: <br />
                        </span>
                        First Floor, 57 Greek Street
                        <br />
                        London
                        <br />
                        W1D 3DX
                        <br />
                        <br />
                        <span className="text-weight-semibold">Contact:</span>
                        <br />
                        <a
                          href="mailto:info@hirebolt.com"
                          className="text-color-white"
                        >
                          info@hirebolt.com
                        </a>
                        <br />
                        +447404165080
                      </div>
                      <div
                        className="text-size-small text-color-white column"
                        style={{ textAlign: "left" }}
                      >
                        <span className="text-weight-semibold">
                          <br />
                        </span>
                        651 N Broad St
                        <br />
                        Suite 206 Middleton, Delaware 19709
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid footer-menu-wrapper"></div>
                </div>
              </div>
              <div className="line-divider footer"></div>
              <div className="padding-top padding-medium">
                <div className="footer-bottom-wrapper">
                  <div className="footer-credits"></div>
                  <div className="w-layout-grid footer-social">
                    <a
                      href="https://www.linkedin.com/company/shopscribe"
                      className="social-link is-with-background w-inline-block"
                    >
                      <img
                        src="images/LinkedIn.svg"
                        loading="lazy"
                        alt=""
                        className="social-icon"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
