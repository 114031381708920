import ReactTooltip from "react-tooltip";
import {Fragment, useEffect, useState} from "react";

const StatusCheckIndicator = ({
                             check = false,
                         }) => {
    const [dataTip, setDataTip] = useState("");
    const [color, setColor] = useState("");
    useEffect(() => {
        if (check) {
            setDataTip("This check passed");
            setColor("#2C5E1A");
        } else if (!check) {
            setDataTip("Something went wrong while checking");
            setColor("#FF0000");
        }
    }, [check])

    return (
        <Fragment>
<span
    data-tip={dataTip}
    data-for="welcome-message"
    style={{
        height: " 15px",
        width: "15px",
        backgroundColor: color,
        borderRadius: "50%",
        display: "inline-block",
        marginLeft: "10px"
    }}>
</span>
            <ReactTooltip effect="solid" id="welcome-message" place="top"/>
        </Fragment>
    );
};

export default StatusCheckIndicator;
