import moment from "moment-timezone";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import Lottie from "react-lottie-player";

import confirmAnimation from "./confirmAnimation.json";

const InterviewScheduler = ({
  employer = {},
  role = {},
  sendInterviewConfirmation = () => {},
}) => {
  // eslint-disable-next-line no-unused-vars
  const [slotsOffset, setSlotsOffset] = useState(
    employer.interviewSlots.slice(0, 4).some((slot) => slot?.times) ? 0 : 4
  );
  const [selectedDuration, setSelectedDuration] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [confirmedSlot, setConfirmedSlot] = useState();

  const confirmSlot = () => {
    setConfirmedSlot(selectedSlot);
    sendInterviewConfirmation(selectedSlot, selectedDuration);
    setSelectedSlot(null);
  };

  const employerHasAvailableSlots = () => {
    let allSlots = [];
    employer.interviewSlots.forEach((slot) => {
      allSlots = allSlots.concat(slot?.times);
    });
    return allSlots.length > 0;
  };

  return (
    <div>
      {confirmedSlot ? (
        <div style={{ textAlign: "center", marginBottom: "48px" }}>
          <Lottie
            animationData={confirmAnimation}
            loop
            play
            style={{
              width: "100px",
              height: "100px",
              margin: "50px auto 20px",
            }}
          />
          <p style={{ fontSize: "25px", fontWeight: "bold" }}>
            We sent your profile to {employer.employerName}
          </p>
          <p>You will hear from them once the trial is confirmed!</p>
        </div>
      ) : employerHasAvailableSlots() ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              margin: "15px -10px 0 -10px",
            }}
          >
            {employer.interviewSlots
              .slice(slotsOffset, slotsOffset + 4)
              .map((slot, i) => {
                return (
                  <>
                    {(!isMobile || i > 0) && (
                      <div
                        style={{
                          flex: "1 0 21%",
                          margin: "10px",
                          height: "fit-content",
                          flexShrink: 0,
                        }}
                        key={"day" + i}
                      >
                        <div
                          style={{
                            height: "80px",
                            borderBottom: "1px solid rgba(26,26,26,0.1)",
                            textAlign: "center",
                            verticalAlign: "center",
                          }}
                        >
                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              {moment(slot.date)
                                .format(isMobile ? "ddd" : "dddd")
                                .toUpperCase()}
                            </span>
                            <br />
                            <span style={{ fontSize: "14px" }}>
                              {moment(slot.date).format("Do MMM")}
                            </span>
                            <br />
                            <span
                              style={{
                                opacity: 0.5,
                                fontSize: "12px",
                                fontWeight: "bold",
                                lineHeight: "1px",
                              }}
                            >
                              {moment(slot.date).isSame(moment(), "day") &&
                                " (today)"}
                            </span>
                          </p>
                        </div>
                        {slot.times
                          .sort(
                            (a, b) =>
                              parseInt(a.split(":")[0]) -
                              parseInt(b.split(":")[0])
                          )
                          .map((time, j) => {
                            return (
                              <div
                                className={
                                  selectedSlot ===
                                  moment(
                                    slot.date.substring(0, 10) + " " + time,
                                    "YYYY-MM-DD H:mm"
                                  ).format()
                                    ? "interview-slot-active"
                                    : "interview-slot"
                                }
                                key={"day" + i + "slot" + j}
                                style={{
                                  borderRadius: "15px",
                                  padding: "15px",
                                  margin: "20px 0",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  height: "80px",
                                }}
                                onClick={() => {
                                  if (
                                    selectedSlot ===
                                    moment(
                                      slot.date.substring(0, 10) + " " + time,
                                      "YYYY-MM-DD H:mm"
                                    ).format()
                                  ) {
                                    confirmSlot();
                                  } else {
                                    setSelectedSlot(
                                      moment(
                                        slot.date.substring(0, 10) + " " + time,
                                        "YYYY-MM-DD H:mm"
                                      ).format()
                                    );
                                    setSelectedDuration(slot.duration);
                                  }
                                }}
                              >
                                {selectedSlot ===
                                moment(
                                  slot.date.substring(0, 10) + " " + time,
                                  "YYYY-MM-DD H:mm"
                                ).format() ? (
                                  <p
                                    style={{
                                      marginTop: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    CONFIRM
                                  </p>
                                ) : (
                                  <div>
                                    <p>
                                      <b>{time}</b>
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {slot.duration % 60 === 0
                                        ? "Duration: " +
                                          slot.duration / 60 +
                                          " hrs"
                                        : "Duration: " + slot.duration + " min"}
                                    </p>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </>
                );
              })}
          </div>
        </div>
      ) : (
        <div>
          <h5 style={{ marginTop: "50px" }}>
            {employer.name} has currently no available interview slots
          </h5>
          <p style={{ margin: "10px auto 30px" }}>
            In order to schedule your interview, please send a message to{" "}
            {employer.hiringManagers[0].name} at{" "}
            <a href={"sms:" + employer.hiringManagers[0].number}>
              {employer.hiringManagers[0].number}
            </a>{" "}
            to find a time that works for both of you.
          </p>
        </div>
      )}
    </div>
  );
};

export default InterviewScheduler;
