import { useEffect, useState } from "react";
import Select from "react-select";
import { getAllEmployers, getBranch } from "../backend";
import { getEmployerSecret } from "../utils";

const LinkGenerator = ({ branches, close }) => {
  const [employers, setEmployers] = useState(null);
  const [linkType, setLinkType] = useState(null);
  const [candidateId, setCandidateId] = useState(null);
  const [employerId, setEmployerId] = useState(null);
  const [interviewId, setInterviewId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [roles, setRoles] = useState([]);
  const linkTypes = {
    interview: {
      label: "Book an Interview",
      fields: ["candidate", "branch", "role"],
      url: `https://www.hirebolt.com/schedule-interview/${employerId}/${candidateId}/${roleId}`,
    },
    reschedule: {
      label: "Cancel/Reschedule Interview",
      fields: ["interview"],
      url: `https://hirebolt.com/reschedule/${interviewId}`,
    },
    employerinfo: {
      label: "Edit Employer Info",
      fields: ["employer"],
      url: `https://www.hirebolt.com/${employerId}/${getEmployerSecret(
        employerId
      ).substring(0, 6)}`,
    },
    opportunities: {
      label: "See Opportunities",
      fields: ["candidate"],
      url: `https://www.hirebolt.com/candidate-opportunities?id=${candidateId}/`,
    },
    candidateCV: {
      label: "Upload Candidate CV",
      fields: ["candidate"],
      url: `https://www.hirebolt.com/upload-cv/${candidateId}/`,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      let e = await getAllEmployers();
      setEmployers(e);
    };
    fetchData();
  }, []);

  const updateEmployer = async (id) => {
    getBranch(id).then((response) => {
      let roles = [];
      if (response.openPositions) {
        response.openPositions.forEach((pos) => {
          roles = roles.concat(pos.role);
        });
      } else {
        response.branches.forEach((branch) => {
          branch.openPositions.forEach((pos) => {
            roles = roles.concat(pos.role);
          });
        });
      }
      setRoles(roles);
    });
    setEmployerId(id);
  };

  const getRoleId = (role) => {
    return role.includes("Supervisor")
      ? "supervisormanager"
      : role?.replace(/[^a-zA-Z]/g, "").toLowerCase();
  };

  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "auto",
        height: "fit-content",
      }}
    >
      <div
        style={{
          backgroundColor: "#efefef",
          padding: "25px",
          borderRadius: "25px",
        }}
      >
        <div style={{ wdith: "100%", display: "inline" }}>
          <h5 style={{ display: "inline-block" }}>Which link do you need?</h5>
          <div
            style={{
              display: "inline-block",
              float: "right",
              position: "absolute",
              top: "15px",
              right: "30px",
              cursor: "pointer",
            }}
            onClick={() => close()}
            className="icon-small"
          >
            close
          </div>
        </div>
        <div style={{ margin: "25px 0", width: "400px" }}>
          <Select
            placeholder="Select one"
            onChange={(e) => setLinkType(e.value)}
            options={Object.keys(linkTypes).map((key) => {
              return { label: linkTypes[key].label, value: key };
            })}
          ></Select>
        </div>
        {linkType && (
          <div style={{ margin: "25px 0" }}>
            <hr />
            {linkTypes[linkType].fields.includes("candidate") && (
              <div>
                <label className="main-label">
                  Candidate ID{" "}
                  <a
                    href="https://res.cloudinary.com/shopscribe/image/upload/v1666108885/Hiring%20MVP/CandidateId_x4r8ky.png"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-small"
                    style={{
                      fontSize: "18px",
                      position: "relative",
                      top: "3px",
                      left: "3px",
                      textDecoration: "none",
                      cursor: "pointer",
                      display: "inline-block",
                      opacity: "0.8",
                    }}
                  >
                    help
                  </a>
                </label>
                <label className="sub-label">
                  Open the candidate on Notion, copy the last part of the url
                </label>
                <input
                  type="text"
                  className="rounded form-text container"
                  style={{ border: "none" }}
                  value={candidateId}
                  maxLength={150}
                  onChange={(e) => setCandidateId(e.target.value)}
                />
              </div>
            )}
            {linkTypes[linkType].fields.includes("branch") && (
              <div>
                <label className="main-label">Employer</label>
                <Select
                  placeholder="Select an Employer"
                  onChange={(e) => updateEmployer(e.value)}
                  options={branches.map((e) => {
                    return { label: e.name, value: e.id };
                  })}
                ></Select>
              </div>
            )}
            {linkTypes[linkType].fields.includes("employer") && (
              <div>
                <label className="main-label">Employer</label>
                <Select
                  placeholder="Select an Employer"
                  onChange={(e) => setEmployerId(e.value)}
                  options={
                    employers &&
                    employers.map((e) => {
                      return { label: e.name, value: e.id };
                    })
                  }
                ></Select>
              </div>
            )}
            {linkTypes[linkType].fields.includes("role") && (
              <div>
                <label className="main-label">Role</label>
                <label className="sub-label">Select the employer first</label>
                <Select
                  placeholder="Select a Role"
                  onChange={(e) => setRoleId(e.value)}
                  options={roles.map((e) => {
                    return { label: e, value: getRoleId(e) };
                  })}
                ></Select>
              </div>
            )}
            {linkTypes[linkType].fields.includes("interview") && (
              <div>
                <label className="main-label">
                  Interview ID{" "}
                  <a
                    href="https://res.cloudinary.com/shopscribe/image/upload/v1666108885/Hiring%20MVP/InterviewId_rg8khz.png"
                    target="_blank"
                    rel="noreferrer"
                    className="icon-small"
                    style={{
                      fontSize: "18px",
                      position: "relative",
                      top: "3px",
                      left: "3px",
                      textDecoration: "none",
                      cursor: "pointer",
                      display: "inline-block",
                      opacity: "0.8",
                    }}
                  >
                    help
                  </a>
                </label>
                <label className="sub-label">
                  Open the interview on Notion, copy the last part of the url
                </label>
                <input
                  type="text"
                  className="rounded form-text container"
                  style={{ border: "none" }}
                  value={interviewId}
                  maxLength={150}
                  onChange={(e) => setInterviewId(e.target.value)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {linkTypes[linkType] && !linkTypes[linkType].url.includes("null") && (
        <div
          style={{
            backgroundColor: "#efefef",
            padding: "30px 25px",
            borderRadius: "25px",
            marginTop: "25px",
          }}
        >
          <div>
            <div style={{ display: "inline" }}>
              <a
                href={linkTypes[linkType].url}
                style={{
                  fontWeight: "bold",
                  position: "relative",
                }}
                target="_blank"
                rel="noreferrer"
              >
                {linkTypes[linkType].url.substring(0, 30) + "..."}
              </a>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(linkTypes[linkType].url);
                }}
                style={{
                  float: "right",
                  fontWeight: "bold",
                  padding: "15px 25px",
                  backgroundColor: "#162e3c",
                  color: "#efefef",
                  borderRadius: "5px",
                  display: "inline-block",
                  position: "relative",
                  bottom: "15px",
                }}
              >
                Copy{" "}
                <span
                  className="icon-small"
                  style={{
                    display: "inline-block",
                    fontSize: "18px",
                    position: "relative",
                    lineHeight: "18px",
                    top: "3px",
                    height: "18px",
                    width: "21px",
                  }}
                >
                  copy
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkGenerator;
