import { Referrals } from "../Components";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { recordReferralInteraction } from "../backend";

const ReferAFriend = () => {
  let { candidateId } = useParams();
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();
  let touchpointId = searchParams.get("touchpointId");

  useEffect(() => {
    try {
      recordReferralInteraction("engage", touchpointId, candidateId);
      navigator.share({
        title: "Invite a friend/colleague to Hirebolt",
        text: "Hey, have a look at Hirebolt. It helps you find a job in hospitality by getting employers to reach out to you, so you don't even have to send applications\n",
        url: `https://www.hirebolt.com/candidate-form?ref=${candidateId}`,
      });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex" }}>
      <Referrals
        candidateId={candidateId}
        style={{ margin: "50px auto", padding: " 0 25px" }}
      />
    </div>
  );
};

export default ReferAFriend;
