import React, { useCallback, useEffect, useState } from "react";
import {
  fetchNearbyEmployers,
  getCandidateInfo,
  getCandidateInterviews,
  updateCandidate,
} from "../backend";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { CandidateLogin, Modal } from "../Components";
import {
  abTesting,
  getAllRoles,
  getRoleFromId,
  getSimilarRoles,
} from "../utils";
import moment from "moment-timezone";
import { MoonLoader } from "react-spinners";
import { CvUpload, Select, TextField } from "../Components/Forms";
import { debounce } from "lodash";
import Switch from "react-switch";

let previousData;
let candidateLoaded = false;

const CandidateDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let candidateId = searchParams.get("id");
  let show = searchParams.get("show");
  let reactivate = searchParams.get("reactivate");

  const [data, setData] = useState();
  const [interviews, setInterviews] = useState([]);
  const [sideBarOpen, setSideBarOpen] = useState(!isMobile);
  const [savedShown, setSavedShown] = useState();
  const [pageShown, setPageShown] = useState("home");
  const [cvUploadErrorShown, setCvUploadErrorShown] = useState(false);
  const [settingsToggled, setSettingsToggled] = useState({});
  const [modalShown, setModalShown] = useState();

  // eslint-disable-next-line
  let saveCandidate = useCallback(
    debounce(
      (args) => {
        updateCandidate(args);
        setSavedShown(true);
        setTimeout(() => setSavedShown(false), 7000);
      },
      1000,
      {
        trailing: true,
        leading: false,
      }
    ),
    []
  );

  useEffect(() => {
    async function fetchData() {
      if (candidateId) {
        document.cookie = `candidateId=${candidateId}`;
      } else {
        // Get candidateId from cookie
        let id;
        for (let i = 0; i < document.cookie.split(";").length; i++) {
          const cookieKeyValue = document.cookie.split(";")[i].split("=");
          if (cookieKeyValue[0].trim() === "candidateId") {
            id = cookieKeyValue[1];
          }
        }
        if (!id) {
          // Show login page
          setPageShown("login");
          return;
        } else {
          //eslint-disable-next-line
          candidateId = id;
        }
      }
      let [e, i] = await Promise.all([
        getCandidateInfo(candidateId),
        getCandidateInterviews(candidateId),
      ]);
      setData(e);
      setInterviews(i);
      if (show) setModalShown(show);
      if (reactivate && JSON.parse(reactivate)) {
        e["Last active check"] = moment().toDate();
        e["Open To Opportunities"] = true;
        e["Reactivated Time"] = moment().toDate();
        setData(e);
      }
    }
    fetchData();
    setSearchParams({});
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      data &&
      candidateLoaded &&
      JSON.stringify(data) !== JSON.stringify(previousData) &&
      data["What positions are you looking for?"].length &&
      data["Minimum Salary"] &&
      data["What job type are you looking for?"].length &&
      data["Postcode"]
    ) {
      saveCandidate({
        id: data["Id"],
        properties: { ...data, _id: undefined },
      });
      previousData = JSON.parse(JSON.stringify(data));
    }
    if (data) candidateLoaded = true;
    //eslint-disable-next-line
  }, [data]);

  // Debounce fetchNearbyEmployers
  let getNearbyEmployers = debounce(
    async (args) => {
      await fetchNearbyEmployers(args).then((res) =>
        setData({ ...data, Postcode: args.Postcode, "Nearby Employers": res })
      );
    },
    2000,
    {
      trailing: true,
      leading: false,
    }
  );

  const setPostcode = (postcode) => {
    const run = async () => {
      getNearbyEmployers({
        ...data,
        Postcode: postcode,
      });
    };
    setData({ ...data, Postcode: postcode });
    if (postcode.replace(/\s/g, "").length >= 5 && data["Max Commute"]) {
      run();
    }
  };

  const displayInterviewStatus = (interview) => {
    let style = {};
    let text = "";
    if (interview && moment(interview["Date"]).isBefore(moment())) {
      style = { color: "#545454", backgroundColor: "#eeeeee" };
      text = "Past";
    } else if (interview && interview["Status"] === "Cancelled") {
      style = { color: "#F44336", backgroundColor: "#ffebeb" };
      text = "Cancelled";
    } else if (interview) {
      style = { color: "#18981d", backgroundColor: "#ccf5cb" };
      text = "Confirmed";
    }
    return { style, text };
  };

  const parseInterviewDuration = (duration) => {
    if (duration <= 60) return `${duration} minutes`;
    return `${Math.floor(duration / 60)}${
      Math.floor(duration % 60) > 0 ? `.${Math.floor(duration % 60)}` : ""
    } hours`;
  };

  const getSalaryFormatted = (role) => {
    return role.minSalary && role.minSalary >= 10
      ? "£" + role.minSalary + "-" + role.maxSalary + "/hr"
      : role.maxSalary
      ? ["£" + role.maxSalary + "/hr"]
      : ["-"];
  };

  return (
    <div
      style={{
        width: "100vw",
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "#f2f0ed",
      }}
    >
      <Modal
        isOpen={modalShown === "no-opportunities"}
        closeModal={() => setModalShown(null)}
      >
        <div style={{ padding: "15px", maxWidth: "500px" }}>
          <br />
          <p
            style={{
              textAlign: "center",
              fontSize: "smaller",
              textTransform: "uppercase",
              fontWeight: "bold",
              opacity: 0.5,
            }}
          >
            No opportunities found
          </p>
          <h5 style={{ textAlign: "center" }}>
            We couldn't find any matching job
          </h5>
          <p style={{ textAlign: "center", margin: "8px 0" }}>
            Please try to update your preferences
            <br />
            or check back later.
          </p>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="button w-button"
              onClick={() => {
                setModalShown(null);
                setSettingsToggled({
                  ...settingsToggled,
                  pause: !settingsToggled.pause,
                });
                window.open("#settings", "_self");
              }}
            >
              Update preferences
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalShown === "update-preferences"}
        closeModal={() => setModalShown(null)}
      >
        <div style={{ padding: "15px", maxWidth: "500px" }}>
          <br />
          <p
            style={{
              textAlign: "center",
              fontSize: "smaller",
              textTransform: "uppercase",
              fontWeight: "bold",
              opacity: 0.5,
            }}
          >
            Welcome back!
          </p>
          <h5 style={{ textAlign: "center" }}>
            Update your preferences to see relevant job opportunities
          </h5>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className="button w-button"
              onClick={() => {
                setModalShown(null);
                setSettingsToggled({
                  ...settingsToggled,
                  pause: !settingsToggled.pause,
                });
                window.open("#settings", "_self");
              }}
            >
              Update preferences
            </button>
          </div>
        </div>
      </Modal>
      {pageShown === "login" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
            width: "100vw",
          }}
        >
          <CandidateLogin
            onLogin={(candidate) => {
              document.cookie = `candidateId=${candidate["Id"]}`;
              setData(candidate);
              setPageShown("home");
            }}
          />
        </div>
      )}
      {data ? (
        <div className="employer-admin-form row-all-devices">
          {savedShown && (
            <div
              style={{
                padding: "15px",
                borderRadius: "15px",
                backgroundColor: "#bcf5bc",
                margin: "15px",
                fontSize: "smaller",
                position: "fixed",
                zIndex: 2000,
                bottom: 0,
                right: 0,
              }}
            >
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                <span
                  className="icon-small"
                  style={{
                    paddingRight: "15px",
                    fontSize: "24px",
                    lineHeight: "16px",
                    display: "inline",
                    position: "relative",
                    bottom: "-5px",
                  }}
                >
                  check_circle
                </span>
                Changes Saved
              </p>
            </div>
          )}
          <div
            style={{
              display: "absolute",
              left: 0,
              top: 0,
              minHeight: "100vh",
              width: sideBarOpen
                ? isMobile
                  ? "100vw"
                  : "275px"
                : isMobile
                ? "0"
                : "30px",
              minWidth: isMobile ? "0" : "30px",
              maxWidth: isMobile ? "100vw" : "275px",
              backgroundColor: "rgb(235, 233, 230)",
              padding: sideBarOpen ? "24px" : 0,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                flexDirection: "column",
                display: sideBarOpen ? "flex" : "none",
                visibility: sideBarOpen ? "visible" : "hidden",
              }}
            >
              {isMobile && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    padding: "24px",
                  }}
                  onClick={() => setSideBarOpen(!sideBarOpen)}
                >
                  <span
                    className="icon-small"
                    style={{
                      fontSize: "30px",
                      height: "30px",
                      float: "right",
                      color: "black",
                      opacity: 0.5,
                    }}
                  >
                    close
                  </span>
                </div>
              )}
              <a href="/candidates">
                <img
                  src="/images/hirebolt-logo-black.png"
                  loading="lazy"
                  width="150"
                  alt=""
                  style={{
                    float: "left",
                    opacity: 0.5,
                    marginLeft: "8px",
                  }}
                />
              </a>
              <div style={{ flex: "1", margin: "64px 0" }}>
                <div
                  className="sidebar-item"
                  onClick={() => {
                    setPageShown("home");
                    if (isMobile) setSideBarOpen(false);
                  }}
                >
                  <span className="icon-small" style={{ fontSize: "20px" }}>
                    home
                  </span>
                  <span style={{ margin: "auto 0" }}>Home</span>
                </div>
                {abTesting(data["WhatsApp Number"], "candidate-profile", 2) && (
                  <div
                    className="sidebar-item"
                    onClick={() => {
                      setPageShown("profile");
                      if (isMobile) setSideBarOpen(false);
                    }}
                  >
                    <span className="icon-small" style={{ fontSize: "20px" }}>
                      person
                    </span>
                    <span style={{ margin: "auto 0" }}>Your Profile</span>
                  </div>
                )}
                <div
                  className="sidebar-item"
                  onClick={() => {
                    window.open(
                      `/candidate-opportunities?id=${data["Id"]}`,
                      "_blank"
                    );
                  }}
                >
                  <span className="icon-small" style={{ fontSize: "20px" }}>
                    work
                  </span>
                  <span style={{ margin: "auto 0" }}>Job Opportunities</span>
                </div>
                <div
                  className="sidebar-item"
                  onClick={() => {
                    document.cookie = `candidateId=`;
                    window.location.reload();
                  }}
                >
                  <span className="icon-small" style={{ fontSize: "20px" }}>
                    logout
                  </span>
                  <span style={{ margin: "auto 0" }}>Logout</span>
                </div>
              </div>
            </div>
            {!isMobile && (
              <div
                style={{
                  position: "absolute",
                  top: "75px",
                  left: sideBarOpen ? "245px" : "0",
                  borderRadius: "100%",
                  backgroundColor: "rgb(235, 233, 230)",
                  width: "50px",
                  height: "50px",
                  cursor: "pointer",
                  WebkitTapHighlightColor: "transparent",
                }}
                onClick={() => setSideBarOpen(!sideBarOpen)}
              >
                <span
                  className="icon-small"
                  style={{
                    display: "block",
                    color: "black",
                    opacity: "0.5",
                    fontSize: "30px",
                    height: "30px",
                    width: "30px",
                    margin: "auto",
                    position: "relative",
                    top: "2px",
                    left: "3px",
                  }}
                >
                  {sideBarOpen
                    ? "keyboard_arrow_left_icon"
                    : "keyboard_arrow_right_icon"}
                </span>
              </div>
            )}
          </div>
          {isMobile && sideBarOpen ? (
            <div></div>
          ) : (
            <div
              style={{
                flex: 1,
                overflowY: "scroll",
              }}
            >
              {isMobile && (
                <div
                  style={{
                    width: "100%",
                    height: "60px",
                    padding: "24px",
                  }}
                  onClick={() => setSideBarOpen(!sideBarOpen)}
                >
                  <span
                    className="icon-small"
                    style={{
                      fontSize: "30px",
                      height: "30px",
                      width: "30px",
                      float: "right",
                    }}
                  >
                    menu
                  </span>
                </div>
              )}
              {pageShown === "home" && (
                <div
                  style={{
                    padding: isMobile
                      ? "24px 24px 256px 24px"
                      : "48px 64px 256px 64px",
                  }}
                >
                  <h3>Hi, {data["Name"]} 👋</h3>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "grey",
                      margin: "48px 0 16px",
                    }}
                  >
                    Upcoming interviews
                  </p>
                  {interviews && interviews.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "scroll",
                        gap: "8px",
                        maxWidth: "100%",
                        position: "absolute",
                        paddingRight: "24px",
                      }}
                      className="hide-scrollbar"
                    >
                      {interviews
                        .filter((i) =>
                          moment(i["Date"]).isSameOrAfter(moment(), "day")
                        )
                        .map((interview) => (
                          <div
                            style={{
                              backgroundColor: "white",
                              borderRadius: "16px",
                              padding: "24px",
                              minWidth: isMobile ? "85%" : "300px",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "4px",
                                    padding: "2px 10px",
                                    width: "fit-content",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                    textTransform: "uppercase",
                                    ...displayInterviewStatus(interview).style,
                                  }}
                                >
                                  {displayInterviewStatus(interview).text}
                                </div>
                                <div
                                  className="icon-small"
                                  style={{
                                    fontSize: "20px",
                                    height: "20px",
                                    color: "black",
                                    opacity: 0.33,
                                    cursor: "pointer",
                                    marginRight: "-8px",
                                  }}
                                >
                                  more_vert
                                </div>
                              </div>
                              <h6 style={{ marginTop: "16px" }}>
                                {interview.employer.employerName
                                  .split(" ")
                                  .map((word) => {
                                    if (word.length > 25) {
                                      return word.slice(0, 25) + "...";
                                    }
                                    return word;
                                  })
                                  .join(" ")
                                  .split("-")
                                  .map((word) => {
                                    if (word.length > 25) {
                                      return word.slice(0, 25) + "...";
                                    }
                                    return word;
                                  })
                                  .join("-")}
                              </h6>
                              <p
                                style={{
                                  opacity: "0.8",
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: "16px",
                                }}
                              >
                                <span
                                  className="icon-small"
                                  style={{
                                    fontSize: "16px",
                                    height: "16px",
                                    width: "16px",
                                    position: "relative",
                                    top: "2px",
                                    marginRight: "4px",
                                  }}
                                >
                                  location_on
                                </span>
                                <a
                                  href={`http://maps.google.com/?q=${interview.employer.name}, ${interview.employer.address}`.replace(
                                    / /g,
                                    "+"
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    fontSize: "12px",
                                    opacity: 0.66,
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {interview.employer.branchName
                                    ? interview.employer.branchName + ", "
                                    : ""}
                                  {interview.employer.address}
                                </a>
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "16px",
                                }}
                              >
                                <p
                                  style={{
                                    opacity: "0.8",
                                    fontSize: "smaller",
                                  }}
                                >
                                  <span
                                    className="icon-small"
                                    style={{
                                      fontSize: "16px",
                                      height: "16px",
                                      width: "16px",
                                      position: "relative",
                                      top: "2px",
                                      marginRight: "4px",
                                      display: "inline-block",
                                    }}
                                  >
                                    calendar_today
                                  </span>
                                  {moment(interview["Date"]).format(
                                    "D MMM - h:mma"
                                  )}{" "}
                                  (
                                  {parseInterviewDuration(
                                    parseInt(
                                      getRoleFromId(
                                        interview.employer,
                                        interview["Role Id"]
                                      )?.roleInterviewSlots
                                        ? getRoleFromId(
                                            interview.employer,
                                            interview["Role Id"]
                                          ).roleInterviewSlots[0].duration
                                        : interview.employer.interviewSlots[0]
                                            .duration
                                    )
                                  )}
                                  )
                                </p>
                                <p
                                  style={{
                                    opacity: "0.8",
                                    fontSize: "smaller",
                                  }}
                                >
                                  <span
                                    className="icon-small"
                                    style={{
                                      fontSize: "16px",
                                      height: "16px",
                                      width: "16px",
                                      position: "relative",
                                      top: "2px",
                                      marginRight: "4px",
                                      display: "inline-block",
                                    }}
                                  >
                                    work
                                  </span>
                                  {
                                    getRoleFromId(
                                      interview.employer,
                                      interview["Role Id"]
                                    )?.name
                                  }{" "}
                                  -{" "}
                                  {getSalaryFormatted(
                                    getRoleFromId(
                                      interview.employer,
                                      interview["Role Id"]
                                    )
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: isMobile ? "88vw" : "50vw",
                        borderRadius: "16px",
                        backgroundColor: "rgb(235, 233, 230)",
                        display: "flex",
                        flexDirection: "column",
                        margin: "16px 0",
                        padding: "48px",
                        alignItems: "center",
                        gap: "16px",
                        position: "absolute",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "center",
                        }}
                      >
                        <b>You have no upcoming interviews</b>
                      </p>
                      <button
                        className="button w-button"
                        style={{
                          borderRadius: "8px",
                          padding: "8px 32px",
                          fontSize: "smaller",
                        }}
                        onClick={() => {
                          window.open(
                            `/candidate-opportunities?id=${data["Id"]}`,
                            "_blank"
                          );
                        }}
                      >
                        See job opportunities
                      </button>
                    </div>
                  )}
                  <p
                    style={{
                      fontSize: "16px",
                      color: "grey",
                      margin: "300px 0 16px",
                    }}
                  >
                    Settings
                  </p>
                  <div
                    id="settings"
                    style={{
                      width: isMobile ? "88vw" : "50vw",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      backgroundColor: "rgb(235, 233, 230)",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomLeftRadius: settingsToggled.pause ? 0 : 16,
                      borderBottomRightRadius: settingsToggled.pause ? 0 : 16,
                    }}
                    onClick={() => {
                      setSettingsToggled({
                        ...settingsToggled,
                        pause: !settingsToggled.pause,
                      });
                    }}
                  >
                    <span
                      className="icon-small"
                      style={{
                        paddingRight: "15px",
                        fontSize: "24px",
                        lineHeight: "24px",
                        float: "left",
                        display: "inline",
                        position: "relative",
                        height: "24px",
                        color: "black",
                        opacity: 0.5,
                      }}
                    >
                      {settingsToggled.pause
                        ? "keyboard_arrow_down_icon"
                        : "keyboard_arrow_right_icon"}
                    </span>
                    <p
                      style={{
                        flex: 1,
                        fontWeight: "bold",
                        display: "inline",
                        fontSize: "16px",
                        color: "black",
                        opacity: 0.5,
                      }}
                    >
                      Update your Preferences
                    </p>
                  </div>
                  {settingsToggled.pause && (
                    <div
                      style={{
                        width: isMobile ? "88vw" : "50vw",
                        padding: "16px 24px",
                        backgroundColor: "rgb(235, 233, 230)",
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                      }}
                    >
                      <div
                        id="candidate-form"
                        style={{
                          margin: "16px 0",
                          borderRadius: "24px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100px",
                            borderRadius: "8px",
                            border: "1px solid rgba(0,0,0,0.1)",
                            marginBottom: "8px",
                            display: "flex",
                            color: "black",
                          }}
                        >
                          <div
                            className="row-all-devices"
                            style={{ height: "100%", flex: 1 }}
                          >
                            <div
                              className="icon-large"
                              style={{
                                margin: "auto 0 auto 16px",
                                fontSize: "3.5rem",
                                height: "3.5rem",
                                opacity: "0.5",
                              }}
                            >
                              picture_as_pdf
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <p>
                                <b>Your CV</b>
                              </p>
                              <p style={{ fontSize: "smaller" }}>
                                Added on:{" "}
                                {moment(
                                  data["CV Added"] ?? data["Created Time"]
                                ).format("Do MMM YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", justifyContent: "right" }}
                        >
                          <CvUpload
                            value={data["Raw CV"]}
                            placeholder="Upload new CV"
                            style={{
                              backgroundColor: "rgba(0,0,0,0.5)",
                              boxShadow: "none",
                              color: "#f2f0ed",
                              padding: "4px",
                            }}
                            onChange={(value) => {
                              if (
                                typeof value.candidateExperience === "object"
                              ) {
                                setData({
                                  ...data,
                                  "Raw CV": value.url,
                                  "CV Added": moment().toDate(),
                                  ...value.candidateExperience,
                                });
                                setCvUploadErrorShown(false);
                              } else {
                                setCvUploadErrorShown(true);
                              }
                            }}
                          />
                        </div>
                        {cvUploadErrorShown && (
                          <p
                            style={{
                              fontSize: "smaller",
                              color: "#d44040",
                              marginTop: "8px",
                              textAlign: "center",
                            }}
                          >
                            <b>Error:</b> We weren't able to process this CV,
                            please try again or use a different file.
                          </p>
                        )}
                        <br />
                        <br />
                        <p
                          style={{
                            color: "black",
                            opacity: 0.5,
                            fontWeight: "bold",
                          }}
                        >
                          What jobs are you looking for?
                        </p>
                        <Select
                          selected={data["What positions are you looking for?"]}
                          placeholder="Head Waiter"
                          allowSearch={false}
                          options={getAllRoles()}
                          onChange={(value) => {
                            if (value.length <= 3) {
                              setData({
                                ...data,
                                "What positions are you looking for?": value,
                              });
                            }
                          }}
                          suggestion={
                            data["What positions are you looking for?"]
                              ?.length &&
                            data["What positions are you looking for?"]
                              ?.length < 3
                              ? `Select also '${getSimilarRoles(
                                  data["What positions are you looking for?"]
                                )
                                  .filter(
                                    (v) =>
                                      !data[
                                        "What positions are you looking for?"
                                      ].includes(v)
                                  )
                                  .map((v) => v.split("/")[0])
                                  .slice(0, 2)
                                  .join(
                                    "' or '"
                                  )}' to increase chances of getting a job`
                              : ""
                          }
                        />
                        <div className="row" style={{ marginTop: "24px" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                opacity: 0.5,
                                fontWeight: "bold",
                              }}
                            >
                              Minimum Salary
                            </p>
                            <TextField
                              placeholder="10"
                              max="2"
                              value={data["Minimum Salary"]}
                              prefix="£"
                              suffix="/hour"
                              onChange={(value) => {
                                if (
                                  (/^\d+$/.test(value) || !value) &&
                                  value <= 40
                                ) {
                                  setData({
                                    ...data,
                                    "Minimum Salary": value,
                                  });
                                }
                              }}
                            />
                          </div>
                          &nbsp;&nbsp;&nbsp;
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                opacity: 0.5,
                                fontWeight: "bold",
                              }}
                            >
                              Full-Time or Part-Time?
                            </p>
                            <Select
                              selected={
                                data["What job type are you looking for?"]
                              }
                              placeholder="Full-Time"
                              allowSearch={false}
                              options={[
                                "Part Time",
                                "Full-Time (Permanent)",
                                "Full-Time (Summer/Winter Break Only)",
                              ]}
                              onChange={(value) => {
                                setData({
                                  ...data,
                                  "What job type are you looking for?": value,
                                });
                              }}
                              suggestion={
                                data["What job type are you looking for?"]
                                  ?.length &&
                                !data[
                                  "What job type are you looking for?"
                                ]?.includes("Full-Time (Permanent)")
                                  ? `Select also 'Full-Time' to increase chances of getting a job`
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="row-all-devices"
                          style={{ marginTop: "24px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                opacity: 0.5,
                                fontWeight: "bold",
                              }}
                            >
                              {isMobile
                                ? "I can commute max"
                                : "I'm willing to commute max"}
                            </p>
                            <Select
                              selected={data["Max Commute"] + " mins"}
                              placeholder="30 mins"
                              allowSearch={false}
                              options={[
                                "15 mins",
                                "30 mins",
                                "45 mins",
                                "60 mins",
                              ]}
                              allowMultiple={false}
                              onChange={(value) => {
                                setData({
                                  ...data,
                                  "Max Commute": value.split(" ")[0],
                                });
                              }}
                              suggestion={
                                data["Max Commute"] &&
                                data["Max Commute"] === "15 mins"
                                  ? "Select '30 mins' to increase chances of getting a job"
                                  : ""
                              }
                            />
                          </div>
                          &nbsp;&nbsp;&nbsp;
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                opacity: 0.5,
                                fontWeight: "bold",
                              }}
                            >
                              From this postcode
                            </p>
                            <TextField
                              placeholder="Your Postcode"
                              value={data["Postcode"]}
                              onChange={(value) => {
                                setPostcode(value);
                              }}
                            />
                          </div>
                        </div>
                        <br />
                        <br />
                        <div
                          className="row-all-devices"
                          style={{ justifyContent: "space-between" }}
                        >
                          <p style={{ color: "black", opacity: 0.5 }}>
                            <b>I'm looking for a job</b>
                          </p>
                          <Switch
                            onChange={(checked) => {
                              setData({
                                ...data,
                                "Open to Opportunities": checked,
                              });
                            }}
                            checked={data["Open to Opportunities"]}
                            offColor="#9c9b99"
                            onColor="#3cb043"
                            offHandleColor="#f2f0ed"
                            onHandleColor="#f2f0ed"
                            height={28}
                            handleDiameter={24}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {pageShown === "profile" && (
                <div style={{ width: "100%", height: "100%", padding: "48px" }}>
                  <p>
                    <b>
                      This page is publicly visible to all employers on
                      Hirebolt:
                    </b>
                  </p>
                  <div
                    style={{
                      margin: "24px 0",
                      padding: "24px 32px",
                      borderRadius: "24px",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: isMobile ? "column" : "row-reverse",
                        gap: "16px",
                      }}
                    >
                      <div
                        className="row-all-devices"
                        style={{
                          height: "100%",
                          backgroundColor: "#f3f3f3",
                          padding: "8px 16px",
                          borderRadius: "8px",
                        }}
                      >
                        <div
                          className="icon-large"
                          style={{
                            margin: "auto 8px auto",
                            fontSize: "3.5rem",
                            height: "3.5rem",
                            width: "3.5rem",
                            opacity: "0.5",
                          }}
                        >
                          picture_as_pdf
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <p>
                            <b>See CV {">"}</b>
                          </p>
                        </div>
                      </div>
                      <h2>{data["Name"]}</h2>
                    </div>
                    <p>
                      Looking for{" "}
                      {data["What positions are you looking for?"].join(", ")}{" "}
                      roles
                    </p>
                    <br />
                    <br />
                    <h6>Past Interview record 🟢</h6>
                    <p>Interviews Scheduled: {interviews.length}</p>
                    <p>
                      Interviews Cancelled:{" "}
                      {
                        interviews.filter((i) => i["Status"] === "Cancelled")
                          .length
                      }
                    </p>
                    <p>
                      Interviews No-Showed:{" "}
                      {
                        interviews.filter((i) => i["Status"] === "No Show")
                          .length
                      }
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <>
          {pageShown !== "login" && (
            <div style={{ height: "100vh", width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "fit-content",
                  height: "fit-content",
                  margin: "auto",
                }}
              >
                <MoonLoader
                  color={"rgb(22, 46, 60)"}
                  loading={true}
                  size={50}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CandidateDashboard;
