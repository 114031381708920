import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobile } from "react-device-detect";
import {getInterviewReminderCheck,
  getOpportunitiesCheck,
  getEmployerReceivesCandidatesCheck,
  getSignupCheck, getEmployerContactCandidatesCheck, getEmployerRecapCheck,
  getInterviewConfirmationsCheck
} from "../backend";
import { MoonLoader } from "react-spinners";
import StatusCheckIndicator from "../Components/StatusCheckIndicator";

const ControlPanel = () => {
    const [checks, setChecks] = useState({});
    const [dataLoaded , setDataLoaded] = useState(false);

    const { isAuthenticated, isLoading, loginWithRedirect, logout, error } =
        useAuth0();

    if (error) {
        logout({ returnTo: window.location.origin });
    }
    useEffect(() => {
        const getData = async () => {

            let [signupCheck, opportunitiesCheck, employerReceivesCandidates, employerContactCandidatesCheck, employerRecapCheck, interviewConfirmationsCheck, interviewReminderCheck ] = await Promise.all([getSignupCheck(),getOpportunitiesCheck(), getEmployerReceivesCandidatesCheck(), getEmployerContactCandidatesCheck(), getEmployerRecapCheck(), getInterviewConfirmationsCheck(), getInterviewReminderCheck()]);
            setChecks({signupCheck, opportunitiesCheck, employerReceivesCandidates, employerContactCandidatesCheck, employerRecapCheck, interviewConfirmationsCheck, interviewReminderCheck});
            setDataLoaded(true)
        };
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        } else if (isAuthenticated) {
            getData();
        }
        //eslint-disable-next-line
    }, [isLoading]);

    return (
        <div
            style={{
                width: "100vw",
                maxWidth: "100vw",
                height: "100vh",
                backgroundColor: "#f2f0ed",
                display: "flex",
            }}
        >
            {isAuthenticated && checks && dataLoaded ? (
                <div
                    style={{
                        margin: "auto",
                        padding: "32px",
                        height: "90vh",
                        flex: 1,
                        maxWidth: isMobile ? "100vw" : "1000px",
                    }}
                >
                    <div className="text-size-regular">
                        <ul>
                            <li>
                                Candidates signup with all compulsory information
                                <StatusCheckIndicator check={checks.signupCheck}></StatusCheckIndicator>
                            </li>
                            <br />
                            <li>
                                Candidates see some relevant opportunities
                                <StatusCheckIndicator check={checks.opportunitiesCheck}></StatusCheckIndicator>
                            </li>
                            <br />
                            <li>
                                Employers receive candidates
                                <StatusCheckIndicator check={checks.employerReceivesCandidates}></StatusCheckIndicator>
                            </li>
                            <br />
                            <li>
                                Employers are able to contact candidates
                                <StatusCheckIndicator check={checks.employerContactCandidatesCheck}></StatusCheckIndicator>
                            </li>
                            <br />
                            <li>
                                Candidates receive interview confirmations
                                <StatusCheckIndicator check={checks.interviewConfirmationsCheck}></StatusCheckIndicator>
                            </li>
                            <br />
                            <li>
                                Candidates receive interview reminders
                                <StatusCheckIndicator check={checks.interviewReminderCheck}></StatusCheckIndicator>
                            </li>
                            <br />
                            <li>
                                Employers receive interview confirmations
                                <StatusCheckIndicator check={checks.employerReceivesCandidates}></StatusCheckIndicator>
                            </li>
                            <br />
                            <li>
                                Employers receive interview reminders/recap
                                <StatusCheckIndicator check={checks.employerRecapCheck}></StatusCheckIndicator>
                            </li>
                        </ul>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        width: "fit-content",
                        height: "fit-content",
                        margin: "auto",
                    }}
                >
                    <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
                </div>
            )}
        </div>
    );
};
export default ControlPanel;
