import confirmAnimation from "../Components/confirmAnimation.json";
import { useSearchParams, useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";

const CandidateOnboardingSuccess = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const candidateId = searchParams.get("id");

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f2f0ed",
        position: "absolute",
        top: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        alignItems: "flex-start",
      }}
    >
      <div className="navbar-container" style={{ height: "fit-content" }}>
        <a href={`/candidates`} style={{ padding: "16px", opacity: "0.25" }}>
          <img
            src="images/hirebolt-logo-black.png"
            loading="lazy"
            width="130"
            alt=""
            className="shopscribe-logo shopscribe-logo-white"
          />
        </a>
      </div>
      <div
        style={{
          textAlign: "center",
          padding: "0 24px",
          maxWidth: "700px",
          margin: "auto",
        }}
      >
        <Lottie
          animationData={confirmAnimation}
          loop
          play
          speed={0.75}
          style={{
            width: "200px",
            height: "200px",
            margin: "35px auto 0",
          }}
        />
        <p
          style={{
            fontSize: "smaller",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Thanks for stating your interests!
        </p>
        <h4 style={{ fontFamily: "Spacegrotesk, sans-serif" }}>
          We sent your profile to relevant employers 🚀
        </h4>
        <br />
        <p
          style={{
            fontSize: "16px",
            marginBottom: candidateId ? "24px" : "150px",
          }}
        >
          If an interview is confirmed, you'll receive the confirmation via
          WhatsApp - usually within 2 days!
        </p>
        {candidateId && (
          <button
            className="button animate__animated animate__headShake animate__delay-4s"
            onClick={() => {
              navigate(`/candidate-opportunities?id=${candidateId}`);
            }}
            style={{
              borderRadius: "10px",
              padding: "0.75rem 1.5rem",
              marginTop: "24px",
              fontWeight: "bold",
              marginBottom: "128px",
            }}
          >
            See more job opportunities
          </button>
        )}
      </div>
    </div>
  );
};

export default CandidateOnboardingSuccess;
