import React, { useEffect, useState } from "react";
import {getAuthEmployerInfo, updateEmployer} from "../backend";
import { isMobile } from "react-device-detect";
import {useNavigate, useParams} from "react-router-dom";

const EmployerPictures = () => {
  let { employerId, employerSecret } = useParams();
  // Track the employer's data
  const [data, setData] = useState();
  const navigate = useNavigate();
  const navigateToEmployerDashboard = () => {
    updateEmployer(data);
      navigate(`/${employerId}/${employerSecret}`);
  };
  useEffect(() => {
    async function fetchData() {
      let e = await getAuthEmployerInfo(employerId, employerSecret);
      // loop over branches and add pictures array if it doesn't exist route to dashboard
      for( let i = 0; i < e.branches.length; i++) {
        if (!e.branches[i].pictures) {
          navigateToEmployerDashboard();
        }
      }
      setData(e);
      }
    fetchData();
    //eslint-disable-next-line
  }, []);

  return (
      <div
          style={{
            backgroundColor: "rgb(242, 240, 237)",
            height: isMobile ? "100%" : "100%",
            padding: "48px",
          }}
      >
        <h4 className="container" style={{ paddingBottom: "8px" }}>
          Branch pictures
        </h4>
        <p className="container">
          Please remove low-quality, unprofessional or irrelevant pictures. Click on an image to remove it.
        </p>
        <br />
        <br />
        <div>
          {data && data.branches.map((branch) => (
        <div className="container" style={{ paddingTop: "10px" }}>
            { branch.pictures && <h2>branch {branch.id}</h2>  }
          <div className="container w-layout-grid" style={{ paddingTop: "10px" }}>
          {branch.pictures && branch.pictures.map((picture) => (
              <div
                  id="candidate-info"
                  className="col-auto"
                  style={{
                    padding: "18px 18px 18px 0",
                    maxWidth: "700px",
                  }}
              >
                <div style={{ gap: "16px" }}>
                  <div
                      className="image-selection"
                      onClick={() => {
                        // delete clicked branch picture
                        let branchIndex = data.branches.findIndex((b) => b.id === branch.id);
                        let pictureIndex = data.branches[branchIndex].pictures.findIndex((p) => p === picture);
                        data.branches[branchIndex].pictures.splice(pictureIndex, 1);
                        setData({...data});
                      }
                      }
                  >
                      <div class="image-selection-container">
                    <img
                        src={picture}
                        style={{
                          borderRadius: "8px",
                          float: "left",
                          width:  "300px",
                          height: "300px",
                          objectFit: "cover",
                        }}
                        loading="lazy"
                        height={isMobile ? "300px" : "300px"}
                        alt=""
                        className="shopscribe-logo shopscribe-logo-white"
                    />
                      <div class="image-overlay">
                          <span
                              className="icon-large"
                              style={{
                                  display: "inline-block",
                                  fontSize: "100px",
                                  lineHeight: "20px",
                                  height: "20px",
                                  width: "20px",
                              }}
                          >
                delete_forever
              </span>

                      </div>
                      </div>
                  </div>
                  <div
                      style={{
                        flex: 3,
                        height: "fit-content",
                        margin: "auto 0",
                      }}
                  >
                    <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "1.5rem",
                          width: "fit-content",
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          gap: "4px",
                        }}
                    >
                  <span>
                  </span>
                    </p>
                  </div>
                </div>
              </div>
          ))}
        </div>
        </div>
          ))}
        </div>
        <div className="container">
          <button
              className="button w-button"
              style={{
                padding: "8px 32px",
                borderRadius: "8px",
                flex: 1,
                whiteSpace: "nowrap",
                width: isMobile ? "80%" : "33%",
                margin: "64px 0 16px 0",
              }}
              onClick={() => navigateToEmployerDashboard()}
          >
            Continue
          </button>
        </div>
      </div>
  );
};

export default EmployerPictures;
