import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

const TextField = ({
  label,
  placeholder = "",
  value = "",
  style = {},
  prefix,
  suffix,
  max,
  focused = false,
  tooltip,
  compulsory = false,
  suggestion = "",
  onChange = () => {},
  onEnter = () => {},
}) => {
  const [interacted, setInteracted] = useState(false);
  let textFieldRef;

  const handleKeyDown = (e) => {
    if (e.key === "Enter") onEnter();
  };

  useEffect(() => {
    if (focused) textFieldRef.focus();
    //eslint-disable-next-line
  }, [focused]);

  return (
    <div className="column" style={{ ...style }}>
      <label
        className="main-label"
        style={{
          fontSize: "smaller",
          fontWeight: "bold",
          margin: "0 0 8px",
        }}
      >
        <span
          style={{
            textTransform: "uppercase",
            color: "#162e3c",
          }}
        >
          {label}
        </span>
        {compulsory && (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              verticalAlign: "super",
              marginLeft: "4px",
            }}
          >
            *
          </span>
        )}
        {tooltip && (
          <span
            className="icon-large"
            data-tip={tooltip}
            data-for="textfield-tooltip"
            style={{
              display: "inline",
              fontSize: "17px",
              lineHeight: "17px",
              height: "17px",
              width: "17px",
              position: "relative",
              top: "4px",
              left: "4px",
              opacity: ".5",
              cursor: "pointer",
            }}
          >
            help
          </span>
        )}
        <ReactTooltip effect="solid" id="textfield-tooltip" place="bottom" />
      </label>
      <div
        className="rounded form-text"
        style={{
          width: "100%",
          boxShadow: "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)",
          borderColor: interacted && !value && compulsory ? "red" : "white",
          border:
            interacted && !value && compulsory
              ? "1px solid red"
              : "0px none white",
          outline: "none",
          padding: "14px 16px",
          backgroundColor: "rgb(250,253,255)",
          display: "flex",
        }}
      >
        {prefix && <span style={{ marginRight: "8px" }}>{prefix}</span>}
        <input
          type="text"
          ref={(input) => {
            textFieldRef = input;
          }}
          value={value}
          placeholder={placeholder}
          maxLength={max ?? Number.MAX_SAFE_INTEGER}
          style={{
            border: "none",
            outline: "none",
            width: "100%",
            padding: 0,
            backgroundColor: "rgb(250,253,255)",
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={() => setInteracted(true)}
          onKeyDown={handleKeyDown}
        />
        {suffix && (
          <span style={{ marginLeft: "8px", whiteSpace: "nowrap" }}>
            {suffix}
          </span>
        )}
      </div>
      {interacted && !value && compulsory && (
        <p style={{ color: "red", fontSize: "smaller", marginTop: "8px" }}>
          Oops... This field is required
        </p>
      )}
      {interacted && suggestion && (
        <p style={{ color: "grey", fontSize: "smaller", marginTop: "8px" }}>
          <span
            className="icon-small"
            style={{
              display: "inline-block",
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              textAlign: "center",
              marginRight: "4px",
              fontSize: "15px",
              position: "relative",
              top: "2px",
            }}
          >
            announcement
          </span>
          {suggestion}
        </p>
      )}
    </div>
  );
};

export default TextField;
