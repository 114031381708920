import { useCallback, useState } from "react";
import { wait } from "../../utils.js";
import { getExperienceFromCV, uploadFile } from "../../backend.js";
import { BeatLoader } from "react-spinners";

const CvUpload = ({
  label,
  compulsory = false,
  placeholder = "",
  onChange = () => {},
  style = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [interacted, setInteracted] = useState(false);
  const [analysing, setAnalysing] = useState(false);
  const [error, setError] = useState();
  const [inputWidth, setInputWidth] = useState(0);
  const fileFormats = [
    ".pdf",
    ".doc",
    ".docx",
    ".html",
    ".ppt",
    ".pptx",
    ".odf",
    ".txt",
    ".rtf",
  ];

  const div = useCallback(async (node) => {
    if (node !== null) {
      // Loop so it run this code every 200ms for 10 times
      for (let i = 0; i < 3; i++) {
        setInputWidth(node.getBoundingClientRect().width);
        await wait(500);
      }
    }
  }, []);

  return (
    <div className="column">
      <label
        className="main-label"
        style={{
          textTransform: "uppercase",
          fontSize: "smaller",
          fontWeight: "bold",
          margin: "0 0 8px",
          color: "#162e3c",
        }}
      >
        {label}
        {compulsory && (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              verticalAlign: "super",
              marginLeft: "4px",
            }}
          >
            *
          </span>
        )}
      </label>
      <div
        className="rounded form-text"
        style={{
          width: "100%",
          boxShadow: "0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)",
          borderColor:
            (interacted && compulsory && !uploaded && !loading && !analysing) ||
            error
              ? "red"
              : "white",
          border:
            (interacted && compulsory && !uploaded && !loading && !analysing) ||
            error
              ? "1px solid red"
              : "0px none white",
          outline: "none",
          padding: "14px 16px",
          backgroundColor: "rgb(250,253,255)",
          display: "flex",
          ...style,
        }}
        ref={div}
      >
        <div
          style={{
            width: "100%",
            display: "inline",
          }}
        >
          <p
            style={{
              margin: "auto",
              width: "fit-content",
              height: "26px",
              marginTop: "-2px",
              fontWeight: "bold",
              color: "inherit",
            }}
          >
            {loading
              ? "Uploading "
              : analysing
              ? "Analysing the CV "
              : uploaded
              ? "Uploaded"
              : placeholder
              ? placeholder
              : "Upload a file"}
            <span>
              {loading || analysing ? (
                <BeatLoader
                  size={5}
                  loading
                  color={"#49616e"}
                  style={{
                    display: "inline-block",
                    marginLeft: "8px",
                    marginTop: "2px",
                  }}
                />
              ) : (
                <span
                  className="icon-small"
                  style={{
                    display: "inline-block",
                    fontSize: "20px",
                    lineHeight: "20px",
                    height: "16px",
                    width: "20px",
                    position: "relative",
                    left: "4px",
                    top: "4px",
                  }}
                >
                  {uploaded ? "check" : "upload"}
                </span>
              )}
            </span>
          </p>
        </div>
        <input
          type="file"
          style={{
            width: inputWidth + "px",
            opacity: 0,
            cursor: "pointer",
            height: "24px",
            position: "absolute",
          }}
          onClick={async () => await wait(2000).then(() => setInteracted(true))}
          onChange={async (e) => {
            setError(null);
            setUploaded(false);
            let fileType =
              e.target.files[0]?.name.match(/\.([0-9a-z]+)(?:|$)/i)[0];
            if (!fileFormats.includes(fileType)) {
              setError(
                "Unsupported file format. Please upload a .pdf, .doc, .docx or .html"
              );
            } else if (e.target.files[0]) {
              setLoading(true);
              let fileUrl = await uploadFile(e.target.files[0]);
              let experience;
              setLoading(false);
              if (fileUrl) {
                setAnalysing(true);
                experience = await getExperienceFromCV(fileUrl);
                setAnalysing(false);
                if (
                  typeof experience === "string" &&
                  experience.includes("Error")
                ) {
                  setError(
                    "This file is not recognised as a CV. Please try a different file."
                  );
                } else {
                  setUploaded(true);
                  onChange({ url: fileUrl, candidateExperience: experience });
                }
              }
            }
          }}
        />
      </div>
      {((interacted && compulsory && !uploaded && !loading && !analysing) ||
        error) && (
        <p style={{ color: "red", fontSize: "smaller", marginTop: "8px" }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default CvUpload;
