import { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  getAuthEmployerInfo,
  getCheckoutUrl,
  getInteractionData,
  sendEmail,
} from "../backend";
import { Modal } from "../Components";
import { isMobile } from "react-device-detect";
import { MoonLoader } from "react-spinners";
import ReactTooltip from "react-tooltip";

const PRICES = { year: 150, month: 190 };

const getManagerMessage = (employer) => {
  return `Hi,\n\nIt's ${
    employer.hiringManagers?.[0]?.name ??
    employer.branches?.[0]?.hiringManagers?.[0].name
  } from ${
    employer.name
  }. I've been using hirebolt.com to hire staff lately and wanted to ask whether we could upgrade the account in order to schedule more interviews, get unlimited hires and hire for all positions.\n\nHirebolt helps to hire staff faster and reduce the admin work. They send relevant CVs of actively-looking candidates, within reasonable commute, all on WhatsApp + take care of scheduling trials and reminding candidates to show up.\n\nThey're way cheaper than agencies and also cheaper than Indeed – and they do all the admin work, saving time and money.\n\nI highly recommend we upgrade,\n\n${
    employer.hiringManagers?.[0]?.name ??
    employer.branches?.[0]?.hiringManagers?.[0].name
  }`;
};

const EmployerUpgrade = () => {
  const [employer, setEmployer] = useState();
  const [paymentFrequency, setPaymentFrequency] = useState("year");
  const [branchSelectionOpen, setBranchSelectionOpen] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [interactionData, setInteractionData] = useState();
  const [sharePageOpen, setSharePageOpen] = useState(false);
  const [managerEmail, setManagerEmail] = useState();
  const [managerMessage, setManagerMessage] = useState("Your message");
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let [e, data] = await Promise.all([
        getAuthEmployerInfo(
          window.location.href.split("/")[3],
          window.location.href.split("/")[4]
        ),
        getInteractionData(
          window.location.href.split("/")[3],
          null,
          moment("01/01/2000", "DD/MM/YYYY"),
          false
        ),
      ]);
      setEmployer(e);
      setManagerMessage(getManagerMessage(e));
      setSelectedBranches(e.branches.map((b) => getBranchName(b)));
      setInteractionData(data);
    }
    fetchData();
    //eslint-disable-next-line
  }, []);

  const redirectToCheckout = async () => {
    let unitPrice =
      PRICES[paymentFrequency] * (selectedPlan === "premium" ? 3 : 1);
    let checkoutUrl = await getCheckoutUrl(
      employer.id,
      selectedBranches,
      employer.branches
        .filter((b) => selectedBranches.includes(getBranchName(b)))
        .map((b) => b.id),
      paymentFrequency,
      unitPrice
    );
    window.location.href = checkoutUrl;
  };

  const sendEmailToManager = () => {
    if (managerEmail && managerMessage) {
      console.log(managerMessage);
      sendEmail(managerEmail, managerMessage, "ask_to_upgrade", {
        employerId: employer.id,
      });
      setEmailSent(true);
    }
  };

  const showBranchSelection = async (plan) => {
    if (!employer) return;
    if (employer.branches.length > 1) {
      setSelectedPlan(plan);
      setBranchSelectionOpen(true);
    } else {
      await redirectToCheckout();
    }
  };

  const getBranchName = (branch) => {
    return branch.name && branch.name.length > 1
      ? branch.name
      : branch.address && branch.address.length > 1
      ? branch.address.split(",")[0]
      : "";
  };

  return (
    <section className="section-pricing">
      <Modal
        isOpen={branchSelectionOpen}
        closeModal={() => {
          setBranchSelectionOpen(false);
        }}
      >
        <div style={{ padding: "16px" }}>
          <h4
            style={{
              margin: "0 0 32px",
              marginTop: "32px",
              paddingRight: "32px",
            }}
          >
            Branches to upgrade:
          </h4>
          {employer &&
            employer.branches.map((branch, i) => {
              return (
                <div style={{ width: "100%", fontSize: "larger" }}>
                  <div
                    key={getBranchName(branch)}
                    style={{ display: "flex", marginTop: "10px" }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedBranches.includes(getBranchName(branch))}
                      onChange={() => {
                        let currentSelection = JSON.parse(
                          JSON.stringify(selectedBranches)
                        );
                        if (currentSelection.includes(getBranchName(branch))) {
                          const index = currentSelection.indexOf(
                            getBranchName(branch)
                          );
                          if (index > -1) currentSelection.splice(index, 1);
                        } else {
                          currentSelection.push(getBranchName(branch));
                        }
                        setSelectedBranches(currentSelection);
                      }}
                      style={{
                        marginRight: "10px",
                        width: "24px",
                        height: "24px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        backgroundColor: selectedBranches.includes(
                          getBranchName(branch)
                        )
                          ? "#162e3c"
                          : "white",
                      }}
                    />
                    <label style={{ lineHeight: "24px" }}>
                      {getBranchName(branch)}
                    </label>
                  </div>
                  {!selectedBranches.includes(getBranchName(branch)) && (
                    <p
                      style={{
                        fontSize: "smaller",
                        padding: "10px",
                        marginLeft: "30px",
                        marginTop: "8px",
                        backgroundColor: "rgba(0,0,0,0.1)",
                        borderRadius: "7px",
                      }}
                    >
                      You will only be able to schedule
                      <br />1 interview/week for this venue
                    </p>
                  )}
                </div>
              );
            })}
          {selectedBranches.length > 0 && (
            <button
              className="button w-button"
              onClick={() => redirectToCheckout()}
              style={{
                width: "100%",
                borderRadius: "10px",
                marginTop: "75px",
                fontSize: "16px",
              }}
            >
              Upgrade for £
              {PRICES[paymentFrequency] *
                selectedBranches.length *
                (paymentFrequency === "year" ? 12 : 1) *
                (selectedPlan === "premium" ? 3 : 1)}
              /{paymentFrequency}
            </button>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={sharePageOpen}
        closeModal={() => {
          setSharePageOpen(false);
        }}
      >
        <div
          style={{
            padding: isMobile ? 0 : "25px",
            maxWidth: "750px",
            textAlign: "center",
          }}
        >
          <h6 style={{ marginBottom: "25px" }}>
            Ask your manager or business owner{isMobile ? " " : <br />}to
            upgrade this account
          </h6>
          <p>
            Insert their email address below and adjust the message as you
            prefer
          </p>
          <div style={{ width: "100%", textAlign: "left", marginTop: "25px" }}>
            <input
              type="text"
              className="rounded form-text"
              value={managerEmail}
              placeholder="Email Address"
              autoFocus
              style={{ width: "300px", maxWidth: "100%" }}
              onChange={(e) => {
                setManagerEmail(e.target.value);
              }}
            />
            <textarea
              className="rounded form-text"
              value={managerMessage}
              rows={18}
              style={{
                width: "100%",
                maxWidth: "100%",
                marginTop: "15px",
              }}
              onChange={(e) => {
                setManagerMessage(e.target.value);
              }}
            />
            {emailSent ? (
              <p
                style={{
                  marginTop: "25px",
                  fontSize: "larger",
                  fontWeight: "bold",
                }}
              >
                ✅ Email sent
              </p>
            ) : (
              <button
                className="button w-button"
                style={{
                  width: "300px",
                  maxWidth: "100%",
                  borderRadius: "10px",
                  padding: "0.5rem",
                  marginTop: "25px",
                }}
                onClick={() => sendEmailToManager()}
              >
                Send
              </button>
            )}
          </div>
        </div>
      </Modal>
      <div className="page-padding">
        {employer && interactionData ? (
          <div className="container-large">
            <div>
              <div
                className="pricing-wrapper"
                style={{ textAlign: "center", display: "flex" }}
              >
                <h4>Get unlimited hires 🚀</h4>
                <div
                  style={{
                    backgroundColor: "#efefef",
                    maxWidth: "100%",
                    height: "fit-content",
                    margin: "35px auto",
                    padding: "5px",
                    borderRadius: "50px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      backgroundColor:
                        paymentFrequency === "year" ? "white" : "transparent",
                      maxWidth: "50%",
                      width: "200px",
                      padding: "5px 25px",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setPaymentFrequency("year")}
                  >
                    Annually <span style={{ color: "#9bc81c" }}>-20%</span>
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        paymentFrequency === "month" ? "white" : "transparent",
                      maxWidth: "50%",
                      width: "200px",
                      padding: "5px 25px",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setPaymentFrequency("month")}
                  >
                    Monthly
                  </div>
                </div>
                <div className="margin-top margin-medium is-tablet-0">
                  <div className="w-layout-grid pricing-plans">
                    {!isMobile && (
                      <div
                        className="pricing-plan"
                        style={{
                          backgroundColor: "#efefef",
                          position: "relative",
                          width: isMobile ? "100%" : "27%",
                        }}
                      >
                        <div className="pricing-content">
                          <div className="pricing-content-top">
                            <div style={{ textAlign: "left" }}>
                              <div className="heading-xlarge text-color-bluemine">
                                Free
                              </div>
                              <p>Your basic plan</p>
                              <div
                                className="button-row"
                                style={{
                                  margin: "0",
                                  width: "100%",
                                  visibility: "hidden",
                                  height: "1px",
                                }}
                              >
                                <a
                                  href="/"
                                  className="button w-button"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    padding: "0.5rem",
                                  }}
                                >
                                  Upgrade
                                </a>
                                <button className="link-secondary w-inline-block">
                                  <div
                                    style={{
                                      borderBottom: "1px solid",
                                      padding: "0 0 3px",
                                    }}
                                  >
                                    Or ask your manager to pay
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div
                              className="pricing-feature-list link-secondary"
                              style={{
                                textAlign: "left",
                                marginBottom: 0,
                                fontSize: "14px",
                                gap: "8px",
                              }}
                            >
                              <div className="pricing-feature">
                                <div
                                  className="pricing-feature-icon-wrapper"
                                  style={{ background: "transparent" }}
                                >
                                  <div className="icon-xsmall">check</div>
                                </div>
                                <div className="text-weight-semibold link-secondary">
                                  1 interview/week
                                </div>
                              </div>
                              <div className="pricing-feature">
                                <div
                                  className="pricing-feature-icon-wrapper"
                                  style={{ background: "transparent" }}
                                >
                                  <div className="icon-xsmall">check</div>
                                </div>
                                <div className="text-weight-semibold link-secondary">
                                  Hire for Front-of-House
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="pricing-plan most-popular"
                      style={{
                        width: isMobile ? "100%" : "30%",
                        marginTop: "-2rem",
                      }}
                    >
                      <div className="pricing-content">
                        <div className="pricing-content-top">
                          <div style={{ textAlign: "left" }}>
                            <div className="heading-xxlarge text-color-bluemine">
                              <span className="heading-medium">£</span>
                              {PRICES[paymentFrequency]}
                              <span className="heading-medium">/mo</span>
                            </div>
                            <p>
                              <b>Price per venue</b>, billed{" "}
                              {paymentFrequency === "year"
                                ? "annually"
                                : "monthly"}
                            </p>
                          </div>
                          <div
                            className="button-row"
                            style={{
                              margin: "25px 0",
                              width: "100%",
                            }}
                          >
                            <button
                              className="button w-button"
                              onClick={() => {
                                showBranchSelection("paid");
                              }}
                              style={{
                                width: "100%",
                                borderRadius: "10px",
                                padding: "0.5rem",
                                color: "#ddf0a7",
                              }}
                            >
                              Upgrade
                            </button>
                            <button
                              className="link-secondary w-inline-block"
                              onClick={() => setSharePageOpen(true)}
                            >
                              <div
                                style={{
                                  borderBottom: "1px solid",
                                  padding: "0 0 3px",
                                }}
                              >
                                Or ask your manager to pay
                              </div>
                            </button>
                          </div>
                          <div
                            className="pricing-feature-list link-secondary"
                            style={{
                              textAlign: "left",
                              marginBottom: 0,
                              fontSize: "14px",
                              gap: "8px",
                            }}
                          >
                            <b>All Free features, plus:</b>
                            <div className="pricing-feature">
                              <div className="pricing-feature-icon-wrapper">
                                <div className="icon-xsmall">check</div>
                              </div>
                              <div className="text-weight-semibold link-secondary">
                                Schedule unlimited interviews
                              </div>
                            </div>
                            <div className="pricing-feature">
                              <div className="pricing-feature-icon-wrapper">
                                <div className="icon-xsmall">check</div>
                              </div>
                              <div className="text-weight-semibold link-secondary">
                                See candidate contact details
                              </div>
                            </div>
                            <div className="pricing-feature">
                              <div className="pricing-feature-icon-wrapper">
                                <div className="icon-xsmall">check</div>
                              </div>
                              <div className="text-weight-semibold link-secondary">
                                Manager roles
                              </div>
                            </div>
                            <div className="pricing-feature">
                              <div className="pricing-feature-icon-wrapper">
                                <div className="icon-xsmall">check</div>
                              </div>
                              <div className="text-weight-semibold link-secondary">
                                Autopilot Hiring{" "}
                                <span
                                  className="icon-large"
                                  data-tip="Vetted candidates automatically show up at your
                venue during the provided time slots. You can
                reschedule/reject them in advance"
                                  data-for="autopilot-tooltip"
                                  style={{
                                    display: "inline",
                                    fontSize: "17px",
                                    lineHeight: "17px",
                                    height: "17px",
                                    width: "17px",
                                    position: "relative",
                                    left: "4px",
                                    opacity: ".5",
                                    cursor: "pointer",
                                  }}
                                >
                                  help
                                </span>
                                <ReactTooltip
                                  effect="solid"
                                  id="autopilot-tooltip"
                                  place="bottom"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isMobile && (
                      <div
                        className="pricing-plan"
                        style={{
                          backgroundColor: "#efefef",
                          position: "relative",
                          width: isMobile ? "100%" : "27%",
                        }}
                      >
                        <div className="pricing-content">
                          <div className="pricing-content-top">
                            <div style={{ textAlign: "left" }}>
                              <div className="heading-xlarge text-color-bluemine">
                                <span className="heading-medium">£</span>
                                {PRICES[paymentFrequency] * 3}
                                <span className="heading-medium">/mo</span>
                              </div>
                              <p>
                                <b>Price per venue</b>, billed{" "}
                                {paymentFrequency === "year"
                                  ? "annually"
                                  : "monthly"}
                              </p>
                              <div
                                className="button-row"
                                style={{
                                  margin: "25px 0",
                                  width: "100%",
                                }}
                              >
                                <button
                                  className="button w-button"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    padding: "0.5rem",
                                    color: "#efefef",
                                  }}
                                  onClick={() => showBranchSelection("premium")}
                                >
                                  Upgrade
                                </button>
                                <button
                                  className="link-secondary w-inline-block"
                                  onClick={() => setSharePageOpen(true)}
                                >
                                  <div
                                    style={{
                                      borderBottom: "1px solid",
                                      padding: "0 0 3px",
                                    }}
                                  >
                                    Or ask your manager to pay
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div
                              className="pricing-feature-list link-secondary"
                              style={{
                                textAlign: "left",
                                marginBottom: 0,
                                fontSize: "14px",
                                gap: "8px",
                              }}
                            >
                              <b>All Paid features, plus:</b>
                              <div className="pricing-feature">
                                <div
                                  className="pricing-feature-icon-wrapper"
                                  style={{ background: "transparent" }}
                                >
                                  <div className="icon-xsmall">check</div>
                                </div>
                                <div className="text-weight-semibold link-secondary">
                                  We manage all hiring platforms
                                </div>
                              </div>
                              <div className="pricing-feature">
                                <div
                                  className="pricing-feature-icon-wrapper"
                                  style={{ background: "transparent" }}
                                >
                                  <div className="icon-xsmall">check</div>
                                </div>
                                <div className="text-weight-semibold link-secondary">
                                  Support team member
                                </div>
                              </div>
                              <div className="pricing-feature">
                                <div
                                  className="pricing-feature-icon-wrapper"
                                  style={{ background: "transparent" }}
                                >
                                  <div className="icon-xsmall">check</div>
                                </div>
                                <div className="text-weight-semibold link-secondary">
                                  Bespoke candidate sourcing
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container-large"
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                margin: "35px 0",
              }}
            >
              {interactionData &&
                [
                  {
                    id: "candidates_sent_total",
                    title: interactionData.sentTimes,
                    value: interactionData.sentTimes,
                    threshold: 10,
                    description: "candidates sent to your business so far",
                  },
                  {
                    id: "trials_scheduled",
                    title: interactionData.scheduledTimes,
                    value: interactionData.scheduledTimes,
                    threshold: 3,
                    description: "Interviews scheduled",
                  },
                  {
                    id: "time_saved",
                    title: `${interactionData.scheduledTimes * 2}hrs`,
                    value: interactionData.scheduledTimes * 2,
                    threshold: 5,
                    description: "of time saved to find and select candidates",
                  },
                  {
                    id: "money_saved",
                    title: `£${20 * interactionData.scheduledTimes * 2}`,
                    value: 20 * interactionData.scheduledTimes * 2,
                    threshold: 100,
                    description: "saved by wasting less time on admin work",
                  },
                  {
                    id: "showup_rate_increase",
                    title: `20%`,
                    value: 20,
                    threshold: 10,
                    description:
                      "Increase in candidates showing up for interviews",
                  },
                ].map((section, i, sections) => {
                  if (section.value < section.threshold) return <div></div>;
                  return (
                    <div
                      id={section.id}
                      style={{
                        flexGrow: 1,
                        height: "fit-content",
                        textAlign: "center",
                        padding: "8px 24px",
                        borderRight:
                          !isMobile &&
                          (i ===
                          sections.filter((s) => s.value >= s.threshold)
                            .length -
                            1
                            ? "none"
                            : "1px solid #efefef"),
                        borderBottom:
                          isMobile &&
                          (i ===
                          sections.filter((s) => s.value >= s.threshold)
                            .length -
                            1
                            ? "none"
                            : "1px solid #efefef"),
                      }}
                    >
                      <h4
                        style={{
                          opacity: ".55",
                        }}
                      >
                        {section.title}
                      </h4>
                      <p
                        style={{
                          opacity: ".8",
                        }}
                      >
                        {section.description}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <div style={{ height: "100vh", width: "100%", display: "flex" }}>
            <div
              style={{
                width: "fit-content",
                height: "fit-content",
                margin: "auto",
              }}
            >
              <MoonLoader color={"rgb(22, 46, 60)"} loading={true} size={50} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default EmployerUpgrade;
