import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { recordReferralInteraction } from "../backend";
import ReactTooltip from "react-tooltip";

const Referrals = ({ candidateId, touchpointId, style }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (touchpointId) {
      recordReferralInteraction("view", touchpointId, candidateId);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div style={{ ...style, maxWidth: "100vw !important" }}>
      <h4>
        Get up to <span style={{ borderBottom: "3px solid" }}>£100</span> for
        every friend you invite
      </h4>
      <p style={{ maxWidth: isMobile ? "100%" : "500px", marginTop: "10px" }}>
        Invite colleagues and friends by sharing the link below. Receive up to
        £100 each, when they get hired.{" "}
        <span
          data-tip={
            "The value of the reward depends on the role they get hired for. Rewards are: Supervisor/Manager (£75), any other role (£50)"
          }
          data-for="more-info"
          data-event="click"
          style={{ fontWeight: "bold", cursor: "pointer" }}
        >
          More info.
        </span>
      </p>
      <ReactTooltip
        effect="solid"
        id="more-info"
        place="top"
        globalEventOff="click"
      />
      {isMobile ? (
        <div style={{ marginTop: "50px", paddingBottom: "48px" }}>
          <button
            onClick={() => {
              navigator.share({
                title: "Invite a friend/colleague to Hirebolt",
                text: "Hey, have a look at Hirebolt. It helps you find a job in hospitality by getting employers to reach out to you, so you don't even have to send applications\n",
                url: `https://www.hirebolt.com/candidate-form?ref=${candidateId}`,
              });
              recordReferralInteraction("engage", touchpointId, candidateId);
            }}
            style={{
              float: "left",
              fontWeight: "bold",
              padding: "15px 25px",
              backgroundColor: "#162e3c",
              color: "#efefef",
              borderRadius: "5px",
              display: "inline-block",
              position: "relative",
              bottom: "15px",
            }}
          >
            Share your Link{" "}
            <span
              className="icon-small"
              style={{
                display: "inline-block",
                fontSize: "18px",
                position: "relative",
                lineHeight: "18px",
                top: "3px",
                height: "18px",
                width: "21px",
              }}
            >
              share
            </span>
          </button>
        </div>
      ) : (
        <div
          style={{ width: "500px", margin: "25px 0", paddingBottom: "48px" }}
        >
          <p style={{ marginBottom: "25px", fontWeight: "bold" }}>
            Share your Link:
          </p>
          <div
            style={{
              display: "inline",
              backgroundColor: "#efefef",
              padding: "17px",
              borderRadius: "5px",
            }}
          >
            {`https://www.hirebolt.com/candidate-form?ref=${candidateId}`.substring(
              0,
              40
            ) + "..."}
            <button
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://www.hirebolt.com/candidate-form?ref=${candidateId}`
                );
                recordReferralInteraction("engage", touchpointId, candidateId);
                setCopied(true);
              }}
              style={{
                float: "right",
                fontWeight: "bold",
                padding: "15px 25px",
                backgroundColor: "#162e3c",
                color: "#efefef",
                borderRadius: "5px",
                display: "inline-block",
                position: "relative",
                bottom: "15px",
                width: "116px",
              }}
            >
              {copied ? "" : "Copy "}
              <span
                className="icon-small"
                style={{
                  display: "inline-block",
                  fontSize: "18px",
                  position: "relative",
                  lineHeight: "18px",
                  top: "3px",
                  height: "18px",
                  width: "21px",
                }}
              >
                {copied ? "done" : "copy"}
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Referrals;
