import moment from "moment-timezone";

const BACKEND_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:5000/"
    : "https://hiring-mvp.herokuapp.com/";

export const getBranch = async (employerId) => {
  let data = await fetch(BACKEND_URL + "employer/" + employerId, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const getAuthEmployerInfo = async (employerId, secret) => {
  let data = await fetch(
    BACKEND_URL + "employer/" + employerId + "/" + secret,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const getCandidateInfo = async (
  candidateId,
  hideContactDetails = false
) => {
  let data = await fetch(
    BACKEND_URL +
      "candidate/" +
      candidateId +
      (hideContactDetails ? "?hide_contact_details=true" : ""),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export const candidateExists = async (identifier) => {
  let data = await fetch(BACKEND_URL + "candidate/exists/" + identifier, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export const getInterviewInfo = async (interviewId) => {
  let data = await fetch(BACKEND_URL + "interview/" + interviewId, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
};

export const sendInterviewConfirmation = async (
  employerId,
  candidateId,
  role,
  interviewTimestamp
) => {
  await fetch(BACKEND_URL + "interview/confirmed", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      interviewTimestamp: interviewTimestamp,
      candidateId: candidateId,
      employerId: employerId,
      role: role,
    }),
  })
    .then((response) => console.log(response))
    .catch((error) => {
      console.log(error);
    });
};

export const sendReschedulingConfirmation = async (
  interviewId,
  newTimestamp,
  candidateId = null,
  employerId = null,
  notifyBoth = true
) => {
  await fetch(BACKEND_URL + "interview/rescheduled", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      interviewId,
      newTimestamp,
      candidateId,
      employerId,
      notifyBoth,
    }),
  })
    .then((response) => console.log(response))
    .catch((error) => {
      console.log(error);
    });
};

export const getInterviewSlots = async (employerId, roleId) => {
  let data = await fetch(
    BACKEND_URL + "employer/interview-slots/" + employerId + "/" + roleId,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const startConversation = async (phoneNumber, messageBody) => {
  await fetch(BACKEND_URL + "messagebird/start-conversation", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      phoneNumber: phoneNumber,
      messageBody: messageBody,
    }),
  })
    .then((response) => console.log(response))
    .catch((error) => {
      console.log(error);
    });
};

export const cancelInterview = async (
  employerId,
  candidateId,
  interviewTimestamp,
  cancelledByEmployer
) => {
  await fetch(BACKEND_URL + "interview/cancelled", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employerId,
      candidateId,
      interviewTimestamp,
      cancelledByEmployer,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const cancelInterviewById = async (interviewId, cancelledByEmployer) => {
  let interview = await getInterviewInfo(interviewId);
  await fetch(BACKEND_URL + "interview/cancelled", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employerId: interview["Employer Id"],
      candidateId: interview["Candidate Id"],
      interviewTimestamp: interview["Date"],
      cancelledByEmployer,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const trackPageView = async (employerId, candidateId) => {
  await fetch(BACKEND_URL + "interaction/track-page-view", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employerId: employerId,
      candidateId: candidateId,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const getAllBranches = async () => {
  let employers = await fetch(BACKEND_URL + "employer/list-branches", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    })
    .catch((error) => {
      console.log(error);
    });
  return employers;
};

export const getOnboardingData = async () => {
  let employers = await fetch(BACKEND_URL + "employer/onboarding-data", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return employers;
};

export const getAllEmployers = async () => {
  let employers = await fetch(BACKEND_URL + "employer/list", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    })
    .catch((error) => {
      console.log(error);
    });
  return employers;
};

export const getInteractionData = async (
  employerId,
  candidateId = null,
  startDate = null,
  isBranch = true
) => {
  let data = await fetch(
    BACKEND_URL +
      "interaction/" +
      employerId +
      "/" +
      (candidateId ?? "all") +
      "/" +
      (startDate ? moment(startDate).format("DDMMYYYY") : "default") +
      `/${isBranch}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const getCandidatesSentToEmployer = async (employerId) => {
  let data = await fetch(BACKEND_URL + "interaction/" + JSON.stringify(employerId), {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const getSupplyBlockers = async (employerId) => {
  let data = await fetch(
    BACKEND_URL + "employer/supply-blockers/" + employerId,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const getCandidateStats = async (employerId) => {
  let data = await fetch(BACKEND_URL + "candidate/stats", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const sendCandidateInterest = async (
  employerId,
  candidateId,
  roleId
) => {
  let data = await fetch(
    BACKEND_URL + `candidate/match/${employerId}/${candidateId}/${roleId}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const rejectCandidate = async (employerId, candidateId, roleId) => {
  let data = await fetch(
    BACKEND_URL + `candidate/reject/${employerId}/${candidateId}/${roleId}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const getWeelyInteractionStats = async () => {
  let data = await fetch(BACKEND_URL + `interaction/weekly-stats`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const recordCandidateNotInterested = async (candidateId, employerId) => {
  await fetch(BACKEND_URL + "interaction/not-interested", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employerId: employerId,
      candidateId: candidateId,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const recordNotInterestedReasons = async (
  employerId,
  candidateId,
  reasons
) => {
  await fetch(BACKEND_URL + "interaction/not-interested-reasons", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employerId: employerId,
      candidateId: candidateId,
      reasons: reasons,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const recordCandidateCalled = async (employerId, candidateId) => {
  await fetch(BACKEND_URL + "interaction/call", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employerId: employerId,
      candidateId: candidateId,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const setNewEmployer = async (employer) => {
  await fetch(BACKEND_URL + "employer/new", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employer: employer,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const updateEmployer = async (employer) => {
  await fetch(BACKEND_URL + "employer/update", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employer: employer,
    }),
  }).catch((error) => {
    console.log(error);
  });
};

export const updateBranch = async (branch) => {
  return await fetch(BACKEND_URL + "employer/update-branch", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      branch: branch,
    }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error);
    });
};

export const updateCandidate = async (properties) => {
  await fetch(BACKEND_URL + "candidate/update", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(properties),
  }).catch((error) => {
    console.log(error);
  });
};

// Record all candidate interactions with our referral engine
// interactionId (string): 'view', 'engage', 'open_link'
// touchpointId (string): 'scheduling_page', 'rescheduling_page', 'welcome_message', 'interview_confirmation_message'
export const recordReferralInteraction = async (
  interactionId,
  touchpointId,
  referrerId
) => {
  await fetch(BACKEND_URL + `interaction/referrals`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ interactionId, touchpointId, referrerId }),
  }).catch((error) => {
    console.log(error);
  });
};

export const sendEmployerWelcomeMessage = async (employer, senderName) => {
  await fetch(BACKEND_URL + `messagebird/welcome-message`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ employer, senderName }),
  }).catch((error) => {
    console.log(error);
  });
};

export const sendCandidateToEmployer = async (branchId, candidateId) => {
  await fetch(BACKEND_URL + `employer/interested/${branchId}/${candidateId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  }).catch((error) => {
    console.log(error);
  });
};

export const getRelevantOpportunities = async (candidate, branches) => {
  return await fetch(BACKEND_URL + "candidate/opportunities", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ candidate, branches }),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error);
    });
};

export const getOpportunitiesById = async (candidateId) => {
  return await fetch(BACKEND_URL + `candidate/opportunities/${candidateId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error);
    });
};

export const recordCandidateInterested = async ({
  candidate,
  interestingEmployers,
}) => {
  await fetch(BACKEND_URL + "candidate/" + candidate["Id"] + "/set-interests", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(interestingEmployers),
  }).catch((error) => {
    console.log(error);
  });
};

export const getInterviewStatus = async (candidateId, employerId, role) => {
  let data = await fetch(
    BACKEND_URL + "interview/" + candidateId + "/" + employerId + "/" + role,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  let url = await fetch(BACKEND_URL + "candidate/upload-cv", {
    method: "POST",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((json) => json.fileUrl);
  return url;
};

export const createNewCandidate = async (candidate) => {
  return await fetch(BACKEND_URL + "candidate/new", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(candidate),
  })
    .then((res) => res.json())
    .catch((error) => {
      console.log(error);
    });
};

export const sendReschedulingRequest = async (candidateId, branchId) => {
  await fetch(BACKEND_URL + "interview/rescheduling-request", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ candidateId, branchId }),
  })
    .then((response) => console.log(response))
    .catch((error) => {
      console.log(error);
    });
};

export const fetchNearbyEmployers = async (candidate) => {
  let data = await fetch(BACKEND_URL + "candidate/nearby-employers/", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ candidate: candidate }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const pauseContact = async (candidateId, duration) => {
  await fetch(BACKEND_URL + "candidate/pause-contact", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ candidateId, duration }),
  })
    .then((response) => console.log(response))
    .catch((error) => {
      console.log(error);
    });
};

export const pauseContactFoundJob = async (candidateId, duration) => {
  await fetch(BACKEND_URL + "candidate/pause-contact", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ candidateId }),
  })
    .then((response) => console.log(response))
    .catch((error) => {
      console.log(error);
    });
};

export const reactivateEmployer = async (employer) => {
  await fetch(BACKEND_URL + "employer/set-active", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employer: employer,
    }),
  })
    .then((response) => console.log(response))
    .catch((error) => {
      console.log(error);
    });
};

export const getInterviews = async (employerIds) => {
  let data = await fetch(
    BACKEND_URL + "interview/get/" + JSON.stringify(employerIds),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const getCheckoutUrl = async (
  employerId,
  branchNames,
  branchIds,
  paymentFrequency,
  unitPrice
) => {
  let url = await fetch(BACKEND_URL + "stripe/get-payment-link", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      employerId,
      branchNames,
      branchIds,
      paymentFrequency,
      unitPrice,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return url;
};

// Send an email message to the specified email address
export const sendEmail = async (address, message, template, otherVariables) => {
  await fetch(BACKEND_URL + `postmark/send`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ address, message, template, otherVariables }),
  }).catch((error) => {
    console.log(error);
  });
};

// Get the IDs of premium branches for a given employer
export const getPremiumbranches = async (employer) => {
  if (!employer.subscriptionId) return [];
  let data = await fetch(
    BACKEND_URL + "stripe/active-branches/" + employer.subscriptionId,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const updateInterview = async (data) => {
  await fetch(BACKEND_URL + "interview/" + data.id, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ properties: data.properties }),
  }).catch((error) => {
    console.log(error);
  });
};

export const getInterviewsThisWeek = async (branchId) => {
  let data = await fetch(BACKEND_URL + "interview/this-week/" + branchId, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const loginCandidate = (email, number) => {
  return fetch(BACKEND_URL + "candidate/login", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ email, number }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCandidateInterviews = async (candidateId) => {
  let data = await fetch(BACKEND_URL + "interview/candidate/" + candidateId, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
};

export const interviewReminderResponse = async (
  candidateId,
  interviewId,
  response
) => {
  await fetch(BACKEND_URL + `interview/reminder-response`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ candidateId, interviewId, response }),
  }).catch((error) => {
    console.log(error);
  });
};

export const uploadCandidateCV = async (candidateId, cvURL) => {
  await fetch(BACKEND_URL + `candidate/upload-cv/later`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({ candidateId, cvURL }),
  }).catch((error) => {
    console.log(error);
  });
};

export const getExperienceFromCV = async (url) => {
  let data = await fetch(BACKEND_URL + `candidate/get-experience`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ url }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.log(error));
  return data;
};

export const getLanguages = async () => {
  let data = await fetch(BACKEND_URL + `utils/languages`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.log(error));
  return data;
};

export const getSkills = async () => {
  let data = await fetch(BACKEND_URL + `utils/skills`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.log(error));
  return data;
};

export const getSignupCheck = async () => {
  let check = await fetch(BACKEND_URL + "admin/check/candidate-signup", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return check;
};

export const getOpportunitiesCheck = async () => {
  let check = await fetch(BACKEND_URL + "admin/check/shown-opportunities", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return check;
};

// export const getSentCandidatesCheck = async () => {
//   let check = await fetch(BACKEND_URL + "admin/check/sent-to-employers", {
//     method: "GET",
//     mode: "cors",
//     headers: {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//     },
//   })
//       .then((response) => response.json())
//       .catch((error) => {
//         console.log(error);
//       });
//   return check;
// };

export const getEmployerReceivesCandidatesCheck = async () => {
  let check = await fetch(BACKEND_URL + "admin/check/sent-to-employers", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return check;
};

export const getInterviewReminderCheck = async () => {
  let check = await fetch(BACKEND_URL + "admin/check/reminder-interview", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return check;
};

export const getEmployerContactCandidatesCheck = async () => {
  let check = await fetch(
    BACKEND_URL + "admin/check/employer-contact-candidates",
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return check;
};

export const getEmployerRecapCheck = async () => {
  let check = await fetch(BACKEND_URL + "admin/check/employer-recap", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return check;
};

export const getInterviewConfirmationsCheck = async () => {
  let check = await fetch(BACKEND_URL + "admin/check/interview-confirmations", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return check;
};

export const getReEngagementBranches = async () => {
  let employers = await fetch(BACKEND_URL + "employer/re-engagement", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return employers;
};

export const getLastWeeksOnboards = async () => {
  let employers = await fetch(BACKEND_URL + "employer/last-week", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log(error);
    });
  return employers;
};
